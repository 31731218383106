<app-popup id="deposit-popup">
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <img src="./assets/images/subscribe.png" class="image">
    <div class="block-title">Transfer money</div>

    <mat-form-field class="transfer-money__amount">
      <mat-label>Amount</mat-label>
      <input matInput [formControl]="amountCtrl" [errorStateMatcher]="errorMatcher(amountCtrl)">
    </mat-form-field>
    <div class="transfer-money__payment-type">
      <div class="payment-item" [class.active]="paymentMethod === 'creditCard'"
           (click)="selectPaymentMethod('creditCard')">
        <img src="./assets/images/cards.png">
      </div>
      <div class="payment-item payment-klarna" [class.active]="paymentMethod === 'sofort'"
           [class.disabled]="amountCtrl?.value < 10 "
           (click)="selectPaymentMethod('sofort', amountCtrl?.value < 10)">


        <div class="klarna-logo" [class.klarnaMore10]="amountCtrl?.value >= 10">
          <img class="klarna-img" src="./assets/images/klarna-tr.png">
          <span class="payment-item-text-pay ">Pay Now</span>
        </div>
        <div class="payment-item-text klarna-text" *ngIf="amountCtrl?.value < 10">starting at {{10 | localeCurrency: null: 'symbol': '1.0-0'}}
        </div>
      </div>
      <div class="payment-item" [class.active]="paymentMethod === 'maestro'"
           (click)="selectPaymentMethod('maestro')">
        <img class="maestro-logo" src="./assets/images/maestro_logo.png">
      </div>
      <div class="direct-payment flex" [class.disablePayment]="!amountCtrl?.value || disableProceedPay">
        <div class="google-pay">
<!--          <google-pay-button-->
<!--            [environment]="envGoogleType"-->
<!--            buttonType="buy"-->
<!--            buttonSizeMode="fill"-->
<!--            [style.height.px]="45"-->
<!--            [style.width.px]="googleWidth"-->
<!--            buttonColor="black"-->
<!--            [paymentRequest]="paymentRequest"-->
<!--            (loadpaymentdata)="onLoadPaymentDataG($event)"-->
<!--            [errorCallback]="errorHandlerGoogle"-->
<!--            [paymentAuthorizedCallback]="onPaymentDataAuthorized"-->
<!--          >-->
<!--          </google-pay-button>-->
        </div>
      </div>
      <button type="button" id="deposit" class="button yellow transfer-money__button" (click)="submit()"
              [disabled]="!paymentMethod || !form?.valid || disableProceedPay">
        <mat-spinner [diameter]="30" *ngIf="submitPressed"></mat-spinner>
        <span>Proceed</span>
      </button>
    </div>
  </div>

</app-popup>
