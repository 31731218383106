import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EMAIL_PATTERN} from '../../../common';
import {AuthenticationService} from '../../../services/authentication.service';
import {AppToastrService } from '../../../services';

@Component({
  selector: 'app-subscribe-newsletter',
  templateUrl: './subscribe-newsletter.html'
})
export class SubscribeNewsletterComponent implements OnInit {

  @Input() type: 'default' | 'new' = 'default';

  emailCtrl: FormControl;
  form: FormGroup;
  submitPressed: boolean;
  successMessage: boolean = false;

  constructor(private fb: FormBuilder,
              private toast: AppToastrService,
              private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.emailCtrl = this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
    this.form = this.fb.group({
      email: this.emailCtrl
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitPressed = true;
      const data = this.form.value;
      const body = {
        email: this.emailCtrl.value,
        firstName: this.emailCtrl.value.split('@')[0]
      };
      this.authenticationService.subscribeVisitor(body).subscribe((res) => {
        this.submitPressed = false;
        this.successMessage = true;
        this.toast.showToast({title: 'You have been subscribed to our newsletter'});
      }, (err) => {
        this.submitPressed = false;
        this.toast.showErrorToast({});
      });
    }
  }
}
