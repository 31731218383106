interface ICompanyTypes {
  business?: string;
  organization?: string;
  soletrader?: string;
}

export class KycTypeDocuments {
  constructor() {
  }

  identityProof: Array<string>;
  articleOfAssosiation: Partial<ICompanyTypes>;
  registrationProof: ICompanyTypes;

  setFromApiModel(apiModel: any): this {
    this.identityProof = apiModel.IDENTITY_PROOF || [];
    this.articleOfAssosiation = apiModel.ARTICLES_OF_ASSOCIATION || {};
    this.articleOfAssosiation.soletrader = this.articleOfAssosiation.soletrader ? this.articleOfAssosiation.soletrader : '';
    this.registrationProof = apiModel.REGISTRATION_PROOF;
    return this;
  }
}
