import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DOC_TYPE} from 'app/common';

@Component({
  selector: 'app-upload-file-popup',
  templateUrl: './upload-file-popup.html'
})
export class UploadFilePopupComponent implements OnInit{
  @Input() isSEP: any;
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  form: FormGroup;
  submitPressed: boolean;
  submitting: boolean;
  documentType: any[];
  innerWidth: any;
  isMobileScreen = false;

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  ngOnInit() {
    this.documentType = DOC_TYPE;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isMobileScreen = true;
    }
  }


  onClose() {
    this.notify.emit({type: 'close'});
  }
  onNotify(e) {
    if (e.type === 'close') {
      this.notify.emit({type: 'close'});
    }
    if (e.type === 'closeUpload') {
      this.notify.emit({type: 'closeUpload'});
    }
  }
}
