<div class="cookies-popup" [class.settings]="openSettings" *ngIf="showCookies">
  <div class="container" [class.settings]="openSettings">
    <div class="flex-align cookies-info">
      <img src="./assets/images/cookies-small.png" class="image cookies-image" loading="lazy" alt="cookie image">
      <div class="cookies-text cookies-text__padding" *ngIf="!openSettings">
        <span
          class="cookies-text__headline"><b>Global Rockstar serves you cookies to give you the best user experience.</b></span>
        <p class="read-more-title" (click)="showReadMore = !showReadMore">Read more</p>
        <p class="read-more" [class.showReadMore]="showReadMore">
        If you are 18+ years and you want to use our website without restriction please click on ”ACCEPT ALL
        COOKIES”.<br>
        You may find out more by clicking on ‘COOKIE SETTINGS’ button.
          </p>
      </div>
      <p class="cookies-text cookies-text__padding" *ngIf="openSettings">
        <span class="cookies-text__headline"><b>We Care About Your Privacy</b></span><br>
        Global Rockstar serves you cookies to give you the best user experience, such as enhanced site navigation,
        optimized site usage, and individualized offers.
        You can select cookies below to improve your usage on globalrockstar.com. <br>
        If you continue to use this site you agree to the use of essential cookies. For more information on privacy
        and cookies please refer to our <a
        class="link-blue" [grsOpenFile]="'terms'">terms & conditions.</a>
      </p>
    </div>
    <div class="cookies-settings">
      <div class="toggle" *ngIf="openSettings">
        <div class="toggle-item small-toggle disable">
          <div class="status" [class.off]="!settingsToggle.essential">{{settingsToggle.essential ? 'ON' : 'OFF'}}
          </div>
          <mat-slide-toggle (change)="settingsToggleEvent($event, 'essential')"
                            class="toggle-box"
                            [disabled]="true"
                            [checked]="!settingsToggle.essential"></mat-slide-toggle>
          <div class="toggle-text" [class.off]="!settingsToggle.essential">Essential</div>
        </div>
        <div class="toggle-item small-toggle yellow">
          <div class="status" [class.off]="!settingsToggle.statistics">{{settingsToggle.statistics ? 'ON' : 'OFF'}}
          </div>
          <mat-slide-toggle (change)="settingsToggleEvent($event, 'statistics')"
                            class="toggle-box"
                            [checked]="!settingsToggle.statistics"></mat-slide-toggle>
          <div class="toggle-text" [class.off]="!settingsToggle.statistics">Statistics</div>
        </div>
        <div class="toggle-item small-toggle yellow">
          <div class="status" [class.off]="!settingsToggle.marketing">{{settingsToggle.marketing ? 'ON' : 'OFF'}}
          </div>
          <mat-slide-toggle (change)="settingsToggleEvent($event, 'marketing')"
                            class="toggle-box"
                            [checked]="!settingsToggle.marketing"></mat-slide-toggle>
          <div class="toggle-text" [class.off]="!settingsToggle.marketing">Marketing</div>
        </div>
      </div>
      <div class="button-wrapper flex-align cookies-button-wrapper">
        <button class="button yellow text-uppercase small-button" (click)="updateNotification('cookies')">
          ACCEPT ALL COOKIES
        </button>
        <button class="button text-uppercase small-button transparent-white" (click)="applyCookiesSettings()"
                *ngIf="openSettings">
          ACCEPT SELECTION
        </button>
        <button class="button text-uppercase small-button transparent-white" (click)="getCookiesSettings()"
                *ngIf="!openSettings">
          COOKIE SETTINGS
        </button>
      </div>
    </div>
  </div>
</div>
