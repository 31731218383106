import { Component, Output, EventEmitter, HostListener } from '@angular/core';
@Component({
  selector: 'app-call-event-popup',
  templateUrl: './call-event-popup.html',
})
export class CallEventPopupComponent {
  newsLetter: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event: KeyboardEvent ): void {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  onSignIn(): void {
    this.notify.emit({ type: 'signIn', data: this.newsLetter });
  }

  onClose(): void {
    this.notify.emit({ type: 'close' });
  }
}
