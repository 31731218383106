import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  HostListener,
} from '@angular/core';

// Services
import { AppToastrService, ProfileService } from 'app/services';

@Component({
  selector: 'add-bank-account-popup',
  templateUrl: './add-bank-account-popup.component.html',
  styleUrls: ['./add-bank-account-popup.component.scss'],
})
export class AddBankAccountPopupComponent {
  @Input() account: any;
  @Input() country: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  onClose() {
    this.notify.emit({ type: 'close' });
  }

  onSubmit(res: any): void {
    this.notify.emit({
      accounts: res,
      type: 'submit',
    });
  }
}
