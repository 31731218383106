import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CardModel } from '@app/models/card.model';

// Consts
import {
  ARTIST_CONTENT,
  FANS_CONTENT,
  SUCCES_CONTENT,
  ABOUT_CONTENT,
} from '@app/common/content';
import { SLIDER_CONFIG } from '@app/common';

// Services
import { ProjectsService } from '@app/services/projects.service';
import { AppToastrService } from '@app/services/toastr.service';
import { VideoService } from '@app/services/video.service';
import { GetLocaleService } from '@app/services/get-locale.service';
import { ModalService } from '@app/services/modal.service';
import { StorageService } from '@app/services/storage.service';

// Components
import { VerifyEmailComponent } from '@app/components/popups/verify-email/verify-email.component';
@UntilDestroy()
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss'],
})
export class MainPageComponent implements OnInit {
  public readonly ARTIST_CONTENT: any = ARTIST_CONTENT;
  public readonly FANS_CONTENT: any = FANS_CONTENT;
  public readonly SUCCESS_CONTENT: any = SUCCES_CONTENT;
  public readonly ABOUT_CONTENT: any = ABOUT_CONTENT;

  isBrowser: boolean = false;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;

  projects: CardModel[];
  currentSpotlightIndex: number = 0;

  enableVideoPlayer: boolean;
  state: any;
  index: any;
  video: HTMLVideoElement;
  cardProject: any;

  isUSA$: Observable<boolean> = this.localeService.isUsLocale$.pipe(
    untilDestroyed(this)
  );

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window: any,
    private projectService: ProjectsService,
    private toastr: AppToastrService,
    private videoService: VideoService,
    private localeService: GetLocaleService,
    private modalService: ModalService,
    private storage: StorageService
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      this.setBreakpoints();
      this.subscribeToResize();

      if (this.storage.user?.verified === false) {
        this.showVerifyEmailPopup();
      }
    }

    this.getSpotlight();
  }

  get slideConfig(): any {
    return SLIDER_CONFIG.HOME_DEFAULT;
  }

  get arrowsSlideConfig(): any {
    return SLIDER_CONFIG.HOME_SUCCESSES;
  }

  get spotlightSlideConfig(): any {
    return SLIDER_CONFIG.HOME_SPOTLIGHT;
  }

  private setBreakpoints(): void {
    this.isDesktop = this.window.innerWidth >= 1200;
    this.isTablet =
      this.window.innerWidth < 1200 && this.window.innerWidth >= 768;
    this.isMobile = this.window.innerWidth < 768;
  }

  private subscribeToResize(): void {
    fromEvent(window, 'resize')
      .pipe(untilDestroyed(this), debounceTime(200))
      .subscribe(() => this.setBreakpoints());
  }

  get currentSpotlight(): any {
    if (!this.projects) return null;

    return this.projects[this.currentSpotlightIndex];
  }

  get spotlightSlideClass(): 'first' | 'last' {
    if (this.currentSpotlightIndex === 0) {
      return 'first';
    } else if (this.currentSpotlightIndex === this.projects?.length - 1) {
      return 'last';
    } else return null;
  }

  showVerifyEmailPopup(): void {
    if (
      !this.modalService.isModalShown('VerifyEmailComponent')
    ) {
      this.modalService.open(VerifyEmailComponent);
    }
  }

  scrollTo(target: HTMLElement): void {
    target.scrollIntoView({ block: 'center' });
  }

  spotlightChange({ currentSlide }: any): void {
    this.currentSpotlightIndex = currentSlide;
    this.enableVideoPlayer && this.stopPlayer();
  }

  getSpotlight(): void {
    this.projectService.getSpotlights().subscribe(
      (res) => {
        this.projects = res;
      },
      (err: any) => {
        this.toastr.showToastFromError(err);
      }
    );
  }

  getVideoObject(cardObject) {
    if (cardObject.status === 'play') {
      this.state = 'play';
      this.cardProject = cardObject.card;
      this.video = cardObject.video;
      this.index = cardObject.index;
      this.enableVideoPlayer = true;
    }
    if (cardObject.status === 'stop') {
      this.state = 'stop';
      this.cardProject = cardObject.card;
      this.video = cardObject.video;
      this.index = cardObject.index;
      this.enableVideoPlayer = false;
    }
  }

  stopPlayer(): void {
    this.videoService.stop();
    this.enableVideoPlayer = false;
  }
}
