import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';

// Consts
import { STATE_NAMES } from 'app/common';

@Component({
  selector: 'app-register-page',
  templateUrl: './register.html',
  styleUrls: ['./register.scss'],
})
export class RegisterComponent implements OnInit {
  p_state: any;
  isDefaultFanUser = true;

  constructor(private router: Router, @Inject(WINDOW) private window) {
    this.p_state = this.router.getCurrentNavigation().extras.state;
  }

  ngOnInit(): void {
    this.scrollFix();
  }

  onUserTypeChange(isDefaultUser: boolean): void {
    this.isDefaultFanUser = isDefaultUser;
  }

  scrollFix() {
    this.window.setTimeout(() => {
      this.window.scrollTo(0, 0);
    }, 0);
  }

  onRegisterSuccess() {
    const p = this.p_state;
    let state = p && p.state ? p.state : STATE_NAMES.base;
    if (
      p &&
      [STATE_NAMES.register, STATE_NAMES.login].indexOf(p.state) >= 0 &&
      !p.params.slug
    ) {
      state = STATE_NAMES.base;
    }
    if (p && p.params && p.params.slug) {
      localStorage.setItem('slug', p.params.slug);
      state = STATE_NAMES.projectsItem;
      this.router.navigate([`/${state}`, p.params.slug]);
    } else {
      this.router.navigate([`/${state}`]);
    }
  }
}
