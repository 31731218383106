import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import {SettingService} from 'app/services';

import {STATE_NAMES} from '../../common';
import {StreamState} from '../../models/stream-state.model';
import {AudioService} from '../../services/audio.service';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';
import {isPlatformBrowser} from "@angular/common";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Observable} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'app-song-preview',
  templateUrl: './song-preview.html'
})
export class SongPreviewComponent implements OnInit, OnDestroy {
  state: StreamState;
  host: string;
  enableButton: boolean;
  project$: Observable<any>;
  @Input() project: any;
  @Input() wouldInvestActiveChange: boolean;
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private audioService: AudioService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: any,
              private projectService: ProjectsService,
              private settingService: SettingService) {
    this.host = settingService.imgUrl;
  }

  ngOnInit() {
    this.checkForProjectPage();
   this.audioService.getState().pipe(untilDestroyed(this)).subscribe(state => {
      this.state = state;
    });
     this.project$ = this.audioService.getProjectPlaying().pipe(untilDestroyed(this));
  }

  ngOnDestroy() {
    this.audioService.pause();
  }

  muteSound(){
    this.audioService.mute();
  }

  updateVolume(event){
    this.audioService.volumeChange(event);
  }

  togglePlayer() {
    if (this.state && !this.state.error) {
      if (this.state.playing) {
        this.audioService.pause();
       // this.project.songIsPlaying = false;
      //  this.project.videoPlaying = false;
        this.notify.emit('stop');
      }
    }
  }

  videoTiming(event){
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10));
    this.audioService.seekTo(skipTo);
   }

  checkForProjectPage() {
    const proj = this.router.url.includes('projects');
    const collect = this.router.url.includes('collectible');
    const nft = this.router.url.includes('my-nft');
    const sell = this.router.url.includes('sell-bid');
    const wizard = this.router.url.includes('wizard');
    if (proj || collect || nft || sell || wizard) {
      this.enableButton = false;
    }
    else {
      this.enableButton = true;
    }
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }
    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard) {
        const name = e.target.className;
        const parentNodeHover = e.target.parentNode.className ? e.target.parentNode.className.indexOf('hover-block') >= 0 : null;
        if (name.indexOf('fas') && name.indexOf('play-button') && !parentNodeHover) {
          this.router.navigate([project.isCollectible ?
            STATE_NAMES.collectible : `/${STATE_NAMES.projectsItem}`, project.slug]);
        }
      }
    }
  }
}
