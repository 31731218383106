import { Collection } from "@app/types";

export const WIZARD_STEP_TITLES: Collection = {
  REGISTER: 'Join',
  OVERVIEW: 'Investment overview',
  INFORMATION: 'Billing Information',
  PAYMENT: 'Payment',
  KYC_UPLOAD: 'KYC validation',
  KYC_SUBMITTED: 'KYC validation',

};

export const WIZARD_STEPS: Collection = {
  REGISTER: 'REGISTER',
  OVERVIEW: 'OVERVIEW',
  INFORMATION: 'INFORMATION',
  PAYMENT: 'PAYMENT',
  KYC_UPLOAD: 'KYC_UPLOAD',
  KYC_SUBMITTED: 'KYC_SUBMITTED',
}
