import {Component, EventEmitter, HostListener, Inject, Input, Output, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {IDictionary, IFilter} from '../../common/interfaces';
import {SORT_TYPE} from '../../common';


@Component({
  selector: 'app-menu-search',
  templateUrl: './menu-search.html'
})
export class MenuSearchComponent {

  @Input() filter: IFilter;
  @Input() count: number;
  @Input() activeTab: number;
  @Input() projectType: IDictionary[];
  @Input() genresList: IDictionary[];
  @Input() artistList: IDictionary[];
  @Input() countriesList: IDictionary[];
  @Input() status;
  @Output() filterChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterClear: EventEmitter<any> = new EventEmitter<any>();
  sortTypes: IDictionary[] = SORT_TYPE;

  constructor() {
  }

  onfilterChange(): void {
    this.filterChange.emit({});
  }

  sortList(): void {
    this.sortChange.emit({});
  }

  clearFilters(): void {
    this.filterClear.emit({});
  }

}
