import {Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import * as moment from 'moment';
import {Router} from '@angular/router';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {Filters} from '../../common/helpers';
import {Utils} from '../../common/utils';
import {RequestModel} from '../../models/request.model';
import {
  STATE_NAMES
} from '../../common';

// Services
import {
  AppToastrService,
  SettingService,
} from '../../services';
import {CookieService} from 'ngx-cookie-service';
import {RoutingService} from '../../services/routing.service';
import {SeoService} from '../../services/seo.service';
import {StorageService} from '../../services/storage.service';
import {ProjectsService} from '../../services/projects.service';

// Components
import { GetLocaleService } from '@app/services/get-locale.service';
import {GoogleAnalyticsService} from "@services/google-analytics.service";

@UntilDestroy()
@Component({
  selector: 'app-home-page',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})

export class HomePageComponent implements OnInit, OnDestroy {
  projectList: any;
  host: string;
  linked: any;
  limit: number;
  leftList: any[];
  loaded: boolean;
  isLoadedAdditional: boolean;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 3,
    dots: false,
    initialSlide: 4,
    nextArrow: '',
    prevArrow: '',
  };
  total: number;
  user: any;
  sliders: any;
  type: string;
  filter = new Filters();
  settingsToggle = {
    statistics: true,
    essential: true,
    marketing: true
  };
  downtime: any;
  showTerms: boolean;
  showSubscribe: boolean;
  isTFAShow: boolean;
  openSettings: boolean;
  sort: boolean;
  allCookies: any;
  status = [];
  scrollTop: any;
  scrollLeft: any;
  paymentInfo: boolean;

  isUSA$: Observable<boolean> = this.localeService
    .isUsaLocale()
    .pipe(untilDestroyed(this));

  @HostListener('window:resize') windowResize() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth < 374) {
        this.slideConfig.slidesToShow = 1;
        this.slideConfig.slidesToScroll = 1;
      }
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private storage: StorageService,
    private router: Router,
    private seoService: SeoService,
    private cookieService: CookieService,
    private settingService: SettingService,
    private toastr: AppToastrService,
    private googleAnalytics: GoogleAnalyticsService,
    private routingService: RoutingService,
    private projectService: ProjectsService,
    private localeService: GetLocaleService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      const state = this.router.getCurrentNavigation().extras.state;
      this.paymentInfo = state.paymentInfo;
    }
    this.host = settingService.imgUrl;
  }

  ngOnInit() {
    this.getProjects();
  }

  ngOnDestroy() {
    this.settingService.setHeaderStick(false);
  }

  cookies(): void {
    const notification = this.cookieService.get('notification');
    this.allCookies = this.cookieService.getAll();
    // const cookieSettings = this.storage.cookiesSetting ? JSON.parse(this.storage.cookiesSetting) : null;
    if (!notification) {
      // this.showCookies = true;
      this.showSubscribePopup();
    }

    this.statusVerification();
    this.showVerifyPopup();

    this.storage.localItemChanged().subscribe((item) => {
      if (item && item.currentUser && JSON.parse(item.currentUser)) {
        this.showVerifyPopup();
      }
    });
  }

  getCookiesSettings(): void {
    this.openSettings = true;
  }

  showSubscribePopup(): void {
    if (isPlatformBrowser(this.platformId)) {
      const prev_url = this.routingService.getPreviousUrl();
      if (prev_url !== STATE_NAMES.base) {
        const states = [STATE_NAMES.login, STATE_NAMES.register, STATE_NAMES.verify, STATE_NAMES.changePassword];
        const notification = this.cookieService.get('notification') ? JSON.parse(this.cookieService.get('notification')).visitorRegistered : null;
        this.showSubscribe = !notification && states.indexOf(prev_url) < 0;
      }
    }
  }

  onTFA(): void {
    this.isTFAShow = false;
    //  TODO send data to back that user seen TFA
  }

  onSubscribe(): void {
    this.showSubscribe = false;
    this.updateNotification('visitorRegistered');
  }

  applyCookiesSettings(): void {
    this.storage.cookiesSetting = JSON.stringify(this.settingsToggle);
    this.updateNotification('cookies');
  }

  settingsToggleEvent(e, key): void {
    this.settingsToggle[key] = !e.checked;
  }

  statusVerification(): void {
    this.projectService.verifyStatusChanged().subscribe(item => {
      if (item) {
        this.showVerifyPopup();
      }
    });
  }

  onVerifyConditions(): void {
    if (this.user && !this.user.showInfoTFA) {
      this.isTFAShow = true;
    }
    this.showTerms = false;
  }

  showVerifyPopup(): void {
    const user = this.storage.user;
    this.user = user;

    if (user && !user.termsAndConditions) {
      this.showTerms = true;
    }
    if (user && !user.showInfoTFA && user.termsAndConditions) {
      this.isTFAShow = true;
    }
  }

  updateNotification(type): void {
    const parsed = JSON.parse(this.cookieService.get('notification') || '{}');
    parsed[type] = true;
    const date = new Date();
    date.setDate(date.getDate() + 365 * 3);
    document.cookie = 'notification' + '=' + JSON.stringify(parsed) + '; expires=' + date.toUTCString() + '; path=/';
  }


  scroll(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.scrollTop || this.scrollLeft) {
        setTimeout(() => {
          window.scrollBy(this.scrollLeft, this.scrollTop);
        }, 100);
      }
      if (this.linked && window.innerWidth > 1024) {
        setTimeout(() => {
          window.scrollBy({top: 600, left: 0, behavior: 'smooth'});
        }, 400);
      }
    }
  }

  loadMore(): void {
    this.projectList = this.projectList.filter(item => !item.isEmptyItem);
    if (this.filter.page === 1) {
      this.projectList = [...this.projectList, ...this.leftList];
      this.leftList = [];
      this.filter.page = Number(this.filter.page) + 1;
    } else {
      this.isLoadedAdditional = true;
      this.filter.offset = Number(this.filter.offset) + 1;
      this.getProjects();
    }
  }

  addCollectible(aggregateList, collectible): any[] {
    // let sorted = collectible.sort((a, b) => {
    //   return b.position - a.position;
    // });
  //  return [...aggregateList, ...collectible];
    collectible.forEach(col => {
      if (!col.finished) {
        aggregateList.unshift(col);
      } else {
        aggregateList.push(col);
      }
    });
    return aggregateList;
  }

  setSeo(page): void {
    this.seoService.updateCanonicalURL();
    if (page.seo) {
      if (page.title) {
        this.seoService.updateTitle(page.title);
      }
      if (page.keywords) {
        this.seoService.updateKeywords(page.keywords);
      }
      if (page.description) {
        this.seoService.updateDescription(page.description);
      }
    }
  }

  prepareSlider(sliders) {
    if (isPlatformBrowser(this.platformId) && sliders.list && sliders.list.length) {
      this.sliders = sliders;
      this.sliders.list = this.sliders.list.filter(item => {
        return item.showForRegistered && this.user || (item.showForVisitors && !this.user);
      });
    }
  }

  getProjects(): void {
    this.loaded = false;
    const data = this.filter.getPaging();
    let type = 'all';

    const request = new RequestModel(Object.assign(data, {type}));
    this.projectService.getAllProjects(request).subscribe(resp => {
      if (resp) {
        const projectList = [];
        const featured = [];
        const soldProject = this.sortArray(resp.funded);
        const releasProject = Utils.sortArrayByDate(resp.released, 'releaseCreatedDate', 'YYYY/MM/DD');
        this.setSeo(resp);
        this.isUSA$.subscribe((isUsa: boolean)=>{
          this.prepareSlider(isUsa ? resp.usaSliders: resp.sliders);
        })
        resp.totalArray.forEach(proj => {
          if (proj.isFeatured) {
            featured.push(proj);
          } else {
            projectList.push(proj);
          }
        });

        let aggregateList = projectList;



        this.total = this.totalItems(resp.total);
        if (featured.length) {
          aggregateList = [...featured, ...aggregateList];
        }

        this.googleAnalytics.logList([...aggregateList, ...soldProject, ...releasProject]);

        if (resp.collectible.length) {
          aggregateList = this.addCollectible(aggregateList, resp.collectible);
        }

        aggregateList = [...aggregateList, ...soldProject, ...releasProject];
        aggregateList = this.prepareNews(aggregateList, resp.cards);

        if (this.filter.page === 1) {
          this.filter.setLimit(resp.pNumber);
          this.leftList = aggregateList.slice(this.filter.limit, aggregateList.length);
          aggregateList = aggregateList.slice(0, this.filter.limit);
          this.projectList = aggregateList;
        } else {
          this.projectList = [...this.projectList, ...aggregateList];
        }

        this.isLoadedAdditional = false;
        this.projectList = this.projectService.addEmptyItem(this.projectList);
        this.loaded = true;
        this.scroll();
      }
    }, err => {
      this.loaded = true;
      this.toastr.showToastFromError(err);
    });
  }

  totalItems(resp): number {
    let total = 0;
    for (const sum in resp) {
      total += !isNaN(resp[sum]) ? resp[sum] : 0;
    }
    return total;
  }

  sortArray(arr: any[] = []): any[] {
    if (arr.length) {
      return arr.sort((a, b) => {
        return Number(b.projectDurationTime.startDate) - Number(a.projectDurationTime.startDate);
      });
    }
    return arr;
  }

  prepareNews(aggregateList, cards): any {
    let listWithNews = [];

    listWithNews = aggregateList;

    const newsCards = cards;
    const newsCardsUpd: any[] = newsCards.filter(item => {
      return moment().diff(moment(item.publishedAt, 'YYYY/MM/DD')) > 0;
    });

    const manualPriority = [];
    const autoPriority = [];
    const positions = [];

    newsCardsUpd.forEach(item => {
      if (item.newsflash && item.newsflash > 0) {
        item.position = item.newsflash;
        positions.push(item.position);  // positions of manual added position of news
        manualPriority.push(item);    // array with manual added position of news
      } else {
        autoPriority.push(item);  // array with auto added news
      }
    });
    const sortedAutoPriority = Utils.sortArrayByDate(autoPriority, 'publishedAt', 'YYYY/MM/DD');
    const totalArray = manualPriority || [];
    let indent = 1;
    sortedAutoPriority.forEach((item, index) => {
      if (positions.indexOf(indent) < 0) {
        item.position = indent;
        totalArray.push(item);
        indent = index === 0 ? indent + 2 : indent + 3;
      } else if (index === 0) {
        indent = indent + 2;
      } else {
        indent = indent + 3;
      }
    });
    totalArray.sort((a, b) => {
      return a.position - b.position;
    });
    const num = aggregateList.length;
    totalArray.forEach(item => {
      if (item.position < num) {
        listWithNews.splice(item.position, 0, item);
      } else {
        listWithNews.push(item);
      }
    });

    return listWithNews;
  }

}
