import {Component} from '@angular/core';

import {ModalService} from '@services/modal.service';
import {StorageService} from '@services/storage.service';
import {Router} from '@angular/router';
import {USA_REDIRECT_POPUP} from "@app/common/content/usa-redirect-popup.content";
import {ProfileService} from "@app/services";


@Component({
  selector: 'magic-verification-popup',
  templateUrl: './usa-redirect-popup.component.html',
  styleUrls: ['./usa-redirect-popup.component.scss'],
})
export class UsaRedirectPopupComponent {

  public settings = this.storage.user ? USA_REDIRECT_POPUP['LOGGED_IN'] : USA_REDIRECT_POPUP['NON_LOGGED_IN'];

  constructor(
    private modalService: ModalService,
    private storage: StorageService,
    private profile: ProfileService,
    private router: Router
  ) {
  }

  onClose(): void {
    this.modalService.close();
  }

  redirectURL() {
    if (this.storage.user) {
      let payload = {
        type: 'US_REMINDER'
      }
     this.profile.keepUserPostedUS(payload).subscribe();
    }
    if (!this.storage.user) {
      this.router.navigate([`/${this.settings.link}`]);
    }
    this.onClose();
  }
}
