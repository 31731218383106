import {Component, Output, Input, EventEmitter, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.html'
})
export class PopupComponent implements OnInit, OnDestroy {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() canCollapse: boolean;

  ngOnInit() {
    this.changeZIndex();
  }

  ngOnDestroy() {
    this.changeZIndex(true);
  }

  changeZIndex(close?: boolean) {
    const element = document.getElementsByTagName('app-navigation')[0];
    if (close) {
      if (element) {
        element.classList.remove('change-index');
      }
    } else {
      if (element) {
        element.classList.add('change-index');
      }
    }
  }
}
