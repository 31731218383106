import {
  Directive,
  HostListener,
  OnDestroy,
  Input,
  Inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { WINDOW } from '@ng-web-apis/common';

// Services
import { DictionaryService } from '@app/services';


@Directive({
  selector: '[grsOpenFile]',
})
export class OpenFileDirective implements OnDestroy {
  @Input() grsOpenFile: string;

  private documents$: Subscription;

  constructor(
    @Inject(WINDOW) private window: Window,
    private dictionaryService: DictionaryService
  ) {}

  @HostListener('click', []) onClick() {

    this.open(this.grsOpenFile);
    
    /* Prevent default behaviour to allow adding href attr to the element */
    return false;
  }

  ngOnDestroy(): void {
    if(this.documents$){
        this.documents$.unsubscribe();
    } 
  }

  open(param: string): void {
    const title = this.dictionaryService.typeDocument(param);
    this.documents$ = this.dictionaryService
      .getDocumentLink()
      .subscribe((resp: any) => {
        if (resp && resp[title]) {
          this.window.open(resp[title]);
        }
      });
  }
}
