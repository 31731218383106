export class RegisterModel {
  getApiModel(data): any {
    const model: any = {
      email: data.email,
      name: data.artistName ? data.artistName : null,
      'first-name': data.firstName,
      country: data.country,
      region: data.region,
      'last-name': data.lastName,
      type: data.isArtist ? 'artist' : 'fan',
      settings: {
        notifications: data.notifications
      }
    };
    if (data.facebookId) {
      model.facebookId = data.facebookId;
      model.facebookToken = data.facebookToken;
    } else {
      model.password = data.password;
      model.confirm = data.confirm;
    }
    return model;
  }
}
