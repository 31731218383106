<ng-container *ngIf="state?.playing && (project$ | async) as project">
<div class="wrapper">
<div class="project-image" [style.backgroundImage]="'url(' + project.backgroundImage + ')'" (click)="togglePlayer()">
  <i class="fas fa-stop" *ngIf="state?.playing"></i>
  <i class="fas fa-play" *ngIf="!state?.playing"></i>
</div>

<div>
  <div class="name">
    <span class="artist">{{project?.artist?.name}} -
    </span>{{project.isAlbum ? project.projectTitle : (project.song || project?.description)}}
  </div>
  <div class="progress-wrapper" *ngIf="!state?.error">
    <div class="played">{{state?.readableCurrentTime}}</div>
    <progress class="progress" (click)="videoTiming($event)" min="0" value="{{state?.progress}}" max="100">{{state?.progress}}
    </progress>
    <div class="total">{{state?.readableDuration}}</div>
  </div>
</div>
<div class="volume">
  <div class="muted"(click)="muteSound()">
  <i *ngIf="!state.muted" class="fas fa-volume-up"></i>
  <i *ngIf="state.muted" class="fas fa-volume-mute"></i>
</div>
<progress class="volume-level" value="{{state.volume}}" max="100" min="0" (click)="updateVolume($event)">
</progress>
</div>


  <div class="desktop-button">
    <a *ngIf="enableButton" class="button share-button desktop yellow"
       href="https://www.globalrockstar.com/projects/{{project?.slug}}"
       (click)="openSupplierProfile(project, $event)"
       [class.headliner]="project?.keepItAll && !project?.isCollectible"
       [class.collectible]="project?.isCollectible">
      <span *ngIf="!project?.isCollectible && !project.isSold">Get your share</span>
      <span *ngIf="project?.isCollectible && !project.finished">Bid Now</span>
      <span *ngIf="project?.isSold || project.finished || project.releaseCreated">View Details</span>

    </a>
  </div>
</div>
</ng-container>
