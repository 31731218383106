<!-- <ng-template #slideWrapper let-slide="slide">
  <div class="slider-text">
    <div class="slider-wrapper">
      <h1 class="slider-title text-uppercase">{{slide?.mainTitle}}</h1>
      <p>{{slide?.title}}</p>
      <ng-template [ngIf]="!user || (!slide.isRegisterURL && user)">
        <a [href]="slide?.url" class="button slider-btn text-uppercase" *ngIf="slide?.text"
           [target]="slide?.targetBlank ? '_blank': '_self'">{{slide?.text}}</a>
      </ng-template>
    </div>
  </div>
</ng-template> -->

<div class="section-slider">
  <h2 class="bold-style text-uppercase label__title" style="text-align: center;">News</h2>
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of visibleNews; trackBy: trackByFn;" class="slide">
      <div class="block">
        <div class="text-block">
          <div *ngIf="slide?.publishedAt" class="text-block-date">
          <span class="lnr lnr-calendar-full"></span>
          <p class="letter-space">{{slide.publishedAt | momentDate}}</p>
          </div>
          <div><h3 class="bold-style text-uppercase">{{slide.title}}</h3></div>
          <div class="img-block" *ngIf="slide?.image && mobile">
            <img loading="lazy"
              [src]="slide.image"
              height="261"
              alt="news slider">
          </div>
          <div class="img-block" *ngIf="!slide?.image && mobile">
            <img loading="lazy"
              height="261"
              src="./assets/images/user/fallback_new.png"
                 alt="news slider mobile">
          </div>
          <div [innerHTML]="slide?.newsDescription"></div>
          <div class="news-buttons">
          <span class="text-uppercase link-blue first-link" *ngIf="slide?.link?.text"><a href="{{slide.link.url}}">{{slide.link.text}}</a></span>
            <div *ngIf="(slide?.social | keyvalue)?.length" class="social-icons">
              <a class="facebook" href="{{slide.social.facebook}}" *ngIf="slide.social.facebook"></a>
              <a class="spotify" href="{{slide.social.spotify}}" *ngIf="slide.social.spotify"></a>
              <a class="instagram" href="{{slide.social.instagram}}" *ngIf="slide.social.instagram"></a>
              <a class="twitter" href="{{slide.social.twitter}}" *ngIf="slide.social.twitter"></a>
              <a class="youtube" href="{{slide.social.youtube}}" *ngIf="slide.social.youtube"></a>
             </div>
          </div>
        </div>
        <div class="img-block" *ngIf="slide?.image && !mobile">
          <img loading="lazy"
            [src]="slide.image"
            height="261"
               alt="news slider mobile">
        </div>
        <div class="img-block" *ngIf="!slide?.image && !mobile">
          <img loading="lazy"
            height="261"
            src="./assets/images/user/fallback_new.png"
               alt="news slider">
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
