<!-- <ng-template #slideWrapper let-slide="slide">
  <div class="slider-text">
    <div class="slider-wrapper">
      <h1 class="slider-title text-uppercase">{{slide?.mainTitle}}</h1>
      <p>{{slide?.title}}</p>
      <ng-template [ngIf]="!user || (!slide.isRegisterURL && user)">
        <a [href]="slide?.url" class="button slider-btn text-uppercase" *ngIf="slide?.text"
           [target]="slide?.targetBlank ? '_blank': '_self'">{{slide?.text}}</a>
      </ng-template>
    </div>
  </div>
</ng-template> -->

<div class="section-slider">
  <div class="titles">
    investment opportunity
  </div>
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of projects; trackBy: trackByFn; let i = index" class="slide">
      <section class="investment-opportunity">
        <div class="investment-image" [ngStyle]="{'background-image': 'url('+ slide.image +')'}">
            <div class="play-button-web">
                <div class="play-button m-screen" *ngIf="slide?.song" (click)="openAudio(slide)">
                    <i class="fas fa-play" *ngIf="!slide.isPlaying"></i>
                    <i class="fas fa-stop" *ngIf="slide.isPlaying"></i>
                  </div>
            </div>
            <div class="share-button web"><a (click)="openProject(slide.slug)" type="button" class="button yellow align-btn default-link " style="width: 334px;
                height: 56px;"> Get your share</a></div>
        </div>
        <div class="investment-text web" (click)="openProject(slide.slug)">
            {{slide?.projectTitle}}
        </div>
        <div class="investment-text mobile" (click)="openProject(slide?.slug)">
            {{slide?.projectTitle}}
        </div>
        <div class="share-button mobile"><a (click)="goToCalculationPage(slide)" type="button" class="button yellow align-btn default-link " style="width: 334px;
            height: 56px;"> Get your share</a></div>
    </section>
    </div>
  </ngx-slick-carousel>
</div>
