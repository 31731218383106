<div class="headliner__icons" *ngIf="project">
<div class="icon-musical" *ngIf="musType()">
  <app-tooltip class="tooltip-component" [img]="'./assets/images/cards/musical.png'">
    <div img-content>
      <img loading="lazy"
           alt="logo tooltip" [src]="'./assets/images/cards/musical.png'">
    </div>
    <div text-content>
      {{CARD_TOOLTIP_CONTENT.musical}}
      <br/><br/>
      <a class="tooltip-link" (click)="learnMore($event)">Learn more</a>
    </div>
  </app-tooltip>
</div>
<div class="icon-disk" *ngIf="project.royaltiesFromMaster">
  <app-tooltip class="tooltip-component" [img]="'./assets/images/cards/disk.svg'">
    <div img-content>
      <img loading="lazy"
           alt="logo tooltip" [src]="'./assets/images/cards/disk.svg'">
    </div>
    <div text-content>
      {{CARD_TOOLTIP_CONTENT.master}}
      <br/><br/>
      <a class="tooltip-link" (click)="learnMore($event)">Learn more</a>
    </div>
  </app-tooltip>
</div>

<div class="icon-melody" *ngIf="project.royaltiesFromPublishingRights">
  <app-tooltip class="tooltip-component" [img]="'./assets/images/cards/melody.svg'">

    <div img-content>
      <img loading="lazy"
           alt="logo tooltip" [src]="'./assets/images/cards/melody.svg'">
    </div>
    <div text-content>
      {{CARD_TOOLTIP_CONTENT.royalty}}
      <br/><br/>
      <a class="tooltip-link" (click)="learnMore($event)">Learn more</a>
    </div>
  </app-tooltip>

</div>
</div>
