import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { WINDOW } from '@ng-web-apis/common';
@Component({
  selector: 'app-timer',
  templateUrl: './timer.html',
})
export class TimerComponent implements OnInit, OnDestroy {
  displayMinutes: Subject<string> = new Subject();
  displayHours: Subject<string> = new Subject();
  displaySeconds: Subject<string> = new Subject();
  intervalId: number = null;
  @Input() project: any;
  @Input() config: any;
  @Input() enableTimer: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(WINDOW) private window,
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkDate();
    }
  }

  ngOnDestroy(): void {
    this.displayMinutes.complete();
    this.displayHours.complete();
    this.displaySeconds.complete();
    this.window.clearInterval(this.intervalId);
  }

  calculateTimeDistance(date?): number {
    const time = moment(date).utc(false).format('x');
    const now = moment().format('x');
    return Number(time) - Number(now);
  }

  calculateTimeDistanceCollectible(date): number {
    const time = moment(date).utc(false).format('x');
    const now = moment().format('x');
    return Number(time) - Number(now);
  }

  checkDate(): void {
    let distance;
    if (this.project && this.project.isCollectible) {
      distance = this.calculateTimeDistanceCollectible;
    } else {
      distance = this.calculateTimeDistance;
    }

    const displayMinutes = this.displayMinutes;
    const displayHours = this.displayHours;
    const displaySeconds = this.displaySeconds;

    let passDate;

    if (this.project.isCollectible) {
      passDate = this.project.endDate;
    } else if (this.project.projectNotStarted) {
      passDate = this.project.projectStartDate
      ? this.project.projectStartDate
      : null;
    } else {
      passDate = this.project.projectEndDate
      ? this.project.projectEndDate
      : null;
    }

    const timer: number = this.window.setInterval(() => {
      const hours = Math.floor(
        (distance(passDate) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (distance(passDate) % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((distance(passDate) % (1000 * 60)) / 1000);
      displayHours.next(hours < 10 ? `0${hours}` : `${hours}`);
      displayMinutes.next(minutes < 10 ? `0${minutes}` : `${minutes}`);
      displaySeconds.next(seconds < 10 ? `0${seconds}` : `${seconds}`);
      // If the count down is over, write some text
      if (distance(passDate) < 0) {
        this.window.clearInterval(timer);
        this.checkTimer();
      }
    }, 1000);
    this.intervalId = timer;
  }

  checkTimer() {
    if (this.project.isCollectible && this.project.projectNotStarted) {
      this.project.projectNotStarted = false;
      this.project.daysLeft = this.project.daysBefore;
      this.checkDate();
    } else if (this.project.isCollectible && !this.project.projectNotStarted) {
      this.project.finished = true;
    }
  }
}
