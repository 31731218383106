import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DOC_MANGOPAY_TYPE, DOC_TYPE, MANGOPAY_LIST, SEP_COUNTRIES} from '../../../../common';
import {CustomFieldErrorMatcher} from '../../../../common/errorMatcher';
import {AppToastrService, ProfileService} from "@services/index";

interface ITypeCompanyDocument {
  article: string;
  registration_proof: string;
}

@Component({
  selector: 'app-kyc-upload-document',
  templateUrl: './kyc-upload-document.html',
  providers: [AppToastrService, ProfileService]
})
export class KycUploadDocumentComponent implements OnInit {
  @Input() isSEP: any;
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() validStateChange: EventEmitter<boolean> = new EventEmitter(false);
  doctypeCtrl: FormControl;
  form: FormGroup;
  submitPressed: boolean;
  submitting: boolean;
  isSelectedType = false;
  documentType: any[];
  isIdcardValid = false;
  imgURL = {
    filefront: '',
    fileback: '',
    statute: '',
    company_register: ''
  };
  twopage = {};
  backPage = {};
  showUploadImage: boolean;
  imageConfig = {
    textButton: ['Choose the picture', '(.png, .jpg, .jpeg)'],
    imageFormat: 'jpg',
    maintainAspectRatio: false,
    allowFormats: ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'webp', 'WEBP']
  };
  showIDExamples = {
    front: true,
    back: true
  };
  textCompanyDocuments: ITypeCompanyDocument = {article: '', registration_proof: ''};
  showExamples = true;
  loadSpinner: boolean;
  checkReadibility: any;
  innerWidth: any;
  photoRegime: boolean;
  isMobileScreen = false;
  fileStep: string;
  pdfName = {
    statute: '',
    company_register: ''
  };

  constructor(private toast: AppToastrService,
              private profileService: ProfileService,
              private fb: FormBuilder) {

  }

  ngOnInit() {
    this.doctypeCtrl = this.fb.control('', [Validators.required]);
    this.form = this.fb.group({
      document: this.doctypeCtrl,
    });
    this.documentType = DOC_TYPE;
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 768) {
      this.isMobileScreen = true;
    }
    if (!this.isSEP) {
      if ((this.user.mangoVerificationStatus && this.user.mangoVerificationStatus.id && this.user.mangoVerificationStatus.id !== 1) || !this.user.mangoVerificationStatus || !this.user.mangoVerificationStatus.id) {
        this.isSEP = MANGOPAY_LIST.indexOf(this.user.country) >= 0 || SEP_COUNTRIES.indexOf(this.user.country) >= 0;
      }
    }
    this.typeDocument();

  }


  selectType(): void {
    this.isSelectedType = true;
    this.updateData();
    this.checkValidation();
  }

  updateData(): void {
    this.twopage = {};
    this.imgURL = {
      filefront: '',
      fileback: '',
      statute: '',
      company_register: ''
    };
    this.pdfName = {
      statute: '',
      company_register: ''
    };
  }

  onSubmit(): void {
    this.submitPressed = true;
    const fileUpload = new FormData();
    fileUpload.append('frontPage', this.twopage['filefront']);
    if (this.twopage.hasOwnProperty('fileback')) {
      fileUpload.append('backPage', this.twopage['fileback']);
    }
    if (this.twopage.hasOwnProperty('statute')) {
      fileUpload.append('statute', this.twopage['statute']);
    }
    if (this.twopage.hasOwnProperty('company_register')) {
      fileUpload.append('company_register', this.twopage['company_register']);
    }
    this.profileService.uploadFileKYC(fileUpload).subscribe(res => {
      this.submitPressed = false;
      this.updateData();
      this.notify.emit({type: 'closeUpload'});
      this.toast.showToast({title: 'Documents were successfully uploaded'});
    }, (err) => {
      this.toast.showToastFromError(err);
    });
  }

  closePhotoRegime(step?: any): void {
    this.photoRegime = false;
    if (step) {
      this.imgURL[step] = null;
      delete this.twopage[step];
    }
    this.checkValidation();
  }

  savePhoto(e, type) {
    if (e.type === 'clickUpload') {
      if (type === 'filefront') {
        this.showExamples = false;
        this.showIDExamples.front = false;
      } else {
        this.showIDExamples.back = false;
      }
    } else {
      this.loadSpinner = true;
      this.saveFileData(type, e.data);
      let file = [e.data];
      this.previewFile(file, type);
      this.checkValidation();
    }
  }

  previewFile(item, type): void {
    const reader = new FileReader();
    reader.readAsDataURL(item[0]);
    reader.onload = (_event) => {
      this.imgURL[type] = reader.result;
    };

  }

  uploadFile(event: any, type: string, repeated?: boolean): any {
    this.showExamples = false;
    this.showIDExamples.front = false;
    this.showIDExamples.back = false;
    let files = event.target.files;
    if (files.item(0)) {
      const file = files.item(0);
      const extension = ['pdf', 'jpg', 'jpeg', 'png', 'JPG', 'PNG', 'JPEG', 'PDF'];
      const sizeBytes = 4 * 1024 * 1024;
      if (file.size > sizeBytes) {
        this.toast.showErrorToast({title: 'File size should be less than 4MB'});
        event.target.value = null;
        return false;
      }
      let fileExtension = file.name.split('.').slice(-1)[0];
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        this.toast.showErrorToast({title: 'File should be pdf, jpg, jpeg, png'});
        event.target.value = null;
        return false;
      }
      if (['pdf', 'PDF'].indexOf(fileExtension) >= 0) {
        this.pdfName[type] = file.name;
      } else {
        this.previewFile(files, type);
      }

      this.saveFileData(type, file, event);
      this.checkValidation();
    }
  }

  deletePDF(type) {
    this.pdfName[type] = '';
  }

  saveFileData(type, file, event?) {
    if (this.isMobileScreen) {
      this.photoRegime = true;
      this.fileStep = type;
      this.checkReadibility = {
        frontCheck: false,
        backCheck: false,
        statute: false,
        company_register: false,
      };
      if (file && type === 'filefront') {
        this.checkReadibility = {
          frontCheck: true,
          backCheck: false,
          statute: false,
          company_register: false,
        };
        this.twopage[type] = file;
      } else if (file && type === 'fileback') {
        this.checkReadibility.frontCheck = false;
        this.checkReadibility.backCheck = true;
        this.checkReadibility.statute = false;
        this.checkReadibility.company_register = false;
        //  event.target.value = '';
        this.twopage[type] = file;
      } else if (file && type === 'statute' && !this.user.isPrivate && this.user.legalType !== 'soletrader') {
        this.photoRegime = false;
        this.checkReadibility.frontCheck = false;
        this.checkReadibility.backCheck = false;
        this.checkReadibility.statute = true;
        this.checkReadibility.company_register = false;
        //  event.target.value = '';
        this.twopage[type] = file;
      } else if (file && type === 'company_register' && !this.user.isPrivate) {
        this.photoRegime = false;
        this.checkReadibility.frontCheck = false;
        this.checkReadibility.backCheck = false;
        this.checkReadibility.statute = false;
        this.checkReadibility.company_register = true;
        this.twopage[type] = file;
      }
      if (event) {
        event.target.value = '';
      }
    } else {
      this.twopage[type] = file;
    }
  }

  errorMatcher(control: FormControl) {
    return new CustomFieldErrorMatcher(control, this.submitPressed);
  }

  typeDocument(): void {
    this.profileService.getTypeDocument().subscribe(resp => {
      if (resp) {
        let type = DOC_MANGOPAY_TYPE;
        this.documentType = type.filter(item => {
          return resp.identityProof.indexOf(item.name) >= 0;
        });
        if (!this.user.isPrivate && this.user.legalType) {
          this.textCompanyDocuments.article = resp.articleOfAssosiation ? resp.articleOfAssosiation[this.user.legalType] : '';
          this.textCompanyDocuments.registration_proof = resp.registrationProof ? resp.registrationProof[this.user.legalType] : '';
        }
      } else {
        this.documentType = [];
      }
      this.prepareForm();
      this.subscribeToDocTypechange();
    });
  }

  private checkValidation(): void {
    const keysLength = Object.keys(this.twopage).length;
    this.isIdcardValid = this.doctypeCtrl?.value === 2 ? keysLength > 0 : keysLength > 1;
    if (!this.user.isPrivate) {
      const numberDocument = this.user.legalType === 'soletrader' ? 1 : 2;
      this.isIdcardValid = this.doctypeCtrl?.value === 2 ? keysLength > numberDocument : keysLength > (numberDocument + 1);
    }
    this.loadSpinner = false;
    this.validStateChange.emit(this.isIdcardValid && this.isSelectedType);
  }

  private prepareForm(): void {
    if (!this.isSEP && this.documentType) {
      this.doctypeCtrl.setValue(2);
      this.isSelectedType = true;
    }
  }

  private subscribeToDocTypechange(): void {
    this.doctypeCtrl.valueChanges.subscribe(()=> {
      this.showExamples = true;
      this.showIDExamples = {
        front: true,
        back: true
      };
    })
  }
}
