import {
  Component,
  Input, ChangeDetectionStrategy, Inject, PLATFORM_ID
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-project-description',
  templateUrl: './project-description.html',
  styleUrls: ['./project-description.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDescriptionComponent {
  @Input() project;

  constructor( private sanitizer: DomSanitizer,
               @Inject(PLATFORM_ID) private platformId: any,) {
  }
  get myDescription() {
    return this.sanitizer.bypassSecurityTrustHtml(this.project.myProjectDescription);
  }
}
