import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SliderModel} from '../../models';
import {timer} from 'rxjs';

@Component({
  selector: 'app-video-slider',
  templateUrl: './video-slider.component.html',
  styleUrls: ['./video-slider.component.scss']
})
export class VideoSliderComponent {
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    dots: false,
    autoplaySpeed: 5000,
    nextArrow: '<div class=\'nav-btn prev-slide\' style=""><span class="lnr lnr-chevron-right"></span></div>',
    prevArrow: '<div class=\'nav-btn next-slide\' style=""><span class="lnr lnr-chevron-left"></span></div>',
  };
  slide: any;
  user: any;
  sliders: {
    title: string,
    list: SliderModel[]
  };
  isCarouselShow = true;
  @Input() list: any[];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  trackByFn(index, item) {
    return item.id;
  }
}
