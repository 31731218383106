<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <img src="./assets/images/subscribe.png" class="image" alt="subscribe to our newsletter" title="subscribe">
    <div class="block-title">subscribe to our newsletter</div>
    <div class="text">And always stay up to date with new investment opportunities</div>
    <form class="form" [formGroup]="form">
      <mat-form-field class="item">
        <mat-label>Name</mat-label>
        <input matInput [formControl]="nameCtrl" [errorStateMatcher]="errorMatcher(nameCtrl)">
      </mat-form-field>
      <mat-form-field class="item">
        <mat-label>E-mail</mat-label>
        <input matInput [formControl]="emailCtrl" [errorStateMatcher]="errorMatcher(emailCtrl)">
      </mat-form-field>
      <button class="button yellow text-uppercase" (click)="onSubmit()" [disabled]="(!form.valid && submitPressed) || submitting">
        Subscribe
      </button>
    </form>
  </div>
</app-popup>
