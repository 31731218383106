import {
  Component, EventEmitter,
  Input, Output,
} from '@angular/core';
import {AppToastrService, SettingService} from '../../../services';
import {ProjectsService} from '../../../services/projects.service';

@Component({
  selector: 'app-project-bids',
  templateUrl: './project-bids.html',
})
export class ProjectBidsComponent {
  selectedBid: any;
  submitBtn: boolean;
  showPopup: boolean;
  @Input() project?: any;
  @Input() user: any;
  @Input() tokenBids;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private toastr: AppToastrService,
    private settingsService: SettingService,
    private projectService: ProjectsService,
  ) {
  }

  updateItemBid(bid): void {
    this.selectedBid = bid;
    this.showPopup = true;
  }

  onNotify(e) {
    if (e.type === 'close') {
      this.showPopup = false;
    } else {
      this.proceedUpdateBid(e);
    }

  }

  proceedUpdateBid(bid): void {
    this.showPopup = false;
    this.selectedBid.submitBtn = true;
    let bidAmount = bid.bidAmount;

    if (this.settingsService.validationIntegerAmount(bidAmount)) {
      bidAmount = 0;
      return this.toastr.showErrorToast({message: 'Not valid amount'});
    }

    if (this.user.revenues.left < Number(bidAmount)) {
      this.selectedBid.submitBtn = false;
      return this.toastr.showErrorToast({message: 'You don\'t have enough money'});
    }
    if (this.selectedBid.amount > Number(bidAmount)) {
      this.selectedBid.submitBtn = false;
      return this.toastr.showErrorToast({message: 'Low bid'});
    }
    if (bidAmount > 0 && (bidAmount > this.selectedBid.amount)) {
      const bidId = this.selectedBid.id;
      const body: any = {
        bid: Number(bidAmount),
        _w: this.user.nft.walletId,
        auctionId: this.project.auctionId,
      };
      if (this.project.isSecondaryAuction) {
        body.auctionMarket = this.project.auctionMarket;
      }
      this.projectService._updateBid(this.project.slug, bidId, body).subscribe(resp => {
        this.selectedBid.submitBtn = false;
        setTimeout(() => {
          this.notify.emit({type: 'updateBid'});
        }, 2000);
        if (resp.isInWinningList) {
          this.toastr.showToast({title: 'Success bid'});
        } else {
          this.toastr.showToastFromError(this.toastr.bidErrorStatus(resp));
        }
      }, err => {
        this.selectedBid.submitBtn = false;
        this.toastr.showToastFromError(err);
      });
    }
  }
}
