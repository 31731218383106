import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UserModel } from '@app/models';

// Consts
import { STATE_NAMES } from 'app/common';

// Services
import { StorageService } from '@services/storage.service';
import { ProfileService } from 'app/services';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.html',
  styleUrls: ['./login.scss'],
})
export class LoginPageComponent implements OnInit {
  private user: UserModel;
  p_state: any = {
    state: '',
  };

  constructor(
    private storageService: StorageService,
    private router: Router,
    private location: Location,
    private profileService: ProfileService
  ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.p_state = this.router.getCurrentNavigation().extras.state;
    }
  }

  ngOnInit(): void {
    this.user = this.storageService.user;
    if (this.user) {
      this.router.navigate([`/${STATE_NAMES.home}`]);
      this.profileService.emitProfileUpdate(this.user);
    }
  }

  login(): void {
    this.redirectFunction();
  }

  goToRegister(): void {
    this.router.navigate([`/${STATE_NAMES.register}`], {
      state: {
        params: this.p_state.params,
        state: this.p_state.state,
      },
    });
  }

  private redirectFunction(): void {
    const p = this.p_state;
    const isWouldInvest = JSON.parse(
      this.storageService.getStorageItem('wouldInvest')
    );
    if (isWouldInvest) {
      this.location.back();
    }
    const redirectToHomePage = !!(
      [
        STATE_NAMES.register,
        STATE_NAMES.forgot,
        STATE_NAMES.login,
        STATE_NAMES.changePassword,
      ].indexOf(p.state) >= 0
    );

    if (this.storageService.getStorageItem('lastAttemptedUrl')) {
      this.router.navigateByUrl(
        this.storageService.getStorageItem('lastAttemptedUrl')
      );
    } else {
      this.router.navigate(
        redirectToHomePage
          ? [`/${STATE_NAMES.home}`]
          : p.state
          ? [`/${p.state}`, p.slug]
          : [`/${STATE_NAMES.home}`],
        { state: p.params }
      );
    }
  }
}
