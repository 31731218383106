import {Component, Output, EventEmitter, HostListener, OnInit, OnDestroy} from '@angular/core';
import {AppToastrService} from '../../../services';
import {AuthenticationService} from '../../../services/authentication.service';
import {StorageService} from '../../../services/storage.service';

@Component({
  selector: 'app-verify-conditions-popup',
  templateUrl: './verify-conditions.html'
})


export class VerifyConditionsComponent implements OnInit {
  user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(private authenticationService: AuthenticationService,
              private toastr: AppToastrService,
              private storageService: StorageService) {
  }

  ngOnInit() {
    this.user = this.storageService.user;
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  onSubmit() {
    this.authenticationService.updateOptionalSettings({termsAndConditions: true}).subscribe(item => {
      if (item) {
        this.user.termsAndConditions = true;
        this.storageService.user = this.user;
        this.notify.emit({type: 'close'});
      }
    }, err => {
      this.notify.emit({type: 'close'});
      this.toastr.showToastFromError(err);
    });
  }
}
