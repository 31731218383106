export class ArtistModel {
  constructor() {

  }

  imgUrl: string;
  id: number;
  name: string;
  type: string;
  contact: any;
  country: string;
  image: string;
  slug: string;
  countryCode: string;
  social: any;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel._id;
    this.name = apiModel.name;
    this.type = apiModel.__t;
    this.image = apiModel.image && !apiModel.image.includes("undefined") ? apiModel.image : null;
    this.country = apiModel.country;
    this.countryCode = apiModel.countryCode;
    this.slug = apiModel.slug;
    this.contact = apiModel.contact;
    this.social = apiModel.social;
    return this;
  }
}
