import {
  AfterContentInit,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  OnInit,
  ViewChild,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.html'
})
export class CheckBoxComponent implements OnInit, AfterContentInit {
  showLabel = true;
  @Input() name = '';
  @Input() value = false;
  @Input() defaultValue = false;
  @Input() switcher;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('label', {static: false}) label: ElementRef;
  @HostBinding('class.disabled') @Input() disabled = false;

  constructor() {

  }

  ngOnInit() {
  }

  @HostListener('click') onClick() {
    if (!this.switcher) {
      this.value = !this.value;
      this.valueChange.emit(this.value);
    }
  }

  @HostListener('keydown.space') onKeySpace() {
    if (!this.switcher) {
      this.value = !this.value;
      this.valueChange.emit(this.value);
    }
  }

  modeToggleSwitch() {
    if (!this.disabled) {
      this.value = !this.value;
      this.valueChange.emit(this.value);
    }
  }

  ngAfterContentInit() {
   // this.showLabel = this.label.nativeElement.innerHTML.trim().length > 0;
  }
}
