import {
  Component,
  Input, ChangeDetectionStrategy, Inject, PLATFORM_ID, OnInit, AfterViewInit, Renderer2, ElementRef
} from '@angular/core';
import * as FileSaver from 'file-saver';
import {DownloadRoyalties} from "@app/pages/project/service/download-royalties.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {catchError, shareReplay} from "rxjs/operators";
import {EMPTY, Observable} from "rxjs";
import {AppToastrService} from "@app/services";
import {
  RoyaltyAgreementLanguageInterface,
} from "@app/interfaces/agreement.interface";

@UntilDestroy()
@Component({
  selector: 'app-project-you-get',
  templateUrl: './project-you-get.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectYouGetComponent implements OnInit, AfterViewInit {
  agreementLanguage$: Observable<RoyaltyAgreementLanguageInterface>;
  @Input() project;

  constructor(private downloadService: DownloadRoyalties,
              private toastr: AppToastrService,
              private elementRef: ElementRef, private renderer: Renderer2,
              @Inject(PLATFORM_ID) private platformId: any,) {
  }

  ngOnInit() {
    this.fetchAgreementLanguage();
  }

  ngAfterViewInit() {
    const ulElements = this.elementRef.nativeElement.querySelectorAll('.gray-text.inner-text ul');
    ulElements.forEach(ul => {
      this.renderer.setStyle(ul, 'list-style', 'disc');
      const liElements = ul.querySelectorAll('li');
      liElements.forEach(li => {
        this.renderer.setStyle(li, 'margin-bottom', '10px');
      });
    });
  }

  fetchAgreementLanguage(): void {
    this.agreementLanguage$ = this.downloadService.getRoyaltyDocument(this.project.slug).pipe(
      shareReplay(1),
      catchError((err) => {
        this.toastr.showToastFromError(err);
        return EMPTY;
      })
    );
  }

  openDocument(e, language) {
    e.preventDefault();
    this.downloadService.downloadRoyaltyDocument(this.project.slug, language).pipe(
      untilDestroyed(this),
      catchError((err) => {
        this.toastr.showToastFromError(err);
        return EMPTY;
      }))
      .subscribe(resp => {
        const name: string = `agreement - ${this.project.projectTitle}.pdf`;
        FileSaver.saveAs(resp, name);
      });
  }
}
