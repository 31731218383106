import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent {
  @Input() activeTab: number;
  @Input() tabs: string[];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  setState(num: number) {
    this.notify.emit({value: num});
  }
}
