import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {SettingService} from '../services';
import {StorageService} from '../services/storage.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  private storage: StorageService;
  private settings: SettingService;

  constructor(private injector: Injector) {
    this.settings = injector.get(SettingService);
    this.storage = injector.get(StorageService);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(this.settings.apiUrl) && req.url !== `${this.settings.apiUrl}/authtoken`) {
      if (this.storage.accessToken && this.storage.accessToken !== 'undefined') {
        const authHeader = `Bearer ${this.storage.accessToken}`;
        const authReq = req.clone({headers: req.headers.set('X-Authorization', authHeader)});
        return next.handle(authReq);
      }
    }
    return next.handle(req);
  }
}
