<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClosePopup()"></span>
    <div class="inner-content">
      <table class="table noborder investment-table">
        <thead>
        <tr>
          <th class='max-screen mid-screen'>Cash-Out <br><span>(per transaction)</span></th>
          <th class='max-screen mid-screen'>EURO</th>
          <th class='max-screen mid-screen'>GBP</th>
          <th class='max-screen mid-screen'>Other currencies</th>
        </tr>
        </thead>
        <tbody>
        <tr class="border_bottom select-account">
          <td class='max-screen mid-screen'>Domestic</td>
          <td class='max-screen mid-screen'>Free<br><span>(within SEPA zone)</span></td>
          <td class='max-screen mid-screen'>£0.45</td>
          <td class='max-screen mid-screen'>2.50€ <br><span> (billed in your own currency)</span></td>
        </tr>
        <tr class="border_bottom select-account">
          <td class='max-screen mid-screen'>Non-domestic</td>
          <td class='max-screen mid-screen'>Free<br><span>(within SEPA zone)</span></td>
          <td class='max-screen mid-screen'>2.50€</td>
          <td class='max-screen mid-screen'>2.50€ <br><span> (billed in your own currency)</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-popup>
