<app-popup>
  <div class="inner">
    <app-popup-part-header
      [icon]="'./assets/images/be-icons/reminder.PNG'"
      (notify)="onClose()">
      <ng-template [ngIf]="!user?.mangoUserCreated">
        <h2 class="popup-title">INFORMATION MISSING</h2>
        <p class="popup-sub-title">
          In order to place your bid, please complete your profile and create your Magic Wallet ID. Please also make
          sure to upload enough funds to your account.
        </p>
      </ng-template>
      <ng-template [ngIf]="user?.mangoUserCreated && !user?.nft?.walletId">
        <h2 class="popup-title">MAGIC WALLET MISSING</h2>
        <p class="popup-sub-title">
          In order to place your bid, please create your Smart Contract Wallet. This Smart Contract Wallet is used to
          secure your Music Collectibles on the blockchain.
        </p>
      </ng-template>
      <ng-template [ngIf]="user?.mangoUserCreated && user?.nft?.walletId && !lowAmount">
        <h2 class="popup-title">INSUFFICIENT FUNDS</h2>
        <p class="popup-sub-title">
          We’re afraid your account balance is too low to successfully place this bid. Please go to your account and
          upload enough funds to your account.
        </p>
      </ng-template>
    </app-popup-part-header>
    <div class="popup-content">
      <button class="button yellow text-uppercase popup-button" (click)="onSubmit()">
        <ng-template [ngIf]="!user?.mangoUserCreated">
          COMPLETE PROFILE
        </ng-template>
        <ng-template [ngIf]="user?.mangoUserCreated && !user?.nft?.walletId">
          CREATE MAGIC WALLET
        </ng-template>
        <ng-template [ngIf]="user?.mangoUserCreated && user?.nft?.walletId && !lowAmount">
          UPLOAD FUNDS
        </ng-template>
      </button>
    </div>
  </div>
</app-popup>
