<ng-template #slideWrapper let-slide="slide">
  <div class="slider-text" *ngIf="type !== 'marketplace'">
    <div class="slider-wrapper">
      <h2 class="slider-title" [innerHTML]="slide?.mainTitle"></h2>
      <p class="slider-subtitle" [innerHTML]="slide?.title"></p>
      <ng-template ngIf [ngIf]="!user || (!slide.isRegisterURL && user)">
        <a
          [href]="slide?.url"
          class="button slider-btn text-uppercase grs-button--orange"
          *ngIf="slide?.text"
          [target]="slide?.targetBlank ? '_blank': '_self'"
          >{{slide?.text}}</a
        >
        <a
          [href]="slide?.url2"
          class="button slider-btn text-uppercase grs-button--white"
          *ngIf="slide?.text2"
          [target]="slide?.targetBlank ? '_blank': '_self'"
          >{{slide?.text2}}</a
        >
      </ng-template>
    </div>
  </div>
  <div class="slider-text" *ngIf="type === 'marketplace'">
    <div class="slider-wrapper">
      <h2 class="slider-title text-uppercase">NFT marketplace</h2>
      <p>Buy, sell & trade Music Assets</p>
      <ng-template ngIf [ngIf]="!user || (!slide.isRegisterURL && user)">
        <div class="market-container">
          <a
            [href]="slide?.url"
            class="button slider-btn text-uppercase"
            *ngIf="slide?.text && type"
            [target]="slide?.targetBlank ? '_blank': '_self'"
            >BUY YOUR NFT</a
          >
          <a
            [href]="slide?.url"
            class="button slider-btn text-uppercase"
            *ngIf="slide?.text && type"
            [target]="slide?.targetBlank ? '_blank': '_self'"
            >SELL YOUR NFT</a
          >
        </div>
      </ng-template>
    </div>
  </div>
</ng-template>

<div class="section-slider">
  <ngx-slick-carousel
    class="carousel"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)"
    (afterChange)="_afterChange($event)"
    (beforeChange)="_beforeChange($event)"
  >
    <div
      ngxSlickItem
      *ngFor="let slide of sliders?.list; trackBy: trackByFn;"
      class="slide"
    >
      <div class="image-wrapper">
        <picture>
          <source srcset="{{slide?.imageMobile}}" media="(max-width: 767px)" />
          <source srcset="{{slide?.image}}" />
          <img class="image" srcset="{{slide?.image}}" alt="My default image" />
        </picture>
        <a
          [href]="slide?.url"
          [target]="slide?.targetBlank ? '_blank': '_self'"
          (click)="checkUrlExist($event, slide?.url)"
        >
          <ng-container
            *ngTemplateOutlet="slideWrapper; context: {slide: slide}"
          ></ng-container>
        </a>

        <!--<img class="image"-->
        <!--[src]="slide?.image"-->
        <!--loading="lazy">-->
        <!--<ng-container *ngTemplateOutlet="slideWrapper; context: {slide: slide}"></ng-container>-->
      </div>
      <!--<div  class="image-wrapper mobile">-->
      <!--<img class="image" [src]="slide?.imageMobile" loading="lazy">-->
      <!--<ng-container *ngTemplateOutlet="slideWrapper; context: {slide: slide}"></ng-container>-->
      <!--</div>-->
    </div>
  </ngx-slick-carousel>
</div>
