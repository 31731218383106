<app-popup>
  <div class="inner popup-inner">
    <app-popup-part-header
      [icon]="'./assets/images/be-icons/profile.png'"
      (notify)="onClose()">
      <h2 class="popup-title">Please Create A NICKNAME</h2>
      <p class="popup-sub-title">
        to secure your privacy while selling Music Assets
        on Global Rockstar’s marketplace
      </p>
    </app-popup-part-header>
    <div class="popup-content">
      <div class="popup-input" [class.error]="duplicate">
        <img src="./assets/images/be-icons/envelop.png" class="popup-image">
        <input type="text" placeholder="Nickname" (keyup)="changeValue()" class="subscribe__input" [formControl]="nicknameCtrl">
        <p class="popup-error" *ngIf="duplicate">Nickname has already exist</p>
      </div>
      <button class="button yellow text-uppercase popup-button" (click)="onSubmit()"
              [disabled]="!nicknameCtrl?.value || submitBtn">
        Submit
      </button>
    </div>
  </div>
</app-popup>
