import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SettingService} from './setting.service';
import {Observable} from 'rxjs';
import {CardModel} from '../models/card.model';
import {RequestModel, SliderModel} from '../models';
import {IPaginationResponse} from '../types/common.interface';
import {map} from 'rxjs/operators';


@Injectable()
export class CollectibleService {
  apiUrl: string;
  imgUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
    this.imgUrl = this.setting.imgUrl;
  }

  createAuction(data): Observable<boolean> {
    return this.http.post<boolean>(`${this.apiUrl}/collectible/auction`, data);
  }

  getEditions(request: RequestModel): Observable<IPaginationResponse<CardModel[]>> {
    const currency: string = this.setting.curency.toLowerCase();
    const params = request ? request.getApiModel() : null;
    return this.http.get<IPaginationResponse<CardModel[]>>(`${this.apiUrl}/editions`, {params}).pipe(map((resp: any) => {
      return {
        list: resp.docs.map(item => new CardModel().setFromApiModel(item, currency, resp.enableLogo, true)),
        total: resp.total
      };
    }));
  }

  getSlider(): Observable<any> {
    return this.http.get(`${this.apiUrl}/landing/home-page`).pipe(map((resp: any) => {
      let sliders = [];
      sliders = resp.slider.list.map(item => new SliderModel(this.imgUrl).setFromApiModel(item));
      return {
        list: sliders
      };
    }));
  }
}
