<div [style.backgroundImage]="'url(assets/images/slider1.jpg)'" class="background-image">
</div>
<main>
  <section class="user__details">
    <div class="user__profile-img" [style.backgroundImage]="user?.image ? 'url('+ user?.image +')' : null"></div>
    <div class="user__add-info">
      <h1 class="page-header__title">{{title}}</h1>
      <div class="page-header__content">
        <ng-content></ng-content>
      </div>
    </div>
  </section>
</main>
