<ng-template #slideWrapper let-slide="slide">
  <div class="slider-text">
    <div class="slider-wrapper">
      <h1 class="slider-title text-uppercase">{{slide?.mainTitle}}</h1>
      <p>{{slide?.title}}</p>
      <a [href]="slide?.url" class="button slider-btn text-uppercase" *ngIf="slide?.text"
         [target]="slide?.targetBlank ? '_blank': '_self'">{{slide?.text}}</a>
    </div>
  </div>
</ng-template>

<!--<app-loader *ngIf="!sliders"></app-loader>-->

<div class="section-slider">
  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig"
                      (init)="slickInit($event)"
                      (breakpoint)="breakpoint($event)"
                      (afterChange)="afterChange($event)"
                      (beforeChange)="beforeChange($event)">
    <div ngxSlickItem *ngFor="let slide of carousel?.list; trackBy: trackByFn;" class="slide">
      <div [style.backgroundImage]="'url(' + slide?.image + ')'" class="image-wrapper desktop">
        <ng-container *ngTemplateOutlet="slideWrapper; context: {slide: slide}"></ng-container>
      </div>
      <div [style.backgroundImage]="'url(' + slide?.imageMobile + ')'" class="image-wrapper mobile">
        <ng-container *ngTemplateOutlet="slideWrapper; context: {slide: slide}"></ng-container>
      </div>
    </div>
  </ngx-slick-carousel>
</div>
