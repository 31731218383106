import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AppToastrService, SettingService } from 'app/services';
import { EMAIL_PATTERN, STATE_NAMES } from '../../common';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { IEventEmiter } from '../../interfaces/social-user.interface';
import { ProjectsService } from '../../services/projects.service';
import { ProjectsModel, RequestModel } from '../../models';
import { ModalService } from '@services/modal.service';
import { VerifyEmailComponent } from '../popups/verify-email/verify-email.component';
import { PaymentBlockComponent } from '../popups/payment-block/payment-block.component';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GetLocaleService } from '@services/get-locale.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MagicAuthService } from '@app/services/magic-auth.service';
import { StorageService } from '@app/services/storage.service';

@UntilDestroy()
@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.html',
  styleUrls: ['./calculation.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationComponent implements OnInit, OnChanges {
  @Input() project: any;
  @Input() user: any;
  @Input() newsLetterNotification: string;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  share: any;
  config = {
    displayTitle: true,
  };
  slug: string;
  shares: any[] = [];
  payAmount = 0;
  socialrow = false;
  youtubeFollowers: number;
  spotifyId: any;
  height: number;
  submitBtn: boolean;
  bidAmount: number;
  menuActive: boolean;
  showPopup: boolean;
  innerProject: any;
  firstDownload = true;
  getShare: boolean;
  emailCtrl: FormControl;
  form: FormGroup;
  sendNewsletter: boolean;
  remindMeStatus: boolean;
  isBlockedByRefund: boolean = false;

  constructor(
    private router: Router,
    private settingsService: SettingService,
    private toastr: AppToastrService,
    private localeService: GetLocaleService,
    private authenticationService: AuthenticationService,
    private projectService: ProjectsService,
    private fb: FormBuilder,
    private modalService: ModalService,
    private magicAuthService: MagicAuthService,
    private storage: StorageService
  ) {
    this.share = 1;
    this.firstDownload = true;
  }

  ngOnInit() {
    this.innerProject = JSON.parse(JSON.stringify(this.project));
    this.emailCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN),
    ]);
    this.form = this.fb.group({
      email: this.emailCtrl,
    });
    this.slug = this.project.slug;
    this.getSpotifyLink();

    if (this.user && this.user?.mangoVerificationStatus?.id !== 1) {
      this.checkRefundBlock$().subscribe();
    }
  }

  ngOnChanges(e): void {
    if (e.user && e.user.currentValue && this.emailCtrl) {
      this.emailCtrl.setValue(this.user.contact.email);
    }
  }

  checkRefundBlock$(): Observable<any> {
    return this.projectService.getMyInvestments(new RequestModel({})).pipe(
      tap((responce: { investments: ProjectsModel[] }) => {
        const hasRefund: boolean = !!responce?.investments?.some(
          (investment: ProjectsModel) => investment.isRefund
        );
        this.isBlockedByRefund = hasRefund;
      })
    );
  }

  remindMe(e): void {
    e.preventDefault();
    if (this.form.valid) {
      this.remindMeStatus = true;
      const body = {
        email: this.emailCtrl.value,
      };
      this.projectService._remindMe(this.project.auctionMarket, body).subscribe(
        (resp) => {
          this.toastr.showToast({ title: 'Success' });
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
    }
  }

  subscribeNewsletter(e): void {
    e.preventDefault();
    if (this.form.valid) {
      const body = {
        email: this.emailCtrl.value,
        firstName: this.emailCtrl.value.split('@')[0],
      };
      this.sendNewsletter = true;
      this.authenticationService.subscribeVisitor(body).subscribe(
        (res) => {
          this.notify.emit({ type: 'updateNewsletter' });
        },
        (err) => {
          this.sendNewsletter = false;
        }
      );
    }
  }

  goToHome(e) {
    e.preventDefault();
    this.router.navigate([
      this.project.editionNumber
        ? `/${STATE_NAMES.marketplace}`
        : `/${STATE_NAMES.home}`,
    ]);
  }

  gotToRegister(e) {
    e.preventDefault();
    //  this.stopMusic();
    this.router.navigate([`/${STATE_NAMES.register}`]);
  }

  addWallet(e: IEventEmiter): void {
    if (e.type !== 'closePopup') {
      const magicWallet = !!(this.user.nft && this.user.nft.walletId);
      const mangoWallet = this.user.mangoUserCreated;
      const money = Number(this.user.revenues.left) < this.bidAmount;
      if (!mangoWallet) {
        this.router.navigate([`/${STATE_NAMES.profile}`]);
      } else if (!magicWallet) {
        this.router.navigate([`/${STATE_NAMES.nftWizard}`], {
          state: { project: this.project.slug, isCollectible: true },
        });
      } else if (money) {
        this.router.navigate([`/${STATE_NAMES.revenue}`]);
      }
    }
    this.showPopup = !this.showPopup;
  }

  _makeBid(e) {
    e.preventDefault();
    if (this.project.finished) {
      return this.toastr.showErrorToast({ message: 'Auction is closed' });
    }
    if (!this.user) {
      this.router.navigate([`/${STATE_NAMES.login}`]);
    }

    if (
      !this.user.mangoUserCreated ||
      !this.user.nft ||
      !this.user.nft.walletId
    ) {
      return (this.showPopup = true);
    }

    if (!this.user.nft || !this.user.nft.walletId) {
      return this.toastr.showErrorToast({
        message: 'You dont have nft wallet. Please generate it',
      });
    }

    if (this.settingsService.validationIntegerAmount(this.bidAmount)) {
      this.bidAmount = 0;
      return this.toastr.showErrorToast({ message: 'Not valid amount' });
    }

    if (Number(this.user.revenues.left) < this.bidAmount) {
      return (this.showPopup = true);
    }

    if (this.bidAmount < this.project.startingPrice) {
      return this.toastr.showErrorToast({
        message: 'Bid less than starting price',
      });
    }

    if (!this.submitBtn) {
      this.submitBtn = true;

      const body: any = {
        bid: Number(this.bidAmount),
        _w: this.user.nft.walletId,
        auctionId: this.project.auctionId,
      };
      if (this.project.isSecondaryAuction) {
        body.auctionMarket = this.project.auctionMarket;
      }
      this.projectService.makeBid(this.project.slug, body).subscribe(
        (resp) => {
          this.submitBtn = false;
          setTimeout(() => {
            this.notify.emit({ type: 'updateBid' });
          }, 2000);

          if (resp.isInWinningList) {
            this.toastr.showToast({ title: 'Success bid' });
          } else {
            this.toastr.showToastFromError(this.toastr.bidErrorStatus(resp));
          }
        },
        (err) => {
          this.submitBtn = false;
          this.toastr.showToastFromError(err);
        }
      );
    }
  }

  getSpotifyLink(): void {
    if (
      this.project.artist &&
      this.project.artist.social &&
      this.project.artist.social.spotify
    ) {
      this.spotifyId =
        'https://open.spotify.com/follow/1?uri=spotify:artist:' +
        this.project.spotifyUser +
        '&size=basic&theme=light&show-count=0';
    }
  }
  alertPopupHandle(isClose?: boolean): void {
    this.getShare = false;
    if (!isClose) {
      this.router.navigate([`/${STATE_NAMES.home}`]);
    }
  }
  goToShare(e: Event, invalid: boolean) {
    e.preventDefault();

    // Remove to unlock USA
    // if (this.user?.country === 'US') {
    //   this.getShare = true;
    //   return false;
    // }

    if (!invalid) {
      this.share =
        this.share !== 'all' ? this.share : this.project.totalInvestorsLeft;

      // MAgic OTP flow for logged in USA users
      if (
        this.user &&
        this.user.state !== 'verified' &&
        this.localeService.isUSUser
      ) {
        this.magicAuthService
          .generateWalletWithOTP()
          .subscribe((DID: string) => {
            this.router.navigate(['api/verify', this.router.url], {
              queryParams: {
                magic_credential: DID,
                grstoken: this.storage.accessToken,
              },
            });
          });

        // show verify popup for non verified email for non USA users
      } else if (
        this.user &&
        this.user.state !== 'verified' &&
        !this.localeService.isUSUser
      ) {
        this.modalService.open(VerifyEmailComponent);
        this.projectService.verifyPopup(true);
        // check block by refund
      } else if (this.isBlockedByRefund) {
        this.modalService.open(PaymentBlockComponent);
      } else {
        // navigate to billing
        this.router.navigate([`/${STATE_NAMES.wizard}`], {
          state: {
            share: this.share,
            slug: this.slug,
            project: this.project.routeProjectModel(),
            payAmount: Number(this.payAmount.toFixed(2)),
          },
        });
      }
    }
  }

  selectAmountToPay(data) {
    this.payAmount = data.payAmount;
    this.share = data.share;
  }
}
