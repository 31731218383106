<ng-template #slideWrapper let-slide="slide">
  <div class="slider-text">
    <div class="slider-wrapper">
      <h1 class="slider-title text-uppercase">{{slide?.mainTitle}}</h1>
      <p>{{slide?.title}}</p>
      <ng-template [ngIf]="!user || (!slide.isRegisterURL && user)">
        <a [href]="slide?.url" class="button slider-btn text-uppercase" *ngIf="slide?.text"
           [target]="slide?.targetBlank ? '_blank': '_self'">{{slide?.text}}</a>
      </ng-template>
    </div>
  </div>
</ng-template>


<div class="section-slider-music">
  <ngx-slick-carousel *ngIf="isCarouselShow" class="carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let slide of list; trackBy: trackByFn;" class="slide">
      <div class="img-block" *ngIf="slide?.imgUrl">
        <img (click)="openProject(slide?.slug, slide?.song?.featureFm)"
             [style.width]="width" [style.height]="height" [src]="slide?.image"
             alt="slider image featureFm"  loading="lazy">
        </div>
        <div class="img-block" *ngIf="!slide?.imgUrl">
         <img (click)="openProject(slide?.slug, slide?.song?.featureFm)"
              [style.width]="width" [style.height]="height"
              [src]="imgUrl+ '/' + slide?.image" alt="slider image featureFm"  loading="lazy">
        </div>

    </div>
  </ngx-slick-carousel>
</div>
