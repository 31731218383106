<div class="mobile-view-p-block">
  <div class="mobile-view-p__title" [class.show]="show" (click)="toggleShow()">
    <img loading="lazy"
         alt="line"
         [class.rotate]="show"
         src="./assets/images/be-icons/chevron-down.png"/>
    <h2 class="project__title-sub">{{ title }}</h2>
  </div>
  <div class="mobile-view-p__body" [class.show]="show">
    <div class="wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>
