<app-popup>
  <div class="inner">
    <!--<span class="lnr lnr-cross default-cross" (click)="onClose()"></span>-->
    <img src="./assets/images/bell.png" class="image">
    <div class="text">User experience is very important to us, so we constantly improve our website.
      That's why we introduced new features for you:<br>
      <ul>
        <li>Headliner Artists: This are our keep-it-all investment opportunities, where we guarantee the release of
          artists with an advanced track record.
        </li>
        <li>Newcomer Artists: This are all-or-nothing investment opportunities, where the release of the song is under
          condition to the total funding.
        </li>
        <li>Talents: We ask for your vote for our upcoming Talents.</li>
      </ul>
      For your convenience all legal documents will be available in EN and DE language.<br>
      Please find here our updated 
      <a class="link-blue" [grsOpenFile]="'terms'">Terms & Conditions</a> 
      and 
      <a class="link-blue" [grsOpenFile]="'dataPolicy'">Privacy Policy.</a>
      <br>
      To continue investing, please confirm that you have read and agree with the changes.
    </div>
    <button class="button yellow text-uppercase" (click)="onSubmit()">
      Accept
    </button>
  </div>
</app-popup>
