import {Component, Output, EventEmitter, HostListener, OnInit, Input} from '@angular/core';
import {ConfigAlertPopup} from '../../../common';

@Component({
  selector: 'app-delete-popup',
  templateUrl: './delete-popup.html'
})


export class DeletePopupComponent implements OnInit {
  @Input() config: ConfigAlertPopup;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.notify.emit({type: 'closeConfirm'});
  }

  onConfirm(): void {
    this.notify.emit({type: this.config.nameTypeConfirm});
  }

  onClosePopup(): void {
    this.notify.emit({type: 'closePopup'});
  }
}
