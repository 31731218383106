import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ApiService} from "@services/api.service";
import {ResponseInterface} from "@app/interfaces/response.interface";
import {
  RoyaltyAgreementLanguageInterface
} from "@app/interfaces/agreement.interface";
import {shareReplay} from "rxjs/operators";


@Injectable()
export class DownloadRoyalties {
  constructor(private apiService: ApiService) {}

  getRoyaltyDocument(slug: string): Observable<RoyaltyAgreementLanguageInterface> {
    const endpoint = `projects/${slug}/agreements`;
    return this.apiService.get<ResponseInterface<RoyaltyAgreementLanguageInterface>>(endpoint, {}).pipe(
      map(response => response.data),
      shareReplay(1),
      );
  }

  downloadRoyaltyDocument(slug: string, language: string = 'en'): Observable<Blob> {
    const endpoint = `projects/${slug}/agreement?language=${language}`;
    return this.apiService.getBlob(endpoint);
  }
}


