import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile-refund-cost',
  templateUrl: './profile-refund-cost.html',
  styleUrls: ['./profile-refund-cost.scss'],
})
export class ProfileRefundCostComponent implements OnInit {
  refundTypeCtrl: FormControl;

  public readonly refundT = [
    {
      id: 1,
      name: 'Bank account',
    },
    {
      id: 2,
      name: 'GRS balance',
    },
  ];

  @Input() type: number = null;

  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    this.refundTypeCtrl = new FormControl(this.type || '');
    this.refundTypeCtrl.valueChanges.subscribe((value) => {
      this.saveSettings(value);
    });
  }

  saveSettings(value: 1 | 2): void {
    this.notify.emit({ type: 'refundType', value });
  }
}
