<bank-account-form
  *ngFor="let account of accounts"
  [account]="account"
  [country]="country"
></bank-account-form>
<span
  class="bank-account__btn link-blue text-uppercase"
  *ngIf="showAddButton && isAllowedToAddAccount"
  (click)="addBlankAccount()"
  >+ add bank account</span
>
<p class="color-light-grey title16" *ngIf="isAllowedToAddAccount && !accounts.length">
  Please add your bank account data to be able to withdraw.
</p>
<p class="color-light-grey title16" *ngIf="!isAllowedToAddAccount">
  Please update your profile to be able to add a bank account.
</p>
