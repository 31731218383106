import {Injectable} from '@angular/core';
import {map, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {SettingService} from './setting.service';
import {VoucherModel} from '../models';

@Injectable()
export class PledgeService {
  apiUrl: string;

  private voucherBallance: BehaviorSubject<VoucherModel[]> = new BehaviorSubject(null);

  voucherBallance$: Observable<VoucherModel[]> = this.voucherBallance.asObservable();

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }

  pledgeNFT(slug: any): Observable<any> {
    return this.http.get(`${this.apiUrl}/project/${slug}/pledge-nft`);
  }

  createPledge(slug: string, data: any): Observable<any>{
    return this.http.post(`${this.apiUrl}/project/${slug}/pledge`, data);
  }

  // getRedirectUrl(slug: string, data: any): Observable<any> {
  //   return this.http.post(`${this.apiUrl}/project/${slug}/pledge`, data);
  // }

  setApplyPaySession(validationData): Observable<any> {
    return this.http.post(`${this.apiUrl}/apple`, {url: validationData});
  }

  googlePaymentToMangopay(slug: string, data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/project/${slug}/googlepay`, data);
  }

  paymentToAccount(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/payment`, data);
  }

  setDraftInvest(slug: string): Observable<boolean> {
    return this.http.get<boolean>(`${this.apiUrl}/project/${slug}/draft`);
  }

  checkTransaction(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/transaction-status`, data);
  }

  checkVoucherTransaction(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/voucher/transaction-status`, data);
  }

  getVoucherRedirectUrl(data: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/voucher/pledge`, data);
  }

  setVoucherBallance(vouchers: VoucherModel[] | null): void {
    this.voucherBallance.next(vouchers);
  }

  getVoucherBallance(): VoucherModel[] | null {
    return this.voucherBallance.getValue();
  }

  getVoucher(): Observable<VoucherModel[]> {
    return this.http.get(`${this.apiUrl}/voucher`)
    .pipe(
      map((resp: any) => {
        return resp.map(item => new VoucherModel().setFromApiModel(item));
      }),
      tap((vouchers) => this.setVoucherBallance(vouchers)),
    );
  }
}
