<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <img src="./assets/images/bell.png" class="image">
    <div class="text">
      <h1 class="text-uppercase">Thank you for your investment!</h1>
      <p class="sub-text"> We have recognized that you have not registered to stay up to date around your investment.
        As investor it’s recommended to receive investor informations about the songs you have invested in.</p>

    </div>
    <div class="flex-align sign-in">
      <app-checkbox class="checkbox-container" [(value)]="newsLetter" [defaultValue]="true"></app-checkbox>
      <div class="title14 agreement-text">
       <span> Sign me in to investor information about all developments of my investment. </span><br><br>
        I will be up to date regarding the
        release of the song, media coverages, performance (report on streams, etc.) and other success stories around
        your
          song.
      </div>
    </div>
    <div class="flex-align button-wrapper">
      <span class="cancel" (click)="onClose()">Cancel</span>
      <button type="button" class="button yellow title16 text-uppercase" [disabled]="!newsLetter" (click)="onSignIn()">
        Sign me in
      </button>
    </div>
  </div>
</app-popup>
