import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// Types
import { PriceForShare } from '@app/types';

// Services
import { SettingService } from '@app/services';

@Pipe({ name: 'localeCurrency' })
export class LocaleCurrencyPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private settingsService: SettingService
  ) {}
  transform(
    value: PriceForShare,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string {
    const localeCurrencyCode: string = this.settingsService.curency;

    if (value != null)
      return this.formatEurSymbol(
        this.currencyPipe.transform(
          this.getValueByLocale(value),
          localeCurrencyCode,
          display,
          digitsInfo,
          locale
        )
      );
    return this.formatEurSymbol(
      this.currencyPipe
        .transform(0, localeCurrencyCode, display, locale)
        .split('0.00')[0]
    );
  }

  private formatEurSymbol(price: string): string {
    if (price?.includes('€')) {
      return `${price.replace('€', '')}€`;
    }
    return price;
  }

  private getValueByLocale(value: unknown): number {
    const localeCurrency: string = this.settingsService.curency.toLocaleLowerCase();

    if(typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      return Number(value.replace(',', '.')) || 0;
    }
    return value[localeCurrency];
  }
}
