import {Component, Output, EventEmitter, HostListener, Input, ChangeDetectionStrategy} from '@angular/core';
import {ConfigAlertPopup} from '../../../common';

@Component({
  selector: 'app-alert-popup',
  templateUrl: './alert-popup.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class AlertPopupComponent {
  user: any;
  @Input() config: ConfigAlertPopup;
  @Input() image: string;
  @Input() typeConfirmButton: string;
  @Input() nameSubmitButton: string;
  @Input() nameSubmitCancel: string;
  @Output() submitEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeEmit: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  constructor() {}

  onClose(): void {
    this.closeEmit.emit();
  }

  onConfirm(): void {
    this.submitEmit.emit();
  }

  onClosePopup(): void {
    this.closeEmit.emit();
  }
}
