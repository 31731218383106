<app-popup>
  <div class="inner">
    <h1 class="text-head">Share opportunity </h1>
    <div class="text"><span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    </div>
    <section>
      <div class="share-button-row">
        <div class="share-flex-container">
          <div class="share-button-container">
            <a class="icon-block"
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopment.globalrockstar.com%2F%23%2Fprojects%2Fwer-2&quote=Get your share in {{songname}} by {{artistname}}. {{biography}}."
              target="_blank">
              <button mat-fab [disabled]="true" class="btn-share">
                <span class="social facebook">
                  <span class="circle"></span>
                  <i class="fab fa-facebook-f"></i>
                </span>
              </button>
            </a>
            <div class="text-icon grey-text">Facebook</div>
          </div>
          <div class="share-button-container">
            <button mat-fab class="btn-share">
              <span class="social twitter">
                <span class="circle"></span>
                <i class="fab fa-twitter"></i>
              </span>
            </button>
            <div class="text-icon grey-text">Twitter</div>
          </div>
          <div class="share-button-container">
            <button mat-fab class="btn-share">
              <span class="social linkedin">
                <span class="circle"></span>
                <i class="fa fa-linkedin" aria-hidden="true"></i>
              </span>
            </button>
            <div class="text-icon grey-text">LinkedIn</div>
          </div>
          <div class="share-button-container ws">
            <button mat-fab class="btn-share">
              <span class="social whatsapp">
                <span class="circle"></span>
                <i class="fab fa-whatsapp"></i>
              </span>
            </button>
            <div class="text-icon grey-text">WhatsApp</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</app-popup>
