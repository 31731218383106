import {
  Component,
  Output,
  Input,
  EventEmitter,
  HostListener,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


// Services
import { ModalService } from '@app/services/modal.service';

@UntilDestroy()
@Component({
  selector: 'kyc-skip-confirm-popup',
  templateUrl: './kyc-skip-confirm.component.html',
  styleUrls: ['./kyc-skip-confirm.component.scss'],
})
export class KycSkipConfirmComponent implements OnInit {
  @Input() hoursLeft: number;

  @Output() onClose: EventEmitter<void> = new EventEmitter();

  @Output() onConfirm: EventEmitter<void> = new EventEmitter();

  @HostListener('window:keyup', ['$event']) onKey(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.close();
    }
  }

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  close(): void {
    this.onClose.emit();
    this.modalService.close();
  }

  confirm(): void {
    this.onConfirm.emit();
    this.modalService.close();
  }
}
