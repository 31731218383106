import * as moment from 'moment';

export class NewsCardModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: any;
  id: number;
  slug: string;
  image: string;
  isPublished: boolean;
  publishedAt: any;
  isNews: boolean;
  social: any;
  link: {
    url: string,
    text: string
  };
  newsflash: number;
  title: string;
  newsDescription: string;
  isSocial: boolean;
  spotifySongLink: string;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel._id;
    this.slug = apiModel.slug;
    this.isNews = true;
    this.social = apiModel.social;
    this.newsflash = apiModel.newsflash;
    this.title = apiModel.title;
    this.spotifySongLink =  apiModel.spotifySongLink ? apiModel.spotifySongLink.replace('https://open.spotify.com', 'https://open.spotify.com/embed') : null;
    this.image = apiModel.image ? `${this.imgUrl}/${apiModel.image}` : null;
    this.isPublished = apiModel.isPublished;
    if (apiModel.social) {
      this.isSocial = !!(apiModel.social.facebook || apiModel.social.instagram || apiModel.social.twitter || apiModel.social.spotify || apiModel.social.youtube);
    } else {
      this.isSocial = false;
    }
    this.link = apiModel.link;
    this.publishedAt = apiModel.publishedAt ? moment(apiModel.publishedAt, 'YYYY/MM/DD') : null;
    this.newsDescription = apiModel.newsDescription ?  apiModel.newsDescription.replace(/(?:\r\n|\r|\n)/g, '<br />') : null;
    return this;
  }
}
