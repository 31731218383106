import {Injectable} from '@angular/core';

import {SettingService} from './setting.service';
import {Observable} from 'rxjs';

import {RequestModel, setRevenueFromApiModel} from '../models';

import {map} from 'rxjs/operators';
import {ApiService} from "@services/api.service";
import {Investments} from "@app/interfaces/revenue.interface";
import {RevenueModify, RevenueResponse} from "@app/pages/revenue/types/revenue-response.interface";

interface IRevenueResponse {
  list: Investments[];
  total: number;
}

@Injectable({providedIn: 'root'})

export class ReleaseService {
  apiUrl: string;

  constructor(private apiService: ApiService,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
  }

  getMyRevenues(request: RequestModel): Observable<RevenueModify> {
    const params = request.getApiModel();
    const endpoint = `revenues`;
    return this.apiService.get<RevenueResponse>(endpoint, params).pipe(map((resp: any) => {
      if (resp) {
        return {
          revenues: resp.list.map(item => setRevenueFromApiModel(item)),
          total: resp.total
        };
      }
    }));
  }

  getReleases(request: RequestModel): Observable<any> {
    const params = request.getApiModel();

    const endpoint = `investments`;
    return this.apiService.get<IRevenueResponse>(endpoint, params)
      .pipe(
        map((resp: IRevenueResponse) => {
          if (resp) {
            return {
              investments: resp.list,
              total: resp.total,
            };
          }
        }));
  }
}
