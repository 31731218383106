import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  SettingService,
} from '../../../services';

@Component({
  selector: 'app-pdf-popup',
  templateUrl: './pdf-popup.component.html',
  styleUrls: ['./pdf-popup.component.scss']
})
export class PdfPopupComponent implements OnInit {

  @Input() fileToShow;
  @Input() ios;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  microsoftViewFileAPI: string;
  width: number;
  height: number;

  constructor(private setting: SettingService) {
    this.microsoftViewFileAPI = this.setting.microsoftViewFileAPI;
  }

  ngOnInit(): void {
    this.width = window.innerWidth - 200;
    this.height = window.innerHeight - 200;
  }

  onClose(): void {
    this.notify.emit({type: 'close'});
  }
}
