import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
@Pipe({name: 'momentDateFormat'})
export class MomentDateFormatPipe implements PipeTransform {

  transform(value, formatInput?: string, format?: string) {
    if (value && formatInput) {
      return moment(value, formatInput).format(format || 'DD.MM.YYYY');
    }
    if (value && !formatInput) {
      return moment(value).format(format || 'DD.MM.YYYY');
    }
  }
}
