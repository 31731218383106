<ul
  class="nav-dropdown"
  [class.open]="isOpen"
  (clickOutside)="close()"
  [clickOutsideEvents]="'click,touchstart'"
  [exclude]="excludeOutsideClickSelector"
  [ngClass]="position + ' ' + (classes || '')"
>
  <li *ngFor="let link of navItems" class="nav-dropdown__item">
    <a
      href="https://www.globalrockstar.com/{{link.path}}"
      (click)="navigate(link.path, $event)"
      class="nav-dropdown__link"
      >{{ link.name }}
      <img
        *ngIf="link.icon"
        [src]="link.icon"
        alt="icon"
        class="nav-dropdown__icon"
    /></a>
  </li>
  <ng-content></ng-content>
</ul>
