<app-popup>
  <div class="inner reinvest-inner">
    <img  src="../../../../assets/images/subscribe.png"
          alt="reinvest popup logo"
          class="image">
    <div class="text">
      <h1 class="text-head">Dear Investor!</h1>
      <p class="sub-text">Thank you! Do you want to use <b>{{dataInput?.payAmount | localeCurrency}}</b>
        of your earned revenues to
        re-invest into
        <b>{{dataInput?.project?.isAlbum ? dataInput?.project?.projectTitle : dataInput?.project?.song?.name}}</b>?
      </p><br>
    </div>
    <div class="button-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">Back</button>
      <button type="button" class="button yellow title16 text-uppercase" (click)="onProceed()">
        Yes, I want to re-invest
      </button>
    </div>
  </div>
</app-popup>
