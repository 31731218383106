import {Directive, ElementRef, EventEmitter, Output} from '@angular/core';

@Directive({
    selector: '[libAppOutside]',
    host: {
        '(document:hover)': 'onClick($event)',
    }
})
export class OutsideDirective {
    @Output() closePopup: EventEmitter<any> = new EventEmitter<any>();

    constructor(private elementRef: ElementRef) {
    }

    onClick(event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.closePopup.emit(event);
        }

    }

}
