import {environment} from '../../environments/environment';

export const GOOGLE_REQUEST: google.payments.api.PaymentDataRequest = {
  apiVersion: 2,
  apiVersionMinor: 0,
  allowedPaymentMethods: [{
    type: 'CARD',
    parameters: {
      allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
      allowedCardNetworks: ['MASTERCARD', 'VISA']
    },
    tokenizationSpecification: {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'monext',
        gatewayMerchantId: 'PAYLINE'
      }
    }
  }
  ],
  merchantInfo: {
    merchantId: environment.merchantId,
    merchantName: environment.merchantName,
  },
  transactionInfo: {
    totalPriceStatus: 'FINAL',
    totalPriceLabel: 'Total',
    totalPrice: '0',
    currencyCode: 'EUR',
    countryCode: 'AU'
  },
  callbackIntents: ['PAYMENT_AUTHORIZATION']
};
