<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <img src="./assets/images/subscribe.png" class="image" alt="verify email" />
    <div class="block-title">VERIFY EMAIL TO INVEST</div>
    <div class="text">
      In order to be able to invest in an opportunity, you need to click on the
      verification-link that we have sent you via Email.
    </div>
    <span *ngIf="email" class="link-blue" (click)="resendEmail()"
      >Resend email to {{ email }}</span
    >
  </div>
</app-popup>
