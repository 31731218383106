import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppToastrService} from 'app/services';
import {PASSWORD_PATTERN_ANY} from 'app/common';
import {CustomFieldErrorMatcher} from '../../../common/errorMatcher';
import {AuthenticationService} from '../../../services/authentication.service';
@Component({
  selector: 'app-edit-password-popup',
  templateUrl: './edit-password.html'
})
export class EditPasswordComponent implements OnInit {
  @Input() types: any[];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  oldPasswordCtrl: FormControl;
  newPasswordCtrl: FormControl;
  approvePasswordCtrl: FormControl;
  form: FormGroup;
  submitPressed: boolean;
  submitting: boolean;
  notValidPassword = false;

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(private toast: AppToastrService,
              private authenticationService: AuthenticationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.prepareForm();
  }

  private prepareForm() {
    this.oldPasswordCtrl = this.fb.control('', [Validators.required]);
    this.newPasswordCtrl = this.fb.control('', [Validators.required, Validators.pattern(PASSWORD_PATTERN_ANY)]);
    this.approvePasswordCtrl = this.fb.control('', [Validators.required, Validators.pattern(PASSWORD_PATTERN_ANY)]);
    this.form = this.fb.group({
      oldPassword: this.oldPasswordCtrl,
      newPassword: this.newPasswordCtrl,
      approvePassword: this.approvePasswordCtrl,
    });
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  checkPassword() {
    this.notValidPassword = !this.newPasswordCtrl.valid;
  }

  onSubmit() {
    this.submitPressed = true;
    if (this.form.valid && !this.submitting) {
      if (this.newPasswordCtrl.value === this.approvePasswordCtrl.value) {
        this.submitting = true;
        const data: any = {
          currentPassword: this.oldPasswordCtrl.value,
          password: this.newPasswordCtrl.value,
          passwordConfirmation: this.approvePasswordCtrl.value
        };
        this.authenticationService.changePassword(data).subscribe((res) => {
          this.notify.emit({type: 'close'});
          this.submitting = false;
          this.toast.showToast({title: 'Password was successfully changed'});
        }, (err) => {
          this.submitting = false;
          this.toast.showToastFromError(err);
        });
      } else {
        this.approvePasswordCtrl.setValue(null);
      }
    }
  }

  errorMatcherPassword(control: FormControl) {
    let error;
    return new CustomFieldErrorMatcher(control, this.submitPressed || this.notValidPassword, error);
  }

  errorMatcher(control: FormControl, additionalCheck?: boolean) {
    let error;
    if (additionalCheck) {
      error = this.approvePasswordCtrl.value !== this.newPasswordCtrl.value && this.approvePasswordCtrl.touched;
    }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }
}
