export const EUCOUNTRY = [
  'AT', 'BE', 'CY', 'EE', 'FI',
  'FR', 'GR', 'AD', 'BG', 'ES',
  'HR', 'CZ', 'DK', 'DE', 'HU',
  'IS', 'IE', 'IT', 'LV', 'LT',
  'LU', 'MT', 'MC', 'NO', 'NL',
  'PL', 'PT', 'RO', 'SI', 'SK',
  'SE', 'UK', 'GB', 'US', 'CA',
  'CH', 'LI', 'SM', 'VA'];


