import {Component, Output, EventEmitter, HostListener} from '@angular/core';

@Component({
  selector: 'app-bid-popup',
  templateUrl: './bid-popup.html'
})
export class BidPopupComponent {
  bidAmount: number;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }


  onSubmit() {
    this.notify.emit({bidAmount: this.bidAmount});
  }
}
