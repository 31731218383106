import {Inject, Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(private title: Title,
              private meta: Meta,
              @Inject('ENVIRONMENT') private env: any,
              @Inject(DOCUMENT) private document: any) {
  }


  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateOgUrl(url: string) {
    this.meta.updateTag({name: 'og:url', content: url});
  }

  updateCanonicalURL(): void {
    if (this.env.production) {
      const head = this.document.getElementsByTagName('head')[0];
      let element: HTMLLinkElement = this.document.querySelector(`link[rel='canonical']`) || null;
      if (element == null) {
        element = this.document.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical');
      element.setAttribute('href', this.document.URL);
    }
  }

  updateKeywords(key: string) {
    this.meta.updateTag({name: 'keywords', content: key});
  }

  updateMetaTitle(key: string) {
    this.meta.updateTag({name: 'title', content: key});
  }

  updateDescription(desc: string) {
    this.meta.updateTag({name: 'description', content: desc});
  }
}
