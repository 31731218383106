<ng-container *ngIf="!idxIframeView">
  <header class="header" [class.idx-branded]="isUSA$ | async">
    <button
      class="header__hamburger hamburger hamburger--squeeze no-tap-highlight"
      type="button"
      #mainNav=""
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <a
      class="header__logo"
      [class.centered]="!(user$ | async) && isActionButtonHidden"
      href="https://www.globalrockstar.com"
      (click)="navigate('/', $event)"
    >
      <ng-container *ngIf="isUSA$ | async; else globalLogo"
        ><img
          class="header__image"
          src="assets/images/logo/grs-x-idx-logo.png"
          alt="globalrockstar-x-idealx logo"
      /></ng-container>
      <ng-template #globalLogo="">
        <img
          class="header__image"
          src="assets/images/logo/grs-tm-white.png"
          alt="GlobalRockstar Logo"
          title="GlobalRockstar"
        />
      </ng-template>
    </a>
    <div class="header__desktop-nav desktop-nav">
      <a
        class="desktop-nav__link desktop-nav__link--lg"
        href="https://www.globalrockstar.com"
        (click)="navigate('/', $event)"
        [ngClass]="{'active-link':currentPath === '/'}"
        >HOME</a
      >
      <a
        class="desktop-nav__link"
        href="https://www.globalrockstar.com/opportunity"
        (click)="navigate(NAVIGATION.OPPORTUNITIES, $event)"
        [ngClass]="{'active-link':currentPath === '/opportunity'}"
        >OPPORTUNITIES</a
      >
      <div class="desktop-nav__dropdown-container">
        <div
          class="desktop-nav__link"
          [ngClass]="{'active-link':currentPath === '/how-it-works' || currentPath === '/api/landing/for-artists'}"
          #desktopNav
        >
          HOW TO
          <i class="fas fa-chevron-down"></i>
        </div>
        <app-nav-dropdown
          [toggleElement]="desktopNav"
          [navItems]="NAVIGATION.APP.slice(1)"
          position="center"
          classes="desktop-nav__dropdown"
        ></app-nav-dropdown>
      </div>
      <a
        class="desktop-nav__idx-link desktop-nav__link"
        href="https://idealidx.com"
        target="_blank"
        aria-label="go to idealidx"
      >
        <img
          *ngIf="isUSA$ | async"
          src="assets/images/logo/go-to-idealidx.png"
          alt="go to idealidx"
        />
      </a>
    </div>
    <div
      #profileNav
      class="header__avatar-wrapper"
      (click)="handleProfileClick()"
      [class.hide]="!(user$ | async) && isActionButtonHidden"
    >
      <span *ngIf="(user$ | async)?.contact?.firstName" class="welcome">
        Welcome, {{(user$ | async).contact?.firstName}}
      </span>
      <div
        class="header__avatar avatar hamburger hamburger--squeeze"
        [ngClass]="{'is-active': profileNav.classList.contains('is-active')}"
      >
        <div *ngIf="(user$ | async)?.image" class="avatar__image">
          <img [src]="(user$ | async)?.image" alt="Avatar" />
        </div>
        <div
          *ngIf="!(user$ | async)?.image"
          class="avatar__placeholder"
          [ngClass]="(user$ | async)?'avatar__placeholder--blue':'avatar__placeholder--orange'"
        >
          <img
            src="assets/images/icons-common/user-avatar-empty.svg"
            alt="Avatar placeholder"
          />
        </div>
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </div>
    </div>

    <div
      class="header__desktop-buttons"
      *ngIf="!(user$ | async) && !isActionButtonHidden"
    >
      <a
        class="desktop-nav__link login-btn"
        href="https://www.globalrockstar.com/{{NAVIGATION.LOGIN}}"
        (click)="navigate(NAVIGATION.LOGIN, $event)"
        >LOG IN</a
      >
      <a
        class="grs-button--orange header__button--small"
        href="https://www.globalrockstar.com/{{NAVIGATION.REGISTER}}"
        (click)="navigate(NAVIGATION.REGISTER, $event)"
        >JOIN</a
      >
    </div>
    <app-nav-dropdown
      classes="profile-nav"
      *ngIf="(user$ | async)"
      [toggleElement]="profileNav"
      [navItems]="profileNavList"
    ></app-nav-dropdown>

    <app-nav-dropdown [toggleElement]="mainNav" [navItems]="NAVIGATION.APP">
      <div class="header__buttons" *ngIf="!(user$ | async)">
        <a
          class="grs-button--orange header__button"
          href="https://www.globalrockstar.com/{{NAVIGATION.REGISTER}}"
          (click)="navigate(NAVIGATION.REGISTER, $event)"
          >JOIN</a
        >
        <a
          class="grs-button--blue grs-button--outline header__button"
          href="https://www.globalrockstar.com/{{NAVIGATION.LOGIN}}"
          (click)="navigate(NAVIGATION.LOGIN, $event)"
          >Log in</a
        >
      </div>
    </app-nav-dropdown>
  </header>
</ng-container>

<!-- IDX IFRAME HEADER -->
<ng-container *ngIf="idxIframeView">
  <header class="header idx-iframe" [class.hide-nav]="isActionButtonHidden">
    <div class="idx-iframe__logo">
      <a
        class="header__logo"
        href="https://www.globalrockstar.com"
        (click)="navigate('/', $event)"
      >
        <img
          class="header__image"
          src="assets/images/logo/grs-tm-blue.png"
          alt="GlobalRockstar Logo"
          title="GlobalRockstar"
        />
      </a>
    </div>
    <div class="idx-iframe__nav" *ngIf="!isActionButtonHidden">
      <button
        class="header__hamburger hamburger hamburger--squeeze no-tap-highlight"
        type="button"
        #mainNav=""
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>

      <div class="header__desktop-nav desktop-nav">
        <a
          class="desktop-nav__link desktop-nav__link--lg"
          href="https://www.globalrockstar.com"
          (click)="navigate('/', $event)"
          [ngClass]="{'active-link':currentPath === '/'}"
          >HOME</a
        >
        <a
          class="desktop-nav__link"
          href="https://www.globalrockstar.com/opportunity"
          (click)="navigate(NAVIGATION.OPPORTUNITIES, $event)"
          [ngClass]="{'active-link':currentPath === '/opportunity'}"
          >OPPORTUNITIES</a
        >
        <div class="desktop-nav__dropdown-container">
          <div
            class="desktop-nav__link"
            [ngClass]="{'active-link':currentPath === '/how-it-works' || currentPath === '/api/landing/for-artists'}"
            #desktopNav
          >
            HOW TO
            <i class="fas fa-chevron-down"></i>
          </div>
          <app-nav-dropdown
            [toggleElement]="desktopNav"
            [navItems]="NAVIGATION.APP.slice(1)"
            position="center"
            classes="desktop-nav__dropdown"
          ></app-nav-dropdown>
        </div>
      </div>

      <app-nav-dropdown [toggleElement]="mainNav" [navItems]="NAVIGATION.APP">
      </app-nav-dropdown>
    </div></header
></ng-container>
