<div class="main-wrapper flex-align-column">
  <app-page-header [title]="'My revenues'">
    <div class="action-tab flex-align">
      <div class="flex-align info-wrapper">
        <div class="info-amount">
          <div class="wrapper-amount">
            <div class="item balance">
              <div class="text title14">Current balance</div>
              <div class="font-bold value current-value" *ngIf="!user?.balance?.open">
                {{ user?.revenues?.left | localeCurrency }}
                <span *ngIf="user?.voucherAmount"
                      class="voucher-value color-light-grey"> / {{user?.voucherAmount | localeCurrency}}</span>
                <i class="far fa-question-circle" *ngIf="user?.voucherAmount"></i>
                <div class="wrapper-tooltip balance-hint">
                  <div class="tooltip">
                    Voucher balance
                  </div>
                </div>
              </div>
              <div *ngIf="user?.balance?.open" class="font-bold value warning-text"> -
                {{user?.balance?.grossBalance | localeCurrency}}
                <i class="far fa-question-circle"></i>
                <div class="wrapper-tooltip balance-hint">
                  <div class="tooltip tooltip-wrap">
                    You have not recouped advances and recoupable costs yet. Once your earnings exceed the advances
                    and recoupable costs you can withdraw your balance.
                  </div>
                </div>
              </div>
              <span class="line"></span>
            </div>
            <app-kyc-status class="mobile-screen" [user]="user" (notify)="onNotify($event)"></app-kyc-status>
            <div class="item">
              <div class="text title14">Total earned</div>
              <div class="font-bold value color-light-grey">{{user?.revenues?.total | localeCurrency}}</div>
            </div>

            <div class="item">
              <div class="text title14">Withdrawn</div>
              <div class="font-bold value color-light-grey">{{withdrawAmount | localeCurrency}}</div>
            </div>
            <div class="item">
              <div class="text title14">Re-Invested</div>
              <div class="font-bold value color-light-grey">{{user?.reinvestTotal | localeCurrency}}</div>
            </div>

            <div class="item advances" *ngIf="user?.recoupable">
              <div class="text title14">Total recouped</div>
              <div class="font-bold value color-light-grey">{{user?.recoupable | localeCurrency}}</div>
            </div>
          </div>
          <div class="button-activity">
            <div class="summary-info__item summary-info__item--secure-btn">
              <button class="button border-blue text-uppercase" (click)="applyVoucher()">Redeem
                Voucher
              </button>
            </div>
            <div class="summary-info__item summary-info__item--secure-btn">
              <app-transfer-money [user]="user" (notify)="onNotify($event)"></app-transfer-money>
            </div>
            <div class="summary-info__item summary-info__item summary-info__item--secure-btn">
              <button class="button border-blue text-uppercase button-spinner"
                      (click)="downloadRevenuesCSV()"
                      [disabled]="!revenues?.length || fileUpload">
                <mat-spinner [diameter]="20"
                             *ngIf="fileUpload"></mat-spinner>
                Download CSV
              </button>
            </div>
          </div>
        </div>
        <app-kyc-status class="desktop-screen" [user]="user"
                        (notify)="onNotify($event)"></app-kyc-status>
      </div>
      <div class="flex-align wrapper mobile-screen withdraw-mobile-screen">
        <button class="button yellow title16"
                (click)="withdrawEvent((user?.revenues?.left < 10 || user?.isPendingStatus || hasAdvances
                      || [2,3].indexOf(user?.mangoVerificationStatus?.id) >=0 || (!uboStatusValid && !user.isPrivate)))"
                [class.disabled]="getValidation()"
        >
          <span *ngIf="user?.revenues?.left > 10" class="text-uppercase">WithDraw Money</span>
          <span *ngIf="user?.revenues?.left < 10">Minimum withdraw amount is {{10 | localeCurrency: null : 'symbol': '1.0-0'}}</span>
        </button>
      </div>
      <div class="mobile-screen-filter mobile-screen">
        <div class="flex-align-column-filter">
          <span class="title14 text color-light-grey filter-title">Show proceeds: </span>
          <div class="flex-align filter-wrapper">
            <mat-form-field class="month">
              <mat-label>From</mat-label>
              <mat-select [formControl]="monthFromControl"
                          [errorStateMatcher]="errorFromMatcher(monthFromControl)">
                <mat-option *ngFor="let item of month" [value]="item.value">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="year">
              <mat-select [formControl]="yearFromControl" [errorStateMatcher]="errorFromMatcher(yearFromControl)">
                <mat-option *ngFor="let item of years" [value]="item.value">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="month">
              <mat-label>To</mat-label>
              <mat-select [formControl]="monthToControl" [errorStateMatcher]="errorToMatcher(monthToControl)">
                <mat-option *ngFor="let item of month" [value]="item.value">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="year">
              <mat-select [formControl]="yearToControl" [errorStateMatcher]="errorToMatcher(yearToControl)">
                <mat-option *ngFor="let item of years" [value]="item.value">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>

            <button class="button border-blue" (click)="onDateChange(false, true)"
                    [disabled]="submitPressed && (!isFiltersFromValid || !isFiltersToValid)">Apply
            </button>
            <span class="reset-filters" (click)="resetFilters()">Clear filters <span
              class="lnr lnr-cross"></span></span>
          </div>
        </div>
      </div>
    </div>
  </app-page-header>
  <main>
    <div class="flex-align-column-filter desktop-screen filter-section">
      <span class="title14 text color-light-grey filter-title">Show proceeds:</span>
      <div class="flex-align filter-wrapper">
        <mat-form-field class="month">
          <mat-label>From</mat-label>
          <mat-select [formControl]="monthFromControl"
                      [errorStateMatcher]="errorFromMatcher(monthFromControl)">
            <mat-option *ngFor="let item of month" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="year">
          <mat-select [formControl]="yearFromControl"
                      [errorStateMatcher]="errorFromMatcher(yearFromControl)">
            <mat-option *ngFor="let item of years" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="month">
          <mat-label>To</mat-label>
          <mat-select [formControl]="monthToControl" [errorStateMatcher]="errorToMatcher(monthToControl)">
            <mat-option *ngFor="let item of month" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="year">
          <mat-select [formControl]="yearToControl" [errorStateMatcher]="errorToMatcher(yearToControl)">
            <mat-option *ngFor="let item of years" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>

        <button class="button border-blue apply-btn" (click)="onDateChange(false, true)"
                [disabled]="submitPressed && (!isFiltersFromValid || !isFiltersToValid)">Apply
        </button>
        <div class="reset-filters" (click)="resetFilters()">Clear filters <span
          class="lnr lnr-cross"></span></div>
      </div>
    </div>
    <section class="data-list">
      <div class="spinner">
        <mat-spinner [diameter]="40" *ngIf="!isLoaded"></mat-spinner>
      </div>
      <table *ngIf="isLoaded" class="table noborder investment-table">
        <thead>
        <tr class="table-header">
          <th class='max-screen column mid-screen'></th>
          <th class='max-screen column'>Song</th>
          <th class='max-screen column mid-screen'>Artist</th>
          <th class='max-screen column mid-screen'>Release date</th>
          <th class='max-screen column mid-screen'>Share</th>
          <th class='max-screen column mid-screen'>Rights</th>
          <th class='max-screen column revenue'>Revenue</th>
          <th class="max-screen column mid-screen" colspan="2">Reports</th>
        </tr>
        </thead>
        <tbody>
        <ng-template ngFor let-revenue [ngForOf]="revenues" let-i="index">
          <tr class="border_bottom tr-line song__row">
            <td class='max-screen mid-screen' (click)="showSubRow(revenue, revenue?.revenueHistory?.length)">
              <span class="lnr lnr-chevron-right" *ngIf="revenue?.revenueHistory?.length"
                    [class.opened]="revenue?.isOpen"></span>
            </td>
            <td class='max-screen mid-screen color-light-grey'>{{revenue?.isAlbum ? revenue?.albumSongName :
              revenue?.song}}
            </td>
            <td class='min-screen mobile-screen' (click)="showSubRow(revenue, revenue?.revenueHistory?.length)">
              <div class="song-name">
                {{revenue?.isAlbum ? revenue?.albumSongName : revenue?.song}}
              </div>
              <div class="artist-name color-light-grey">{{revenue?.cardArtistTitle || revenue?.artistName}}</div>
              <div class="email-wrapper color-light-grey">{{revenue?.date | momentDate}}</div>
              <div class="email-wrapper color-light-grey">Share: {{revenue?.share}}%</div>
              <div class="taken" *ngIf="revenue?.takenDown">rights and obligations taken over by artist</div>
              <div class="email-wrapper color-light-grey">Rights: {{revenue.rights}}</div>
            </td>
            <td class='max-screen mid-screen color-light-grey'>{{ revenue?.cardArtistTitle || revenue?.artistName}}</td>
            <td class='max-screen mid-screen color-light-grey'>{{revenue?.date | momentDate}}</td>
            <td class='max-screen mid-screen color-light-grey'>
              <span *ngIf="!revenue.leftShare; else leftShares">{{revenue.share}}% </span>
              <ng-template #leftShares>
                Init. Share {{revenue.share}}% <br> Actual Share {{revenue.leftShare}}%
              </ng-template>
              <div class="taken" *ngIf="revenue?.takenDown">rights and obligations<br>taken over by artist</div>
            </td>
            <td class='max-screen mid-screen color-light-grey'>{{revenue.rights}}</td>
            <td class='max-screen revenue'>
              <span class="font-bold title18" *ngIf="revenue?.revenue > 0">{{revenue?.revenue | localeCurrency}}</span>
              <div class="not-revenue" *ngIf="!revenue?.revenueHistory?.length">
                <div><span class="not-revenue__title">Pending </span>
                </div>
                <!--<i class="fas fa-hourglass-end"></i>-->

                <div class="wrapper-tooltip rights-tooltip" [appTooltip]="revenue">
                  <i class="far fa-question-circle not-revenue-tooltip"></i>
                </div>
              </div>

              <span class="left" *ngIf="revenue?.advance">/-{{revenue?.advance?.left | localeCurrency}}</span>
              <i class="far fa-question-circle" *ngIf="revenue?.advance"></i>
              <div class="wrapper-tooltip advance-hint">
                <div class="tooltip" [class.showTooltip]="revenue.showTooltip">
                  Non recouped amount from advance #{{revenue.advance?.number}}
                </div>
              </div>
            </td>
            <td class='max-screen mid-screen'  (click)="showSubRow(revenue, revenue?.revenueHistory?.length)">
              <span *ngIf="revenue?.revenueHistory?.length" class="link-blue view text-uppercase">VIEW</span></td>
          </tr>
          <tr class="table__nested">
            <td class="nested__td" colspan="8" [class.active]="revenue?.isOpen">
              <div class="table-open" [class.openNested]="revenue?.isOpen">
              <app-sub-table-revenue-history
                (reportEmit)="loadReport($event)"
                (showPdfPopUp)="showPdfPopUp($event)"
                [user]="user"
                [revenue]="revenue">
              </app-sub-table-revenue-history>
              </div>
            </td>
          </tr>
        </ng-template>
        </tbody>
      </table>
      <div class="wrapper-pagination-footer" *ngIf="isLoaded">
        <app-pagination [total]="total"
                        [range]="true"
                        [paging]="listFilters?.paging"
                        (selectRangeEmit)="onPageRangeChange($event)"
                        (notify)="onPageChange($event)"></app-pagination>
      </div>
    </section>
    <section class="refund-money desktop-screen">
      <div class="refund-title text-uppercase" (click)="openSection()">
            <span class="lnr lnr-chevron-right"
                  [class.opened]="openSetting"></span>
        <span class="title-m"> Withdraw money</span>
      </div>
      <div class="wrapper flex-align section-toggle-mob" [class.openSetting]="openSetting">
        <div class="button-wrapper button-wrapper-refund">
          <button class="button border-blue withdraw large-size"
                  (click)="withdrawEvent((user?.revenues?.left < 10 || user?.isPendingStatus || hasAdvances
                  || [2,3].indexOf(user?.mangoVerificationStatus?.id) >=0 || (!uboStatusValid && !user.isPrivate)))"
                  [class.disabled]="getValidation()"
          >
            <span *ngIf="user?.revenues?.left >= 10" class="text-uppercase">WithDraw</span>
            <span *ngIf="user?.revenues?.left < 10">Minimum withdraw amount is {{10 | localeCurrency: null : 'symbol': '1.0-0'}}</span>
          </button>
          <div class="reinvest-btn">
            <button class="button yellow " (click)="goToReinvest()">
              <span class="text-uppercase">Re-invest</span>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="under-review" *ngIf="user">
      <span class="title-m"
            *ngIf="user?.mangoVerificationStatus?.id === 2 || (uploadedFile && user?.mangoVerificationStatus?.id === 0)">
        Your identity validation is currently in process. You will be notified via email as soon as this process is completed. <br>
      </span>
      <span class="title-m"
            *ngIf="!uboStatusValid && !user?.isPrivate && user?.legalType === legalTypeBusiness && user?.statusUBO?.id === 1">
        Your UBO validation is currently in process<br>
      </span>
      <span class="title-m"
            *ngIf="!uboStatusValid && !user?.isPrivate && user?.legalType === legalTypeBusiness && user?.statusUBO?.id === 4">
        Your UBO is incomplete: {{user?.statusUBO?.reason}}<br>
      </span>
      <span class="title-m"
            *ngIf="!uboStatusValid && !user?.isPrivate && user?.legalType === legalTypeBusiness && user?.statusUBO?.id === 3">
        Your UBO is refused: {{user?.statusUBO?.reason}}<br>
      </span>
      <span class="title-m"
            *ngIf="!user?.isPrivate && user?.legalType === legalTypeBusiness && !user?.statusUBO?.id">
        Your UBO has not been added. <br>
      </span>
      <span class="title-m" *ngIf="user?.mangoVerificationStatus?.id === 3"
      >
        KYC validation has failed <span *ngIf="user?.kycFailedReason">({{user?.kycFailedReason}})</span>. Please contact <a
        class="link-blue" href="mailto: office@globalrockstar.com"> office@globalrockstar.com</a></span>
    </section>
    <div class="button-wrapper btn-check" *ngIf="!revenues?.length && isLoaded">
      <button type="button" class="button yellow title16 text-uppercase" (click)="checkProjects()">
        Check Active OPPORTUNITIES
      </button>
    </div>
  </main>
  <app-footer></app-footer>
</div>
<app-voucher-popup *ngIf="showVoucherPopup" (notify)="onNotify($event)"></app-voucher-popup>
<app-verify-email-popup *ngIf="showVerifyPopup" (notify)="onNotify($event)"></app-verify-email-popup>

<app-alert-popup
  *ngIf="showAlert"
  (closeEmit)="alertPopupHandle(true)">
  Please populate Profile page
  <div class="button-wrapper">
    <button class="cancel button border-blue"
            type="button"
            (click)="alertPopupHandle(true)"
    >
      Cancel
    </button>
    <button type="button"
            class="button yellow text-uppercase" (click)="alertPopupHandle()">
      Proceed
    </button>
  </div>
</app-alert-popup>
<app-withdraw-money-popup *ngIf="showWithdrawPopup"
                          [revenue]="user?.revenues"
                          [currentBlance]="user?.revenues?.left"
                          (notify)="onNotify($event)"></app-withdraw-money-popup>
<app-pdf-popup *ngIf="showPdf"
               (notify)="onNotify($event)"
               [ios]="ios"
               [fileToShow]="pdfToShow"></app-pdf-popup>
