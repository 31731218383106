import * as moment from 'moment';

export class Utils {
  static sortArrayByDate(arr: any[] = [], key: string, format: string | null = null): any[] {
    if (arr.length) {
      return arr.sort((left, right) => {
        return moment.utc(right[key], format).diff(moment.utc(left[key], format));
      });
    }
    return arr;
  }
}

