<div class="wrapper search">
  <div class="search__col">
    <mat-form-field class="mobile__view">
      <mat-label>Type</mat-label>
      <mat-select [disabled]="activeTab === 3" [(ngModel)]="filter.finding.typeSong"
                  (selectionChange)="onfilterChange()">
        <mat-option *ngFor="let item of projectType" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__col">
    <mat-form-field class="mobile__view">
      <mat-label>Status</mat-label>
      <mat-select [disabled]="activeTab === 3" [(ngModel)]="filter.finding.status"
                  (selectionChange)="onfilterChange()">
        <mat-option *ngFor="let item of status" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__col">
    <mat-form-field class="mobile__view">
      <mat-label>Artist</mat-label>
      <mat-select multiple [(ngModel)]="filter.finding.artist"
                  (selectionChange)="onfilterChange()">
        <mat-option *ngFor="let item of artistList" [value]="item.slug">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__col">
    <mat-form-field class="mobile__view">
      <mat-label>Genre</mat-label>
      <mat-select multiple [(ngModel)]="filter.finding.genres"
                  (selectionChange)="onfilterChange()">
        <mat-option *ngFor="let item of genresList" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__col">
    <mat-form-field class="mobile__view">
      <mat-label>Country</mat-label>
      <mat-select [(ngModel)]="filter.finding.country" (selectionChange)="onfilterChange()">
        <mat-option *ngFor="let item of countriesList" [value]="item.id">{{item.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="search__col search__clear">
    <button class="clear__desktop border-blue button text-uppercase" [class.active]="count"
            (click)="clearFilters()">
      Clear filters
    </button>
    <span class="clear__mobile" (click)="clearFilters()"><i class="fas fa-times"></i></span>
  </div>
  <div class="search__col sort" *ngIf="activeTab !== 3">


    <div class="arrows-tablet search__arrow" (click)="sortList()">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19
      22H5C3.89 22 3 21.1 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 10V20H19V10H5ZM19
      8H5V6H19V8ZM17 13H12V18H17V13Z" fill="#5AA2E5"/>
      </svg>
      <i class="fas fa-arrow-down" [class.active]="filter.sorting.sortHow === 'desc'"
         *ngIf="filter?.sorting?.sortHow === 'desc' || !filter?.sorting?.sortHow"></i>
      <i class="fas fa-arrow-up" [class.active]="filter.sorting.sortHow === 'asc'"
         *ngIf="filter?.sorting?.sortHow === 'asc'"></i>
    </div>
    <div class="sort__dropdown">
      <mat-form-field class="mobile__view">
        <mat-label>Sort By</mat-label>
        <mat-select [(ngModel)]="filter.sorting.sortBy" (selectionChange)="sortList()">
          <mat-option *ngFor="let item of sortTypes" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <p class="sort__value" (click)="sortList()">
        <i class="fas fa-arrow-down" [class.active]="filter.sorting.sortHow === 'desc'"></i>
        <i class="fas fa-arrow-up"
           [class.active]="filter.sorting.sortHow === 'asc'"></i>
      </p>
    </div>
  </div>
</div>

