import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SettingService} from './setting.service';
import {SliderModel} from '../models/slider.model';


@Injectable()
export class ImageService {
  apiUrl: string;
  imgUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
    this.imgUrl = this.setting.imgUrl;
  }

  getSliderImages(): Observable<any> {
    return this.http.get(`${this.apiUrl}/landing/home-slides`).pipe(map((resp: any) => {
      if (resp && resp.list) {
        resp.list = resp.list.map(item => new SliderModel(this.imgUrl).setFromApiModel(item));
      }
      return resp;
    }));
  }
}
