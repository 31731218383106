import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
// Consts
import {LEGAL_TYPEs, MANGOPAY_LIST, SEP_COUNTRIES} from '../../common';

// Services
import {ModalService} from '@services/modal.service';
import {
  ReminderCompleteProfileComponent
} from "@app/components/popups/reminder-complete-profile/reminder-complete-profile.component";

@Component({
  selector: 'app-kyc-status',
  templateUrl: './kyc-status.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KycStatusComponent {
  showLabel = true;
  showUploadPopup: boolean;
  showTooltip: any;
  legalTypeBusiness: LEGAL_TYPEs = 'business';
  isSEP: boolean;
  @Input() user;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  openKYCvalidation(): void {
    if(!this.user?.contact?.birthdate) {
      this.modalService.open(ReminderCompleteProfileComponent);
      return;
    }
    if (this.user && (this.user.mangoVerificationStatus.id === 3 || !this.user.mangoVerificationStatus.id)) {
      this.isSEP = MANGOPAY_LIST.indexOf(this.user.country) >= 0 || SEP_COUNTRIES.indexOf(this.user.country) >= 0;
      const isLegalBusiness = !this.user.isPrivate && this.user.legalType === this.legalTypeBusiness && this.user.statusUBO && this.user.statusUBO.id === 2;
      if (this.user.mangoUserCreated && (this.user.isPrivate || isLegalBusiness || this.user.legalType !== this.legalTypeBusiness)) {
        this.showUploadPopup = true;
        this.cdr.markForCheck();
      }
    }
  }


  onNotify(e): void {
    if (e.type === 'close' || e.type === 'closeUpload') {
      this.showUploadPopup = false;
    }
    if (e.type === 'closeUpload') {
      this.notify.emit({type: 'uploaded'});
    }
  }

  get kycFailedReason(): string {
    if(this.user?.kycFailedReason === 'Mangopay STATUS CREATED') {
      return 'KYC verification failed. Please contact support.'
    }
    return `IDENTITY_PROOF: ${this.user?.kycFailedReason}`
  }
}
