import {SliderModel} from './slider.model';
import * as moment from 'moment';

export class CarouselModel {
  constructor(props) {
    this.imgUrl = props;
  }

  title: string;
  description: string;
  imgUrl: string;
  list: any[];
  background: string;
  isEnabled: boolean;

  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    this.description = apiModel.description;
    this.background = new GetColor().color(apiModel.background);
    this.isEnabled = apiModel.isEnabled;
    if (apiModel.list && apiModel.list.length) {
      this.list = apiModel.list.map((item) => {
        let objUrlCarousel = {
          image: item.image ? `${this.imgUrl}/${item.image}` : null,
          title: item.title,
          isEnabled: item.isEnabled,
          targetBlank: item.targetBlank
        };
        if (item.link) {
          const urlCar = item.link && (item.link.indexOf('mailto') === 0 || item.link.indexOf('http') === 0) ?
            item.link : 'https://' + item.link;
          objUrlCarousel = Object.assign(objUrlCarousel, {link: urlCar});
        }
        return objUrlCarousel;
      });
    }
    return this;
  }
}

export class SliderModelLanding {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  title: string;
  text: string;
  image: string;
  id: number;
  mainTitle: string;
  imageMobile: string;
  isEnabled: boolean;
  targetBlank: boolean;
  url: string;


  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    this.mainTitle = apiModel.mainTitle;
    this.text = apiModel.text;
    this.id = apiModel._id;
    this.image = apiModel.image ? `${this.imgUrl}/${apiModel.image}` : null;
    this.imageMobile = apiModel.imageMobile ? `${this.imgUrl}/${apiModel.imageMobile}` : null;
    this.isEnabled = apiModel.isEnabled;
    this.targetBlank = apiModel.targetBlank;
    if (apiModel.url) {
      this.url = apiModel.url && (apiModel.url.indexOf('mailto') === 0 || apiModel.url.indexOf('http') === 0) ?
        apiModel.url : 'https://' + apiModel.url;
    }
    return this;
  }
}


export class GetColor {
  color(col) {
    let type;
    if (col === 'g') {
      type = 'grey-color-landing';
    } else if (col === 'w') {
      type = 'white-color-landing';
    } else if (col === 'b') {
      type = 'black-color-landing';
    }
    return type;
  }
}

export class CardsLandingModel {
  constructor(props) {
    this.imgUrl = props;
  }

  title: string;
  description: string;
  imgUrl: string;
  background: string;
  isEnabled: boolean;
  list: any[];

  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    this.description = apiModel.description;
    this.background = new GetColor().color(apiModel.background);
    this.isEnabled = apiModel.isEnabled;
    if (apiModel.list && apiModel.list.length) {
      this.list = apiModel.list.map((item) => {
        let objUrl = {
          image: item.image ? `${this.imgUrl}/${item.image}` : null,
          class: item.class ? item.class : null,
          title: item.title,
          subTitle: item.subTitle,
          headline: item.headline ? item.headline : null,
          text: item.text ? item.text : null,
          button: item.button ? item.button : null,
          isEnabled: item.isEnabled ? item.isEnabled : false,
          targetBlank: item.targetBlank ? item.targetBlank : null,
          useImage: item.useImage
        };
        if (item.url) {
          const link = item.url && (item.url.indexOf('mailto') === 0 || item.url.indexOf('http') === 0) ? item.url : 'https://' + item.url;
          objUrl = Object.assign(objUrl, {url: link});
        }
        return objUrl;
      });
    }
    return this;
  }
}

export class TextLanguageModel {
  descriptionAdditional: string;
  title: string;
  description: string;
  link: string;
  button: string;
  url: string;
  background: string;
  targetBlank: boolean;
  isEnabled: boolean;

  constructor() {
  }

  setFromApiModel(apiModel: any): this {
    this.descriptionAdditional = apiModel.descriptionAdditional;
    this.title = apiModel.title;
    this.description = apiModel.description;
    this.link = apiModel.link;
    this.button = apiModel.button;
    this.url = apiModel.url;
    this.background = new GetColor().color(apiModel.background);
    this.targetBlank = apiModel.targetBlank;
    this.isEnabled = apiModel.isEnabled;
    return this;
  }
}

export class NewsLandingModel {
  constructor(props) {
    this.imgUrl = props;
  }

  title: string;
  description: string;
  imgUrl: string;
  // background: string;
  isEnabled: boolean;
  list: any[];

  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    this.description = apiModel.description;
    // this.background = new GetColor().color(apiModel.background);
    this.isEnabled = apiModel.isEnabled;
    if (apiModel.list && apiModel.list.length) {
      this.list = apiModel.list.map((item) => {
        const spotifyURL = item.spotifyArtistUrl ? 'https://open.spotify.com/follow/1?uri=spotify:artist:' + item.spotifyArtistUrl + '&size=basic&theme=light' : null;
        let objUrl = {
          image: item.image ? `${this.imgUrl}/${item.image}` : null,
          title: item.title ? item.title : null,
          isNews: true,
          label: item.label,
          spotifyArtist: item.spotifyArtist ? item.spotifyArtist : null,
          isPublished: item.isPublished ? item.isPublished : null,
          social: item.social ? item.social : null,
          spotifyArtistUrl: spotifyURL,
          spotifySongLink: item.spotifySongLink ? item.spotifySongLink.replace('https://open.spotify.com', 'https://open.spotify.com/embed') : null,
          publishedAt: item.publishedAt ? moment(item.publishedAt, 'YYYY/MM/DD') : null,
          time: item.publishedAt ? moment(item.publishedAt).format('X000') : null,
          newsDescription: item.newsDescription ? item.newsDescription.replace(/(?:\r\n|\r|\n)/g, '<br />') : null,
        };

        if (item.url) {
          const link = item.url && (item.url.indexOf('mailto') === 0 || item.url.indexOf('http') === 0) ? item.url : 'https://' + item.url;
          objUrl = Object.assign(objUrl, {url: link});
        }

        return objUrl;
      });
    }
    return this;
  }
}


interface TextLandingBlock {
  title: string;
  subTitle: string;
  description: string;
  link: string;
  list: any[];
  image: string;
  descriptionAdditional: string;
  button: string;
  url: string;
  background: string;
  targetBlank: boolean;
  isEnabled: boolean;
}

export class LandingModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  carousel: CarouselModel;
  title: string;
  text: TextLandingBlock;
  cards: CardsLandingModel;
  news: NewsLandingModel;
  video: {
    title: string;
    description: string;
    linkVideo: string;
    background: string;
    isEnabled: boolean;
    targetBlank: boolean;
  };
  seo: any;
  textLanguage: {
    de: any;
    en: any;
  };
  spotify: {
    background: string,
    isEnabled: boolean
  };
  slider: {
    title: string,
    isEnabled: boolean,
    list: SliderModel
  };
  mainBlock: {
    title: string,
    text: string,
    photo: string
  };
  isPublished: boolean;

  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    this.carousel = new CarouselModel(this.imgUrl).setFromApiModel(apiModel.carousel);
    this.cards = new CardsLandingModel(this.imgUrl).setFromApiModel(apiModel.cards);
    this.seo = apiModel.seo;
    this.news = apiModel.news ? new NewsLandingModel(this.imgUrl).setFromApiModel(apiModel.news) : null;
    if (apiModel.mainBlock) {
      this.mainBlock = {
        title: apiModel.mainBlock.title,
        text: apiModel.mainBlock.description,
        photo: apiModel.mainBlock.photo ? `${this.imgUrl}/${apiModel.mainBlock.photo}` : null,
      };
    }
    if (apiModel.text) {
      this.text = {
        descriptionAdditional: apiModel.text.descriptionAdditional,
        title: apiModel.text.title,
        list: apiModel.text.list,
        subTitle: apiModel.text.subTitle,
        description: apiModel.text.description,
        link: apiModel.text.link,
        image: apiModel.text.image ? `${this.imgUrl}/${apiModel.text.image}` : null,
        button: apiModel.text.button,
        url: apiModel.text.url,
        background: new GetColor().color(apiModel.text.background),
        targetBlank: apiModel.text.targetBlank,
        isEnabled: apiModel.text.isEnabled
      };
    }
    if (apiModel.textLanguage && apiModel.textLanguage.de) {
      this.textLanguage = {
        de: new TextLanguageModel().setFromApiModel(apiModel.textLanguage.de),
        en: ''
      };
    }
    if (apiModel.video) {
      this.video = {
        title: apiModel.video.title,
        description: apiModel.video.description,
        linkVideo: 'https:' + apiModel.video.link,
        background: new GetColor().color(apiModel.video.background),
        targetBlank: apiModel.video.targetBlank,
        isEnabled: apiModel.video.isEnabled
      };
    }

    this.spotify = apiModel.spotify;
    if (apiModel.slider) {
      this.slider = {
        title: apiModel.slider.title,
        list: apiModel.slider.list.map((item) => new SliderModelLanding(this.imgUrl).setFromApiModel(item)),
        isEnabled: apiModel.slider.isEnabled,
      };
    }
    this.isPublished = apiModel.isPublished;
    return this;
  }
}

export class LandingModelReleases {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  image: string;
  slug?: string;
  song?: any;

  setFromApiModel(apiModel: any): this {
    this.image = apiModel.song.project.image ? `${this.imgUrl}/${apiModel.song.project.image}` : null;
    this.slug = apiModel.song.project.slug;
    this.song = apiModel.song.project.song;
    return this;
  }
}

export class LandingModelArtists {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  image: string;
  name: string;
  slug: string;
  social: any;
  country: string;
  type: boolean;

  setFromApiModel(apiModel: any): this {
    if (apiModel.artist.image){
      const image = apiModel.artist.image.includes('project');
      if (image) {
        this.image = apiModel.artist.image ? `${this.imgUrl}/${apiModel.artist.image}` : null;
      }
      else {
        this.image = apiModel.artist.image ? `${this.imgUrl}/images/${apiModel.artist.image}` : null;
      }
    }
    // this.image = apiModel.artist.image ? `${this.imgUrl}/images/${apiModel.artist.image}` : null;
    this.slug = apiModel.artist.slug;
    this.name = apiModel.artist.name;
    this.social = apiModel.artist.social;
    this.country = apiModel.artist.country;
    this.type = apiModel.keepItAll;
    return this;
  }
}
