import {Component, Output, EventEmitter, HostListener, OnInit} from '@angular/core';

@Component({
  selector: 'app-fees-popup',
  templateUrl: './fees-popup.html'
})


export class FeesPopupComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  ngOnInit() {
  }

  onClose() {
    this.notify.emit({type: 'closeConfirm'});
  }

  onConfirm() {
    this.notify.emit({type: 'confirm'});
  }

  onClosePopup() {
    this.notify.emit({type: 'closePopup'});
  }
}
