import {Component, Output, EventEmitter, ChangeDetectionStrategy, Input} from '@angular/core';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-notification-info-popup',
  templateUrl: './notification-info.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class NotificationInfoComponent {
  @Input() user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private authenticationService: AuthenticationService) {
  }

  onClose(): void {
    this.notify.emit({type: 'closePopup'});
  }

  onSubmit(): void {
    this.authenticationService.updateOptionalSettings({infoPayment: true}).subscribe(() => {
      this.notify.emit({type: 'closePopup'});
    }, () => {
      this.notify.emit({type: 'closePopup'});
    });
  }
}
