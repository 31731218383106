import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SettingService} from './setting.service';
import {LandingModel, LandingModelArtists, LandingModelReleases} from '../models/landing.model';
import {NewsCardModel} from '../models';

@Injectable()
export class LandingService {
  apiUrl: string;
  imgUrl: string;

  constructor(private http: HttpClient,
              private setting: SettingService) {
    this.apiUrl = this.setting.apiUrl;
    this.imgUrl = this.setting.imgUrl;
  }

  getPageContent(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/landing/${slug}`).pipe(map((resp: any) => {
      return new LandingModel(this.imgUrl).setFromApiModel(resp);
    }));
  }
  getLabelInfo(request?): Observable<any> {
    const params = request ? request.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/label`, {params}).pipe(map((resp: any) => {
      return {
        news: resp.card.map(v => {
          return new NewsCardModel(this.imgUrl).setFromApiModel(v);
        }),
        releases: resp.releases.map(v => {
          return new LandingModelReleases(this.imgUrl).setFromApiModel(v);
        }),
        artists: resp.artists.map(v => {
          return new LandingModelArtists(this.imgUrl).setFromApiModel(v);
        }),

      };
    }));
  }
}
