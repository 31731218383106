import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { NAVIGATION } from '@app/common/navigation';
import {StorageService} from "@services/storage.service";

@Injectable({
  providedIn: 'root',
})
export class WizardGuard implements CanActivate {
  constructor(private storage: StorageService, private router: Router) {}

  canActivate() {
    if (!this.storage.investmentDetail) {
      this.router.navigate([NAVIGATION.HOME]);
      return false;
    } else {
      return true;
    }
  }
}
