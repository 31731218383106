import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';

import {CARD_CONTENT} from "@app/common/content/card-page.content";

@Component({
  selector: 'header-card',
  templateUrl: './header-card.html',
  styleUrls: ['./header-card.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderCardComponent {
  public readonly CARD_CONTENT: any = CARD_CONTENT;
  @Input() project;


  constructor() {
  }
}
