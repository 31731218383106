import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AppToastrService, ImageService} from '@app/services';
import {base64ToFile, ImageCroppedEvent} from 'ngx-image-cropper';


@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.html'
})
export class ImageCropperComponent {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileName: any = '';
  imageLoaded = false;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Input() maintainAspectRatio;
  @Input() config;
  @Input() stringToFile;

  constructor(
    private imageService: ImageService,
    private toastr: AppToastrService,
  ) {
  }

  selectEvent(): void {
    this.notify.emit({
      type: 'clickUpload'
    });
  }

  savePhoto(): void {

    this.notify.emit({
      data: this.croppedImage,
      fileName: this.fileName
    });
  }

  fileChangeEvent(event: any): any {
    const allFormats = ['jpg', 'jpeg', 'png', 'PNG', 'JPG', 'JPEG', 'gif', 'GIF', 'webp', 'WEBP'];
    const extension = this.config.allowFormats ? this.config.allowFormats : allFormats;
    if (extension.indexOf(event.target.files[0].name.split('.').slice(-1)[0]) < 0) {
      const uniqExt = this.config.allowFormats.map(v => v.toLowerCase()).filter((v, i, a) => a.indexOf(v) === i);
      this.toastr.showErrorToast({
        title: `Allowed ${this.config.allowFormats ? uniqExt : 'jpg, png, jpeg, webp'} formats only`
      });
      return false;
   }
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
  }

  imageCroppedw(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    if (this.stringToFile) {
      this.croppedImage = base64ToFile(event.base64);
    }
  }

  imageLoadedw(image?: any): void {
    this.imageLoaded = true;
  }

  cropperReadyw(): void {
    // cropper ready
  }

  loadImageFailedw(e): void {
    this.toastr.showErrorToast({message: 'File extension should be jpg, jpeg, png, webp'});
  }
}
