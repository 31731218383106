import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable({providedIn: 'root'})

export class ApiService {
  apiUrl: string;
  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  get<T>(endpoint: string, params: any): Observable<T> {
    const api = `${this.apiUrl}/${endpoint}`;
    return this.http.get<T>(api, { params});
  }

  getBlob(endpoint: string): Observable<any> {
    const api = `${this.apiUrl}/${endpoint}`;
    const options = {responseType: 'blob' as 'blob'};
    return this.http.get(api,  options);
  }

  post<T>(endpoint: string, data: any = {}): Observable<T> {
    const api = `${this.apiUrl}/${endpoint}`;
    return this.http.post<T>(api, data);
  }

  put<T>(endpoint: string, data: any): Observable<T> {
    const api = `${this.apiUrl}/${endpoint}`;
    return this.http.put<T>(api, data);
  }
}
