<div class="cropper__buttons">
  <label class="button yellow crop__upload">
    <input
      class="desktop-upload"
      type="file"
      (change)="fileChangeEvent($event)"
      (click)="selectEvent()"
    />
    <ng-container *ngIf="!imageLoaded">
      <span
        >{{config.textButton[0]}}<br
          *ngIf="config.textButton[1]"
        />{{config.textButton[1]}}</span
      >
    </ng-container>
    <ng-container *ngIf="imageLoaded">
      <span>Reupload</span>
    </ng-container>
  </label>
  <button
    type="button"
    class="button yellow cropper-save"
    *ngIf="imageLoaded"
    (click)="savePhoto()"
  >
    Continue
  </button>
</div>
<image-cropper
  *ngIf="config.imageFormat === 'webp'"
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="config.maintainAspectRatio"
  [aspectRatio]="16 / 6"
  format="webp"
  (imageCropped)="imageCroppedw($event)"
  (imageLoaded)="imageLoadedw($event)"
  (cropperReady)="cropperReadyw()"
  (loadImageFailed)="loadImageFailedw($event)"
></image-cropper>
<image-cropper
  *ngIf="config.imageFormat === 'jpg'"
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="config.maintainAspectRatio"
  [aspectRatio]="16 / 6"
  format="jpeg"
  (imageCropped)="imageCroppedw($event)"
  (imageLoaded)="imageLoadedw($event)"
  (cropperReady)="cropperReadyw()"
  (loadImageFailed)="loadImageFailedw($event)"
></image-cropper>
<image-cropper
  *ngIf="config.imageFormat === 'png'"
  [imageChangedEvent]="imageChangedEvent"
  [maintainAspectRatio]="config.maintainAspectRatio"
  [aspectRatio]="16 / 6"
  format="png"
  (imageCropped)="imageCroppedw($event)"
  (imageLoaded)="imageLoadedw($event)"
  (cropperReady)="cropperReadyw()"
  (loadImageFailed)="loadImageFailedw($event)"
></image-cropper>
