import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from '@abacritt/angularx-social-login';

// Consts
import { environment } from '../environments/environment';

// Modules
import { PortalModule } from '@angular/cdk/portal';
import { PagesModule } from '@app/pages/pages.module';
import { AppRoutingModule } from '@app/app-routing.module';
import { HeaderModule } from '@app/components/global/header/header.module';
import { HammerModule } from "@angular/platform-browser";

// Components
import { AppComponent } from '@app/app.component';
import { CookiesComponent } from '@app/components/cookies/cookies.component'; 

@NgModule({
  declarations: [AppComponent, CookiesComponent],
  imports: [
    AppRoutingModule,
    TransferHttpCacheModule,
    PagesModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    PortalModule,
    HeaderModule,
    HammerModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: 'ENVIRONMENT',
      useValue: environment,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
