<div class="wrapper-kyc" (click)="openKYCvalidation()">
  <span class="kyc-title">KYC validation</span>
  <div
    class="kyc-status-block text-uppercase"
    [class.inactive]="user?.mangoVerificationStatus?.id !== 1 && user?.mangoVerificationStatus?.id !== 2"
    [class.active]="user?.mangoVerificationStatus?.id === 1"
    [class.pending]="user?.mangoVerificationStatus?.id === 2"
  >
    <span *ngIf="user?.mangoVerificationStatus?.id === 1"
      ><i class="fas fa-check"></i>Verified</span
    >
    <span *ngIf="user?.mangoVerificationStatus?.id === 2"
      ><i class="fas fa-times"></i>Submitted</span
    >
    <span *ngIf="user?.mangoVerificationStatus?.id === 0"
      ><i class="fas fa-times"></i>Not Verified</span
    >
    <span *ngIf="user?.mangoVerificationStatus?.id === 3"
      ><i class="fas fa-times"></i>Failed</span
    >
  </div>
</div>
<div class="error" *ngIf="user?.mangoVerificationStatus?.id === 3">
  <i class="far fa-question-circle"></i>
  <div class="wrapper-tooltip error-hint">
    <div class="tooltip tooltip-kyc error__tooltip">
      <p class="error__list" *ngIf="user?.kycFailedReason && user?.isPrivate">
        {{kycFailedReason}}
      </p>
      <ul
        class="error__list"
        *ngIf="user?.kycFailedReasonCompany?.length && !user?.isPrivate"
      >
        <li
          class="error__item"
          *ngFor="let item of user.kycFailedReasonCompany"
        >
          <p class="error__title">{{item?.code }}&nbsp; :&nbsp;</p>
          <p>{{item?.text}}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="wrapper-tooltip-kyc">
  <div class="tooltip tooltip-kyc" [class.showTooltip]="showTooltip">
    <ng-container
      *ngIf="this.user?.contact?.birthdate; else completeProfileMessage"
    >
      A KYC validation is needed to withdraw money from your balance.
      <br />
      You can verify with your first withdrawal or by clicking on the icon "not
      verified"</ng-container
    >
    <ng-template #completeProfileMessage="">
      In order to be KYC verified and invest in an opportunity, it is necessary
      to complete your profile.
    </ng-template>
  </div>
</div>
<app-upload-file-popup
  [isSEP]="isSEP"
  [user]="user"
  *ngIf="showUploadPopup"
  (notify)="onNotify($event)"
></app-upload-file-popup>
