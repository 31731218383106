<div class="gray-text inner-text" [innerHTML]="project?.myOffersDetails"></div>

<ng-container *ngIf="(agreementLanguage$ | async) as agreementResp">
  <ng-container *ngIf="agreementResp.en">

    <a class="item-share-document"
       href="#"
       (click)="openDocument($event, 'en')"
       target="_blank">
      <img src="./assets/images/file-pdf.png" alt="file-pdf">
      <span class="link-blue">preview Proceeds Agreement (ENG)</span>
    </a>
  </ng-container>
  <ng-container *ngIf="agreementResp.de">
    <a class="item-share-document"
       href="#"
       (click)="openDocument($event, 'de')"
       target="_blank">
      <img src="./assets/images/file-pdf.png" alt="file-pdf">
      <span class="link-blue">preview Proceeds Agreement (GER)</span>
    </a>
  </ng-container>
  <ng-container *ngIf="project?.artistCategory === 'grs'">
    <a class="item-share-document" [grsOpenFile]="'Informationblatt'" target="_blank">
      <img src="./assets/images/file-pdf.png" alt="file-pdf">
      <span class="link-blue">INFORMATIONSBLATT NACH AltFG</span>
    </a>
  </ng-container>
</ng-container>
