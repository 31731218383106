import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ClickOutsideModule} from 'ng-click-outside';

// Components
import {GlobalHeaderComponent} from './header.component';
import {NavDropdownComponent} from './nav-dropdown/nav-dropdown.component';

@NgModule({
  imports: [CommonModule, ClickOutsideModule, RouterModule],
  exports: [
    GlobalHeaderComponent,
    NavDropdownComponent,
  ],
  declarations: [
    GlobalHeaderComponent,
    NavDropdownComponent,
  ]
})
export class HeaderModule {
}
