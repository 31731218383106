<div class="wrapper project-description">
  <div class="m-flex">
    <div class="gray-text description text-description" [innerHTML]="description()"></div>
  </div>
</div>
<div class="spotify-section" *ngIf="initLoad()">
  <ng-container *ngIf="isSpotifyLink()">
    <div class="spotify-wrapper iframe-block">
      <iframe
        style="border-radius:12px"
        [src]="generateIframeUrl('spotify')"
        width="100%"
        height="352" frameBorder="0" allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="isYoutubeLink()">
    <div class="iframe-block">
      <iframe
        width="100%" height="315"
        [src]="generateIframeUrl('youtube')"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="isSoundcloudLink()">
    <div class="soundcloud-wrapper iframe-block">
      <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay"
              [src]="generateIframeUrl('soundcloud')">
      </iframe>
    </div>
  </ng-container>
  <ng-container *ngIf="isInstagramLink()">
    <app-instagram-iframe [instagramId]="getId('instagram')"></app-instagram-iframe>
  </ng-container>
</div>
