import {
  Component,
  Input, ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-project-mobile-view-block',
  templateUrl: './project-mobile-view-block.html',
  styleUrls: ['./project-mobile-view-block.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMobileViewBlockComponent {
  @Input() title: string;
  @Input() show: boolean = false;

  toggleShow() {
    this.show = !this.show;
  }
}
