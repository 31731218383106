import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SliderModel} from '../../models';
import {timer} from 'rxjs';
import {Router} from '@angular/router';
import {StorageService} from '../../services/storage.service';
import {SettingService} from '../../services';

export interface SlideConfig {
  slidesToShow: number;
  slidesToScroll: number;
  dots: boolean;
  autoplay: boolean;
  autoplaySpeed: number;
  nextArrow: string;
  prevArrow: string;
}

@Component({
  selector: 'app-new-music-slider',
  templateUrl: './new-music-slider.component.html',
  styleUrls: ['./new-music-slider.component.scss']
})
export class NewMusicSliderComponent implements OnInit {

  slideWrapper: any;
  slide: any;
  user: any;
  sliders: {
    title: string,
    list: SliderModel[]
  };
  isCarouselShow = true;
  width: string;
  height: string;
  imgUrl: string;
  @Input() page: string;
  @Input() slideConfig: SlideConfig;
  @Input() list: any[];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize') windowResize() {
    if (window.innerWidth < 768) {
      this.setCarouselConfig(1, false);
    }
    if (window.innerWidth > 768 && window.innerWidth < 1024 && this.page === 'label') {
      this.setCarouselConfig(3, true);
    }
    if (window.innerWidth > 1024 && this.page === 'label') {
      this.setCarouselConfig(4, true);
    }

  }

  constructor(
    private storageService: StorageService,
    private router: Router,
    private settingService: SettingService
  ) {
  }

  ngOnInit() {
    this.user = this.storageService.user;
    this.windowResize();
    this.getStyle();
    this.imgUrl = this.settingService.imgUrl;
  }

  getStyle() {
    if (this.page === 'artist') {
      this.width = '300px !important';
      this.height = '300px !important';
    }
    if (this.page === 'label') {
      this.width = '240px !important';
      this.height = '240px !important';
    }
  }

  trackByFn(index, item) {
    return item.id;
  }

  openProject(slug, featureFm?) {
    if (featureFm){
      window.open(featureFm);
    }
    else{
    this.router.navigate([`/projects`, slug]);
    }
  }

  setCarouselConfig(slidesNumber, dots?): void {
    this.slideConfig.slidesToShow = slidesNumber;
    this.slideConfig.slidesToScroll = slidesNumber;
    this.slideConfig.dots = dots;
    this.isCarouselShow = false;
    const time = timer(10);
    time.subscribe(() => this.isCarouselShow = true);
  }
}
