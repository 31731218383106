import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import {isValidIBANNumber} from '@app/common/helpers';

// Services
import {AppToastrService, DictionaryService, ProfileService} from '@app/services';

// Consts
import {
  IBAN_PATTERN,
  BSB_PATTERN,
  BIC_PATTERN,
  ABA_PATTERN,
  BRANCH_CODE_PATTERN,
  INSTITUTION_NUMBER_PATTERN,
  ACCOUNTING_NUMBER_US,
  ACCOUNTING_NUMBER_AU,
  ACCOUNTING_NUMBER_CA,
  BANK_ACCOUNT_FORM_CONFIG,
} from '@app/common';
import {BankAccountModel, RequestModel} from '@app/models';
import {Collection} from '@app/types';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {finalize} from "rxjs/operators";
import {BehaviorSubject} from "rxjs";

@UntilDestroy()
@Component({
  selector: 'bank-account-form',
  templateUrl: './bank-account-form.component.html',
  styleUrls: ['bank-account-form.component.scss'],
})
export class BankAccountFormComponent implements OnInit {
  @Input() account: BankAccountModel | any = {};

  @Input() country: string = '';

  @Input() styleType: 'horizontal' | 'vertical' = 'horizontal';

  @Output() onSuccess: EventEmitter<any> = new EventEmitter();

  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  form: FormGroup;

  public readonly depositAccountTypes = ['CHECKING', 'SAVINGS'];
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  apiError: string = null;

  constructor(
    private toastr: AppToastrService,
    private profileService: ProfileService,
    private fb: FormBuilder,
    private dictionaryService: DictionaryService
  ) {
  }

  get isNewAccount(): boolean {
    return !this.account?.bankId;
  }

  ngOnInit() {
    const request = new RequestModel({
      countries: 'ibanCountries'
    });
    this.dictionaryService.getIbanCountries(request).pipe(untilDestroyed(this)).subscribe(countries => {
      const ibanArrayIndex = BANK_ACCOUNT_FORM_CONFIG.findIndex(item => item.inputName === 'IBAN');
      const accNumIndex = BANK_ACCOUNT_FORM_CONFIG.findIndex(item => item.inputName === 'AccountNumber');

      const existingExcludedCountriesAccNum = BANK_ACCOUNT_FORM_CONFIG[accNumIndex].excludedCountries || [];
      BANK_ACCOUNT_FORM_CONFIG[accNumIndex].excludedCountries = [...countries, ...existingExcludedCountriesAccNum];

      const existingIncludedCountriesIban = BANK_ACCOUNT_FORM_CONFIG[ibanArrayIndex].includedCountries || [];
      BANK_ACCOUNT_FORM_CONFIG[ibanArrayIndex].includedCountries = [...countries, ...existingIncludedCountriesIban];

      this.initForm();
      if (!this.isNewAccount) {
        this.form.disable();
      }
    })
  }

  initForm(): void {

    const filetredNames: any = BANK_ACCOUNT_FORM_CONFIG.filter(
      (inputConfig) => {
        // permission for new accounts
        const isInputAllowed: boolean = inputConfig.forNewAccountOnly
          ? this.isNewAccount
          : true;
        // no restriction
        if (!inputConfig.excludedCountries && !inputConfig.includedCountries) {
          return isInputAllowed;
          // exclude restricted & allow others
        } else if (inputConfig.excludedCountries) {
          return (
            !inputConfig.excludedCountries.includes(this.country) &&
            isInputAllowed
          );
          // include allowed & exclude restricted
        } else if (inputConfig.includedCountries) {
          return (
            inputConfig.includedCountries.includes(this.country) &&
            isInputAllowed
          );
        }
      }
    ).reduce((result, inputConfig) => {
      let {inputName} = inputConfig;
      return {
        ...result,
        [inputName]: [
          this.account[inputName] || '',
          this.getValidator(inputName, this.country),
        ],
      };
    }, {});

    this.form = this.fb.group(filetredNames);
  }

  getValidator(fieldName: string, country: string): Object[] | null {
    switch (fieldName) {
      case 'IBAN':
        return [
          Validators.required,
          Validators.pattern(IBAN_PATTERN),
          this.IBANValidator(),
        ];
      case 'BSB':
        return [Validators.required, Validators.pattern(BSB_PATTERN)];
      case 'BIC':
        return [Validators.required, Validators.pattern(BIC_PATTERN)];
      case 'ABA':
        return [Validators.required, Validators.pattern(ABA_PATTERN)];
      case 'BankName':
        return [Validators.required];
      case 'DepositAccountType':
        return [Validators.required];
      case 'BranchCode':
        return [Validators.required, Validators.pattern(BRANCH_CODE_PATTERN)];
      case 'InstitutionNumber':
        return [
          Validators.required,
          Validators.pattern(INSTITUTION_NUMBER_PATTERN),
        ];
      case 'AccountNumber':
        if (country === 'US') {
          return [
            Validators.required,
            Validators.pattern(ACCOUNTING_NUMBER_US),
          ];
        } else if (this.country === 'CA') {
          return [
            Validators.required,
            Validators.pattern(ACCOUNTING_NUMBER_CA),
          ];
        } else if (this.country === 'AU') {
          return [
            Validators.required,
            Validators.pattern(ACCOUNTING_NUMBER_AU),
          ];
        } else {
          break;
        }

      default:
        return null;
    }
  }

  IBANValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return isValidIBANNumber(value).ibanValid
        ? null
        : {IBAN: 'Invalid IBAN value'};
    };
  }

  removeGaps() {
    const form = this.form.controls;
    for (const key in form) {
      if (
        form.hasOwnProperty(key) &&
        form[key].value &&
        typeof form[key].value === 'string'
      ) {
        form[key].setValue(form[key].value.trim());
      }
    }
  }

  formatBsb(data: Collection) {
    data?.BSB?.replace('-', '');
  }

  setAsPrimary(): void {
    const data = {
      id: this.account.bankId,
      isPreferred: true
    }
    this.profileService
      .updateBankAccount(data, this.country)
      .subscribe();
  }

  saveAccount(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid || this.isStatusLoading()) return;

    this.removeGaps();

    const data = this.form.getRawValue();

    this.formatBsb(data);


    let serviceMethod: string = 'addBankAccount';
    let successMessage: string = 'Bank account successfully added';

    if (!this.isNewAccount) {
      serviceMethod = 'updateBankAccount';
      successMessage = 'Bank account successfully updated';
      data.id = this.account.bankId;
    }
    this.isLoading$.next(true);
    this.profileService[serviceMethod](data, this.country).pipe(
      finalize(() => {
        this.isLoading$.next(false);
      })).subscribe(
      (resp: any) => {
        this.toastr.showToast({title: successMessage});
        this.form.disable();
        this.onSuccess.emit(resp);
      },
      (err) => {
        this.toastr.showToastFromError(err);
        this.apiError = err?.error?.message;
      }
    );
  }

  private isStatusLoading(): boolean {
    return this.isLoading$.getValue();
  }

  cancelChanges(): void {

    if (this.isStatusLoading()) return;

    if (this.isNewAccount) {
      this.form.reset();
    } else {
      this.initForm();
      this.form.disable();
    }
    this.apiError = null;
    this.onCancel.emit();
  }

  editAccount(): void {
    this.form.controls.placeholder.enable();
  }

  deleteAccount(): void {
    if (confirm('Are you sure you want to delete this account?')) {
      this.isLoading$.next(true);
      this.profileService.deleteBankAccount(this.account.bankId).pipe(
        finalize(() => {
          this.isLoading$.next(false);
        })).subscribe(
        () => {
          this.toastr.showToast({title: 'Bank account successfully deleted'});
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
    }
  }
}
