import {Pipe, PipeTransform} from '@angular/core';
@Pipe({name: 'momentDate'})
export class MomentDatePipe implements PipeTransform {

  transform(value, format?: string, formatInput?: string) {
    if (value && !formatInput) {
      return value.format(format || 'DD.MM.YYYY');
    } else {
      return value;
    }
  }
}
