import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// Modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { UiModule } from '@app/components/global/ui.module';
import { PipesModule } from '@app/pipes/pipes.module';
import { KycModule } from '@app/components/features/kyc/kyc.module';
import { AppFormsModule } from '../forms/app-forms.module';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

// Components
import { MagicVerificationPopupComponent } from '@app/components/popups/magic-verification-popup/magic-verification-popup.component';
import { PopupComponent } from '@app/components/popups/popup/popup.component';
import { AlertPopupComponent } from '@app/components/popups/alert-popup/alert-popup.component';
import { AddBankAccountPopupComponent } from './bank-account/add-bank-account-popup.component';
import { BidPopupComponent } from '@app/components/popups/bid-popup/bid-popup.component';
import { CallEventPopupComponent } from '@app/components/popups/call-event-popup/call-event-popup.component';
import { DeletePopupComponent } from '@app/components/popups/delete-popup/delete-popup.component';
import { EditPasswordComponent } from '@app/components/popups/edit-password/edit-password.component';
import { FeesPopupComponent } from '@app/components/popups/fees-popup/fees-popup.component';
import { InvestorPopupComponent } from '@app/components/popups/investor-popup/investor-popup.component';
import { NicknamePopupComponent } from '@app/components/popups/nickname-popup/nickname-popup.component';
import { NotificationInfoComponent } from '@app/components/popups/notification-inform/notification-info.component';
import { PopupPartHeaderComponent } from '@app/components/popups/popup-part-header/popup-part-header.component';
import { ReinvestPopupComponent } from '@app/components/popups/reinvest-popup/reinvest-popup.component';
import { SubmitSongAlertComponent } from '@app/components/popups/submit-song-alert/submit-song-alert.component';
import { UploadFilePopupComponent } from '@app/components/popups/upload-file-popup/upload-file-popup.component';
import { ReminderCompleteProfileComponent } from '@app/components/popups/reminder-complete-profile/reminder-complete-profile.component';
import { KycSkipConfirmComponent } from '@app/components/popups/kyc-skip-confirm/kyc-skip-confirm.component';
import { CountdownComponent } from '@app/components/global/countdown/countdown.component';
import { PaymentBlockComponent } from '@app/components/popups/payment-block/payment-block.component';
import { UsaRedirectPopupComponent } from '@app/components/popups/usa-redirect-popup/usa-redirect-popup.component';
import {ReminderToKycComponent} from "@app/components/popups/reminder-to-kyc/reminder-to-kyc.component";
@NgModule({
  exports: [
    MagicVerificationPopupComponent,
    PopupComponent,
    AlertPopupComponent,
    AddBankAccountPopupComponent,
    BidPopupComponent,
    CallEventPopupComponent,
    DeletePopupComponent,
    EditPasswordComponent,
    FeesPopupComponent,
    InvestorPopupComponent,
    NicknamePopupComponent,
    NotificationInfoComponent,
    PopupPartHeaderComponent,
    ReinvestPopupComponent,
    ReminderToKycComponent,
    ReminderCompleteProfileComponent,
    SubmitSongAlertComponent,
    UploadFilePopupComponent,
    KycSkipConfirmComponent,
    PaymentBlockComponent,
    UsaRedirectPopupComponent,
  ],
  declarations: [
    MagicVerificationPopupComponent,
    PopupComponent,
    AlertPopupComponent,
    AddBankAccountPopupComponent,
    BidPopupComponent,
    CallEventPopupComponent,
    DeletePopupComponent,
    EditPasswordComponent,
    ReminderToKycComponent,
    FeesPopupComponent,
    InvestorPopupComponent,
    NicknamePopupComponent,
    ReminderCompleteProfileComponent,
    NotificationInfoComponent,
    PopupPartHeaderComponent,
    ReinvestPopupComponent,
    SubmitSongAlertComponent,
    UploadFilePopupComponent,
    KycSkipConfirmComponent,
    CountdownComponent,
    PaymentBlockComponent,
    UsaRedirectPopupComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    PipesModule,
    KycModule,
    AppFormsModule,

    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
  ],
})
export class PopupModule {}
