import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SliderModel } from '../../models';
import { ImageService } from '../../services';
import { Router } from '@angular/router';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-opportunity-slider',
  templateUrl: './opportunity-slider.component.html',
  styleUrls: ['./opportunity-slider.component.scss'],
})
export class OpportunitySliderComponent implements OnInit {
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000
  };
  slideWrapper: any;
  slide: any;
  user: any;
  sliders: {
    title: string;
    list: SliderModel[];
  };
  mobile: boolean;
  id: any;
  @Input() url: any;
  @Input() projects: any[];
  @Input() state: any;
  @Input() config: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize') windowResize() {
    if (window.innerWidth > 550) {
      this.mobile = false;
    }
    if (window.innerWidth < 550) {
      this.mobile = true;
    }
  }

  constructor(
    private imageService: ImageService,
    private storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit() {

    this.user = this.storageService.user;
    this.windowResize();
    this.slideConfig = this.config;
    // this.slideConfig.dots = false;
  }

  trackByFn(index, item) {
    return item.id;
  }

  openAudio(item){
    this.id = item.id;
    this.notify.emit(item);
  }

  openProject(slug){
    this.router.navigate([`/projects`, slug]);
 }
}
