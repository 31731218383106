import {Directive, Output, EventEmitter, HostListener, ElementRef} from '@angular/core';

@Directive({
  selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {
  @Output() scrollingFinished = new EventEmitter<void>();
  emitted = false;
  constructor(private el: ElementRef) { }
  @HostListener('window:scroll', [])

  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= this.el.nativeElement.offsetHeight && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else if ((window.innerHeight + window.scrollY) < this.el.nativeElement.offsetHeight) {
      this.emitted = false;
    }
  }
}
