import {Injectable} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {IFRAME_LINK} from "@app/pages/project/constant/constants";
import {iFrameLink} from "@app/pages/project/type/iframe-link";


@Injectable()
export class IframeUrlGeneratorService {

  constructor(private sanitizer: DomSanitizer) {}

  generateIframeUrl(linkType: iFrameLink, id: string): SafeResourceUrl {
    let url = '';
    const IFrameLink = IFRAME_LINK;

    switch (linkType) {
      case IFrameLink.SPOTIFY:
        url = `https://open.spotify.com/embed/${id}?utm_source=generator`;
        break;
      case IFrameLink.YOUTUBE:
        url = `https://www.youtube.com/embed/${id}`;
        break;
      case IFrameLink.INSTAGRAM:
        url = `https://www.instagram.com/${id}/?utm_source=ig_embed&amp;utm_campaign=loading`;
        break;
      case IFrameLink.SOUNDCLOUD:
        url = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/${id}%3Fsecret_token%3Ds-n9lWO2iZkLz&color=%23ff5500&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false`;
        break;
      default:
        break;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

