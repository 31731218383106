  <div class="detail-song-info__grs-details grs-details">
    <ng-container
      *ngTemplateOutlet="projectDetail; context: {project: project}"
    ></ng-container>
  </div>

<ng-template #projectDetail let-project="project">
  <div class="grs-details__body">
    <ul class="grs-details__col">
      <li class="row-auto-height">
        <p>Rights:</p>
        <div class="song-value">
          <ng-container *ngIf="project?.isCollectible">
            {{ project?.collectionRights }}
          </ng-container>
          <ng-container *ngIf="!project?.isCollectible">
             <div  style="white-space: pre-line" [innerHtml]="project?.details?.rights"></div>
          </ng-container>
        </div>
      </li>
      <li>
        <p>Type:</p>
        <p class="song-value">
          {{project?.isCollectible ? 'Music Collectible' : 'Participation Rights'}}
        </p>
      </li>
      <li *ngIf="project?.isCollectible">
        <p>Collection:</p>
        <p class="song-value">{{project?.collectionName}}</p>
      </li>
      <li>
        <p>Rights Holder:</p>
        <p class="song-value">{{project?.details?.rightsHolder}}</p>
      </li>
      <li>
        <p>Status:</p>
        <p class="song-value">{{project?.details?.statusDetail}}</p>
      </li>
      <li *ngIf="!project?.isCollectible">
        <p>{{project?.details?.dateKey}}:</p>
        <p class="song-value">{{project?.details?.datePlannedRelease}}</p>
      </li>
      <li>
        <p>Contract Address:</p>
        <p class="song-value">
          <a
            href="https://polygonscan.com/address/0xFB66799af0Aa4cD63cb660Ede462a4AdD13aCF52"
          >0xFB667...13aCF52</a
          ><img
          class="img-copy"
          src="./assets/images/cards/copy.png"
          [cdkCopyToClipboard]="'0xFB66799af0Aa4cD63cb660Ede462a4AdD13aCF52'"
        />
        </p>
      </li>
      <li>
        <p>Token Standard:</p>
        <p class="song-value">
          {{project?.isCollectible ? 'ERC721' : 'ERC1155'}}
        </p>
      </li>
      <li>
        <p>Blockchain:</p>
        <p class="song-value">Polygon</p>
      </li>
      <li class="grs-details__line">
        <p>{{project?.isCollectible ? 'Minimum Bid:' : 'Starting from:'}}</p>
        <p class="song-value">
          <span *ngIf="!project?.isCollectible"
          >{{project?.priceForShare | localeCurrency | tenth :
            project?.decimalType}}</span
          >
          <span *ngIf="project?.isCollectible"
          >{{project?.startingPrice | localeCurrency }}</span
          >
        </p>
      </li>
      <li *ngIf="!project?.isCollectible && project?.details?.breakEven">
        <p>Break-even:</p>
        <p class="song-value">{{project?.details?.breakEven}}</p>
      </li>
    </ul>
  </div>
</ng-template>
