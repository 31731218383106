export const DAYS = [
  {value: 1, name: 1},
  {value: 2, name: 2},
  {value: 3, name: 3},
  {value: 4, name: 4},
  {value: 5, name: 5},
  {value: 6, name: 6},
  {value: 7, name: 7},
  {value: 8, name: 8},
  {value: 9, name: 9},
  {value: 10, name: 10},
  {value: 11, name: 11},
  {value: 12, name: 12},
  {value: 13, name: 13},
  {value: 14, name: 14},
  {value: 15, name: 15},
  {value: 16, name: 16},
  {value: 17, name: 17},
  {value: 18, name: 18},
  {value: 19, name: 19},
  {value: 20, name: 20},
  {value: 21, name: 21},
  {value: 22, name: 22},
  {value: 23, name: 23},
  {value: 24, name: 24},
  {value: 25, name: 25},
  {value: 26, name: 26},
  {value: 27, name: 27},
  {value: 28, name: 28},
  {value: 29, name: 29},
  {value: 30, name: 30},
  {value: 31, name: 31}
];
export const MONTH = [
  {value: 1, name: 'Jan'},
  {value: 2, name: 'Feb'},
  {value: 3, name: 'Mar'},
  {value: 4, name: 'Apr'},
  {value: 5, name: 'May'},
  {value: 6, name: 'Jun'},
  {value: 7, name: 'Jul'},
  {value: 8, name: 'Aug'},
  {value: 9, name: 'Sep'},
  {value: 10, name: 'Oct'},
  {value: 11, name: 'Nov'},
  {value: 12, name: 'Dec'}
];

const start = 1905;
const current = +new Date().getFullYear();
const years = [];
for (let i = current; i >= start; i--) {
  years.push({value: i, name: i});
}
export const YEARS = years;
