import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {AppToastrService} from '../../../services';
import {STATE_NAMES} from '../../../common';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../services/authentication.service';
import {StorageService} from '../../../services/storage.service';

@Component({
  selector: 'app-tfa-popup',
  templateUrl: './tfa-popup.component.html',
  styleUrls: ['./tfa-popup.component.scss']
})
export class TfaPopupComponent implements OnInit {
  user: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private router: Router,
              private storageService: StorageService,
              private toastr: AppToastrService,
              private authenticationService: AuthenticationService) {
  }

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  ngOnInit() {
    this.user = this.storageService.user;
  }

  onClose() {
    this.onSubmit(true);
  }

  onSubmit(close?: boolean) {
    if (!close) {
      this.router.navigate([STATE_NAMES.profile]);
    }
    this.authenticationService.updateOptionalSettings({showInfoTFA: true}).subscribe(item => {
      if (item) {
        this.user.showInfoTFA = true;
        this.storageService.user = this.user;
        this.notify.emit({type: 'close'});
      }
    }, err => {
      this.notify.emit({type: 'close'});
      this.toastr.showToastFromError(err);
    });

    this.notify.emit({type: 'close'});
  }
}
