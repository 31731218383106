<div class="hover-block">
<!--  <img loading="lazy"-->
<!--       *ngIf="!hoverText && img"-->
<!--       alt="logo tooltip" src="{{img}}">-->
  <div class="img-wrapper"  *ngIf="!hoverText">
    <ng-content select="[img-content]"></ng-content>
  </div>

  <div class="tooltip-card"
       *ngIf="showTooltipKey" (click)="stopPropagation($event)">
    <p  class="tooltip-text"
        *ngIf="!hoverText && innerHtmlText"
        [innerHTML]="innerHtmlText"
    ></p>
    <ng-content select="[text-content]"></ng-content>
    </div>
</div>
