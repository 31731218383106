<app-project-mobile-view-block [title]="'Details'" [show]="showDetail" >
  <app-project-song-detail [project]="project"></app-project-song-detail>
</app-project-mobile-view-block>
<app-project-mobile-view-block [title]="'Description'" [show]="showDescription">
  <app-project-description [project]="project"></app-project-description>
</app-project-mobile-view-block>
<ng-container *ngIf="project?.sLink?.title">
  <app-project-mobile-view-block [title]="iFrameSection" [show]="showDescription">
    <app-project-social-iframe [project]="project"></app-project-social-iframe>
  </app-project-mobile-view-block>
</ng-container>
<app-project-mobile-view-block [title]="'You get'" [show]="showYouGet" >
  <app-project-you-get [project]="project" ></app-project-you-get>
</app-project-mobile-view-block>
<app-project-mobile-view-block [title]="artistName" [show]="showAboutArtist">
  <app-artist-social-links [project]="project"></app-artist-social-links>
</app-project-mobile-view-block>
