import { Component, Input, OnInit } from '@angular/core';

import { ModalService } from '@services/modal.service';
import { STATE_NAMES } from '@app/common';
import { StorageService } from '@services/storage.service';
import { Router } from '@angular/router';
import { decodeStringUri } from '@app/common/helpers';

@Component({
  selector: 'magic-verification-popup',
  templateUrl: './magic-verification-popup.component.html',
  styleUrls: ['./magic-verification-popup.component.scss'],
})
export class MagicVerificationPopupComponent implements OnInit {
  @Input() inputValue: string;

  constructor(
    private modalService: ModalService,
    private storage: StorageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    
    this.redirectURL(this.inputValue);
  }

  onClose(): void {
    this.modalService.close();
  }

  redirectURL(page: string) {
    const investmentDetail = this.storage.investmentDetail;
    const redirectPage = investmentDetail ? STATE_NAMES.wizard : page;
    switch (redirectPage) {
      case STATE_NAMES.wizard:
        this.router.navigate([`/${STATE_NAMES.wizard}`], {
          queryParams: { isNewUser: true },
        });
        break;
      default:
        this.router.navigate([`/${decodeStringUri(page)}`]);
        break;
    }
  }
}
