export class VoucherModel {
  amount: number;
  id: string;
  leftAmount: number;
  time: string;
  serialNumber: string;
  constructor() {

  }

  setFromApiModel(apiModel: any): this {
    this.amount = apiModel.amount;
    this.id = apiModel._id;
    this.leftAmount = apiModel.leftAmount;
    this.serialNumber = apiModel.serialNumber;
    return this;
  }
}
