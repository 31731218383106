import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IErrorBid} from '../common/interfaces';

@Injectable()
export class AppToastrService {
  constructor(private toastrService: ToastrService) {
  }

  showToast(data: any, params?: any) {
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});

    this.toastrService.success(data.message, data.title, obj);
  }

  showWarningToast(data: any, params?: any) {
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});

    this.toastrService.warning(data.message, data.title, obj);
  }

  showErrorToast(data: any, params?: any) {
    if (!data.title && !data.message) {
      data.title = 'Something went wrong';
      data.message = 'Please try again or contact support';
    }
    const obj = Object.assign({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }, params || {});
    this.toastrService.error(data.message, data.title, obj);
  }

  showToastFromError(err: any, disableTimeOut?: boolean) {
    if (err.error && err.error.modelState) {
      let list = [];
      for (const key in err.error.modelState) {
        if (err.error.modelState.hasOwnProperty(key)) {
          list = list.concat(err.error.modelState[key]);
        }
      }
      this.showErrorToast({
        title: err.error.message,
        message: list.join(' ')
      });
    } else if (err.error && (err.error.message || err.error.info)) {
      if (typeof err.error.message === 'string'){
        this.showErrorToast({
          title: err.error.info || err.error.message,
          disableTimeOut
        });
      } else {
        this.showErrorToast({
          title: err.error.message.error && err.error.message.error.Message ? err.error.message.error.Message : err.error.message.error,
          disableTimeOut
        });
      }
    } else if (err.error && err.error.errors) {
      let str = '';
      if (typeof err.error.errors === 'object') {
        for (const item in err.error.errors) {
          str += err.error.errors[item].toString();
        }
      }
      this.showErrorToast({
        title: str,
        disableTimeOut
      });
    } else {
      this.showErrorToast({
        title: err.error.info || err.error.message,
        disableTimeOut
      });
    }
  }

  bidErrorStatus(resp: IErrorBid): object {
    return {
      error: {
        message: `Your bid is too low. Please bid at least ${resp.winningPrice + 1} Euros`
      }
    };
  }
}
