<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="close()"></span>
    <h1 class="kyc-skip-confirm__title">ARE YOU SURE?</h1>
    <p class="kyc-skip-confirm__text">
      Due to local laws and regulations the Know-Your-Customer process (KYC) is
      <br />
      <b>mandatory</b> for the purchase of securities in music.
    </p>
    <p class="kyc-skip-confirm__text">
      By skipping you agree to <br />
      <b> successfully completing the KYC process within 72 hours</b> <br />
      of purchasing your music shares.
    </p>
    <countdown class="kyc-skip-confirm__countdown" [hours]="hoursLeft"></countdown>
    <p class="kyc-skip-confirm__text">
      If the KYC process is not completed within this time frame, your chosen
      payment <br />method will be refunded with the invested amount less a
      <b> 5% handling fee.</b>
    </p>
    <span class="kyc-skip-confirm__button grs-button--orange m-b-20" (click)="close()"
      >Complete now</span
    >
    <button
      *ngIf="hoursLeft"
      class="kyc-skip-confirm__button grs-button--orange grs-button--outline"
      (click)="confirm()"
    >
      Skip
    </button>
  </div>
</app-popup>
