import {Pipe, PipeTransform} from '@angular/core';
import {SettingService} from "@app/services";

@Pipe({name: 'videoUrl'})

export class VideoUrlPipe implements PipeTransform {
  constructor(private settingService: SettingService) {
  }

  transform(value) {
    if (value && !value.includes('undefined')) {
      let imgUrl = this.settingService.imgUrl;
      return `${imgUrl}/${value}`;
    }
    return null;
  }
}
