<aside class="info-text">
  <div class="inner">
    <div *ngIf="isDefaultFanUser" class="inner-text">
      Believe in<br />
      a Song?
      <div class="sub-text">
        Invest in it!<br />
        Be part of it!
      </div>
    </div>
    <div *ngIf="!isDefaultFanUser" class="inner-text">
      Got a Song?
      <div class="sub-text">
        We'll help finance it<br />
        Earn more & stay free!
      </div>
    </div>
  </div>
</aside>
<div class="wrapper flex-align-column">
  <div class="inner">
    <register-form (userTypeChange)="onUserTypeChange($event)"></register-form>
    <div class="register">
      Already have an account?
      <a class="link" [routerLink]="['/api/login']">Log in</a>
      <a class="button border-blue" [routerLink]="['/api/login']">Log in</a>
    </div>
  </div>
</div>
