<div class="popup-wrapper">
  <div class="inner">
    <div class="content-popup">
      <div class="mark-circle">
        <img class="check-mark" src="./assets/images/be-icons/check-mark.png">
      </div>
      <h2 class="text-success">Login Successful</h2>
      <p class="text-proceed">You can now continue with your journey</p>
      <div class="powered-by">
        <div class="text">
          Secured by
        </div>
        <img class="magic-logo" src="./assets/images/logo/magic-logo.svg">

      </div>
    </div>

  </div>
  <button class="button yellow" (click)="onClose()">Continue</button>
</div>




