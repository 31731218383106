<div class="wrapper project-description">
  <div class="m-flex">
    <div class="gray-text description text-description" [innerHTML]="myDescription"></div>
  </div>
  <section class="section-faq section--white" *appExclusiveSection="project">
    <div class="section__wrapper">
      <h3>FREQUENTLY ASKED QUESTIONS</h3>
      <mat-accordion displayMode="flat" class="home-accordion">
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>What are the funds collected on Global Rockstar used for?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            The funds will support a 12-day workshop culminating in two shows (August 30 & 31, 2024), where a
            star-studded cast will perform a preliminary version of the Musical EPOS (around 24 songs) in a concert
            format. Depending on the amount raised, we can achieve a minimum version (18k EUR direct costs), a medium
            version (30k EUR direct costs), or a maximum version (45k EUR direct costs).
          </p>

          <div>
            <div align="left">
              <table>
                <colgroup>
                  <col width="151">
                  <col width="151">
                  <col width="151">
                  <col
                    width="151">
                </colgroup>
                <tbody>
                <tr>
                  <td><span class="bold">Budget</span></td>
                  <td><span>18k EUR</span></td>
                  <td><span>30k EUR</span></td>
                  <td><span>45k EUR</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Venue &amp; Rehearsal Rooms</span></td>
                  <td><span>✅</span></td>
                  <td><span>✅</span></td>
                  <td><span>✅</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Cast</span></td>
                  <td><span>8pax</span></td>
                  <td><span>9pax</span></td>
                  <td><span>10pax</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Team &amp; allowance</span></td>
                  <td><span>5pax</span></td>
                  <td><span>8pax</span></td>
                  <td><span>12pax</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Audio pre- &amp; post-production</span></td>
                  <td><span>In-house</span></td>
                  <td><span>partially external</span></td>
                  <td><span>mainly external</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Video pre- &amp; post-production</span></td>
                  <td><span>basic</span></td>
                  <td><span>medium</span></td>
                  <td><span>advanced</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Visuals &amp; animations</span></td>
                  <td><span>-</span></td>
                  <td><span>-</span></td>
                  <td><span>✅</span></td>
                </tr>
                <tr>
                  <td><span class="bold">Catering Team &amp; Guests</span></td>
                  <td><span>-</span></td>
                  <td><span>-</span></td>
                  <td><span>✅</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>What is the goal of the Workshop?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            The workshop has two main goals:
          </p>
          <ul>
            <li>To present the Musical EPOS to key figures in the international musical industry (producers, theatres,
              directors, librettists, managers, etc.) to persuade them to produce and stage EPOS.
            </li>
            <li>
              To evaluate the current status for necessary adaptations to the book, dramaturgy, music and roles.
            </li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>Who manages the Use of Funds?</mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            The funds are managed by EPOS Musical Development FlexCo, a music publishing company founded and managed by
            Zoë Straub. The proper use of funds is overseen by Global Rockstar GmbH. All funds collected, minus the
            Global Rockstar platform fee (10%), are dedicated exclusively to the workshop, the shows on August 30 & 31,
            2024, and their documentation. EPOS Musical Development FlexCo will provide detailed spending reports.
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>What happens if the funding goal is not reached?</mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            The minimum funding goal to realize the workshop is 20k EUR (18k direct costs + 2k Global Rockstar platform
            fee). If this goal is not met, all investors will receive a full refund of their investment. Given the
            current funding status and commitments from trusted partners, we are optimistic about reaching the minimum
            funding goal soon.
          </p>

        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>What happens if the minimum funding goal of 20k EUR is surpassed?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            If the funding exceeds 20k EUR, the workshop and shows on August 30 & 31, 2024, can be executed at a higher
            level. With a larger budget, we can:
          </p>
          <ul>
            <li>Increase the number of performers (ensemble members).
            </li>
            <li>Expand the production team.
            </li>
            <li>Outsource more of the audio pre-production and post-production.
            </li>
            <li>Enhance video documentation of the workshop and shows.
            </li>
            <li>Add visuals, animations, and scene moods.
            </li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>When and how will I profit from my investment?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Investors will double their investment when a producer agrees to finance the musical's production for a
            theatre with over 300 seats. Here's how it works:
          </p>
          <p>Step 1: Two options to double your investment:
          </p>
          <ul>
            <li>Option 1: The producer pays an upfront fee to EPOS Musical Development FlexCo. Investors receive 200% of
              their investment back first.
            </li>
            <li>Option 2: If no upfront fee is paid, investors receive a pro-rata share from 100% of the revenue
              generated by the publisher's share from ticket sales until they have doubled their investment. This is
              estimated to occur after 100 shows, each selling 1000 tickets at an average net ticket price of 50 EUR.
            </li>
          </ul>
          <br>
          <p>Step 2: Generate up to 500% ROI:
          </p>
          <ul>
            <li>After doubling the investment, investors receive a pro-rata share from 15% of the revenue generated by
              the publisher's share from ticket sales. Achieving a 500% ROI is estimated after 1100 shows, each with
              1000 tickets sold at an average net ticket price of 50 EUR.
            </li>
          </ul>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>Of what is my share a percentage of?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Zoë Straub has founded the company EPOS Musical Development FlexCo (EPOS FlexCo) that acts as a music
            publisher. EPOS FlexCo has signed publishing agreements with Zoë as composer, lyricist and librettist of
            EPOS, in which is defined that EPOS FlexCo receives 1/3 of the revenues generated by the exploitation of the
            grand rights to EPOS (grand rights is a type of music licensing, specifically covering the right to perform
            musical compositions within the context of a dramatic work).
            Typically, creators receive royalties from ticket sales, usually 6-8%. In our model, assuming 6% in total
            royalties, the split would be 4% for Zoë and 2% for EPOS FlexCo. Investors receive their share from the 2%
            EPOS FlexCo earns from each net ticket sale (100% of 2% in step 1, 15% of 2% in step 2).
          </p>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before">
          <mat-expansion-panel-header>
            <mat-panel-title>Can I preview the contract before investing?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Yes, the investor contract (royalty agreement) is available in English and German on the EPOS investment
            <a
              [routerLink]="['/projects', 'epos-the-musical']">opportunity page</a>
            <br><br>
            You can also preview it here:<br>
            In English: <a href="https://d1mfwo6pcdbmd.cloudfront.net/rights/1715172578917-EPOS_Proceeds-Agreement-[ENG].pdf">
            Royalty Agreement </a>

            <br>In German: <a href="https://d1mfwo6pcdbmd.cloudfront.net/rights/1715172578917-EPOS_Proceeds-Agreement-[ENG].pdf">
            Tantiemen Vereinbarungf</a>

          </p>
        </mat-expansion-panel>
        <mat-expansion-panel togglePosition="before" class="last">
          <mat-expansion-panel-header>
            <mat-panel-title>Do you have more questions?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Click <a [routerLink]="['/how-it-works']">here</a> to
            learn more about Global Rockstar and find
            more frequently asked questions or contact us via email office@globalrockstar.com
          </p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>
</div>
<div class="wrapper profile-album-content" *ngIf="project?.isAlbum || project?.isBundle">
  <div class="m-flex profile-album">
    <div class="audio-list" id="style-scrollbar">
      <div class="wrapper-scrollbar">
        <div class="item-song" *ngFor="let item of project?.songs; let i = index">
          <app-song-player [project]="project"
                           [song]="item"
          ></app-song-player>
        </div>

      </div>
    </div>
  </div>
</div>
