import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {jsonTo} from '../../common/helpers';
import {SORT_TYPE_MOBILE} from '../../common';


@Component({
  selector: 'app-sorting-home-mobile',
  templateUrl: './sorting-home-mobile.html'
})
export class SortingHomeMobileComponent {
  @Input() sortOpen: any;
  @Input() filter: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() notifyClose: EventEmitter<any> = new EventEmitter<any>();
  SORT_TYPE_MOBILE = SORT_TYPE_MOBILE;
  sortBy: number;

  constructor() {
  }

  sortList(type, sortBy): void {
    this.sortBy = sortBy;
    this.notify.emit({type: 'sort', sortType: type, sortBy});
  }

  closeMenu(): void {
    this.notifyClose.emit();
  }
}

