export class Dictionary {
  id: string;
  name: string;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel.value;
    this.name = apiModel.text;
    return this;
  }
}
