import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  OnInit
} from '@angular/core';


@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipCustomComponent implements OnInit {
  showTooltipKey: boolean;
  @Input() img: string;
  @Input() innerHtmlText: string;
  @Input() hoverText: string;
  @HostListener('mouseenter')  onMouseEnter(): void {
    this.showTooltipKey = true;
  }

  @HostListener('mouseleave') onMouseLeave(e): void {
    this.showTooltipKey = false;
  }
  constructor() {}

  ngOnInit() {
    if (this.hoverText) {
      this.showTooltipKey = true;
    }
  }
  stopPropagation(event: Event) {
    event.stopPropagation();
  }
}
