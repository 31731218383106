<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="block-title">KYC VALIDATION</div>
    <div class="text">
      The Know-Your-Customer (KYC) verification process has not yet been
      successfully completed for your account. 
      <br>
      <br>
      Please click below and submit
      your documents to be enabled to purchase further music shares or contact
      us at office@globalrockstar.com in case you need any assistance.
    </div>
    <span class="grs-button--orange popup__button" (click)="goToProfile()">COMPLETE NOW</span>
  </div>
</app-popup>
