import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {filter} from "rxjs/operators";
import {AudioService} from "@services/audio.service";
import {Observable} from "rxjs";
import {isPlatformBrowser} from "@angular/common";


@Component({
  selector: 'app-song-player',
  templateUrl: './song-player..html'
})
export class SongPlayerComponent implements OnInit {
  state: any;
  firstTime = true;
  wouldInvestActive: boolean;
  state$: Observable<any>;
  @Input() project: any;
  @Input() song: any;
  @Input() wouldInvestActiveChange: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private audioService: AudioService) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.state$ = this.audioService.getState()
        .pipe(
          filter(resp => resp.url === this.song.soundtrack)
        )
    }
  }

  stopWhenPreview() {
    // this.project.songs.find((res) => {
    //   if (res.hasOwnProperty('audObj') && res.audObj.playing) {
    //     this.audioService.stop(res.audObj.audPlayer);
    //     res.audObj.playing = false;
    //   }
    // });
  }

  togglePlayer() {
    this.audioService.playSongAudio(this.song);
  }
}
