import {
  Component,
  Input, ChangeDetectionStrategy,
} from '@angular/core';

@Component({
  selector: 'app-project-mobile-view',
  templateUrl: './project-mobile-view.html',
  styleUrls: ['./project-mobile-view.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectMobileViewComponent {
  showDetail: boolean = true;
  showAboutArtist: boolean = true;
  showYouGet: boolean = true;
  showDescription: boolean = true;

  @Input() project;
  @Input() user;

  constructor() {
  }

  get iFrameSection(): string {
    return this.project?.sLink?.title;
  }

  get artistName(): string {
    return `About ${this.project?.artist?.name}`;
  }
}
