<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="title">Change password</div>
    <form class="form" [formGroup]="form">
      <mat-form-field class="item">
        <mat-label>Old password</mat-label>
        <input matInput [formControl]="oldPasswordCtrl" [errorStateMatcher]="errorMatcher(oldPasswordCtrl)"
               type="password">
      </mat-form-field>
      <mat-form-field class="item">
        <mat-label>New password</mat-label>
        <input matInput [formControl]="newPasswordCtrl"
               (blur)="checkPassword()"
               [errorStateMatcher]="errorMatcherPassword(newPasswordCtrl)"
               type="password">
        <mat-error *ngIf="!newPasswordCtrl?.valid && (notValidPassword || submitPressed)"> Password should have a length of minimum 8 characters
        </mat-error>
      </mat-form-field>
      <mat-form-field class="item">
        <mat-label>Confirm password</mat-label>
        <input matInput [formControl]="approvePasswordCtrl"
               [errorStateMatcher]="errorMatcher(approvePasswordCtrl, true)" type="password">
      </mat-form-field>
    </form>

    <div class="buttons-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">
        Cancel
      </button>
      <button class="button yellow text-uppercase" (click)="onSubmit()"
              [disabled]="(!form.valid && submitting) || (approvePasswordCtrl.value !== newPasswordCtrl.value && submitting)">
        Change
      </button>
    </div>
  </div>
</app-popup>
