import {Component, Output, EventEmitter, ChangeDetectionStrategy, Input} from '@angular/core';
import {UserModel} from '../../../models';

@Component({
  selector: 'app-reminder-kyc-popup',
  templateUrl: './reminder-to-kyc.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})


export class ReminderToKycComponent {
  lowAmount: boolean;
  @Input() user: UserModel;
  @Input() bidAmount: number;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  constructor() {
    if (!isNaN(this.bidAmount) && Number.isInteger(Number(this.bidAmount))) {
      if (Number(this.user.revenues.left) < this.bidAmount) {
       this.lowAmount = true;
      }
    }
  }

  onClose(): void {
    this.notify.emit({type: 'closePopup'});
  }

  onSubmit(): void {
    this.notify.emit({type: 'submit'});
  }
}
