<section class="about-company">

  <p class="gray-text">“We envision a world where everyone benefits from the success of music: artists, creators, music
    rights owners and fans.”
  </p>
  <p class="gray-text">Global Rockstar is a Fintech company & music rights marketplace which allows fans and retail
    investors to become shareholders in hit songs while offering artists and rights owners a new financing ecosystem via
    crowd-investing.

  </p>
  <p class="gray-text">Fans and retail investors can purchase the rights to participate in revenues from newly created
    and already existing music rights, and secure the purchased rights including conjoined digital cover artwork
    animations as smart contracts in the blockchain.

    .</p>
  <p class="gray-text">Disclaimer: In addition to offering a great opportunity, an investment in a song also entails
    risks. Nobody can know for sure if a song becomes a hit and you might not recoup your investment within the desired
    time frame, or in the worst case, at all. The history of music shows: While a big majority or releases do not even
    recoup their production costs, one becomes a huge hit. Therefore, we advise splitting your investments into several
    opportunities.
  </p>
  <p class="gray-text">Investors are aware, that the concrete investments is no savings product and that they bear the
    risk to lose this invested money partially, in total or that they potentially do not earn a return on their
    investment.

  </p>
  <p class="gray-text">Investors are also aware that the investment is not secured through a security instrument or an
    official deposit protection system. For further information, please read the risk notice contained in the
    “Informationsblatt für Anleger” (for Austrians only).

  </p>
</section>

