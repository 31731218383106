import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-nav-dropdown',
  templateUrl: './nav-dropdown.component.html',
  styleUrls: ['./nav-dropdown.component.scss'],
})
export class NavDropdownComponent implements OnInit, OnDestroy {
  @Input() toggleElement: HTMLElement;
  @Input() navItems: any[];
  @Input() position: 'left' | 'right' | 'center' = 'right';
  @Input() classes: string;
  isOpen: boolean = false;
  constructor(private router: Router) {}

  ngOnInit(): void {
    fromEvent(this.toggleElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe((event: MouseEvent) => {
        this.toggleElement.classList.toggle('is-active');
        this.toggle();
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.close();
      });
  }

  ngOnDestroy(): void {
    this.close();
  }

  get excludeOutsideClickSelector(): string {
    return `.${this.toggleElement.className.split(' ').join('.')}`;
  }

  navigate(path: string, event: MouseEvent): void {
    event.preventDefault();
    this.router.navigate([path]);
    this.close();
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  close(): void {
    this.isOpen = false;
    this.toggleElement.classList.remove('is-active');
  }
}
