<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <img src="./assets/images/bell.png" class="image" alt="bell">
    <div class="text">{{configPopup?.message}}</div>
    <button class="button yellow text-uppercase" (click)="onSubmit(configPopup?.redirectTo)" >
      {{configPopup?.buttonMessage}}
    </button>
  </div>
</app-popup>
