import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {Observable, identity} from 'rxjs';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {tap, map, switchMap, startWith} from 'rxjs/operators';
import {Dictionary} from '@app/models';

// Services
import {DictionaryService} from '@app/services';

@UntilDestroy()
@Component({
  selector: 'country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['country-select.component.scss'],
})
export class CountrySelectComponent implements OnInit {
  @Input() countryControl: AbstractControl;

  @Input() regionControl: AbstractControl;

  @Input() showRegion: boolean = true;

  @Input() label: string = 'Country';

  @Input() required: boolean = true;

  private countries: Dictionary[];

  public filteredCountries$: Observable<string[]>;

  public regions: any;

  public regionCountryCode: string = null;

  constructor(private dictionaryService: DictionaryService) {
  }

  ngOnInit() {
    this.dictionaryService
      .getCountries()
      .pipe(
        untilDestroyed(this),
        tap((countries: Dictionary[]) => {
          this.countries = countries;
          this.countryControl.setValidators([
            this.countryValidator(),
            this.required ? Validators.required : null,
          ]);
        }),
        this.showRegion
          ? switchMap(
            this.dictionaryService.getRegions.bind(this.dictionaryService)
          )
          : identity
      )
      .subscribe((res) => {
        this.regions = this.showRegion ? res : null;

        if (this.showRegion) {
          const selectedCountry: string = this.countryControl.value;
          if (selectedCountry === 'Canada' || selectedCountry === 'USA') {
            this.regionControl?.setValidators([Validators.required]);
          }

          this.checkCountryWithRegion(this.countryControl.value);
        }
        this.subscribeToCountryChange();
      });
  }

  subscribeToCountryChange() {
    this.filteredCountries$ = this.countryControl.valueChanges.pipe(
      untilDestroyed(this),
      this.showRegion ? tap(this.checkCountryWithRegion.bind(this)) : identity,
      map((value) => this.filterCountries(value || ''))
    );
  }

  private filterCountries(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.countries.reduce((names, option) => {
      return option.name.toLowerCase().includes(filterValue)
        ? [...names, option.name]
        : names;
    }, []);
  }

  checkCountryWithRegion(country: string): void {
    switch (country) {
      case 'Canada':
        this.regionCountryCode = 'CA';
        break;
      case 'USA':
        this.regionCountryCode = 'US';
        break;
      default:
        this.regionCountryCode = null;
        this.regionControl.reset();
        break;
    }
  }

  regionValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const selectedCountry: string = this.countryControl.value;
      if (selectedCountry === 'Canada' || selectedCountry === 'USA') {
        return !control.value ? Validators.required : null;
      } else {
        return null;
      }
    };
  }

  countryValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      } else if (
        this.countries?.some(
          (country: Dictionary) => country.name === control.value.trim()
        )
      ) {
        return null;
      } else {
        return {match: true};
      }
    };
  }
}
