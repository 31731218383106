import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppToastrService, ProfileService} from 'app/services';
import {ONLY_LETTER_AND_NUM} from 'app/common';
import {CustomFieldErrorMatcher} from '../../../common/errorMatcher';
import {catchError} from "rxjs/operators";

@Component({
  selector: 'app-voucher-popup',
  templateUrl: './voucher-popup.html'
})
export class VoucherPopupComponent implements OnInit {
  @Input() account: any;
  @Input() country: any;
  @Input() listAccounts: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  codeVoucherCtrl: FormControl;
  form: FormGroup;
  submitPressed: boolean;
  depositAccountTypes = ['CHECKING', 'SAVINGS'];

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  constructor(private toastr: AppToastrService,
              private profileService: ProfileService,
              private fb: FormBuilder) {

  }

  ngOnInit() {
    this.prepareForm();
  }

  private prepareForm() {
    this.codeVoucherCtrl = this.fb.control('', [Validators.required, Validators.pattern(ONLY_LETTER_AND_NUM)]);
    this.form = this.fb.group({
      codeVoucher: this.codeVoucherCtrl,
    });
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  onSubmit() {
    this.submitPressed = true;
    if (this.form.valid) {
      const data: any = {
        code: this.codeVoucherCtrl.value
      };
      this.profileService.applyVoucherCode(data).pipe(catchError((err: any) => {
        this.toastr.showToastFromError(err);
        throw err;
      })).subscribe((res) => {
        this.notify.emit({
          accounts: res,
          type: 'submitVoucher'
        });
        this.submitPressed = false;
        this.toastr.showToast({title: 'Voucher applied'});
      }, (err) => {
        const errorType = err.error.type;
        this.notify.emit({
          error: errorType,
          type: 'error'
        });
        this.submitPressed = false;
        if (errorType !== 'UserNotFilledProfile') {
          this.toastr.showToastFromError(err.error);
        }
      });
    }
  }

  errorMatcher(control: FormControl) {
    return new CustomFieldErrorMatcher(control, this.submitPressed);
  }
}
