<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="title">Update Bid</div>
    <div class="content">
      <mat-form-field class="item">
        <mat-label>Bid</mat-label>
        <input matInput [(ngModel)]="bidAmount"
               type="text">
      </mat-form-field>
      <div class="buttons-wrapper">
        <button class="button border-blue text-uppercase" (click)="onClose()">
          Cancel
        </button>
        <button class="button yellow text-uppercase" (click)="onSubmit()">
          Change
        </button>
      </div>
    </div>
  </div>
</app-popup>
