import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatRadioModule} from "@angular/material/radio";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {KycUploadDocumentComponent} from "@app/components/features/kyc/kyc-upload-document/kyc-upload-document.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CropperModule} from "@app/components/features/image-cropper/cropper.module";

@NgModule({
  exports: [
    KycUploadDocumentComponent,
  ],
  declarations: [
    KycUploadDocumentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    CropperModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
  ],
})
export class KycModule {
}
