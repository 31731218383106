import {Component, Output, EventEmitter, HostListener, OnInit, Input} from '@angular/core';

interface RepeatedEmail {
  type: string;
  slug: string;
}

@Component({
  selector: 'app-reinvest-popup',
  templateUrl: './reinvest-popup.html'
})


export class ReinvestPopupComponent implements OnInit {
  user: any;
  showInvestorWarning: boolean;
  @Input() dataInput: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  constructor() {
  }

  ngOnInit() {
  }

  onProceed() {
    this.notify.emit({type: 'proceed', data: this.showInvestorWarning});
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }
}
