import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

// Services
import {JwtHelperService} from '@auth0/angular-jwt';
import {StorageService} from '@services/storage.service';
import {SettingService} from '@services/setting.service';
import { ProfileService } from './profile.service';

// Consts
import {STATE_NAMES} from '@app/common';

// Types
import { VerifyEmailResponse, RepeatedEmail } from '@app/types';
import { IdxAuthData, IdxToGrsUserPayload } from '@app/interfaces/social-user.interface';
interface IOAuth{
  code: string;
}

@Injectable()
export class AuthenticationService {
  apiUrl: string;

  constructor(private http: HttpClient,
              @Inject(PLATFORM_ID) private platformId: any,
              private storage: StorageService,
              private router: Router,
              private settings: SettingService,
              private profileService: ProfileService,
  ) {
    this.apiUrl = this.settings.apiUrl;
    this.checkForTiming();
  }

  getToken(auth: any) {
    return this.http.post(`${this.apiUrl}/authtoken`, auth).pipe(map((data: any) => {
      if (!data.requiredCodeTFA) {
        data.user.artist = data.user.__t.toLowerCase() === 'artist';
        delete data.user.__t;
        this.processLoginData(data);
      }
      return data;
    }));
  }

  updateOptionalSettings(body): Observable<any> {
    return this.http.put(`${this.apiUrl}/user/optional`, body).pipe(map((data: any) => {
      this.updateStorage(Object.keys(body), body);
      return data;
    }));
  }

  signUp(dataRequest: any): any {
    return this.http.post(`${this.apiUrl}/register`, dataRequest).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  getGoogleOauthURL() {
    return this.http.get(`${this.apiUrl}/authtoken/google/url`).pipe(map((data: any) => {
      return data;
    }));
  }

  getTokenWithGoogle(auth: IOAuth) {
    return this.http.post(`${this.apiUrl}/authtoken/google`, auth).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      data.user.verify = true;
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  getTokenWithApple(auth: IOAuth) {
    return this.http.post(`${this.apiUrl}/authtoken/apple`, auth).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      data.user.verify = true;
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  getTokenWithFB(auth: any) {
    return this.http.post(`${this.apiUrl}/authtoken/facebook`, auth).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      data.user.verify = true;
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  sentVerification(param: RepeatedEmail): Observable<VerifyEmailResponse> {
    return this.http.post(`${this.apiUrl}/resend-verification-email`, param).pipe(map((data: VerifyEmailResponse) => {
      return data;
    }));
  }

  private updateStorage(keyArr, data): void {
    const KEY = Array.isArray(keyArr) ? keyArr[0] : '';
    const user = {...this.storage.user};
    user[KEY] = data[KEY];
    this.storage.user = user;
  }

  private processLoginData(data: any) {
    this.storage.accessToken = data.token.access;
    this.storage.refreshToken = data.token.refresh;
    if (data.user) {
      const image = data.user.image && data.user.image.indexOf('/images/') === 0 ?
        `${ this.settings.imgUrl}${data.user.image}` : `${ this.settings.imgUrl}/images/${data.user.image}`;
      data.user.image = image.indexOf('undefined') < 0 ? image : null;
      this.storage.user = data.user;
      this.profileService.emitProfileUpdate(data.user);
    }
  }

  private processLogoutData(redirect?: boolean): void {
    this.storage.accessToken = null;
    this.storage.refreshToken = null;
    this.storage.user = null;
    this.storage.image = null;
    this.profileService.emitProfileUpdate(null);
    if (redirect) {
      this.router.navigate([`/${STATE_NAMES.login}`]);
    }
  }

  logout(redirect?: boolean) {
    if (isPlatformBrowser(this.platformId)) {
      this.processLogoutData(redirect);
      localStorage.clear();
    }

  }

  forgotPassword(dataRequest: any) {
    return this.http.put(`${this.apiUrl}/forgot-password`, dataRequest);
  }

  changePassword(data: any) {
    return this.http.put(`${this.apiUrl}/change-password`, data).pipe(map((resp: any) => {
      this.processLoginData(resp);
      return resp;
    }));
  }

  resetPassword(data: any) {
    return this.http.put(`${this.apiUrl}/reset-password`, data).pipe(map((resp: any) => {
      this.processLoginData(resp);
      return resp;
    }));
  }

  checkForTiming() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.storage.accessToken) {
        const data = new JwtHelperService().decodeToken(this.storage.accessToken);
        if (!data.exp || data.exp * 1000 <= new Date().getTime()) {
          this.logout();
        }
      }
    }
  }

  verify(token) {
    return this.http.post(`${this.apiUrl}/verify`, {token}).pipe(map((data: any) => {
      data.user.artist = data.user.__t.toLowerCase() === 'artist';
      delete data.user.__t;
      this.processLoginData(data);
      return data;
    }));
  }

  subscribeVisitor(data) {
    return this.http.post(`${this.apiUrl}/register-visitor`, data);
  }

  idxLogin(credentials: IdxToGrsUserPayload): Observable<any> {
    return this.http.post(`${this.apiUrl}/user/verify`, credentials).pipe(
      tap((data: any) => {
        this.processLoginData(data);
      })
    );
  }
}
