<div class="tfa">
<div class="title text-uppercase" (click)="openSection('tfa')">
            <span class="lnr lnr-chevron-right"
                  [class.opened]="openSettings"></span>
  <span class="title-m">Two-Factor authentication</span>
</div>
<div class="wrapper section-toggle-mob" [class.openSetting]="openSettings">

  <div class="toggle-item">
    <div class="status">{{tfa?.enabled ? 'ON' : 'OFF'}}</div>
    <mat-slide-toggle (change)="setupTFA($event)"
                      [disabled]="tfa?.verified"
                      [checked]="!tfa?.enabled"></mat-slide-toggle>
  </div>
  <div class="tfa__info" *ngIf="tfa?.enabled">
    <i class="far fa-question-circle"></i>
    <span class="color-light-grey">We recommend using Google Authenticator on your mobile to scan the QR code below.
          When using different authenticators, you might need to change the name to Global Rockstar in the authenticator app.</span>
  </div>
  <div class="tfa__process" *ngIf="tfa?.enabled">

    <img [src]="tfa?.dataURL" alt="" class="img-thumbnail" style="display:block;margin:auto">
    <p class="tfa__key">Secret Key - <b>{{tfa?.tempSecret}}</b></p>
    <form class="form-signin">
      <mat-form-field class="item tfa-input">
        <mat-label>Enter code</mat-label>
        <input matInput
               [formControl]="authCodeCtrl"
               name="authcode">
        <mat-error *ngIf="apiErrorMessage">{{apiErrorMessage}}</mat-error>
        <mat-error *ngIf="authCodeCtrl.errors?.pattern">Code may contain only numbers</mat-error>
        <mat-error *ngIf="authCodeCtrl.errors?.required">Code is required</mat-error>
        <mat-error *ngIf="authCodeCtrl.errors?.minlength || authCodeCtrl.errors?.maxlength">Code may be 6 numbers only</mat-error>
      </mat-form-field>
      <button type="Submit"
              (click)="verifyTFA()"
              class="button yellow text-uppercase button-standart height40"
              *ngIf="!tfa?.verified"
              [disabled]="authCodeCtrl.invalid"
      >Enable 2FA
      </button>
      <button type="Submit"
              (click)="verifyTFA()"
              [disabled]="!authCodeCtrl.valid"
              class="button border-blue text-uppercase button-standart height40"
              *ngIf="tfa?.verified"
      >Disable 2FA
      </button>
    </form>
  </div>
</div>
</div>
