import * as moment from 'moment';
import {DEFAULT_SONG_IMG} from '../common/constants_IMG';


interface IReadMoreContent {
  imgReadMore?: string;
  text?: string;
  title?: boolean;
}
interface ISeo {
  title?: string;
  description?: string;
  keywords?: string;
}

export interface IOfferTeaser {
  headline: string;
  text: string;
  icon: string;
}

export class CollectibleModel {
  constructor(props) {
    this.imgUrl = props;
  }

  id: string;
  imgUrl: string;
  slug: string;
  soundtrack: string;
  song: string;
  backgroundImage: string;
  imgNFT: string;
  image: string;
  isCollectible: boolean;
  edition: number;
  editionNumber?: number;
  startingPrice: number;
  cardColor: string;
  goodiesIcon: boolean;
  limitedIcon: boolean;
  offeredBy: string;
  auctionId: string;
  teaser: string;
  statusDetail: string;
  readMoreContent: IReadMoreContent;
  position: number;
  endDate: string;
  daysBefore: number;
  cardDescription: string;
  collectionRights: string;
  video: string;
  projectStartDate: string;
  daysLeft: number;
  biography: string;
  myOffersDetails: string[];
  myProjectDescription: string;
  startDate: string;
  reminderExist: boolean;
  finished: boolean;
  collectibleImg: string;
  offerTeaser: IOfferTeaser[];
  projectNotStarted: boolean;
  collectionName: string;
  genre: string;
  projectEndDate: string;
  enableLogo?: boolean;
  auctionMarket: string;
  isSecondaryAuction: boolean;
  publish_year: string;
  seo: ISeo;
  artist: {
    name: string;
    image: string;
  };

  setFromApiModel(apiModel: any): this {
    Object.assign(this, apiModel);
    if (apiModel.auctionMarket) {
      this.auctionMarket = apiModel.auctionMarket;
    }
    this.isCollectible = true;
    this.publish_year = moment(apiModel.startDate).format('YYYY');
    let image;
    if (apiModel.artistExistId && apiModel.artistExistId.image) {
      image = apiModel.artistExistId.image.indexOf('project-component-images') >= 0 ? `${this.imgUrl}/${apiModel.artistExistId.image}` :
        apiModel.artistExistId.image.indexOf('images') >= 0 ? `${this.imgUrl}/${apiModel.artistExistId.image}` : `${this.imgUrl}/images/${apiModel.artistExistId.image}`;
    } else {
      image = DEFAULT_SONG_IMG;
    }
    this.artist = {
      name: apiModel.artist,
      image
    };

    const offerTeaser = apiModel.offerTeaser ? JSON.parse(JSON.stringify(apiModel.offerTeaser)) : null;
    if (offerTeaser) {
      offerTeaser.forEach(item => {
        item.icon = item.icon ? `${this.imgUrl}/${item.icon}` : '';
      });
      this.offerTeaser = offerTeaser;
    }

    this.backgroundImage = apiModel.backgroundImage ? `${this.imgUrl}/${apiModel.backgroundImage}` : DEFAULT_SONG_IMG;
    this.imgNFT = apiModel.nft && apiModel.nft.imageURL ? `${this.imgUrl}/${apiModel.nft.imageURL}` : `${this.imgUrl}/${apiModel.imgNFT}`;
    this.collectibleImg = apiModel.collectibleImg ? `${this.imgUrl}/${apiModel.collectibleImg}` : DEFAULT_SONG_IMG;
    this.video = apiModel.video ? `${this.imgUrl}/${apiModel.video}` : DEFAULT_SONG_IMG;
    this.reminderExist = !!apiModel.reminder_sent;
    this.collectionRights = apiModel.collectionRights;
    this.projectEndDate = apiModel.endDate;
    this.myOffersDetails = apiModel.myOffersDetails ? apiModel.myOffersDetails.split('\n') : [];
    const readMoreContent = apiModel.readMoreContent ? JSON.parse(JSON.stringify(apiModel.readMoreContent)) : null;
    if (readMoreContent && readMoreContent.imgReadMore) {
      readMoreContent.imgReadMore = `${this.imgUrl}/${readMoreContent.imgReadMore}`;
      this.readMoreContent = readMoreContent;
    }

    if (Array.isArray(apiModel.genre)) {
      this.genre = '';
      apiModel.genre.forEach((g, i) => {
        this.genre += g.charAt(0).toUpperCase() + g.slice(1);
        if (i < (apiModel.genre.length - 1)) {
          this.genre += ', ';
        }
      });
    } else {
      this.genre = apiModel.genre;
    }
    this.projectStartDate = moment(apiModel.startDate).format('DD MMM YYYY, HH:mm');
    this.daysLeft = this.projectNotStarted ? apiModel.daysBefore : apiModel.daysLeft;
    this.soundtrack = apiModel.audio ? apiModel.audio : null;
    return this;
  }
}
