export const CARD_CONTENT: any = {
  grs: {
    logo: './assets/images/logo/grs-small-logo.png',
    icon: './assets/images/cards/logo-white.svg',
    t_text: 'Global Rockstar Artists are directly signed to the Global Rockstar\n' +
      '              Music Label and/or Publishing and supported in longterm contracts\n' +
      '              by the company.',
  },
  hit: {
    logo: './assets/images/logo/grs-small-logo.png',
    icon: './assets/images/cards/logo-white.svg',
    t_text: 'Global Rockstar Artists are directly signed to the Global Rockstar\n' +
      '              Music Label and/or Publishing and supported in longterm contracts\n' +
      '              by the company.',
  },
  exclusive: {
    logo: './assets/images/logo/exclusive.png',
    icon: './assets/images/cards/crown.svg',
    t_text: 'The exclusive category consists of established artists & rights holders who sell participation' +
      ' rights in the commercial exploitation of different kinds of intellectual property rights of selected exclusive projects.',
    link: true
  },

  collectible: {
    logo: './assets/images/logo/grs-small-logo.png',
    icon: './assets/images/cards/diamond.svg',
    t_text: 'Collectibles are unreleased and never to be released recordings by\n' +
      '              superstars or established artists. Owners have the exclusive right\n' +
      '              to play the recording in full-length from their Global Rockstar\n' +
      '              dashboard, whenever they want and will be able to trade the\n' +
      '              collectibles on the Global Rockstar marketplace or on other\n' +
      '              platforms in the near future.',
  },
  diy: {
    logo: './assets/images/logo/independent.png',
    icon: './assets/images/cards/fire.svg',
    t_text: 'Independent Artists use the Global Rockstar platform to non-exclusively fund, distribute & ' +
      'promote their music with the support of the Global Rockstar Label &/or Publishing.',
    link: true
  }
}
export const CARD_TOOLTIP_CONTENT: any = {
  master: `Master Rights: You will participate in the commercial
          exploitation of the Master Rights for 70 years. Every time
          the track is streamed, downloaded, licenced to TV, Film or
          Ads or compiled on a physical product (CD, Vinyl) you will
          earn proceeds.`,
  royalty: ` Publishing Rights: You will participate in the commercial
                      exploitation of the Publishing Rights. You will earn money
                      every time the work (lyrics & composition) is played in
                      radio, TV, live or licenced to film or advertisements.`,
  musical: `”Grand Rights”: You will participate in the commercial exploitation of the Grand Rights.
              You will earn money from the pro-rata box-office remuneration of the Publisher’s Net Proceeds from exploiting
              the stage musical EPOS in musical theatres worldwide.`
}

