<ng-container *ngIf="project">
  <div class="card__header">
    <p class="card__header-col year">
      <span *ngIf="!project.saleAmount">{{project.publish_year}}</span>
      <span class="sale-off" *ngIf="project.saleAmount"
      >{{project.saleAmount}}% <br/>
              OFF</span
      >
    </p>
    <p class="card__header-col card__title">
      <img
        class="card__logo-title"
        [class.exclusive]="project.artistCategory === 'exclusive'"
        loading="lazy"
        *ngIf="project.enableLogo"
        alt="line"
        [src]="CARD_CONTENT[project.artistCategory || 'grs'].logo"
        src="./assets/images/logo/small-logo.png"
      />
    </p>

    <div class="card__header-col card__logo"
         [class.exclusive]="project.artistCategory === 'exclusive'"
    >

      <app-tooltip
        class="tooltip-component"
        [img]="CARD_CONTENT[project.artistCategory || 'grs'].icon"
        *ngIf="!project.isCollectible && !project.legacyHit?.isLegacyHit"
      >
        <div img-content>
          <img loading="lazy"
               *ngIf="CARD_CONTENT[project.artistCategory || 'grs'].icon"
               alt="logo tooltip" [src]="CARD_CONTENT[project.artistCategory || 'grs'].icon">
        </div>
        <div text-content>
          <span>{{CARD_CONTENT[project.artistCategory || 'grs'].t_text}}</span>
          <br><br>
          <a *ngIf="project.artistCategory && CARD_CONTENT[project.artistCategory].link"
             [routerLink]="['/how-it-works']">Learn more</a>
        </div>
      </app-tooltip>
      <app-tooltip
        class="tooltip-component"
        *ngIf="project.isCollectible && !project.legacyHit?.isLegacyHit"
        [img]="CARD_CONTENT['collectible'].icon"
      >
        <div img-content>
          <img loading="lazy"
               *ngIf="CARD_CONTENT['collectible'].icon"
               alt="logo tooltip" [src]="CARD_CONTENT['collectible'].icon">
        </div>
        <div text-content>
          <span>{{CARD_CONTENT['collectible'].t_text}}</span>
        </div>
      </app-tooltip>
    </div>
  </div>
</ng-container>
