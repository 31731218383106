<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClosePopup()"></span>
    <img src="./assets/images/delete-user.png" class="image">
    <p class="text text-uppercase"> Sorry to see you leave the Global Rockstar community!</p>
    <p class="sub-text"> Are you sure to delete your account permanently? <br> This action can not be reversed.</p>
    <div class="button-wrapper">
      <span class="cancel text-uppercase" (click)="onConfirm()">
      Close
      </span>
      <button class="button yellow text-uppercase" type="button" (click)="onClosePopup()">
        Leave
      </button>
    </div>
  </div>
</app-popup>
