<app-verify-conditions-popup
  (notify)="onVerifyConditions()"
  *ngIf="showTerms"
></app-verify-conditions-popup>
<app-tfa-popup *ngIf="isTFAShow" (notify)="onTFA()"></app-tfa-popup>
<section class="home-page">
  <main class="main-wrapper home">
    <div class="home-page__slider">
      <app-main-slider
        *ngIf="sliders?.list?.length"
        [sliders]="sliders"
      ></app-main-slider>
    </div>
    <div class="home-page__icons">
      <div class="usp-icon">
        <div class="home-icon--43 white">
          <img
            class="home-icon__image"
            src="assets/images/project/hand.png"
            alt="Hand with a coin"
          />
        </div>
        <span class="usp-icon__text">Invest in Music</span>
      </div>
      <div class="usp-icon">
        <div class="home-icon--43 white">
          <img
            class="home-icon__image"
            src="assets/images/project/coins.png"
            alt="Royalties icon"
          />
        </div>
        <span class="usp-icon__text">Earn Royalties</span>
      </div>
      <div class="usp-icon">
        <div class="home-icon--43 white">
          <img
            class="home-icon__image"
            src="assets/images/project/calendar-home.png"
            alt="Calendar icon"
          />
        </div>
        <span class="usp-icon__text">Benefit for 70 Years</span>
      </div>
    </div>
    <div class="main_content">
      <div class="cards">

        <app-music-item-list
          *ngIf="projectList?.length"
          [projectList]="projectList"
          [loaded]="loaded"
        ></app-music-item-list>
      </div>
      <div class="spinner-wrapper" *ngIf="!loaded || isLoadedAdditional">
        <mat-spinner [diameter]="40"></mat-spinner>
      </div>
      <div
        class="wrapper load-more"
        *ngIf="(total > filter?.limit) && !isLoadedAdditional"
      >
        <button class="button border-blue text-uppercase" (click)="loadMore()">
          Load More
        </button>
      </div>
    </div>
  </main>
  <footer>
    <app-footer></app-footer>
  </footer>
</section>
<app-scroll-top></app-scroll-top>
<div class="main-wrapper-downtime" *ngIf="downtime">
  <app-downtime></app-downtime>
</div>
<app-notification-info-popup
  *ngIf="user && paymentInfo"
  (notify)="paymentInfo = !paymentInfo"
></app-notification-info-popup>
