<div class="range" *ngIf="range">
  <span class="range__text color-light-grey">Item per page</span>
  <mat-form-field class="month">
    <mat-select [(ngModel)]="paging.limit" (selectionChange)="selectRange($event)">
      <mat-option *ngFor="let item of rangePages" [value]="item">{{item}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<ng-template [ngIf]="total > paging?.limit">
  <span class="prev item" [class.disabled]="current === 1" (click)="setCurrent(current - 1, current === 1)">PREV</span>
  <span class="item" *ngIf="current > 2" (click)="setCurrent(1)">1</span>
  <span class="splitter" *ngIf="current > 3">...</span>

  <span class="item" *ngIf="current > 1" (click)="setCurrent(current - 1)">{{current - 1}}</span>
  <span class="item active">{{current}}</span>
  <span class="item" *ngIf="current < all" (click)="setCurrent(current + 1)">{{current + 1}}</span>

  <span class="splitter" *ngIf="current < (all-2)">...</span>
  <span class="item" *ngIf="current < (all-1)" (click)="setCurrent(all)">{{all}}</span>

  <span class="next item" [class.disabled]="current === all"
        (click)="setCurrent(current + 1, current === all)">NEXT</span>
</ng-template>

