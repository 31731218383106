import {Component} from '@angular/core';

@Component({
  selector: 'app-downtime',
  templateUrl: './downtime.html',
  styleUrls: ['./_downtime.scss']
})
export class DowntimeComponent{
  lock: any;


  constructor(

  ) {
  }

}
