import {Component, Output, EventEmitter, HostListener, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-submit-song-alert-popup ',
  templateUrl: './submit-song-alert.html'
})


export class SubmitSongAlertComponent implements OnInit {
  @Input() configPopup: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  ngOnInit() {
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  onSubmit(goTo: string) {
    this.notify.emit({type: 'submit', goTo});
  }
}
