import * as moment from 'moment';
import {EUCOUNTRY} from '../common/country';
import {LEGAL_TYPEs, SEP_COUNTRIES} from '../common';

export interface INft {
  walletId: string;
  alwaysUseNft: boolean;
  secureAllShares: boolean;
}

export interface DataStorage {
  _id: string;
  artist: boolean;
  projectCreationAllowed: boolean;
  verified: boolean;
  language: string;
  contact: any;
  infoPayment: boolean;
  isPrivate: boolean;
  showInfoTFA: boolean;
  termsAndConditions: boolean;
  slug: string;
  image?: string;
}

export interface IKYCcompanyError {
  code: string;
  text: string;
}

export interface UBOuser {
  lastName: string;
  firstName: string;
  country: string;
  postalCode: string;
  nationality: string;
  billingAddress: string;
  birthCity: string;
  city: string;
  birthdate: string;
  birthCountry: string;
}

export class UserModel {
  constructor(props?: string) {
    this.imgUrl = props;
  }
  artist: boolean;
  imgUrl: any;
  id: string;
  advances: any[];
  bankAccounts: any[];
  contact: {
    birthdate: string,
    telephone: string;
    birthdateOLD: string;
    city: string,
    email: string,
    firstName: string,
    gender: string,
    lastName: string,
    region: string,
    facebookId: number
  };
  tfa: any;
  try: string;
  legalType: LEGAL_TYPEs;
  genresPlay: any[];
  genresLike: any[];
  globalRevenueView: boolean;
  isPrivate: boolean;
  name: string;
  projectCreationAllowed: string;
  releases: any[];
  revenues: {
    history: {
      _id: string,
      amount: number,
      release: string
    }[],
    left: any,
    payouts: any[],
    total: any
  };
  settings: {
    newsletter: boolean,
    updates: boolean,
    royalty: boolean
  };
  type: string;
  typeID: number;
  social: any;
  slug: string;
  birthCity: string;
  europeanCountry: boolean;
  birthCountry: string;
  mangoVerificationStatus: {
    text: string,
    id: number
  };
  isProducer: any;
  headquarters: {
    address: string,
    city: string,
    code: string,
    country: string
  };
  balance: {
    open: boolean;
    grossBalance: number;
  };
  state: string;
  ubos: [UBOuser];
  country: string;
  statusUBO: any;
  vatNumber: string;
  companyName: string;
  companyNumber: string;
  billingAddress: string;
  language: string;
  nickname: string;
  nft: INft;
  optionalSettings: {
    typeRefund: number;
  };
  kycFailedReasonCompany: IKYCcompanyError[];
  kycFailedReason: string;
  recoupable: number;
  postalCode: string;
  refundType: number;
  representativeEmail: string;
  voucherAmount: number;
  mangoUserCreated: boolean;
  image: string;
  isPendingStatus: boolean;
  reinvestTotal: number;

  setFromApiModel(apiModel: any): this {
    const typeId = {
      fan: {
        id: 1
      },
      artist: {
        id: 2
      }
    };
    this.artist = apiModel.__t.toLowerCase() === 'artist';
    this.isPendingStatus = !!(apiModel.revenues && apiModel.revenues.inPending && apiModel.revenues.inPending.amount);
    this.type = apiModel.__t;
    this.typeID = this.type ? typeId[(apiModel.__t).toLowerCase()].id : 0;
    this.id = apiModel._id;
    this.tfa = apiModel.tfa;
    this.recoupable = apiModel.recoupable || 0;
    this.balance = apiModel.balance;
    this.nft = apiModel.nft;
    this.kycFailedReason = apiModel.kycFailedReason;
    this.language = apiModel.language;
    this.legalType = apiModel.legalType;
    this.advances = apiModel.advances;
    this.statusUBO = {};
    this.nickname = apiModel.nickname;
    if (apiModel.uboDeclaration && apiModel.uboDeclaration.status) {
      if (apiModel.uboDeclaration.status.toLowerCase() === 'pending') {
        this.statusUBO = {
          id: 1,
          text: 'UBO is in pending status',

        };
      } else if (apiModel.uboDeclaration.status.toLowerCase() === 'validated') {
        this.statusUBO = {
          id: 2,
          text: 'Validated'
        };
      } else if (apiModel.uboDeclaration.status.toLowerCase() === 'refused') {
        this.statusUBO = {
          id: 3,
          text: 'UBO was refused',
          reason: apiModel.uboDeclaration.reason
        };
      } else if (apiModel.uboDeclaration.status.toLowerCase() === 'incomplete') {
        this.statusUBO = {
          id: 4,
          text: 'UBO is incomplete',
          reason: apiModel.uboDeclaration.reason
        };
      }
    }
    this.ubos = apiModel.ubos;
    this.representativeEmail = apiModel.representativeEmail;
    this.voucherAmount = apiModel.voucherAmount;
    this.optionalSettings = apiModel.optionalSettings;
    this.bankAccounts = apiModel.bankAccounts;
    this.contact = apiModel.contact;
    this.reinvestTotal = apiModel.reinvestAmount > 0 ? Number(apiModel.reinvestAmount) : 0;
    // this.country = apiModel.country;
    if (this.contact && this.contact.birthdate) {
      this.contact.birthdateOLD = this.contact.birthdate;
      this.contact.birthdate = moment(this.contact.birthdate, 'YYYY/MM/DD').format('YYYY/MM/DD');
    }
    this.country = apiModel.country ? apiModel.country : null;
    this.isProducer = apiModel.isProducer;

    if (apiModel.headquarters) {
      this.headquarters = {
        address: apiModel.headquarters.address,
        city: apiModel.headquarters.city,
        code: apiModel.headquarters.code,
        country: apiModel.headquarters.country,
      };
    }
    this.companyNumber = apiModel.companyNumber;
    this.mangoUserCreated = apiModel.mangoUserCreated;
    if (apiModel.mangoVerificationStatus) {
      if (apiModel.mangoVerificationStatus === 'succeeded') {
        this.mangoVerificationStatus = {
          id: 1,
          text: 'Succeeded'
        };
      } else if (apiModel.mangoVerificationStatus === 'processing') {
        this.mangoVerificationStatus = {
          id: 2,
          text: 'Processing'
        };
      } else {
        this.mangoVerificationStatus = {
          id: 3,
          text: 'Failed'
        };
      }
    } else {
      this.mangoVerificationStatus = {
        id: 0,
        text: 'Not uploaded document'
      };
    }
    if (apiModel.social) {
      this.social = {
        facebook: apiModel.social.facebook,
        instagram: apiModel.social.instagram,
        soundcloud: apiModel.social.soundcloud,
        twitter: apiModel.social.twitter,
        dropbox: apiModel.social.dropbox,
        spotify: apiModel.social.spotify,
        tiktok: apiModel.social.tiktok,
        youtube: apiModel.social.youtube,
      };
    }
    if (apiModel.settings) {
      this.settings = {
        newsletter: apiModel.settings.notifications,
        updates: apiModel.settings.investmentNotifications,
        royalty: apiModel.settings.royaltyNotifications
      };
    }
    if (!apiModel.isPrivate) {
      this.kycFailedReasonCompany = apiModel.kycFailedReasonCompany;
    }
    this.refundType = apiModel.refundType;
    this.genresPlay = apiModel.genresPlay;
    this.genresLike = apiModel.genresLike;
    this.globalRevenueView = apiModel.globalRevenueView;
    this.isPrivate = apiModel.isPrivate;
    this.name = apiModel.name;
    this.birthCity = apiModel.birthCity;
    this.birthCountry = apiModel.birthCountry;
    this.projectCreationAllowed = apiModel.projectCreationAllowed;
    this.releases = apiModel.releases;
    this.revenues = apiModel.revenues;
    this.europeanCountry = EUCOUNTRY.indexOf(apiModel.country) >= 0;
    this.social = apiModel.social;
    this.slug = apiModel.slug;
    this.state = apiModel.state;
    this.companyName = apiModel.companyName;
    this.vatNumber = apiModel.vatNumber;
    this.billingAddress = apiModel.billingAddress;
    this.postalCode = apiModel.postalCode;

    if (apiModel.revenues) {
      this.revenues.left = apiModel.revenues.left ? Number(apiModel.revenues.left.toFixed(2)) : 0;
      this.revenues.total = apiModel.revenues.total ? Number(apiModel.revenues.total.toFixed(2)) : 0;
    }
    if (apiModel.image) {
      this.image = `${this.imgUrl}/images/${apiModel.image}`;
    }
    return this;
  }

  getSettingSwitcher(apiModel: any): any {
    const data = {
      notifications: apiModel.newsletter,
      royaltyNotifications: apiModel.royalty,
      investmentNotifications: apiModel.updates,
      releasedNotifications: false,
      listedOnPlaylistNotifications: false,
      opportunitiesNotifications: false
    };
    return data;
  }

  setStorageUser(apiModel: any): any {
    const data: DataStorage = {
      _id: apiModel._id,
      artist: apiModel.__t.toLowerCase() === 'artist',
      projectCreationAllowed: apiModel.projectCreationAllowed,
      verified: apiModel.state === 'verified',
      language: apiModel.language,
      isPrivate: apiModel.isPrivate,
      infoPayment: apiModel.infoPayment,
      showInfoTFA: apiModel.showInfoTFA,
      termsAndConditions: apiModel.termsAndConditions,
      contact: {
        firstName: apiModel.contact.firstName,
        lastName: apiModel.contact.lastName
      },
      slug: apiModel.slug
    };
    if (apiModel.image) {
      data.image = `${this.imgUrl}/images/${apiModel.image}`;
    }
    return data;
  }

  getApiModel(data: any, facebookId: number): any {
    const model: any = {
      contact: {
        region: data.region,
        telephone: data.telephone,
        birthdate: data.birthdate,
        email: data.email,
        city: data.city,
        lastName: data.lastName,
        firstName: data.firstName,
        gender: data.gender
      },
      country: data.country,
      companyNumber: data.companyNumber,
      image: data.image,
      name: data.name,
      nickname: data.nickname ? data.nickname.toLowerCase() : '',
      vatNumber: data.vatNumber,
      genresLike: data.genresLike,
      genresPlay: data.genresPlay,
      language: data.language,
      refundType: data.refundType,
      isPrivate: data.isPrivate,
      postalCode: data.postalCode,
      birthCity: data.birthCity,
      birthCountry: data.birthCountry,
      billingAddress: data.billingAddress,
      companyName: data.companyName,
      representativeEmail: data.representativeEmail,
      isUpdateUBO: data.isUpdateUBO,
      social: {
        facebook: data.facebook,
        twitter: data.twitter,
        youtube: data.youtube,
        spotify: data.spotify,
        tiktok: data.tiktok,
        instagram: data.instagram
      },
      legalType: data.legalType,
      headquarters: {
        country: data.headquartersCountry,
        code: data.headquartersCode,
        city: data.headquartersCity,
        address: data.headquartersAddress,
      },
      ubos: data.ubos
    };
    if (facebookId) {
      model.contact.facebookId = facebookId;
    }
    return model;
  }
}

