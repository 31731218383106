import {
  Component,
  Input, Inject, PLATFORM_ID, AfterViewInit, Renderer2, ElementRef
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-instagram-iframe',
  templateUrl: './instagram-iframe.html',
})
export class InstagramIframeComponent implements AfterViewInit {
  @Input() instagramId;
  constructor(private sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: any, private elementRef: ElementRef, private renderer: Renderer2) {
  }

  ngAfterViewInit(): void {

    if (isPlatformBrowser(this.platformId)) {
      const iframeUrl = this.instagramStringUrl(this.instagramId, this.instagramId);
      const blockquoteElement = this.elementRef.nativeElement.querySelector('.instagram-media');
      this.renderer.setAttribute(blockquoteElement, 'data-instgrm-permalink', iframeUrl);
      if (window['instgrm'] && window['instgrm']['Embeds'] && typeof window['instgrm']['Embeds']['process'] === 'function') {
        window['instgrm']['Embeds']['process']();
      }
    }

  }

  instagramStringUrl(linkType: string, id: string){
    return `https://www.instagram.com/${id}/?utm_source=ig_embed&amp;utm_campaign=loading`;
  }

  generateIframeUrl(linkType: string, id: string): SafeResourceUrl {
    let url = `https://www.instagram.com/${id}/?utm_source=ig_embed&amp;utm_campaign=loading`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
