<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="title">Withdraw funds</div>
    <form class="form" [formGroup]="form">
      <mat-form-field class="item">
        <mat-label>Withdraw</mat-label>
        <input matInput [formControl]="withdrawAmountCtrl" [errorStateMatcher]="errorMatcher(withdrawAmountCtrl)"
               type="text">
      </mat-form-field>
      <span class="current-balance">Current balance: {{currentBlance | localeCurrency}}</span>

      <mat-form-field>
        <mat-label>To*</mat-label>
        <mat-select [formControl]="creditCardCtrl" [errorStateMatcher]="errorMatcher(creditCardCtrl)">
          <mat-option *ngFor="let item of accounts" [value]="item.bankId">{{item.placeholder}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="buttons-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">
        View re-invest options
      </button>
      <button class="button yellow text-uppercase" (click)="onSubmit()"
              [disabled]="!form.valid && submitting">
        Withdraw
      </button>
    </div>
  </div>
</app-popup>
