import {Component, Input} from '@angular/core';
import {StorageService} from '../../../services/storage.service';


@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.html'
})
export class PageHeaderComponent {
  user: any = {};
  @Input() title: string;

  constructor(private storage: StorageService) {
    this.user = this.storage.user;
  }
}
