<app-popup>
  <div class="inner">
    <app-popup-part-header
      [icon]="'./assets/images/be-icons/reminder.PNG'"
      [hideClose]="true"
      (notify)="onClose()">
      <h2 class="popup-title">INFORMATION</h2>
    </app-popup-part-header>
    <div class="popup-content">
      <ng-template [ngIf]="!user?.language || user?.language === 'en'">
        <p>An investor may only invest more than EUR 5,000.00 per year if he provides information in a separate
          declaration at the latest when the contract is concluded, (i) that he invests no more than twice his average
          monthly
          net income calculated over twelve months, or (ii) that he invests a maximum of ten percent of his financial
          assets.<br> In this context, a technical possibility had to be created for the investor to be able to submit
          such a
          declaration before the binding acquisition of a participation right.</p>
      </ng-template>
      <ng-template [ngIf]="user?.language === 'de'">
        <p>Ein Anleger dürfte mehr als EUR 5.000,00 im Jahr nur dann investieren, wenn er spätestens bei
          Vertragsabschluss in einer gesonderten Erklärung die Auskunft erteilt, dass er höchstens das Doppelte seines
          durchschnittlichen monatlichen Nettoeinkommens über zwölf Monate gerechnet investiert, oder (ii) dass er
          maximal zehn Prozent seines Finanzanlagevermögens investiert. <br> In diesem Zusammenhang musste technisch
          eine Möglichkeit geschaffen werden, dass eine solche Erklärung durch den Anleger vor dem verbindlichen Erwerb
          eines Genussrechts abgegeben werden kann.</p>
      </ng-template>
      <button class="button yellow text-uppercase popup-button" (click)="onSubmit()">
        Accept
      </button>
    </div>
  </div>
</app-popup>
