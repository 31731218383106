import {ChangeDetectionStrategy, Component, HostListener, Inject, OnInit} from '@angular/core';
import * as moment from 'moment';
import { ProfileService} from '@app/services';
import {Router} from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'global-footer',
  templateUrl: './global-footer.component.html',
  styleUrls: ['./global-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalFooterComponent implements OnInit {
  globalTime: any;
  isBlocked: boolean;
  innerWidth: any;
  mobile = false;

  constructor(
    @Inject(WINDOW) private window: Window,
    private profileService: ProfileService,
    private router: Router,
  ) {}

  @HostListener('window:resize', ['$event'])

  onResize(event) {
    this.innerWidth = this.window.innerWidth;
    if (this.innerWidth <= 768) {
      this.mobile = true;
    }
    else {
      this.mobile = false;
    }
  }

  ngOnInit(): void {
    this.innerWidth = this.window.innerWidth;
    if (this.innerWidth <= 768) {
      this.mobile = true;
    }
    this.globalTime = moment().format('YYYY');
    this.profileService.getStatusProfile().subscribe(resp => {
      this.isBlocked = resp;
    });
  }

  navigateTo(nav, e?): void {
    if (e) {
      e.preventDefault();
    }
    if (this.isBlocked) {
      this.profileService.setUpdateProfileClick({
        isBlocked: true,
        state: nav
      });
    }
    this.router.navigate([`/${nav}`]);
    this.profileService.setUpdateProfileClick({
      isBlocked: false,
      state: nav
    });
  }
}
