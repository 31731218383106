import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  Inject,
  PLATFORM_ID
} from '@angular/core';
import {STATE_NAMES} from '../../common';
import {Router} from '@angular/router';

import {isPlatformBrowser} from '@angular/common';
import {Observable, of, Subject, switchMap} from 'rxjs';
import {VideoPlayerComponent} from 'app/components/video-player/video-player.component';
import {CardProjectComponent} from 'app/components/cards/card-project/card-project.component';
import {ProjectsService} from '../../services/projects.service';
import {StorageService} from '../../services/storage.service';
import {AudioService} from "@services/audio.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'app-music-item-list',
  templateUrl: './music-item-list.html'
})
export class MusicItemListComponent implements OnInit {
  user: any;
  @Input() projectList: any[];
  @Input() exportItem: boolean;
  @Input() loaded: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  enableTimer: boolean;
  displayMinutes = new Subject();
  displayHours = new Subject();
  enableVideoPlayer: boolean;
  video: HTMLVideoElement;
  cardProject: any;
  state: any;
  index: any;
  currentPlayingVideo: HTMLVideoElement;
  project$: Observable<any>;
  @ViewChild(VideoPlayerComponent, {static: false}) videoPlayer: VideoPlayerComponent;
  @ViewChild(CardProjectComponent) card: CardProjectComponent;

  constructor(private projectService: ProjectsService,
              private router: Router,
              private audioService: AudioService,
              @Inject(PLATFORM_ID) private platformId: any,
              private storageService: StorageService,
  ) {
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.user = this.storageService.user;
      this.enableTimer = true;
      this.audioService.getState().pipe(
        untilDestroyed(this),
        filter(val => val.pause),
        switchMap(() => {
          return this.audioService.getProjectPlaying().pipe(filter(val => val), untilDestroyed(this));
        })).subscribe(project => {
        if (this.card) {
          this.card.flipByService(project.index);
        }
      })
    }

    this.enableVideoPlayer = false;
  }

  getVideoObject(cardObject) {
    if (cardObject.status === 'play') {
      this.state = 'play';
      this.cardProject = cardObject.card;
      this.video = cardObject.video;
      this.index = cardObject.index;
      this.enableVideoPlayer = true;
    }
    if (cardObject.status === 'stop') {
      this.state = 'stop';
      this.cardProject = cardObject.card;
      this.video = cardObject.video;
      this.index = cardObject.index;
      this.enableVideoPlayer = false;
    }
  }

  stopPlayer($event) {
    this.card.currentPlayingVideo = this.video;
    this.card.pauseVideo(this.cardProject, this.index);
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }

    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard) {
        const name = e.target.className;
        const parentNodeHover = e.target.parentNode.className ? e.target.parentNode.className.indexOf('hover-block') >= 0 : null;
        if (name.indexOf('fas') && name.indexOf('play-button') && !parentNodeHover) {
          this.router.navigate([project.isCollectible ?
            STATE_NAMES.collectible : `/${STATE_NAMES.projectsItem}`, project.slug]);
        }
      }
    }
  }
}
