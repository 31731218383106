<app-popup>
  <div class="inner">
    <img  src="./assets/images/subscribe.png" class="image" alt="subscribe">
    <div class="text">
      <h1 class="text-head">Dear Investor!</h1><br>
      <p class="sub-text">We have recognized that you want to unsubscribe from notifications.</p><br>
      <p class="sub-text-bold">As investor it’s recommended to receive updates on songs you are invested in.</p>
      If you leave you will not receive any updates regarding the release of your songs, media covereges, performance(report on stream, etc)
        and other success stories.
    </div>
    <div class="flex-align button-wrapper">
      <span class="cancel" (click)="onClose()">Unsubscribe</span>
      <span class="cansel" (click)="onClose()"></span>
      <button type="button" class="button yellow title16 text-uppercase" (click)="onStay()">
        Stay
      </button>
    </div>
  </div>
</app-popup>
