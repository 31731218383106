<div class="inner-card" #myDiv id="{{index + 'inner'}}">

  <div
    class="card"

    #myCard
    id="{{index + 'card'}}"
  >
    <div class="front" #frontCard id="{{index}}">
      <div class="card-image-wrapper">
        <img
          *ngIf="!item?.typeEditions"
          class="card__background"
          loading="lazy"
          alt="project {{item?.projectTitle}}"
          src="{{item?.backgroundImage}}"
        />
        <img
          *ngIf="item?.typeEditions"
          class="card__background"
          loading="lazy"
          alt="project {{item?.projectTitle}}"
          src="{{item?.imgNFT}}"
        />
        <img
          class="sale_corner-img"
          *ngIf="item?.saleAmount"
          loading="lazy"
          alt="line"
          src="./assets/images/cards/rectangle_with_curve.svg"
        />
      </div>

      <a
        class="default-link link-block card-outer"
        [style.backgroundColor]="item?.cardColor"
        href="https://www.globalrockstar.com/projects/{{item?.slug}}"
        (click)="openSupplierProfile(item, $event)"
        [class.headliner]="item?.keepItAll && !item?.isCollectible && !item?.legacyHit?.isLegacyHit"
        [class.collectible]="item?.isCollectible"
      >
        <header-card [project]="item"></header-card>
        <div class="card__content">
          <div class="play-button" (click)="playVideo(item)">
            <i class="fas fa-play"></i>
          </div>
        </div>
        <div class="info__holder">
          <div class="card__name">
            <h2 class="card__title-h2 card__name--padding">
              {{item?.cardArtistTitle || item?.artist?.name || item?.artist}}
            </h2>
            <h3 class="card__title-h3 card__name--padding">
              <ng-template [ngIf]="!item?.isAlbum && !item.isBundle">
                {{item?.song?.name || item?.song}}
              </ng-template>
              <ng-template [ngIf]="item?.isAlbum || item.isBundle">
                {{item?.projectTitle}}
              </ng-template>
            </h3>
<!--            <p class="card__description">{{item?.cardDescription}}</p>-->
          </div>
          <h3
            class="track__numbers"
            *ngIf="item?.songs?.length > 1"
          >
            Tracks: {{item?.songs?.length}}
          </h3>
        </div>
        <div class="card__footer">
          <ng-template
            [ngIf]="item?.isCollectible && enableTimer && item?.projectNotStarted"
          >
            <div class="card-collectible__auction-sh">
              <div class="auction-time">
                <div class="auction__title">Auction starts in</div>
                <app-timer
                  [project]="item"
                  [config]="config"
                  [enableTimer]="enableTimer"
                ></app-timer>
              </div>
              <div class="footer-detail">
                <div class="footer-detail__icons">
                  <div class="icon-key" *ngIf="item?.limitedIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/key.png'"
                    >
                      <div img-content>
                        <img loading="lazy"
                             alt="logo tooltip" [src]="'./assets/images/cards/key.png'">
                      </div>
                      <div text-content>
                        By purchasing Music Collectibles you obtain a limited
                        ownership right to the recording.
                      </div>
                    </app-tooltip>
                  </div>
                  <div class="icon-gift" *ngIf="item?.goodiesIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/gift.svg'"
                    >
                      <div img-content>
                        <img loading="lazy"
                             alt="logo tooltip" [src]="'./assets/images/cards/gift.svg'">
                      </div>
                      <div text-content>
                        Goodies: This Music Collectible contains special goodies
                        for the top bidders.
                      </div>
                    </app-tooltip>
                  </div>
                </div>
                <p *ngIf="item?.typeEditions" class="auction-edition">
                  Edition: #{{item?.editionNumber}}
                </p>
                <p *ngIf="!item?.typeEditions" class="auction-edition">
                  Editions: {{item?.edition}}
                </p>
              </div>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && !item?.projectNotStarted && !item?.finished"
          >
            <div class="card-collectible__auction-run">
              <div class="footer-detail">
                <div class="footer-detail__icons">
                  <div class="icon-key" *ngIf="item?.limitedIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/key.png'"
                    >
                      <div img-content>
                        <img loading="lazy"
                             alt="logo tooltip" [src]="'./assets/images/cards/key.png'">
                      </div>
                      <div text-content>
                        By purchasing Music Collectibles you obtain a limited
                        ownership right to the recording.
                      </div>
                    </app-tooltip>
                  </div>
                  <div class="icon-gift" *ngIf="item?.goodiesIcon">
                    <app-tooltip
                      class="tooltip-component"
                      [img]="'./assets/images/cards/gift.svg'"
                    >
                      <div img-content>
                        <img loading="lazy"
                             alt="logo tooltip" [src]="'./assets/images/cards/gift.svg'">
                      </div>
                      <div text-content>
                        Goodies: This Music Collectible contains special goodies
                        for the top bidders.
                      </div>
                    </app-tooltip>
                  </div>
                </div>
                <p *ngIf="item?.typeEditions" class="auction-edition">
                  Edition: #{{item?.editionNumber}}
                </p>
                <p *ngIf="!item?.typeEditions" class="auction-edition">
                  Editions: {{item?.edition}}
                </p>
              </div>
              <div class="auction-time">
                <div class="auction__title">Auction ends in</div>
                <app-timer
                  [project]="item"
                  [config]="config"
                  [enableTimer]="enableTimer"
                ></app-timer>
              </div>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && item?.finished && !item?.typeEditions"
          >
            <div class="card__end" [style.backgroundColor]="item?.cardColor">
              <span>Sold out</span>
            </div>
          </ng-template>
          <ng-template
            [ngIf]="item?.isCollectible && item?.finished && item?.typeEditions"
          >
            <div class="card__end" [style.backgroundColor]="item?.cardColor">
              <span>AUCTION ENDED</span>
            </div>
          </ng-template>
          <ng-template [ngIf]="!item?.isCollectible">
            <ng-template
              [ngIf]="item?.daysLeft && !item?.isFunded && !item?.isCollectible"
            >
              <div class="headliner-project">
                <div class="price__per-share">
                  Price:
                  <span class="error-message" *ngIf="item?.salePricePerShare">
                    {{item?.salePricePerShare | localeCurrency | tenth : item?.decimalType}}</span
                  >
                  <span
                    [class.price__per-share-underline]="item?.salePricePerShare"
                  >{{item?.pricePerShare | localeCurrency | tenth : item?.decimalType}}</span
                  >
                  /  {{item.decimalType === 'hundred' ? '0.01' : '0.1'}}%
                </div>
                <app-card-tooltip-rights [project]="item"></app-card-tooltip-rights>
                <div class="card__progress-bar">
                  <div
                    class="result"
                    [style.backgroundColor]="item?.cardColor"
                    [style.width]="item?.progressBar + '%'"
                  ></div>
                </div>
                <div class="card__share">
                  <div class="share__time-left">
                    <span
                      *ngIf="item?.daysLeft && !item.isFunded && item?.daysLeft > 1 && item?.daysLeft !== 1"
                      [class.daysleft]="item?.daysLeft <= 7"
                    >
                      {{item.daysLeft}} {{item.daysLeft > 1 ? 'days' : 'day'}}
                      to go
                    </span>
                    <div
                      *ngIf="item?.daysLeft === 1"
                      [class.daysleft]="item?.daysLeft === 1"
                    >
                      <span
                        *ngIf="this.calculateTimeDistance(endDate) > 3600000"
                      >{{displayHours | async}} to go</span
                      >
                      <span
                        *ngIf="this.calculateTimeDistance(endDate) > 0 && this.calculateTimeDistance(endDate) < 3600000"
                      >{{displayMinutes | async}} to go</span
                      >
                    </div>
                  </div>
                  <div class="price-item">
                    <span
                      *ngIf="item?.totalInvestorsLeft != item?.totalInvestorsShares"
                    >shares left:
                    </span>
                    <span
                      *ngIf="item?.totalInvestorsLeft === item?.totalInvestorsShares"
                    >available shares:
                    </span>
                    <span class="percent"
                    >{{item?.totalInvestorsLeft | number:'.0-2'}}%</span
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <div
              class="button card__end"
              [style.backgroundColor]="item?.cardColor"
              *ngIf="item?.isSold || item?.isFunded || item?.releaseCreated || item?.isFailed"
            >
              <ng-template
                [ngIf]="(item?.isSold || item?.isFunded) && !item?.releaseCreated && !item?.isFailed"
              >
                <span *ngIf="!item.statusCard">Sold out</span>
                <span *ngIf="item.statusCard">{{item.statusCard}}</span>
              </ng-template>
              <ng-template [ngIf]="item?.isFailed">Failed</ng-template>
              <ng-template [ngIf]="item?.releaseCreated">
                Released on {{item?.releaseCreatedDate |
                momentDateFormat}}</ng-template
              >
            </div>
          </ng-template>
        </div>
      </a>
    </div>
    <div
      class="back"
      (mouseenter)="hideElement = false"
      (mouseleave)="hideElement = true"
      [style.backgroundColor]="item?.cardColor"
    >
      <div class="card__content"></div>

        <div
          class="play-button"
          *ngIf="!hideElement && currentPlayingVideo?.buffered"
          (click)="pauseVideo()"
        >
          <i class="fas fa-stop"></i>
        </div>
        <ng-container *ngIf="isGif(item?.video)">
          <img class="upl__image"
               [src]="item?.video | imgUrl"
          />
        </ng-container>
        <ng-container *ngIf="!isGif(item?.video)">
          <video
            #myVideo
            class="video"
            *ngIf="item?.video"
            id="{{(item?.id || item?._id) + 'video'}}"
            width="310px"
            height="473px"
            preload="auto"

            (ended)="pauseVideo()"
            (playing)="onVideoPlaying()"
            (pause)="onVideoPause()"
            (waiting)="onVideoWaiting()"
            (loadstart)="onVideoLoadStart()"
          >
            <source
              [src]="item?.video | videoUrl"
              [type]="'video/'+ getVideoFormat(item?.video)"
            />
          </video>
          <mat-spinner *ngIf="loadingVideo"
            [diameter]="40"
          ></mat-spinner>
        </ng-container>

        <div
          class="card__video-overlay"
          [style.backgroundColor]="item?.cardColor"
          [class.opaque]="videoPlaying"
        ></div>
        <div
          class="play-button"
          *ngIf="!hideElement && (currentPlayingVideo?.buffered || isGif(item?.video))"
          (click)="pauseVideo()"
        >
          <i class="fas fa-stop"></i>
        </div>
    </div>
  </div>
</div>

