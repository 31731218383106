<div class="m-filters">
  <div class="m-filters__page">
    <header class="m-filters__header">
      <div class="wrapper">
        <span class="picture-back" (click)="goBack()">
        <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.966797 9.71387L9.97559 0.719727C10.1221 0.573242 10.2979 0.5 10.5029 0.5C10.708 0.5 10.8838
             0.573242 11.0303 0.719727C11.1768 0.866211 11.25 1.04199 11.25 1.24707C11.25 1.45215 11.1768 1.62793
             11.0303 1.77441L3.31055 9.49414H27.7441C27.959 9.49414 28.1396 9.56738 28.2861 9.71387C28.4326 9.86035
              28.5059 10.041 28.5059 10.2559C28.5059 10.4609 28.4326 10.6367 28.2861 10.7832C28.1396 10.9297 27.959
              11.0029 27.7441 11.0029H3.31055L11.0303 18.7227C11.1768 18.8691 11.25 19.0449 11.25 19.25C11.25 19.4551
               11.1768 19.6309 11.0303 19.7773C10.9619 19.8555 10.8789 19.9141 10.7812 19.9531C10.6934 19.9824 10.6006
                19.9971 10.5029 19.9971C10.4053 19.9971 10.3076 19.9824 10.21 19.9531C10.1221 19.9141 10.0439 19.8555
                9.97559 19.7773L0.966797 10.7832C0.820312 10.6367 0.74707 10.4609 0.74707 10.2559C0.74707 10.041 0.820312
                 9.86035 0.966797 9.71387Z"
            fill="#5AA2E5"/>
        </svg>
          </span>
        <span class="m-filters__title">Filters <span *ngIf="count">({{count}})</span></span>
        <span class="picture-cross" (click)="clearFilter()">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59
             14L14 12.59L8.41 7L14 1.41Z" fill="#5AA2E5"/>
          </svg>
        </span>
      </div>
    </header>
    <main class="m-filters__body">
      <div class="body__item">
        <div class="wrapper">
          <span class="body__text">
            Type
          </span>
          <div class="body__input">
            <mat-form-field>
              <mat-select placeholder="Any" [disabled]="activeTab === 3" [(ngModel)]="filter.finding.typeSong">
                <mat-option *ngFor="let item of projectType" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="body__item">
        <div class="wrapper">
          <span class="body__text">
            Status
          </span>
          <div class="body__input">
            <mat-form-field>
              <mat-label>Status</mat-label>
              <mat-select placeholder="Any" [disabled]="activeTab === 3" [(ngModel)]="filter.finding.status">
                <mat-option *ngFor="let item of status" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="body__item">
        <div class="wrapper">
          <div class="body__text">
            Country
          </div>
          <div class="body__input">
            <mat-form-field>
              <mat-select [(ngModel)]="filter.finding.country" placeholder="Any">
                <mat-option *ngFor="let item of countries" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="body__item">
        <div class="wrapper">
          <div class="body__text">
            Artist
          </div>
          <div class="body__input">
            <mat-form-field>
              <mat-select multiple [(ngModel)]="filter.finding.artist" placeholder="Any">
                <mat-option *ngFor="let item of artistList" [value]="item.slug">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="body__item">
        <div class="wrapper">
          <div class="body__text">
            Genres
          </div>
          <div class="body__input">
            <mat-form-field>
              <mat-select multiple [(ngModel)]="filter.finding.genres" placeholder="Any">
                <mat-option *ngFor="let item of genres" [value]="item.id">{{item.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </main>
    <footer class="m-filters__footer">
      <div class="wrapper">
        <span class="cancel" (click)="clearFilter()">Clear</span>
        <button type="button" class="button yellow text-uppercase" (click)="getList()">
          Filter
        </button>
      </div>
    </footer>
  </div>
</div>
