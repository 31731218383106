<div class="range-block" [class.disable]="isDisabled">
  <span class="title title--position">{{title}}</span>
  <div class="change less"
       (click)="decrease()"
       [class.limit]="(limitBlock$ | async)?.min"
  ><img
    class="home-icon__image"
    src="assets/images/be-icons/less_active.svg"
    alt="Hand with a coin"
  /></div>
  <div class="change more"
       (click)="increase()"
       [class.limit]="(limitBlock$ | async)?.max">
    <img
      class="home-icon__image"
      src="assets/images/be-icons/more_active.svg"
      alt="Hand with a coin"
    />
  </div>
  <input
    matInput
    readonly
    class="input-box"
    type="text"
    [formControl]="formControl"
  />
</div>
