<section class="section--hero">
  <div class="section__wrapper">
    <p>
      GET YOUR SHARE <br />
      IN HIT SONGS
    </p>
    <h1>
      Invest in music and earn royalties from streams & radio plays
      <br />
      for up to 70 years
    </h1>
    <a [routerLink]="['opportunity']" class="grs-button--orange section__button"
      ><span class="white">BROWSE NOW</span></a
    >
    <span class="grs-button--white section__button" (click)="scrollTo(fans)"
      ><h3>LEARN MORE</h3></span
    >
  </div>
</section>

<section
  class="spotlight section"
  [ngStyle]="{ 'background-color': currentSpotlight?.cardColor }"
>
  <div class="section__wrapper">
    <h4 class="white spotlight__title">IN THE SPOTLIGHT</h4>

    <div class="spotlight__wrapper">
      <div class="spotlight__info" *ngIf="isDesktop || isTablet">
        <p class="spotlight__artist">{{ currentSpotlight?.artist?.name }}</p>
        <p class="spotlight__song">
          {{
            currentSpotlight?.isAlbum
              ? currentSpotlight?.projectTitle
              : currentSpotlight?.song?.name
          }}
        </p>
        <p class="spotlight__teaser" *ngIf="currentSpotlight?.details">
          {{ currentSpotlight?.details?.teaser }}
        </p>
        <a
          [routerLink]="['projects', currentSpotlight?.slug]"
          class="grs-button--orange section__button"
          >INVEST NOW</a
        >
        <a
          [routerLink]="['opportunity']"
          class="grs-button--white section__button"
          >VIEW ALL</a
        >
      </div>
      <div class="spotlight__slider">
        <ngx-slick-carousel
          class="carousel"
          [ngClass]="spotlightSlideClass"
          #slickModal="slick-carousel"
          [config]="spotlightSlideConfig"
          (afterChange)="spotlightChange($event)"
        >
          <div ngxSlickItem *ngFor="let card of projects" class="slide">
            <app-card-project
              class="home-project-card"
              [exportItem]="true"
              [enableTimer]="isBrowser"
              (enablePlayer)="getVideoObject($event)"
              [item]="card"
              [subscribeToVideoService]="true"
            >
            </app-card-project>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
    <a
      *ngIf="isMobile"
      [routerLink]="['opportunity']"
      class="grs-button--orange section__button"
      >VIEW ALL</a
    >
  </div>
</section>

<section class="section--black section idealidx" *ngIf="isUSA$ | async">
  <p class="idealidx__subtitle--green">A POWERFUL COLLABORATION</p>
  <div class="idealidx__labels">
    <h1 class="idealidx__title">IDEAL EXCHANGE</h1>
    <img
      class="idealidx__icon"
      src="assets/images/icons-common/cross-green.svg"
      alt="cross icon"
    />
    <h1 class="idealidx__title">GLOBAL ROCKSTAR</h1>
  </div>
  <p class="idealidx__text">
    The World's Stock Market for Creative Ideas and Works
    <b>Ideal Exchange</b> joins forces with fintech company and music rights
    marketplace <b>Global Rockstar</b>
    to offer you shares in future royalties from hit songs generated via
    streams, radio plays, live performances and more for up to 70 years.
  </p>
  <p class="idealidx__title">BECOME A SHAREHOLDER IN MUSIC</p>
  <a
    [routerLink]="['api/register']"
    class="idealidx__button grs-button--orange section__button"
    ><h3>START NOW</h3></a
  >
</section>

<section class="section--pale-blue section" #fans>
  <div class="section__wrapper">
    <h4>FANS & INVESTORS</h4>
    <!-- DESKTOP -->
    <div class="section__cards" *ngIf="isDesktop">
      <ng-container *ngFor="let card of FANS_CONTENT">
        <ng-container *ngTemplateOutlet="fansCard; context: { card: card }">
        </ng-container>
      </ng-container>
    </div>
    <!-- MOBILE -->
    <div class="section__wrapper--slider">
      <ngx-slick-carousel
        *ngIf="isMobile || isTablet"
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem *ngFor="let card of FANS_CONTENT" class="slide">
          <ng-container *ngTemplateOutlet="fansCard; context: { card: card }">
          </ng-container>
        </div>
      </ngx-slick-carousel>
    </div>
    <a
      [routerLink]="['api/register']"
      class="grs-button--orange section__button"
      ><h3>JOIN NOW</h3></a
    >
  </div>
</section>

<section class="section">
  <div class="section__wrapper">
    <h4>Artists</h4>
    <!-- DESKTOP -->
    <div class="section__cards" *ngIf="isDesktop">
      <ng-container *ngFor="let card of ARTIST_CONTENT">
        <ng-container *ngTemplateOutlet="artistCard; context: { card: card }">
        </ng-container>
      </ng-container>
    </div>
    <!-- TABLET & MOBILE -->
    <div class="section__wrapper--slider">
      <ngx-slick-carousel
        *ngIf="isMobile || isTablet"
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem *ngFor="let card of ARTIST_CONTENT" class="slide">
          <ng-container *ngTemplateOutlet="artistCard; context: { card: card }">
          </ng-container>
        </div>
      </ngx-slick-carousel>
    </div>
    <a
      [routerLink]="['api/landing/for-artists']"
      class="grs-button--orange grs-button--outline section__button"
    >
      <h3>SUBMIT YOUR MUSIC</h3>
    </a>
  </div>
</section>

<section class="section--pale-blue section" id="successesSection">
  <div class="section__wrapper">
    <h4>SUCCESSES</h4>
    <div class="section__wrapper--slider">
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="arrowsSlideConfig"
      >
        <div ngxSlickItem *ngFor="let card of SUCCESS_CONTENT" class="slide">
          <a
            class="artist-card"
            *ngIf="card.name"
            [ngStyle]="{ 'background-image': 'url(' + card.image + ')' }"
            [class.clickable]="card.url"
            [href]="card.url"
            target="_blank"
          >
            <div class="artist-card__header">
              <span>{{ card.year }}</span>
              <img
                src="assets/images/logo/grs-tm-white.png"
                alt="GRS logo"
                class="logo"
              />
              <img
                src="assets/images/logo/logo-white.svg"
                alt="GRS logo"
                class="icon"
              />
            </div>
            <div class="artist-card__content">
              <div class="artist-card__name">{{ card.name }}</div>
              <div class="artist-card__song">{{ card.song }}</div>
              <hr class="artist-card__divider" />
              <div
                class="details"
                [ngStyle]="{ 'background-color': card.detailsColor }"
              >
                <div class="details__icon home-icon--68">
                  <img
                    [src]="card.detailsIcon"
                    alt="icon"
                    class="home-icon__image"
                  />
                </div>
                <div>
                  <p class="details__value">{{ card.detailsValue }}</p>
                  <p class="details__text">{{ card.detailsText }}</p>
                </div>
              </div>
            </div>
          </a>

          <div class="artist-card--info" *ngIf="!card.name">
            <div class="info">
              <div class="home-icon--130 info__icon">
                <img [src]="card.icon" alt="icon" class="home-icon__image" />
              </div>
              <div class="info__value">{{ card.value }}</div>
              <div class="info__text">{{ card.text }}</div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</section>

<section class="section" id="aboutSection">
  <div class="section__wrapper">
    <h4>About us</h4>
    <!-- DESKTOP -->
    <div class="section__cards" *ngIf="isDesktop">
      <div class="home-card home-card--pale-blue">
        <div class="home-card__icon home-icon--58">
          <img
            class="home-icon__image"
            src="assets/images/logo/logo-blue.svg"
            alt="GRS logo"
          />
        </div>
        <h1 class="home-card__title">GLOBAL ROCKSTAR</h1>
        <p class="home-card__text">
          Global Rockstar is a Fintech company & music rights marketplace.
          <br /><br />
          “We envision a world where <br />
          everyone benefits from the success of music: artists, creators, rights
          owners and fans.”
        </p>
      </div>
      <ng-container *ngFor="let card of ABOUT_CONTENT">
        <ng-container *ngTemplateOutlet="aboutCard; context: { card: card }">
        </ng-container>
      </ng-container>
    </div>
    <!-- TABLET -->
    <div class="section__wrapper--slider">
      <ngx-slick-carousel
        *ngIf="isTablet"
        class="carousel"
        #slickModal="slick-carousel"
        [config]="slideConfig"
      >
        <div ngxSlickItem class="slide">
          <div class="home-card home-card--pale-blue home-card--wrapper">
            <div class="home-card__icon home-icon--58">
              <img
                class="home-icon__image"
                src="assets/images/logo/logo-blue.svg"
                alt="GRS logo"
              />
            </div>
            <h1 class="home-card__title">GLOBAL ROCKSTAR</h1>
            <p class="home-card__text">
              Global Rockstar is a Fintech company & music rights marketplace.
              <br /><br />
              “We envision a world where everyone benefits from the success of
              music: artists, creators, rights owners and fans.”
            </p>
          </div>
        </div>
        <div ngxSlickItem *ngFor="let card of ABOUT_CONTENT" class="slide">
          <ng-container *ngTemplateOutlet="aboutCard; context: { card: card }">
          </ng-container>
        </div>
      </ngx-slick-carousel>
    </div>
    <!-- MOBILE -->
    <div
      class="home-card home-card--pale-blue home-card--wrapper"
      *ngIf="isMobile"
    >
      <div class="home-card__icon home-icon--58">
        <img
          class="home-icon__image"
          src="assets/images/logo/logo-blue.svg"
          alt="GRS logo"
        />
      </div>
      <h1 class="home-card__title">GLOBAL ROCKSTAR</h1>
      <p class="home-card__text">
        Global Rockstar is a Fintech company & music rights marketplace.
        <br /><br />
        “We envision a world where everyone benefits from the success of music:
        artists, creators, rights owners and fans.”
      </p>
      <div class="section__wrapper--slider">
        <ngx-slick-carousel
          class="carousel"
          #slickModal="slick-carousel"
          [config]="slideConfig"
        >
          <div ngxSlickItem *ngFor="let card of ABOUT_CONTENT" class="slide">
            <ng-container
              *ngTemplateOutlet="aboutCard; context: { card: card }"
            >
            </ng-container>
          </div>
        </ngx-slick-carousel>
      </div>
      <a
        [routerLink]="['about-grs']"
        aria-label="Learn more about GRS"
        class="grs-button--orange grs-button--outline section__button"
        ><h3>LEARN MORE</h3></a
      >
    </div>
    <a
      *ngIf="isDesktop || isTablet"
      [routerLink]="['about-grs']"
      aria-label="Learn more about GRS"
      class="grs-button--orange grs-button--outline section__button"
      ><h3>LEARN MORE</h3></a
    >
  </div>
</section>

<section class="section section--pale-blue">
  <div class="section__wrapper">
    <div
      class=""
      [ngClass]="
        isMobile ? 'home-card home-card--white home-card--wrapper' : ''
      "
    >
      <app-subscribe-newsletter [type]="isMobile ? 'new' : 'default'"
        >Join our Newsletter
      </app-subscribe-newsletter>
    </div>
  </div>
</section>

<section class="section section--white">
  <div class="section__wrapper">
    <h4>FREQUENTLY ASKED QUESTIONS</h4>
    <mat-accordion displayMode="flat" class="home-accordion">
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>Why should I invest?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          An investment in the right track can be a true jackpot. If you
          purchase 1% of future royalties of a track for 100 EUR, and this track
          reaches 50 million streams on Spotify in the first year, you’ll make
          approximately 1,875 EUR.
        </p>
        <a [routerLink]="['how-it-works']"><h3>Learn more</h3></a>
      </mat-expansion-panel>
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>How do I invest?</mat-panel-title>
        </mat-expansion-panel-header>
        <ol>
          <li>Select your favorite song on our opportunity-page</li>
          <li>
            Buy your share wit a credit card, Klarna, Google Pay, or re-invest
            your earnings
          </li>
        </ol>
        <a [routerLink]="['how-it-works']"><h3>Learn more</h3></a>
      </mat-expansion-panel>
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>What are Smart Contracts?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          Smart contracts are used on the Global Rockstar platform in order to
          secure your investments immutable and perfectly traceable on the
          blockchain. By utilizing computer code and blockchain technology,
          smart contracts enable secure and transparent transactions without the
          requirement of a central authority, legal system, or external
          enforcement mechanism.
        </p>
        <br />
        <p>
          Although it is voluntary to create smart contracts out of your
          investment, we highly recommend it. Of course, you will still have the
          same participation rights and are receiving earnings, when not
          creating your smart contracts.
        </p>
        <a [routerLink]="['how-it-works']"><h3>Learn more</h3></a>
      </mat-expansion-panel>
      <mat-expansion-panel togglePosition="before">
        <mat-expansion-panel-header>
          <mat-panel-title>Am I taking a risk?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          In addition to offering a great opportunity, an investment in a music
          production and/or its marketing entails risks. Nobody can know for
          sure if a song becomes a hit and you might not recoup your investment
          within the desired time frame, or in the worst case, at all.
        </p>
        <a [routerLink]="['how-it-works']"><h3>Learn more</h3></a>
      </mat-expansion-panel>
      <mat-expansion-panel togglePosition="before" class="last">
        <mat-expansion-panel-header>
          <mat-panel-title>What is my investment used for?</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
          You will find the information concerning the assignment of the funding
          on every opportunity detail page. They might vary depending on the
          different setup, but basically, the possible use of the investments
          are as follows:
        </p>
        <ol>
          <li>Budget to enable high-quality music production</li>
          <li>Budget for marketing and promotion activities</li>
        </ol>
        <a [routerLink]="['how-it-works']"><h3>Learn more</h3></a>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>

<app-video-player
  *ngIf="enableVideoPlayer"
  [video]="video"
  [project]="cardProject"
  [stateVideo]="state"
  (notify)="stopPlayer()"
></app-video-player>

<app-footer></app-footer>

<!-- CARD TEMPLATES -->
<ng-template #aboutCard let-card="card">
  <div class="home-card home-card--solid-blue inner-slide">
    <div class="home-card__icon home-icon--58">
      <img class="home-icon__image" [src]="card?.icon" alt="icon" />
    </div>
    <h5 class="home-card__title--small">{{ card?.title }}</h5>
    <p class="home-card__text">{{ card?.text }}</p>
  </div>
</ng-template>

<ng-template #artistCard let-card="card">
  <div class="home-card home-card--pale-blue">
    <div class="home-card__icon home-icon--58">
      <img class="home-icon__image" [src]="card.icon" alt="icon" />
    </div>
    <h2 class="home-card__title">{{ card.title }}</h2>
    <p class="home-card__text">{{ card.text }}</p>
  </div>
</ng-template>

<ng-template #fansCard let-card="card">
  <div class="home-card home-card--white">
    <div class="home-card__icon home-icon--58">
      <img class="home-icon__image" [src]="card.icon" alt="icon" />
    </div>
    <h2 class="home-card__title">{{ card.title }}</h2>
    <p class="home-card__text">{{ card.text }}</p>
  </div>
</ng-template>
