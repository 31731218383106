<div class="select-share">
  <h2 class="title">SELECT YOUR SHARES</h2>
  <div class="range-section">
    <app-range-block [formControlItem]="form?.get('price')"
                     (increaseItem)="increasePrice()"
                     (decreaseItem)="decreasePrice()"
                     [title]="'PRICE'">
    </app-range-block>
    <app-range-block [formControlItem]="form?.get('share')"
                     (increaseItem)="increaseShare()"
                     (decreaseItem)="decreaseShare()"
                     [title]="'SHARE OF REVENUES'"></app-range-block>
  </div>
  <div class="slider">
    <input type="range"
           [min]="(settings$ | async).min"
           [step]="(settings$ | async).step"
           [max]="(settings$ | async).max"
           #sliderInput
           [ngClass]="(settings$ | async).style"
           class="slider-input"
           [formControl]="form?.get('slider')"
           name="slider"
       >
    <div class="label-bar">
      <div class="range-max-min">

       <span class="range-min">
               {{(settings$ | async).minView}} <br/>Min.</span>
        <span class="range-max"> {{(settings$ | async).maxView}}<br/>Max.</span>


      </div>
    </div>
  </div>
</div>
