<div class="main-wrapper">
  <div class="item">
    <div class="align-items">
      <div class="image">
        <img class="logo" src="./assets/images/grs_logo.png">
      </div>
      <div class="image">
        <img class="icon" src="./assets/images/downtime.png">
      </div>
      <div class="title">We'll be back soon!
      </div>
      <div class="text">Dear Users and Investors,<br>
        We are improving your user experience.<br><br>
        Global Rockstar is currently down for maintenance.<br><br>
        The platform will be back up shortly.<br>
        Please check back in a few minutes. Thank you for your patience!
      </div>
    </div>
  </div>
</div>
