<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <!--    TODO set pdf file below-->
    <embed type='application/pdf' class="embed-image" *ngIf="fileToShow.type === 'pdf' && ios" [src]="fileToShow.url | safeHtmlUrl"
           width="{{width}}" height="{{height}}">
    <iframe class="embed-image" *ngIf="fileToShow.type === 'pdf' && !ios" [src]="fileToShow.url | safeHtmlUrl"
            type="application/pdf" width="{{width}}" height="{{height}}" >
      <p>Your web browser doesn't have a PDF plugin.
      Instead you can <a target="_blank" [href]="fileToShow.url | safeHtmlUrl"> click here to
      download the PDF file.</a></p>
    </iframe>
    <iframe *ngIf="fileToShow.type === 'xls'"
            [src]="microsoftViewFileAPI + fileToShow.url | safeHtmlUrl"
            width="{{width}}" height="{{height}}" frameborder='0'></iframe>
  </div>
</app-popup>


