<div class="countdown">
  <div class="countdown__item" [style.backgroundColor]="color">
    {{ (countdown$ | async)?.days || "00" }}
  </div>
  <div class="countdown__item" [style.backgroundColor]="color">
    {{ (countdown$ | async)?.hours || "00" }}
  </div>
  <div class="countdown__item" [style.backgroundColor]="color">
    {{ (countdown$ | async)?.minutes || "00" }}
  </div>
  <div class="countdown__item" [style.backgroundColor]="color">
    {{ (countdown$ | async)?.seconds || "00" }}
  </div>
</div>
