import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppToastrService, ProfileService} from 'app/services';
import {NUMBER_LENGTH_NINE} from 'app/common';
import {BankAccountModel} from '../../../models';
import {CustomFieldErrorMatcher} from '../../../common/errorMatcher';

@Component({
  selector: 'app-withdraw-money-popup',
  templateUrl: './withdraw-money.html'
})
export class WithdrawMoneyComponent implements OnInit {
  @Input() revenue: any;
  @Input() currentBlance: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  withdrawAmountCtrl: FormControl;
  creditCardCtrl: FormControl;
  approvePasswordCtrl: FormControl;
  form: FormGroup;
  accounts: BankAccountModel[];
  filteredAccounts: any;
  submitPressed: boolean;
  submitting: boolean;

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(private toast: AppToastrService,
              private profileService: ProfileService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.getData();
    this.prepareForm();
  }

  private prepareForm() {
    this.withdrawAmountCtrl = this.fb.control('', [Validators.required, Validators.pattern(NUMBER_LENGTH_NINE)]);
    this.creditCardCtrl = this.fb.control('', [Validators.required, Validators.minLength(4)]);
    this.form = this.fb.group({
      amount: this.withdrawAmountCtrl,
      bankId: this.creditCardCtrl
    });
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  getData() {
    this.profileService.getBankAccount().subscribe((resp) => {
      if (resp) {
        this.accounts = resp.accounts;
        this.filteredAccounts = this.accounts.filter(item => item.placeholder);
      }
    }, err => {
      this.toast.showToastFromError(err);
    });
  }
  onSubmit() {
    this.submitPressed = true;
    if (this.form.valid && !this.submitting) {
        this.submitting = true;
        const data: any = {
          withdraw: this.withdrawAmountCtrl.value,
          bank: this.creditCardCtrl.value
        };
        this.profileService.payoutMoney(data).subscribe((res) => {

          this.submitting = false;
          this.toast.showToast({title: 'You successfully withdraw your revenue'});
          this.notify.emit({type: 'close'});
        }, (err) => {
          this.submitting = false;
          this.toast.showToastFromError(err);
        });
    }
  }

  errorMatcher(control: FormControl, additionalCheck?: boolean) {
    let error;
    if (additionalCheck) {
      error = this.creditCardCtrl.value  && this.creditCardCtrl.touched;
    }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }
}
