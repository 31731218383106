import {
  Component, EventEmitter, Inject,
  Input, OnInit, Output, PLATFORM_ID,
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {STATE_NAMES} from '../../../common';
import {Router} from '@angular/router';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-card-news',
  templateUrl: './card-news.html',
})
export class CardNewsComponent implements OnInit {
  showLabel = true;
  showUploadPopup: boolean;
  showTooltip: any;
  isSEP: boolean;
  displayMinutes = new Subject();
  displayHours = new Subject();
  @Input() item;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(PLATFORM_ID) private platformId: any, private router: Router) {

  }

  ngOnInit() {
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }
    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard) {
        const name = e.target.className;
        if (name.indexOf('fas') && name.indexOf('play-button')) {
          this.router.navigate([project.publishedUpcoming ? STATE_NAMES.projectsItemUpcoming : `/${STATE_NAMES.projectsItem}`, project.slug]);
        }
      }
    }
  }
}
