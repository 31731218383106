import {Component, Input, OnInit} from '@angular/core';
import {ImageService} from 'app/services/index';
import {SliderModelLanding} from '../../../models/landing.model';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.html'
})
export class CarouselComponent implements OnInit {
  @Input() carousel: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: '',
    prevArrow: '',
  };
  sliders: {
    title: string,
    list: SliderModelLanding[]
  };

  constructor(
    private imageService: ImageService
  ) {
  }

  ngOnInit() {
  }

  slickInit(e) {
    // console.log('slick initialized');
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }

  trackByFn(index, item) {
    return item.id;
  }
}
