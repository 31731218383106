<div class="main-wrapper flex-align-column">
  <app-page-header [title]="'Dashboard'">
    <div class="action-tab flex-align">
      <div class="info-item">
        <div class="item">
          <div class="text title14">Total revenue</div>
          <div class="font-bold value">
            {{user?.revenues?.total | localeCurrency}}
          </div>
        </div>
        <app-kyc-status class="mobile-screen" [user]="user"></app-kyc-status>
      </div>
      <div class="button-wrapper info-wrapper button-action">
        <div class="list-buttons">
          <button
            class="button border-blue title16 text-uppercase"
            (click)="goTo('investments')"
          >
            My investments
          </button>
          <button
            class="button border-blue title16 text-uppercase"
            (click)="goTo('balance')"
          >
            My transactions
          </button>
          <button
            class="button border-blue title16 text-uppercase"
            (click)="goTo('nft')"
          >
            My Music Assets
          </button>
        </div>
        <app-kyc-status class="desktop-screen" [user]="user"></app-kyc-status>
      </div>
    </div>
  </app-page-header>

  <main class="main">
    <section class="kyc__status data-list">
      <p class="warning" *ngIf="user?.mangoVerificationStatus?.id === 3 && user?.kycFailedReason && user?.isPrivate">{{kycFailedReason}}</p>
      <ul class="warning" *ngIf="user?.kycFailedReasonCompany?.length && !user?.isPrivate">
        <li class="warning__list "  *ngFor="let item of user.kycFailedReasonCompany">
          <p class="warning__title">{{item?.code }}&nbsp; :&nbsp;</p>
          <p>{{item?.text}}</p>
        </li>
      </ul>
    </section>
    <section class="section-projects">
      <h1 class="section-list-title text-uppercase title18" *ngIf="isLoaded">
        Supported Opportunities
      </h1>
      <app-music-item-list
        [loaded]="isLoaded"
        [projectList]="projectList"
      ></app-music-item-list>
      <div
        class="no-list-data color-light-grey title16"
        *ngIf="!sumProjects?.length && isLoaded"
      >
        You haven't invested yet
      </div>
      <div
        class="wrapper load-more"
        *ngIf="total > paging?.limit && isLoadedAdditional && (sumProjects?.length < total) && !loadedArray && isLoaded"
      >
        <button
          class="button border-blue text-uppercase button-standart"
          (click)="loadMore()"
        >
          Load More
        </button>
        <app-loader *ngIf="!isLoadedAdditional"></app-loader>
      </div>
    </section>
    <section class="favorite-artist" *ngIf="isLoaded">
      <h1 class="section-list-title title18">Favorite artists</h1>
      <div
        class="no-list-data color-light-grey title16"
        *ngIf="!artist?.length"
      >
        You don't have a favorite artist yet
      </div>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        (afterChange)="afterChange($event)"
        [config]="slideConfig"
        (init)="slickInit($event)"
      >
        <div ngxSlickItem class="slide" *ngFor="let item of artist">
          <div class="img-circle slider-item">
            <div
              class="inner"
              [style.backgroundImage]="item?.image ? 'url(' + item?.image + ')' : null"
            ></div>
          </div>
        </div>
      </ngx-slick-carousel>
      <div class="become-fan flex-align-column flex-align-center">
        <div class="active-text" *ngIf="activeUser">{{activeUser?.name}}</div>
      </div>
    </section>
    <!--<app-loader *ngIf="!isLoaded"></app-loader>-->
  </main>

  <app-footer></app-footer>
</div>
<ngx-slick-carousel
  class="carousel"
  #slickModal="slick-carousel"
  (afterChange)="afterChange($event)"
  [config]="slideConfig"
  (init)="slickInit($event)"
></ngx-slick-carousel>
