<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClosePopup()"></span>
    <img *ngIf="!image" src="./assets/images/save.png"
         alt="alert popup"
         class="image">
    <!--    TODO waiting for trash icon-->
    <img *ngIf="image" src="./assets/images/{{image}}" class="image">
    <div class="text">
      <ng-content></ng-content>
      {{config?.text}}
    </div>
  </div>
</app-popup>
