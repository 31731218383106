<aside class="info-text">
  <div class="inner">
    <div>
      <img class="info-text__logo" src="/assets/images/logo/grs-tm-white-xl.png" alt="Globalrockstar logo" />
      <p class="sub-text">YOUR SHARE IN HIT SONGS</p>
    </div>
  </div>
</aside>
<div class="wrapper">
  <div class="inner">
    <login-form (onSuccess)="login()"></login-form>
    <div class="register">
      Don’t have an account yet?
      <a class="link" (click)="goToRegister()">Join</a>
      <a class="button border-blue" (click)="goToRegister()">Join</a>
    </div>
  </div>
</div>
