import {Directive, ElementRef, Input, HostListener, SecurityContext} from '@angular/core';
import {IRevenueItem} from "@app/pages/revenue/types/revenue-response.interface";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {QUESTION_MARK} from "@app/pages/revenue/constant/questionary";

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective {
  @Input() appTooltip: IRevenueItem;

  private tooltipElement: HTMLElement;

  constructor(private el: ElementRef, private sanitizer: DomSanitizer) {
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.show();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.hide();
  }

  private getTooltipText(): SafeHtml {
    const rights = this.appTooltip.rights;
    switch (rights) {
      case 'Publishing Rights':
        return this.sanitizer.bypassSecurityTrustHtml(QUESTION_MARK.publish);
      case 'Master Rights':
        return this.sanitizer.bypassSecurityTrustHtml(QUESTION_MARK.master);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(
          QUESTION_MARK.master + `<br><br>` + QUESTION_MARK.publish);
    }
  }

  private show() {

    this.tooltipElement = document.createElement('div');
    this.tooltipElement.className = 'tooltip showTooltip';
    const content = this.getTooltipText();
    this.tooltipElement.innerHTML = this.sanitizer.sanitize(
      SecurityContext.HTML,
      content
    ) as string;

    const hostElem = this.el.nativeElement;
    hostElem.appendChild(this.tooltipElement);
  }

  private hide() {
    if (this.tooltipElement) {
       this.tooltipElement.remove();
    }
  }
}
