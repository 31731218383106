export class BankAccountModel {
  bankId: number;
  usAba: string;
  caName: string;
  auName: string;
  gbCode: string;
  accountNumber: string;
  iban: string;
  isPreferred: boolean;
  bic: string;
  bsb: string;
  placeholder: string;
  isSelectedItem: any;
  shortIban: any;

  setFromApiModel(apiModel: any, country: string): this {
    this.bankId = apiModel.bankId;
    this.placeholder = apiModel.placeholder;
    let list = apiModel.title.split(', Account number: ');
    this.isPreferred = apiModel.isPreferred;
    switch (country) {
      case('US'):
        this.usAba = list[0].replace('ABA: ', '');
        this.accountNumber = list[1];
        break;
      case('CA'):
        this.caName = list[0];
        this.accountNumber = list[1];
        break;
      case('AU'):
        this.bsb = list[0].replace('BSB: ', '');
        this.accountNumber = list[1];
        break;
      // case('GB'):
      //   this.gbCode = list[0].replace('Sort code: ', '');
      //   this.accountNumber = list[1];
      //   break;
      default:
        const str = apiModel.title;
        const bic = /BIC: (\w+)/;
        const iban = /IBAN: (\w+)/;
        const accountNumber = /Account number: (\w+)/;

        this.iban = str.match(iban) && str.match(iban)[1] ? str.match(iban)[1] : null;
        this.accountNumber = str.match(accountNumber) && str.match(accountNumber)[1] ? str.match(accountNumber)[1] : null;
        this.bic = str.match(bic) && str.match(bic)[1] ? str.match(bic)[1] : null;
    }
    return this;
  }
  getApiModel(data: any): any {
    const model: any = {
      bank: data.bank,
      accountNumber: data.accountNumber ? data.accountNumber : null,
      withdraw: data.withdraw,
      bic: data.bic ? data.bic : null,
      iban: data.iban ? data.iban : null
    };
    return model;
  }

  get ABA():string {
    return this.usAba;
  }
  get IBAN():string {
    return this.iban;
  }
  get BSB():string {
    return this.bsb;
  }
  get BIC():string {
    return this.bic;
  }
  get BankName():string {
    return this.caName;
  }
  get AccountNumber(): string {
    return this.accountNumber;
  }
}
