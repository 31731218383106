import {SongModel} from './song.model';
import * as moment from 'moment';
import {DEFAULT_SONG_IMG} from '../common/constants_IMG';
import {ArtistModel} from './artist.model';
import {PledgesModel} from './pledges.model';
import {IOfferTeaser} from './collectible.model';
import { decimalType } from '../types/common';
import {IProjectRoute} from '../pages/project/project-interface';
import {PriceForShare} from '@app/types';
import {IProjectDetails} from "@app/interfaces/project.interface";
import {iFrameLink} from "@app/pages/project/type/iframe-link";

type artistCategory = 'diy' | 'grs' | 'exclusive';

interface IReadMoreContent {
  imgReadMore?: string;
  text?: string;
  title?: boolean;
}

interface sLinkInterface {
  id?: string;
  title?: string;
  link?: [{ [key: string]: string }];
  content?: string;
}

export class ProjectsModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: any;
  id: string;
  _id: string;
  slug: string;
  image: string;
  totalPledged: number;
  artist: ArtistModel;
  readMoreContent: IReadMoreContent;
  song: SongModel;
  releaseDate: any;
  progressBar: number;
  share: number | string;
  soundtrack: string;
  projectTitle: string;
  genre: string;
  daysLeft: number;
  biography: string;
  spotifyUser: string;
  video: string;
  totalInvestedPercents: number;
  myOffersDetails?: string[];
  totalInvestorsLeft?: number;
  totalInvestorsShares?: number;
  priceForShare: PriceForShare;
  pledge: any;
  myProjectDescription: string;
  isFeatured: string;
  songIsPlaying = false;
  royalties: string;
  isFunded: boolean;
  isSold: boolean;
  songs: any;
  albumStatus: string;
  isFailed: boolean;
  statusCard: string;
  royaltiesFromMaster: boolean;
  royaltiesFromPublishingRights: boolean;
  instagramFollowers: any;
  youtubeFollowers: any;
  twitterFollowers: any;
  teaser: string;
  seo: any;
  country: string;
  isBundle: boolean;
  cardDescription: string;
  amount: number;
  projectEndDate: moment.Moment;
  projectStartDate: moment.Moment;
  details: IProjectDetails;
  spotifyFollowers: any;
  projectStartTime: any;
  facebookFollowers: any;
  socialrow = false;
  cardColor: string;
  decimalType: decimalType;
  keepItAll: boolean;
  albumSongsLength: number;
  backgroundImage: string;
  salePricePerShare: number | null;
  cardArtistTitle: string;
  offerTeaser: IOfferTeaser[];
  datePlannedRelease: string;
  artistCategory: artistCategory;
  shareRefund: number | string;
  releaseCreated: boolean;
  displayShare: number;
  publish_year: string;
  sLink: sLinkInterface;
  releaseCreatedDate: any;
  enableLogo: boolean;
  pricePerShare: number;
  isRefund: boolean;
  saleAmount: number;
  isAlbum: boolean;

  setProjectPledgeModel(apiModel: any, currency: string): this {
    Object.assign(this, apiModel);
    this.amount = apiModel.amount ? apiModel.amount : 0;
    this.artist = apiModel.artist ? new ArtistModel().setFromApiModel(apiModel.artist) : null;
    this.id = apiModel._id;
    this.isFunded = !!apiModel.isFunded;
    if (apiModel.pledges) {
      this.pledge = {
        refund: apiModel.pledges.refund ? apiModel.pledges.refund : null,
        own: apiModel.pledges.own ? new PledgesModel().setFromApiModel(apiModel.pledges.own) : null,
        list: apiModel.pledges.list ? apiModel.pledges.list.map(item => new PledgesModel().setFromApiModel(item)) : null,
        ownMerged: apiModel.pledges.ownMerged ? new PledgesModel().setFromApiModel(apiModel.pledges.ownMerged) : null,
        gift: apiModel.pledges.gift ? apiModel.pledges.gift.map(item => new PledgesModel().setFromApiModel(item)) : null
      };
    }
   // this.projectTitle = apiModel.projectTitle;
    this.releaseDate = apiModel.releaseDate && apiModel.releaseDate.date ? moment(apiModel.releaseDate.date, 'YYYY/MM/DD') : null;
    this.royaltiesFromMaster = apiModel.royaltiesFromMaster;
    this.royaltiesFromPublishingRights = apiModel.royaltiesFromPublishingRights;
    this.share = Number(apiModel.share).toFixed(2);
   // this.totalPledged = apiModel.totalPledged;
   // this.songs = apiModel.songs;
    const royalties = [];
    if (apiModel.royaltiesFromMaster) {
      royalties.push('Master');
    }
    if (apiModel.royaltiesFromPublishingRights) {
      royalties.push('Publishing');
    }

    this.isRefund = !!apiModel.isRefund;
    this.royalties = royalties.join(' & ') + ' Rights';
    this.song = apiModel.song ? new SongModel().setFromApiModel(apiModel.song) : null;
   // this.totalInvestorsShares = apiModel.totalInvestorsShares;
    this.shareRefund = Number(apiModel.shareRefund).toFixed(2);
    this.pricePerShare = apiModel.priceForShare ? apiModel.priceForShare[currency] : 0;

    this.salePricePerShare = apiModel.saleAmount ? this.pricePerShare - (apiModel.saleAmount * this.pricePerShare / 100) : null;

    return this;
  }

  setFromFullApiModel(apiModel: any, currency: string): this {
    Object.assign(this, apiModel);
    const offerTeaser = apiModel.offerTeaser ? apiModel.offerTeaser.map(item => ({
      ...item,
      icon: item.icon ? `${this.imgUrl}/${item.icon}` : ''
    })) : null;

    this.offerTeaser = offerTeaser;
    this.amount = apiModel.amount ? apiModel.amount : 0;
    this.publish_year = '2024';
    if (apiModel.readMoreContent && apiModel.readMoreContent.imgReadMore) {
      this.readMoreContent = {
        ...apiModel.readMoreContent,
        imgReadMore: `${this.imgUrl}/${apiModel.readMoreContent.imgReadMore}`
      };
    } else {
      this.readMoreContent = null;
    }
    if (apiModel.projectDurationTime?.endDate) {
      this.projectEndDate = moment(apiModel.projectDurationTime.endDate);
    }
    if (apiModel.projectDurationTime?.startDate) {
      this.projectStartDate = moment(apiModel.projectDurationTime.endDate);
    }

    // this.projectDateEnd = moment(apiModel.projectStartDate, 'MM/DD/YYYY').add(apiModel.projectDuration, 'days');

    this.projectStartTime = moment(apiModel.projectDurationTime ? apiModel.projectDurationTime.startDate : apiModel.projectStartTime)
      .format('x');

    const isComponentImage = apiModel.image && apiModel.image.includes('project-component-images');
    const isRegularImage = apiModel.image && apiModel.image.includes('images');

    if (apiModel.image) {
      this.image = isComponentImage || isRegularImage
        ? `${this.imgUrl}/${apiModel.image}`
        : `${this.imgUrl}/images/${apiModel.image}`;
    } else {
      this.image = DEFAULT_SONG_IMG;
    }

    this.albumSongsLength = apiModel.songs ? apiModel.songs.length : 0;
    this.backgroundImage = apiModel.backgroundImage ? `${this.imgUrl}/${apiModel.backgroundImage}` : null;
    const artistShare = 100 - apiModel.totalInvestorsShares;
    this.progressBar = apiModel.totalInvestedPercents ? ((apiModel.totalInvestedPercents + artistShare) / 100) * 100 : artistShare;
    this.artist = apiModel.artist ? new ArtistModel().setFromApiModel(apiModel.artist) : null;
    this.song = apiModel.song ? new SongModel().setFromApiModel(apiModel.song) : null;
    // this.daysLeft = apiModel.daysLeft;
    this.releaseDate = apiModel.releaseDate && apiModel.releaseDate.date ? moment(apiModel.releaseDate.date, 'YYYY/MM/DD') : null;
    this.myOffersDetails = apiModel.myOffersDetails ? apiModel.myOffersDetails.split('\n') : [];
    this.totalInvestorsLeft = apiModel.totalInvestorsLeft ? apiModel.totalInvestorsLeft : 0;

    /*TODO use appropriate currency eur or usd for perShare */

    this.pricePerShare = apiModel.priceForShare[currency];
    this.salePricePerShare = apiModel.saleAmount ? this.pricePerShare - (apiModel.saleAmount * this.pricePerShare / 100) : null;


    this.isFunded = !!apiModel.isFunded;
    this.twitterFollowers = apiModel.twitterCounts;
    this.spotifyFollowers = apiModel.spotifyCounts;

    this.instagramFollowers = apiModel.instagramCounts;
    this.facebookFollowers = apiModel.facebookCounts;
    const royalties = [];
    if (apiModel.royaltiesFromMaster) {
      royalties.push('Master');
    }
    if (apiModel.royaltiesFromPublishingRights) {
      royalties.push('Publishing');
    }
    this.royalties = royalties.join(' & ') + ' Rights';
    this.isFailed = !this.isFunded && this.daysLeft < 0 && !this.isSold;
    if (apiModel.releaseCreated) {
      this.releaseCreated = apiModel.releaseCreated;
    }

    if (apiModel.releaseCreatedDate) {
      this.releaseCreatedDate = apiModel.releaseCreatedDate;
    }
    return this;
  }

  routeProjectModel(): IProjectRoute {
    const royalties = [];
    if (this.royaltiesFromMaster) {
      royalties.push('Master');
    }
    if (this.royaltiesFromPublishingRights) {
      royalties.push('Publishing');
    }
    return {
      slug: this.slug,
      id: this._id,
      royalties: royalties.join(' & ') + ' Rights',
      keepItAll: this.keepItAll,
      royaltiesFromMaster: this.royaltiesFromMaster,
      royaltiesFromPublishingRights: this.royaltiesFromPublishingRights,
      isAlbum: this.isAlbum,
      artist: this.artist,
      projectTitle: this.projectTitle,
      song: this.song,

    };
  }
}
