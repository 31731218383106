import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject, Subscriber, of } from 'rxjs';
import {
  takeUntil,
  tap,
  switchMap,
  map,
} from 'rxjs/operators';
import * as moment from 'moment';
import { StreamState } from '../models/stream-state.model';

interface MediaStreamState {
  playing: boolean;
  readableCurrentTime: string;
  readableDuration: string;
  duration: number;
  currentTime: number;
  progress: number;
  canplay: boolean;
  error: boolean;
  volume: number;
}

const MEDIA_EVENTS: string[] = [
  'ended',
  'error',
  'play',
  'playing',
  'pause',
  'timeupdate',
  'canplay',
  'loadedmetadata',
  'loadstart',
];

const DEFAULT_STATE: MediaStreamState = {
  playing: false,
  readableCurrentTime: '',
  readableDuration: '',
  duration: undefined,
  currentTime: undefined,
  progress: 0,
  canplay: false,
  error: false,
  volume: 50,
};

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  private stop$ = new Subject();
  private videoObj: HTMLVideoElement = null;
  private state: MediaStreamState = { ...DEFAULT_STATE };
  private handlerFn: Function;
  private playPromise: Promise<any> = null;
  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(
    this.state
  );
  private currentVideo = new BehaviorSubject(null);
  public state$: Observable<StreamState> = this.stateChange.asObservable();
  private streamObservable(video: HTMLVideoElement): Observable<Event> {
    let startStreamAfter$: Observable<boolean>;

    // Check for prev stream is already running & clean listeners
    if (this.videoObj) {
      startStreamAfter$ = this._stop$().pipe(
        tap(() => {
          this.unsubscribeStreamFn();
        })
      );
      // Sync set new stream
    } else {
      startStreamAfter$ = of(true);
    }

    return startStreamAfter$.pipe(
      tap(() => {
        this.videoObj = video;
        this.videoObj.load();
      }),
      switchMap(() => {
        return new Observable((observer: Subscriber<Event>) => {
          this.handlerFn = this._handlerFn.bind(this, observer);
          this.addEventListeners();
          this.playPromise = this.videoObj.play().catch((e) => {
            if (this.videoObj) {
              this.unsubscribeStreamFn.bind(this)();
            }
            observer.complete();
          });

          return this.unsubscribeStreamFn.bind(this);
        });
      })
    );
  }

  private unsubscribeStreamFn(): void {
    this.videoObj.pause();
    this.removeEventListeners();
    this.resetState();
  }

  private _handlerFn(subscriber: Subscriber<Event>, event: Event): void {
    this.updateStateEvents(event);
    subscriber.next(event);
  }

  private addEventListeners(): void {
    MEDIA_EVENTS.forEach((event) => {
      this.videoObj.addEventListener(event, this.handlerFn as any);
    });
  }

  private removeEventListeners(): void {
    MEDIA_EVENTS.forEach((event) => {
      this.videoObj.removeEventListener(event, this.handlerFn as any);
    });
  }

  public play(video: HTMLVideoElement) {
    return this.streamObservable(video).pipe(takeUntil(this.stop$));
  }

  public stop() {
    this.videoObj.pause();
  }

  public servicePlay(video: HTMLMediaElement): void {
    this.currentVideo.next(video);
  }

  public serviceReset(): void {
    this.currentVideo.next(null);
  }

  public getCurrentVideo(): HTMLMediaElement {
    return this.videoObj;
  }

  private _stop$(): Observable<boolean> {
    if (this.playPromise !== null) {
      return of(this.playPromise).pipe(
        map(() => {
          return true;
        })
      );
    } else {
      return of(true);
    }
  }

  reset() {
    this.resetState();
  }

  private resetState() {
    this.state = { ...DEFAULT_STATE };
  }

  seekTo(seconds) {
    this.videoObj.pause();
    this.videoObj.currentTime = seconds;
    this.videoObj.play();
  }

  formatTime(time: number, format: string = 'mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }

  volumeChange(event) {
    const skipTo = Math.round(
      (event.offsetX / event.target.clientWidth) *
        parseInt(event.target.getAttribute('max'), 10)
    );
    const volume = (1 * skipTo) / 100;
    this.state.volume = volume * 100;
    this.videoObj.volume = volume;
  }

  private updateStateEvents(event: Event): void {
    if (!this.videoObj) return;
    switch (event.type) {
      case 'canplay':
        this.state.duration = this.videoObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        break;
      case 'ended':
        this.state.playing = false;
        break;
      case 'timeupdate':
        this.state.currentTime = this.videoObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(
          this.state.currentTime
        );
        const progress =
          (this.videoObj.currentTime * 100) / this.state.duration;
        this.state.progress = Number(progress)
          ? progress > 100
            ? 100
            : progress
          : 0;
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
    }
    this.stateChange.next(this.state);
  }

  getState(): Observable<any> {
    return this.state$;
  }
}
