import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {ProjectsModel} from "@app/models";
import {PROJECT_TYPE} from "@app/common/content/artist-category";


@Directive({
  selector: '[appExclusiveSection]'
})
export class ExclusiveSectionDirective  {

  private hasView = false;

  @Input() set appExclusiveSection(project: ProjectsModel) {
    const condition = project.artistCategory === PROJECT_TYPE.exclusive;
    if (condition && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!condition && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}
}
