import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserModel } from '../../models';
import { ProfileService } from '../../services';
import { NUMBER_LENGTH_SIX, TFA } from '../../common';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-tfa',
  templateUrl: './tfa.component.html',
  styleUrls: ['./tfa.component.scss'],
})
export class TfaComponent implements OnInit {
  submitTFA: boolean;
  tfa = {} as TFA;
  openSettings: boolean;
  formAuth: FormGroup;
  authCodeCtrl: FormControl;
  apiErrorMessage: string;
  @Input() user: UserModel;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private profile: ProfileService, private fb: FormBuilder) {}

  ngOnInit() {
    this.authCodeCtrl = this.fb.control('', {
      validators: [Validators.required, Validators.pattern(NUMBER_LENGTH_SIX), Validators.minLength(6), Validators.maxLength(6)],
      updateOn: 'change',
    });
    this.formAuth = this.fb.group({
      code: this.authCodeCtrl,
    });
    this.tfa = this.user.tfa;
  }

  openSection(r) {
    this.openSettings = !this.openSettings;
  }

  setupTFA(e): void {
    const ENABLE = !e.checked;
    if (ENABLE) {
      if (
        !this.user.tfa ||
        (this.user.tfa && !this.user.tfa.enabled && !this.user.tfa.verified)
      ) {
        this.profile.setupAuth().subscribe((data) => {
          this.tfa = data;
          this.tfa.enabled = true;
        });
      }
    } else {
      if (!this.user.tfa.verified) {
        this.profile._removeTFA(null).subscribe((data) => {
          this.submitTFA = false;
          this.user.tfa = null;
          this.tfa = null;
          this.formAuth.reset();
        });
      }
    }
  }

  verifyTFA(): void {
    this.apiErrorMessage = null;
    this.submitTFA = true;
    if (this.authCodeCtrl.valid) {
      this.submitTFA = false;
      if (this.tfa?.verified) {
        this.profile._removeTFA(this.authCodeCtrl.value.trim()).subscribe(
          (data) => {
            if (data) {
              this.user.tfa = null;
              this.tfa = null;
            }
            this.formAuth.reset();
          },
          (err) => {
            this.authCodeCtrl.setErrors({ incorrect: true });
            this.apiErrorMessage =
              err && err.error ? err.error.message : 'Code is not valid';
          }
        );
      } else {
        this.profile._enableTFA(this.authCodeCtrl.value).subscribe(
          (data) => {
            this.user.tfa = this.user.tfa || {};
            this.user.tfa.verified = true;
            this.tfa.verified = true;
            this.formAuth.reset();
          },
          (err) => {
            this.authCodeCtrl.setErrors({ incorrect: true });
            this.apiErrorMessage =
              err && err.error ? err.error.message : 'Code is not valid';
          }
        );
      }
    } else {
      this.apiErrorMessage = 'Code is not valid';
    }
  }
}
