<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'new'">
    <div *ngIf="!successMessage else message" class="subscribe--new">
      <h3 class="subscribe-title">Join our Newsletter</h3>
      <input
        type="text"
        placeholder="Enter email address"
        class="subscribe__input"
        [formControl]="emailCtrl"
      />
      <button
        class="button yellow text-uppercase subscribe__button"
        (click)="onSubmit()"
        [disabled]="!emailCtrl?.value || submitPressed"
      >
        Sign up now
      </button>
      <div class="subscribe__text">
        <span>By submitting this form, I agree to the </span
        ><a
          class="link-blue"
          [grsOpenFile]="'terms'"
          href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1683560339366-NUTZUNGSBEDINGUNGEN---TERM-OF-USE-German-English-Update_-May-23-2.pdf"
          >Terms & Conditions</a
        >
        and
        <a
          class="link-blue"
          [grsOpenFile]="'dataPolicy'"
          href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658137131511-Data_and_privacy_policy.pdf"
          >Privacy Policy.</a
        >
      </div>
    </div>
    <ng-template #message>
      <div class="subscribe__success">
        <img src="assets/images/icons-common/icon-check-green.png" alt="icon" />
        <p>You’re all set!</p>
      </div>
    </ng-template>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <h3 class="subscribe-title">
      <span #ref><ng-content></ng-content></span>
      <ng-container *ngIf="!ref.hasChildNodes()">
        Don’t miss out on <span>investment opportunities!</span>
      </ng-container>
    </h3>
    <div class="subscribe__button-wrapper">
      <input
        type="text"
        placeholder="Enter email address"
        class="subscribe__input"
        [formControl]="emailCtrl"
      />
      <button
        class="button yellow text-uppercase subscribe__button"
        (click)="onSubmit()"
        [disabled]="!emailCtrl?.value || submitPressed"
      >
        Sign up now
      </button>
    </div>
    <div class="subscribe__text">
      <span>By submitting this form, I agree to the Global Rockstar </span
      ><a
        class="link-blue"
        [grsOpenFile]="'terms'"
        href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1683560339366-NUTZUNGSBEDINGUNGEN---TERM-OF-USE-German-English-Update_-May-23-2.pdf"
        >Terms & Conditions</a
      >
      and
      <a
        class="link-blue"
        [grsOpenFile]="'dataPolicy'"
        href="https://d1mfwo6pcdbmd.cloudfront.net/documents/1658137131511-Data_and_privacy_policy.pdf"
        >Privacy Policy.</a
      >
    </div>
  </ng-container>
</ng-container>
