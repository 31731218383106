<div class="timer"
     [class.cardBlack]="enableTimer"
     [class.defaultGrey]="config?.smallTimer"
>
  <div class="timer__title">
    <ng-template [ngIf]="project?.isCollectible">
      {{project?.projectNotStarted ? 'AUCTION STARTS IN' : 'AUCTION ENDS IN'}}
    </ng-template>
    <ng-template [ngIf]="!project?.isCollectible">
      OPPORTUNITY ENDS IN
    </ng-template>
  </div>
  <div class="timer__timing">
    <div class="timer__timing-item days" [style.backgroundColor]="project?.cardColor">
      <span *ngIf="project?.daysLeft >= 1">
        {{project?.daysLeft < 10 ? '0' + (project?.daysLeft - 1) : (project?.daysLeft - 1)}}
      </span>
      <span *ngIf="project?.daysLeft < 1">
        00
      </span>
    </div>
    <div class="timer__timing-item hours"
         [style.backgroundColor]="project?.cardColor">{{displayHours | async}}
    </div>
    <div class="timer__timing-item minutes"
         [style.backgroundColor]="project?.cardColor">{{displayMinutes | async}}
    </div>
    <div class="timer__timing-item seconds"
         [style.backgroundColor]="project?.cardColor">{{displaySeconds | async}}
    </div>
  </div>
  <div class="timer__title" *ngIf="!enableTimer">
    <ng-template [ngIf]="project?.isCollectible">
       {{ project?.projectNotStarted ? project?.projectStartDate : project?.projectDateEnd | momentDateFormat: '' : 'DD MMM YYYY, HH:mm'}} {{'timeZone' | timeZone}}
    </ng-template>
    <ng-template [ngIf]="!project?.isCollectible">
       {{ project?.projectEndDate | momentDateFormat: '' : 'DD MMM YYYY, HH:mm'}} {{'timeZone' | timeZone}}
    </ng-template>

  </div>
</div>
