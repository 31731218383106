import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

export class CustomFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private customControl?: FormControl,
              private isSubmitted?: any,
              private error?: boolean) {
  }

  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (!this.customControl) {
      return false;
    } else if (this.error !== undefined) {
      return this.customControl && (this.customControl.invalid || this.error) && this.isSubmitted;
    } else {
      return this.customControl && this.customControl.invalid && this.isSubmitted;
    }
  }
}
