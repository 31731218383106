<div class="inner-card" #myDiv>
  <div
    class="card"
    [class.flipped]="toggleProperty"
    #myCard
    id="{{this.index + 'card'}}"
  >
    <div class="front" #frontCard id="{{index}}">
      <div class="card-image-wrapper">
        <img
          class="card__background"
          loading="lazy"
          [alt]="'image of' + song?.name"
          [src]="song?.nft.imageURL"
        />
      </div>
      <a class="default-link link-block card-outer">
        <header-card [project]="item"></header-card>
        <div class="card__content">
          <div class="play-button" (click)="playVideo(item)">
            <i class="fas fa-play"></i>
          </div>
        </div>
        <div class="card__name">
          <div>
            <h2 class="card__title-h2 card__name--padding">
              {{item?.artist?.name}}
            </h2>
            <h3 class="card__title-h3 card__name--padding">
              {{item?.isAlbum?item?.projectTitle: song?.name }}
            </h3>
            <h4
              *ngIf="item?.isAlbum"
              class="card__title-h4 card__name--padding"
            >
              {{song?.name}}
            </h4>
          </div>
          <div
            *ngIf="item?.isAlbum && item?.songs?.length > 1"
            class="track-numbers"
          >
            Track: {{song?.orderId}} of {{item?.songs?.length}}
          </div>
        </div>
        <div class="card__footer">
          <div
            class="headliner__icons footer-text"
            *ngIf="item?.royaltiesFromMaster || item?.royaltiesFromPublishingRights"
          >
            <div class="icon-disk" *ngIf="item?.royaltiesFromMaster">
              <app-tooltip
                class="tooltip-component"
                [img]="'./assets/images/cards/disk.svg'"
              >
                <div img-content>
                  <img loading="lazy"
                       alt="logo tooltip" [src]="'./assets/images/cards/disk.svg'">
                </div>
                <div text-content>
                  Master Rights: You will participate in the commercial
                  exploitation of the Master Rights for 70 years. Every time the
                  track is streamed, downloaded, licenced to TV, Film or Ads or
                  compiled on a physical product (CD, Vinyl) you will earn
                  proceeds.
                  <br /><br />
                  <a
                    class="tooltip-link"
                    (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                  >Learn more</a
                  >
                </div>



              </app-tooltip>
            </div>
            <div
              class="icon-melody"
              *ngIf="item?.royaltiesFromPublishingRights"
            >
              <app-tooltip
                [img]="'./assets/images/cards/melody.svg'"
                class="tooltip-component"
              >
                <div img-content>
                  <img loading="lazy"
                       alt="logo tooltip" [src]="'./assets/images/cards/melody.svg'">
                </div>
                <div text-content>
                  Publishing Rights: You will participate in the commercial
                  exploitation of the Publishing Rights. You will earn money every
                  time the work (lyrics & composition) is played in radio, TV,
                  live or licenced to film or advertisements.
                  <br /><br />
                  <a
                    class="tooltip-link"
                    (click)="goToLink($event, STATE_NAMES?.howItWorks)"
                  >Learn more</a
                  >
                </div>

              </app-tooltip>
            </div>
            <span class="rights-text" *ngIf="item?.royalties"
              >{{item?.royalties}}</span
            >
          </div>
          <div class="headliner-project">
            <div
              class="price__per-share card__end"
              [style.backgroundColor]="item?.cardColor"
            >
              {{song?.shares | number: '.2-2'}}%
            </div>
          </div>
        </div>
      </a>
    </div>
    <div
      class="back"
      (mouseenter)="showPause = true"
      (mouseleave)="showPause = false"
    >
      <ng-container *ngIf="song?.nft">
      <div class="card__content">
        <div
          class="play-button"
          *ngIf="showPause && (currentPlayingVideo?.buffered || isGif(song.nft.videoURL))"
          (click)="pauseVideo()"
        >
          <i class="fas fa-stop"></i>
        </div>
      </div>

      <ng-container *ngIf="isGif(song.nft.videoURL)">
        <img class="image-gif"
             [src]="song.nft.videoURL"
        />
      </ng-container>
      <ng-container *ngIf="!isGif(song.nft.videoURL)">
      <video
        class="video"
        (ended)="pauseVideo($event)"
        (playing)="onPlayingVideo($event, item)"
        (play)="onPlayingVideo($event, item)"
        preload="auto"
        #myVideo
        [id]="index + 'video'"
        width="310px"
        height="473px"
      >
        <source
          [src]="song.nft.videoURL"
          [type]="'video/'+ getVideoFormat(song.nft.videoURL)"
        />
      </video>
      </ng-container>
      </ng-container>
    </div>
  </div>
</div>
