import {
  Component, ElementRef, EventEmitter, ChangeDetectionStrategy, Inject,
  Input, OnInit, Output, PLATFORM_ID, ViewChild
} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {STATE_NAMES} from '../../../common';
import {Router} from '@angular/router';
import {ProjectsService} from '../../../services/projects.service';

@Component({
  selector: 'app-card-collectible',
  templateUrl: './card-collectible.component.html',
  styleUrls: ['./card-collectible.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CardCollectibleComponent implements OnInit {
  toggleProperty: boolean;
  config = {
    colorTimer: '',
  };
  selectedProject: any;
  currentPlayingVideo: HTMLVideoElement;
  videoObj: {
    video: HTMLVideoElement,
    card: any,
    status: string,
    index: number,
    front: HTMLElement,
  };
  showPause: boolean = false;
  @Input() user;
  @Input() item;
  @Input() editionIndex = 0;
  @Input() showArrows;
  @Input() index;
  @Input() enableTimer;
  @Input() isProject;
  @Input() exportItem: boolean;
  currentDate: Date = new Date();
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() videoObject: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myCard') myCard: ElementRef;
  @ViewChild('frontCard') frontCard: ElementRef;
  @ViewChild('myVideo') myVideo: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private projectService: ProjectsService,
              private router: Router) {
  }

  ngOnInit(): void {
    if (this.item) {
      this.config.colorTimer = this.item.cardColor;
    }
    if (this.item && !this.item.editionNumber && this.item.internalNumbers) {
      this.item.editionNumber = this.item.internalNumbers[this.editionIndex];
    }
  }

  calculateTimeDistance(): number {
    // Set Austrian time
    const countDownDate = new Date().setUTCHours(21, 59, 59, 999);
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    return countDownDate - now;
  }

  playVideo(item): void {
    this.projectService.getVideoUrl(item.slug, item.isCollectible).subscribe(resp => {
      if (resp.data.videoNFT) {
        item.video = `${resp.imgURL}/${resp.data.videoNFT}`;
      }

      this.flipCard();

      // let card = document.getElementById(this.index + 'card');
      const card = this.myCard.nativeElement;
      card.setAttribute('class', 'card flipped');

      // this.currentPlayingVideo = <HTMLVideoElement>document.getElementById(item.id + 'video');
      this.currentPlayingVideo = this.myVideo.nativeElement;
      this.currentPlayingVideo.setAttribute('playsinline', '1');

      if (this.iOSversion()) {
        // let front = <HTMLElement>document.getElementById(this.index);
        const front = this.frontCard.nativeElement;
        front.setAttribute('style', 'opacity:0');
      }

      this.videoObj = {
        video: this.currentPlayingVideo,
        card: item,
        status: 'play',
        index: this.index,
        front: this.myCard.nativeElement
      };
      this.videoObject.emit(this.videoObj);
    });
  }


  changeEditionNumber(index) {
    const value = this.item.internalNumbers.length - 1;
    if (index < value) {
      this.editionIndex++;
    } else {
      this.editionIndex = 0;
    }
    this.item.editionNumber = this.item.internalNumbers[this.editionIndex];
  }

  flipCard() {
    const cards = document.querySelectorAll('.card');
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].classList.contains('flipped')) {
        cards[i].classList.remove('flipped');
        if (this.iOSversion()) {
          cards[i].firstElementChild.setAttribute('style', 'opacity:unset');
        }
      }
    }
  }

  goToLink(e, link): void {
    e.preventDefault();
    this.router.navigate([`/${STATE_NAMES.howItWorks}`]);
  }

  onPlayingVideo(event, item) {
    event.preventDefault();
  }

  pauseVideo(item?, i?, div?) {
    this.currentPlayingVideo.currentTime = 0;

    this.videoObj = {
      video: this.currentPlayingVideo,
      card: item,
      status: 'stop',
      index: this.index,
      front: this.myCard.nativeElement
    };
    this.videoObject.emit(this.videoObj);

    if (this.iOSversion()) {
      if (i) {
        const front = div.firstElementChild;
        front.setAttribute('style', 'opacity:unset');
      }
      const front = this.videoObj.front.firstElementChild;
      front.setAttribute('style', 'opacity:unset');
    }

    if (i) {
      const card = div;
      card.setAttribute('class', 'card');
    }
    const card = this.videoObj.front;
    card.setAttribute('class', 'card');
  }


  getSongPlaying(e) {
    if (this.selectedProject.publishedUpcoming) {
      this.sendStatistic(e.project, e.value);
    }
  }

  iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
      const ver = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || (0 as any), 10)];
      if (ver[0] < 15) {
        return true;
      }
      else {
        return false;
      }
    }
  }

  sendStatistic(slugProject: string, key: string) {
    const type = {};
    type[key] = true;
    this.projectService.becomeUpcomingInvestor(slugProject, type).subscribe(resp => {
    });
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }

    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard && !this.isProject) {
        const name = e.target.className;
        if (name.indexOf('fas') && name.indexOf('play-button')) {
          this.router.navigate([project.isCollectible ?
            STATE_NAMES.collectible : `/${STATE_NAMES.projectsItem}`, project.slug]);
        }
      }
    }
  }
}
