<app-popup>
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="title">Voucher</div>
    <form class="form" [formGroup]="form">
      <mat-form-field class="item">
        <mat-label>Voucher code</mat-label>
        <input matInput [formControl]="codeVoucherCtrl" [errorStateMatcher]="errorMatcher(codeVoucherCtrl)" type="text">
      </mat-form-field>
    </form>

    <div class="buttons-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">Cancel</button>
      <button class="button yellow text-uppercase" (click)="onSubmit()" [disabled]="!form.valid || submitPressed">
        Validate
      </button>
    </div>
  </div>
</app-popup>
