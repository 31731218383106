import {Component, Output, EventEmitter, HostListener, Input, ChangeDetectionStrategy} from '@angular/core';

@Component({
  selector: 'app-popup-part-header',
  templateUrl: './popup-part-header.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class PopupPartHeaderComponent {
  user: any;
  text: any;
  @Input() icon: string;
  @Input() hideClose: boolean;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor() {
  }

  onClose(): void {
    this.notify.emit();
  }
}
