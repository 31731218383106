
import {DEFAULT_SONG_IMG} from '../common/constants_IMG';
import {SongModel} from './song.model';
import {FansModel} from './fans.model';


export class UpcomingProjectModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: any;
  artist: any;
  soundtrack: string;
  comment: string;
  description: string;
  publishedUpcoming: string;
  songLink: any;
  isEmptyItem: boolean;
  status: string;
  image: string;
  slug: string;
  upcomingProject: any;
  id: string;
  song: SongModel;
  genre: any;
  fans: any[];
  isUpcoming: boolean;
  fansCount: number;
  biography: string;
  myProjectDescription: string;
  statistic: {
    wouldInvest: number,
    becomeFan: number,
    playMusic: number
  };
  activeUpcoming: boolean;
  audio: string;
  publisher: string;
  songName: string;
  audioUrl: string;
  imageShort: string;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel._id;
    this.artist = apiModel.artist;
    this.fans = [];
    this.status = apiModel.status;
    this.songName = apiModel.songName;
    this.description = apiModel.description;
    if (apiModel.audio) {
      this.soundtrack = `project-soundtracks/${apiModel.audio}`;
    } else if (apiModel.upcomingProject && apiModel.upcomingProject.audio) {
      this.soundtrack = `project-soundtracks/${apiModel.upcomingProject.audio}`;
    }
    this.audioUrl = `${this.imgUrl}/project-soundtracks/${apiModel.audio}`;
    this.publishedUpcoming = apiModel.publishedUpcoming;
    this.isUpcoming = true;
    this.audio = apiModel.audio;

    this.upcomingProject = apiModel.upcomingProject;
    this.imageShort = apiModel.upcomingProject ? apiModel.upcomingProject.photo : null;
    if (apiModel.upcomingProject && apiModel.upcomingProject.photo) {
      this.image = apiModel.upcomingProject.photo.indexOf('project-component-images') >= 0 ? `${this.imgUrl}/${apiModel.upcomingProject.photo}` : `${this.imgUrl}/images/${apiModel.upcomingProject.photo}`;
    } else {
      this.image = DEFAULT_SONG_IMG;
    }

    if (apiModel.upcomingProject) {
      this.fansCount = apiModel.upcomingProject.artist.fans ? apiModel.upcomingProject.artist.fans.length : 0;
      this.slug = apiModel.upcomingProject.slug;
      this.publisher = apiModel.upcomingProject.publisher;
      this.biography = apiModel.upcomingProject.biography;
      this.myProjectDescription = apiModel.upcomingProject.aboutSong;
      this.statistic = apiModel.upcomingProject.statistic;
      const song = {
        name: apiModel.upcomingProject ? apiModel.upcomingProject.songName : null,
        producer: apiModel.upcomingProject ? apiModel.upcomingProject.producer : null,
        label: apiModel.upcomingProject.label ? apiModel.upcomingProject.label : null,
        genre: apiModel.upcomingProject.genresPlay ? apiModel.upcomingProject.genresPlay : null
      };
      this.song = new SongModel().setFromApiModel(song);
      this.genre = apiModel.upcomingProject.genresPlay ? apiModel.upcomingProject.genresPlay : null;
      if (apiModel.upcomingProject.artist.fans && apiModel.upcomingProject.artist.fans.length) {
        apiModel.upcomingProject.artist.fans.forEach((item) => {
          this.fans.push(new FansModel(this.imgUrl).setFromApiModel(item));
        });
      }
    }
    this.songLink = apiModel.songLink;

    return this;
  }

  setFromFullApiModel(apiModel: any): this {
    this.fans = [];
    this.artist = apiModel.artist;
    this.id = apiModel._id;
    this.status = apiModel.status;
    this.isUpcoming = true;
    this.soundtrack = apiModel.audio ? `project-soundtracks/${apiModel.audio}` : null;
    this.fansCount = apiModel.artist.fans ? apiModel.artist.fans.length : 0;
    this.slug = apiModel.slug;
    this.publisher = apiModel.publisher;
    this.activeUpcoming = true;
    this.biography = apiModel.biography;
    this.myProjectDescription = apiModel.aboutSong;
    this.statistic = apiModel.statistic;
    const song = {
      name: apiModel.songName ? apiModel.songName : null,
      producer: apiModel.producer ? apiModel.producer : null,
      label: apiModel.label ? apiModel.label : null,
      genre: apiModel.genresPlay ? apiModel.genresPlay : null
    };

    if (apiModel.photo) {
      this.image = apiModel.photo.indexOf('project-component-images') >= 0 ? `${this.imgUrl}/${apiModel.photo}` : `${this.imgUrl}/images/${apiModel.photo}`;
    } else {
      this.image = DEFAULT_SONG_IMG;
    }

    this.imageShort = apiModel.photo;
    this.song = new SongModel().setFromApiModel(song);
    this.genre = apiModel.genresPlay ? apiModel.genresPlay : null;
    if (apiModel.artist.fans && apiModel.artist.fans.length) {
      apiModel.artist.fans.forEach((item) => {
        this.fans.push(new FansModel(this.imgUrl).setFromApiModel(item));
      });
    }
    return this;
  }
}
