<div class="main-wrapper flex-align-column">
  <div [style.backgroundImage]="'url(assets/images/slider1.jpg)'" class="image-wrapper backgroundImage">
  </div>
  <main>
    <section class="section-image">
      <div class="flex-align section-image-wrapper">
        <div class="profile-img" [style.backgroundImage]="user?.image ? 'url('+ user?.image +')' : null" ></div>
        <div class="flex-align-column user-name">
          <div class="name title27 font-bold">My Opportunities</div>
          <div class="action-tab flex-align">
            <div class="flex-align action-tab-wrapper">
              <div class="item">
                <div class="text title14">Active opportunities</div>
                <div class="title28 font-bold value">{{count?.active}}</div>
              </div>
              <div class="item">
                <div class="text title14">Funded opportunities</div>
                <div class="title28 font-bold value">{{count?.funded}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-projects">
      <section class="tabs tabs-margin">
        <div class="tab-header">
          <div class="tab-item" [class.active]="activeTab === 3" (click)="setState(3)">
            Current<span></span></div>
          <div class="tab-item" [class.active]="activeTab === 4" (click)="setState(4)">
            Funded<span></span></div>
        </div>
      </section>
      <div class="spinner">
        <mat-spinner [diameter]="40" *ngIf="!loaded"></mat-spinner>
      </div>
      <div class="no-projects" *ngIf="originLength < 1 && loaded">You don't have opportunities yet</div>
      <app-music-item-list [loaded]="loaded" [projectList]="projectList"></app-music-item-list>
    </section>
  </main>
  <app-footer></app-footer>
</div>
