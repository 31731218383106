import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { Location } from '@angular/common';
import {
  FacebookLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

// Consts
import {
  AUTH_METHOD,
  EMAIL_PATTERN,
  NUMBER_LENGTH_NINE,
  PASSWORD_PATTERN_ANY,
} from 'app/common';

// Services
import { AuthenticationService } from '@services/authentication.service';
import { AppToastrService } from 'app/services';
import { AppleSignUpService } from '@services/apple-sign-up.service';


@UntilDestroy()
@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter();
  form: FormGroup;
  loginCtrl: FormControl;
  codeTFACtrl: FormControl;
  passwordCtrl: FormControl;
  submitPressed: boolean;
  showCodeField: boolean;
  AUTH_METHOD: any = AUTH_METHOD;
  isLoading: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private socialAuthService: SocialAuthService,
    private appleService: AppleSignUpService,
    private location: Location,
    private toastr: AppToastrService,
    private fb: FormBuilder
  ) {
    const cd_google =
      window.location.href.indexOf(this.AUTH_METHOD.GOOGLE) >= 0;
    const cd_apple = window.location.href.indexOf(this.AUTH_METHOD.APPLE) >= 0;
    if (cd_google) this.getSignInToken(this.AUTH_METHOD.GOOGLE);
    if (cd_apple) this.getSignInToken(this.AUTH_METHOD.APPLE);
  }

  ngOnInit(): void {
    this.loginCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN),
    ]);
    this.passwordCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(PASSWORD_PATTERN_ANY),
    ]);
    this.codeTFACtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(NUMBER_LENGTH_NINE),
    ]);
    this.form = this.fb.group({
      login: this.loginCtrl,
      codeTFA: this.codeTFACtrl,
      password: this.passwordCtrl,
    });
    this.codeTFACtrl.disable();
  }

  submit(): void {
    this.submitPressed = true;
    if (this.form.valid) {
      this.authenticationService
        .getToken({
          email: this.loginCtrl.value.trim(),
          password: this.passwordCtrl.value.trim(),
          code: this.codeTFACtrl.value.trim(),
        })
        .subscribe(
          (data) => {
            if (data.requiredCodeTFA) {
              this.showCodeField = true;
              this.codeTFACtrl.enable();
            } else {
              this.onSuccess.emit();
            }
          },
          () => {
            this.toastr.showErrorToast({
              message:
                'Your email address or password is incorrect" please try again.',
            });
          }
        );
    }
  }

  submitEnter(e): void {
    if (e.which == 13 || e.keyCode == 13 || e.code == 'Enter') {
      this.submit();
    }
  }

  getOAuthCode(key: string) {
    if (key === AUTH_METHOD.GOOGLE) {
      this.googleGetOauthCode();
    } else if (key === AUTH_METHOD.APPLE) {
      this.appleGetOauthCode();
    }
  }

  appleGetOauthCode(): void {
    this.appleService.signInWithApple();
  }

  googleGetOauthCode(): void {
    this.authenticationService
      .getGoogleOauthURL()
      .pipe(untilDestroyed(this))
      .subscribe(
        (resp) => {
          setTimeout(() => {
            if (resp && resp.url) {
              location.replace(resp.url);
            }
          }, 4);
        },
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
  }

  fbLogin(): void {
    this.isLoading = true;
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then(() => {
        this.socialAuthService.authState
          .pipe(
            filter(Boolean),
            switchMap((user: SocialUser) =>
              this.authenticationService.getTokenWithFB({
                token: user.authToken,
                facebookId: user.id,
                profile: { email: user.email },
              })
            ),
            finalize(()=> {
              this.isLoading = false;
            })
          )
          .subscribe(
            () => {
              this.onSuccess.emit();
            },
            () => {
              this.toastr.showToastFromError({ message: 'FB login failed' });
            }
          );
      })
      .catch(() => {
        this.isLoading = false;
        this.socialAuthService.signOut();
        this.toastr.showToastFromError({ message: 'FB login failed' });
      });
  }

  getSignInToken(key: string): boolean {
    let code_split = window.location.href.split(`${key}=`);
    let code = Array.isArray(code_split) ? code_split[1] : '';
    if (!code) {
      return false;
    }
    if (key === AUTH_METHOD.GOOGLE) {
      this.googleToken(code);
    } else if (key === AUTH_METHOD.APPLE) {
      this.appleToken(code);
    }
  }

  googleToken(code) {
    this.authenticationService
      .getTokenWithGoogle({ code })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => this.onSuccess.emit(),
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
  }

  appleToken(code) {
    this.authenticationService
      .getTokenWithApple({ code })
      .pipe(untilDestroyed(this))
      .subscribe(
        () => this.onSuccess.emit(),
        (err) => {
          this.toastr.showToastFromError(err);
        }
      );
  }
}
