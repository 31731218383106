<ng-template ngIf [ngIf]="!switcher">
  <input class="hidden-button" type="checkbox" [name]="name" [(ngModel)]="value">
  <div class="checkbox"><span class="check-mark" *ngIf="value"></span>
    <span class="check-mark-grey" *ngIf="defaultValue"></span>
  </div>
</ng-template>
<ng-template ngIf [ngIf]="switcher">
  <label class="toggle-button__container">
    <input type="checkbox" (click)="modeToggleSwitch()" class="mode-toggle__input"/>
    <span class="mode-toggle__bg" [class.mode-toggle__bg-checked]="value"></span>
    <span class="mode-toggle__circle" [class.mode-toggle__circle-checked]="value"></span>
  </label>
</ng-template>
<span class="label" #label [hidden]="!showLabel">
    <ng-content></ng-content>
</span>
