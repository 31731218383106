import {DEFAULT_SONG_IMG} from '../common/constants_IMG';

type AUCTION_STATUS = 'create' | 'approve' | 'bid_close';

interface ITokenNumbers {
  internalNumber: number;
  closed?: boolean;
  auction_status?: AUCTION_STATUS;
  auction_live?: string | null;
}

export class AuctionNFTModel {
  imgUrl: string;

  constructor(props?: string) {
    this.imgUrl = props;
  }

  artist: string;
  backgroundImage: string;
  cardColor: string;
  edition?: number;
  editionNumber?: ITokenNumbers | any;
  fees: number;
  id?: number;
  imgNFT: string;
  internalNumber: number;
  internalNumbers: ITokenNumbers[];
  isCollectible: boolean;
  nftType: string;
  projectTitle: string;
  releaseCreated: boolean;
  shares: number;
  slug: string;
  song: string;
  tokenGenerated: boolean;
  video: string;


  setFromApiModel(apiModel: any, index): this {
    this.id = index;
    this.artist = apiModel.collectible.artist;
    this.backgroundImage = apiModel.collectible.backgroundImage ? `${this.imgUrl}/${apiModel.collectible.backgroundImage}` : DEFAULT_SONG_IMG;
    this.projectTitle = apiModel.projectTitle;
    this.shares = apiModel.shares;
    this.fees = apiModel.collectible.feesAmount || 0;
    this.song = apiModel.collectible.song;
    this.internalNumbers = apiModel.internalNumbers;
    this.cardColor = apiModel.collectible.cardColor;
    this.internalNumber = apiModel.internalNumber;
    this.edition = apiModel.collectible.edition;
    this.nftType = 'collectible';
    this.slug = apiModel.collectible.slug;
    this.isCollectible = true;
    this.imgNFT = apiModel.collectible.imgNFT ? `${this.imgUrl}/${apiModel.collectible.imgNFT}` : null;
    this.video = apiModel.collectible.video || null;
    return this;
  }
}
