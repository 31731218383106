import { Component, Input } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { map, share, takeUntil } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Collection } from '@app/types';

@UntilDestroy()
@Component({
  selector: 'countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent {

  private _hours: number;

  get hours(): number {
    return this._hours;
  }

  @Input() set hours(value: number) {
    this._hours = value;
    this.countdown$ = this.getTimer(this.hours).pipe(
      untilDestroyed(this),
      share(),
    );
  }

  @Input() color: string = '#8089A0';

  public countdown$: Observable<Collection>;

  private getTimer(hour: number): Observable<Collection> {
    let distance = hour * 60 * 60 * 1000 - 1000;

    return timer(0, 1000).pipe(
      takeUntil(timer(distance)),
      map(() => {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        distance -= 1000;
        return {
          days: this.formatDecimalValues(days),
          hours: this.formatDecimalValues(hours),
          minutes: this.formatDecimalValues(minutes),
          seconds: this.formatDecimalValues(seconds),
        };
      })
    );
  }

  private formatDecimalValues(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
