import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// Modules
import { PipesModule } from './pipes/pipes.module';
import { DirectivesModule } from './directives/directives.module';
import { PopupModule } from './components/popups/popup.module';
import { FooterModule } from './components/global/footer/footer.module';

// Directives
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';

// Components
import { FooterComponent } from './components/global/footer/footer.component';
import { PaginationComponent } from './components/global/pagination/pagination.component';
import { MainSliderComponent } from './components/main-slider/main-slider.component';
import { OpportunitySliderComponent } from './components/opportunity-slider/opportunity-slider.component';
import { NewsSliderComponent } from './components/news-slider/news-slider.component';
import { NewMusicSliderComponent } from './components/new-music-slider/new-music-slider.component';
import { VideoSliderComponent } from './components/new-artist-releases-slider/video-slider.component';
import { CarouselComponent } from './components/global/carousel/carousel.component';
//import { ImageCropperComponent } from './components/features/image-cropper/image-cropper.component';
import { UboComponent } from './components/ubo/ubo.component';
import { PageHeaderComponent } from './components/global/page-header/page-header.component';

import {
  DatePickerComponent,
  DatePickerHeader,
} from './components/global/mat-date-picker/date-picker.component';
import { TabsComponent } from './components/global/tabs/tabs.component';
import { CountrySelectComponent } from '@app/components/forms/country-select/country-select.component';
import { CardProjectComponent } from '@app/components/cards/card-project/card-project.component';
//import { TooltipCustomComponent } from '@app/components/global/tooltip/tooltip.component';
import { VideoPlayerComponent } from '@app/components/video-player/video-player.component';
import { KycStatusComponent } from '@app/components/kyc-status/kyc-status.component';
import { RouterModule } from '@angular/router';
@NgModule({
  declarations: [
    PaginationComponent,
    MainSliderComponent,
    NewsSliderComponent,
    OpportunitySliderComponent,
    NewMusicSliderComponent,
    VideoSliderComponent,
    KycStatusComponent,
    CarouselComponent,
    UboComponent,
    PageHeaderComponent,
    DatePickerComponent,
    DatePickerHeader,
    TabsComponent,
    ScrollTrackerDirective,
    CountrySelectComponent,
    VideoPlayerComponent,
  ],
  imports: [
    CommonModule,
    SlickCarouselModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCardModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    RouterModule,
    ImageCropperModule,
    MatProgressSpinnerModule,
    PipesModule,
    MatDatepickerModule,
    MatIconModule,
    MatButtonModule,
    DirectivesModule,
    PopupModule,
    FooterModule,
  ],
  exports: [
    MainSliderComponent,
    CarouselComponent,
    KycStatusComponent,
    FooterComponent,
    NewMusicSliderComponent,
    OpportunitySliderComponent,
    VideoSliderComponent,
    NewsSliderComponent,
    PaginationComponent,
    UboComponent,
    PageHeaderComponent,
    DatePickerComponent,
    TabsComponent,
    ScrollTrackerDirective,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    CountrySelectComponent,
    VideoPlayerComponent,
    DirectivesModule,
  ],
})
export class SharedModule {}
