import {
  Component,
  ElementRef,
  EventEmitter,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { map, distinctUntilChanged } from 'rxjs';

import { TokenNFTmodel } from '@app/models/tokenNFT.model';
import { IProjectNFTSong } from '@app/interfaces/nft.interface';
import { extractFileExtension } from '@app/common/helpers';

// Consts
import { STATE_NAMES } from '../../../common';

// Services
import { VideoService } from '@app/services/video.service';
import {AudioService} from "@services/audio.service";

@UntilDestroy()
@Component({
  selector: 'app-card-nft',
  templateUrl: './card-nft.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardNftComponent implements OnInit {
  toggleProperty: boolean = false;
  currentPlayingVideo: HTMLVideoElement;
  videoObj: {
    video: HTMLVideoElement;
    card: any;
    status: string;
    index: number;
    front: HTMLElement;
  };
  showPause: boolean = false;
  currentDate: Date = new Date();

  @Input() item: TokenNFTmodel;
  @Input() song: IProjectNFTSong;
  @Input() index: number;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @Output() videoObject: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('myDiv') myDiv: ElementRef;
  @ViewChild('myCard') myCard: ElementRef;
  @ViewChild('frontCard') frontCard: ElementRef;
  @ViewChild('myVideo') myVideo: ElementRef;

  constructor(
    private router: Router,
    private audioService: AudioService,
    private videoService: VideoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.videoService.state$
      .pipe(
        untilDestroyed(this),
        map((state) => state.playing),
        distinctUntilChanged()
      )
      .subscribe((isPlaying) => {
        if (this.videoService.getCurrentVideo() === this.currentPlayingVideo) {
          this.toggleProperty = isPlaying;
          this.cdr.detectChanges();
        } else if (this.toggleProperty) {
          this.toggleProperty = false;
          this.cdr.detectChanges();
        }
      });
  }
  playGifAudio() {
    this.audioService.playProjectAudio(this.item, this.index);
  }

  stopGifAudio() {
    this.audioService.stop();
  }

  playVideo(item): void {
    if (this.isGif(item?.song?.nft.videoURL)) {
     this.playGifAudio();
    } else {
     this.stopGifAudio();
      if (this.myVideo) {
        this.currentPlayingVideo = this.myVideo.nativeElement;
        this.currentPlayingVideo.setAttribute('playsinline', '1');
        this.videoObj = {
          video: this.currentPlayingVideo,
          card: item,
          status: 'play',
          index: this.index,
          front: this.myCard.nativeElement,
        };
        this.videoObject.emit(this.videoObj);
      }
    }
   this.flipByCard();
  }

  flipByCard() {
    const cards = document.querySelectorAll('.card');
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].classList.contains('flipped')) {
        cards[i].classList.remove('flipped');
        const mac = /(Mac|macos)/i.test(navigator.platform);
        if (this.iOSversion() || mac) {
          cards[i].firstElementChild.setAttribute('style', 'opacity:unset');
        }
      }
    }

    const inner = this.myDiv.nativeElement;
    inner.style.perspective = '1000px';

    const card = this.myCard.nativeElement;
    card.setAttribute('class', 'card flipped');
    card.style.transformStyle = 'preserve-3d';
    card.style.transition = 'transform 1.5s';
    const front = this.frontCard.nativeElement;
    front.style.perspective = '0px';

    const mac = /(Mac|macos)/i.test(navigator.platform);
    if (this.iOSversion() || mac) {
      front.setAttribute('style', 'opacity:0');
    }

  }


  public isGif(value: string) {
    return value ? value.toLowerCase().endsWith(".gif") : false;
  }
  goToLink(e, link): void {
    e.preventDefault();
    this.router.navigate([`/${STATE_NAMES.howItWorks}`]);
  }

  onPlayingVideo(event, item) {

    event.preventDefault();
  }

  flipByService(index){
    const mac = /(Mac|macos)/i.test(navigator.platform);
    if (this.iOSversion() || mac) {
      if (index) {
        const front = document.getElementById(index) as HTMLElement;
        front.setAttribute('style', 'opacity:unset');
      }
      const front = this.frontCard.nativeElement;
      front.setAttribute('style', 'opacity:unset');
    }

    if (index) {
      const card = document.getElementById(index + 'card') as HTMLElement;
      card.setAttribute('class', 'card');
    }
    const card = this.myCard.nativeElement;
    card.setAttribute('class', 'card');


  }

  pauseVideo(item?, i?, div?) {

    if (this.isGif(this.item.song?.nft?.videoURL)) {
      this.stopGifAudio();
      this.flipByService(i);
    } else {
      if (!this.currentPlayingVideo) return;

      this.currentPlayingVideo.currentTime = 0;

      this.videoObj = {
        video: this.currentPlayingVideo,
        card: item,
        status: 'stop',
        index: this.index,
        front: this.myCard.nativeElement,
      };
      this.videoObject.emit(this.videoObj);
    }
  }

  iOSversion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      const ver = [
        parseInt(v[1], 10),
        parseInt(v[2], 10),
        parseInt(v[3] || (0 as any), 10),
      ];
      if (ver[0] < 15) {
        return true;
      } else {
        return false;
      }
    }
  }

  getVideoFormat(url: string): string {
    if (!url) {
      return 'mp4';
    }
    return extractFileExtension(url);
  }
}
