import * as moment from 'moment';

export class ProjectLiveModel {
  constructor(props, artist) {
    this.imgUrl = props;
    this.artist = artist;
  }
  artist: {};
  imgUrl: any;
  id: number;
  slug: string;
  image: string;
  projectTitle: string;
  song: {
       name: string;
  };
  soundtrack: string;
  _id: number;
  isPlaying?: boolean;

  setFromApiModel(apiModel: any): this {
    this.id = apiModel.id;
    this.slug = apiModel.slug;
    const image = apiModel.image?.includes('project');
    if (image){
      this.image = apiModel.image ? `${this.imgUrl}/${apiModel.image}` : null;
    }
    else{
      this.image = apiModel.image ? `${this.imgUrl}/images/${apiModel.image}` : null;
    }
    this.projectTitle = apiModel.projectTitle;
    this.song = apiModel.song;
    this.soundtrack = apiModel.soundtrack;
    this._id = apiModel._id;
    this.isPlaying = false;
    return this;
  }
}
