import {Component, Output, EventEmitter, HostListener, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-investor-popup',
  templateUrl: './investor-popup.html'
})


export class InvestorPopupComponent implements OnInit {
  showInvestorWarning: boolean;
  @Input() config: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(
  ) {
  }

  ngOnInit() {
  }

  onStay() {
    this.notify.emit({type: 'updateBtn', data: this.showInvestorWarning});
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }
}
