import {Component} from '@angular/core';



@Component({
  selector: 'app-loader',
  templateUrl: './loader.html'
})
export class LoaderComponent {
  constructor() {
  }

}
