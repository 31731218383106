import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'tenth'})
export class TenthPipe implements PipeTransform {
  transform(value: number | string, decimal: 'ten' | 'hundred'): number | string {
    const typeDecimal = decimal === 'hundred' ? 100 : 10;
    if (typeof value === 'number') return (value / typeDecimal).toFixed(2);

    return this.formatString(value, typeDecimal);
  }

  private formatString(price: string, typeDecimal: number): string {

    const numericPrice: number = Number(
      price.replace('€', '').replace('$', '').replace(',', '')
    );

    if (typeof numericPrice !== 'number') return price;

    if (price.includes('€')) {
      return `${(numericPrice / typeDecimal).toFixed(2)} €`;
    } else if (price.includes('$')) {
      return `$${(numericPrice / typeDecimal).toFixed(2)}`;
    }

    return (numericPrice / typeDecimal).toFixed(2);
  }
}
