import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'safeHtmlUrl'})
export class InnerHtmlUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    const shortyoutube = value.includes('youtu.be');
    const youtube = value.includes('youtube.com');
    if (youtube){
      const youtubeLink = value.replace('watch?v=', 'embed/');
      return this.sanitized.bypassSecurityTrustResourceUrl(youtubeLink + '?enablejsapi=1');
    }
    if (shortyoutube) {
      const youtubeLinkShort = value.replace('youtu.be/', 'www.youtube.com/embed/');
      return this.sanitized.bypassSecurityTrustResourceUrl(youtubeLinkShort);
    }
    else{
      return this.sanitized.bypassSecurityTrustResourceUrl(value);
    }
  }
}
