<div class="main-wrapper project project-page">
  <div [style.backgroundImage]="'url(' + project?.image + ')'" class="image-wrapper backgroundImage" *ngIf="isLoaded">
  </div>
  <div class="wrapper" *ngIf="isLoaded">
    <section class="project__song-info">
      <div class="project__sign-highlight" *ngIf="project?.legacyHit?.isLegacyHit">
        <div class="sign-highlight__wrapper">
          <div class="project-highlighted"
               [style.backgroundColor]="project?.cardColor"
               [class.superRare]="project?.legacyHit?.isLegacyHit"
               *ngIf="project?.daysLeft"
          >
            <ng-template ngIf [ngIf]="project?.legacyHit?.isLegacyHit">
              <img class="project__logo-image"
                   loading="lazy" alt="LEGACY HIT" src="./assets/images/cards/dnk.svg" title="LEGACY HIT">
              <span class="text-uppercase">LEGACY HIT</span>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="project__box-header">
        <div class="project__detail">
          <app-card-project
            [exportItem]="true"
            [isProject]="true"
            [item]="project"
            (enablePlayer)="getVideoObject($event)"></app-card-project>
          <div class="project__detail-song-information detail-song-info">
            <div class="project__detail-teaser">
              <app-project-teaser [project]="project"></app-project-teaser>
            </div>
            <div class="project__detail-song">
              <h3 class="grs-details__title">Details</h3>
              <app-project-song-detail [project]="project"></app-project-song-detail>
            </div>
          </div>

          <div class="project__calculation" *ngIf="!tab">
            <app-calculation *ngIf="project"
                             [newsLetterNotification]="newsLetterNotification"
                             [project]="project"
                             [user]="user"></app-calculation>
          </div>
        </div>
      </div>
    </section>
    <section class="section__calculation" *ngIf="tab">
      <app-calculation *ngIf="project"
                       [newsLetterNotification]="newsLetterNotification"
                       [project]="project" [user]="user"></app-calculation>
      <div class="project__get-information">
        <h3 class="project__title-sub get-title">You get</h3>
        <app-project-you-get [project]="project"></app-project-you-get>
      </div>
    </section>
    <section class="project__call-to-action project-blue-box" *ngIf="project?.readMoreContent?.title">
      <div class="call-to-action__header">
        <div class="call-to-action__title">
          <img src="./assets/images/project/marketing.png" alt="globalrockstar read more content"
               title="globalrockstar read more content">
          <h3 [innerHTML]="project?.readMoreContent?.title">
          </h3>
        </div>
        <button class="button border-blue" [class.show]="showReadMore" (click)="showReadMore = !showReadMore">
          <span>Read more</span> <i class="fas fa-chevron-down"></i>
        </button>
      </div>
      <div class="call-to-action__body" [class.show]="showReadMore">
        <img
          class="call-to-action__image"
          alt="globalrockstar read more content"
          title="globalrockstar read more content"
          loading="lazy" src="{{project?.readMoreContent?.imgReadMore}}">
        <p class="call-to-action__text" [innerHTML]="project?.readMoreContent?.text"></p>
      </div>
    </section>
    <section class="project__mobile-view-p" *ngIf="project && mobile">
      <app-project-mobile-view [project]="project" [user]="user"></app-project-mobile-view>
    </section>
    <section class="profile-info">
      <div class="profile-artist-info">
        <div class="flex-align-column profile-info-left">
          <div class="project__description">
            <h2 class="profile-title project__title-sub">Description</h2>
            <app-project-description [project]="project"></app-project-description>
          </div>
          <ng-container *ngIf="project?.sLink?.title">
            <div class="project__description">
              <h2 class="profile-title project__title-sub">{{project?.sLink?.title}}</h2>
              <app-project-social-iframe [project]="project"></app-project-social-iframe>
            </div>
          </ng-container>
          <div class="project__about-artist">
            <h2 class="profile-title project__title-sub">About {{project?.artist?.name}}</h2>
            <app-artist-social-links [project]="project"></app-artist-social-links>
          </div>
        </div>
        <div class="project__get-information" *ngIf="!mobile && !tab">
          <h3 class="project__title-sub get-title">You get</h3>
          <app-project-you-get [project]="project"></app-project-you-get>
        </div>
      </div>
    </section>
    <section class="project__subscribe project-blue-box">
      <app-subscribe-newsletter></app-subscribe-newsletter>
    </section>
    <app-footer-about-company></app-footer-about-company>
    <app-footer></app-footer>
    <app-song-preview
      [project]="project"
      [user]="user"
    ></app-song-preview>
    <app-video-player #videoPlayer
                      *ngIf="enableVideoPlayer"
                      [video]="video"
                      [project]="cardProject"
                      [user]="user"
                      [stateVideo]="state"
                      (notify)="stopPlayer($event)"></app-video-player>
  </div>
  <app-loader *ngIf="!isLoaded"></app-loader>
</div>

<app-song-preview
  [user]="user"
></app-song-preview>
