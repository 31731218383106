import {Component, OnDestroy, OnInit, OnChanges, Input, Output, EventEmitter, PLATFORM_ID, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

// Consts
import {STATE_NAMES} from '@app/common';
import {StreamState} from '@app/models/stream-state.model';

// Services
import {SettingService} from '@services/setting.service';
import {VideoService} from '@services/video.service';

@UntilDestroy()
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html'
})
export class VideoPlayerComponent implements OnInit, OnDestroy, OnChanges {
  state: StreamState;
  host: string;
  wouldInvestActive: boolean;
  @Input() project: any;
  @Input() wouldInvestActiveChange: boolean;
  @Input() user: any;
  @Input() video: any;
  @Input() stateVideo: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  currentTime: any;
  seek: any;
  videoDuration: any;
  progressBar: any;
  soundMuted: boolean;
  videoVolume: number;
  enableButton: boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: any,
              private videoService: VideoService,
              private router: Router,
              private settingService: SettingService) {
    this.host = settingService.imgUrl;
  }

  ngOnInit() {
    this.videoService
    .getState()
    .pipe(untilDestroyed(this))
    .subscribe((state) => {
      this.state = state;
    });
  this.checkForProjectPage();
  }

  ngOnChanges(data) {

    if (data.project && data.project.currentValue) {

      this.playStream(this.project.video);
    }
  }

  ngOnDestroy() {
    this.videoService.stop();
  }

  checkForProjectPage() {
    const proj = this.router.url.includes('projects');
    const collect = this.router.url.includes('collectible');
    const nft = this.router.url.includes('my-nft');
    const sell = this.router.url.includes('sell-bid');
    const wizard = this.router.url.includes('wizard');
    if (proj || collect || nft || sell || wizard) {
      this.enableButton = false;
    }
    else {
      this.enableButton = true;
    }
  }

  playStream(url) {
    this.videoService.play(this.video).subscribe((events: any) => {
      if (events.type === 'playing') {
        this.project.songIsPlaying = true;
        this.project.videoPlaying = true;
      } else if (events.type === 'error') {
        this.project.songIsPlaying = false;
        this.project.videoPlaying = false;
      }
    });
  }

  muteSound() {
    if (this.video.muted) {
      this.video.muted = false;
    }
    else {
      this.video.muted = true;
    }
  }

  updateVolume(event) {
    if (this.video.muted) {
      this.video.muted = false;
    }
    this.videoService.volumeChange(event);
  }

  togglePlayer() {
    if (this.state && !this.state.error) {

      if (this.state.playing) {
        this.videoService.stop();
        this.project.songIsPlaying = false;
        this.project.videoPlaying = false;
        this.notify.emit('stop');
      }
    }

  }

  videoTiming(event) {
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10));
    const currentTime = (skipTo * this.video.duration) / 100;
    this.videoService.seekTo(currentTime);
  }

  wouldInvestF(key: string) {
    this.notify.emit({type: 'stat', value: key, project: this.project.slug});
    this.wouldInvestActive = true;
  }

  openSupplierProfile(project: any, e?: any, isNews?: boolean): void {
    if (!isNews) {
      e.preventDefault();
    }
    if (isPlatformBrowser(this.platformId)) {
      if ((!project.isUpcoming || project.status === 'upcoming') && !project.isNews && !project.infoCard) {
        const name = e.target.className;
        const parentNodeHover = e.target.parentNode.className ? e.target.parentNode.className.indexOf('hover-block') >= 0 : null;
        if (name.indexOf('fas') && name.indexOf('play-button') && !parentNodeHover) {
          this.router.navigate([project.isCollectible ?
            STATE_NAMES.collectible : `/${STATE_NAMES.projectsItem}`, project.slug]);
        }
      }
    }
  }
}
