export class SliderModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  title: string;
  text: string;
  text2: string;
  image: string;
  id: number;
  mainTitle: string;
  imageMobile: string;
  isEnabled: boolean;
  targetBlank: boolean;
  url: string;
  url2: string;
  isRegisterURL: boolean;
  showForRegistered: boolean;
  showForVisitors: boolean;


  setFromApiModel(apiModel: any): this {
    this.title = apiModel.title;
    if (apiModel.allowToShow) {
      this.showForRegistered = apiModel.allowToShow.logged;
      this.showForVisitors = apiModel.allowToShow.visitors;
    }
    this.mainTitle = apiModel.mainTitle;
    this.text = apiModel.text;
    this.text2 = apiModel.text2;
    this.id = apiModel._id;
    this.image = apiModel.image ? `${this.imgUrl}/${apiModel.image}` : null;
    this.imageMobile = apiModel.imageMobile ? `${this.imgUrl}/${apiModel.imageMobile}` : null;
    this.isEnabled = apiModel.isEnabled;
    this.targetBlank = apiModel.targetBlank;
    this.url = apiModel.url;
    this.url2 = apiModel.url2;
    this.isRegisterURL = apiModel.url ? apiModel.url.indexOf('/register') > 0 : false;
    return this;
  }
}
