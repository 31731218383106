import {Injectable} from '@angular/core';
import {catchError, map, shareReplay, tap} from 'rxjs/operators';
import {HttpBackend, HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {StorageService} from './storage.service';
import {SettingService} from './setting.service';
import {UserModel, BankAccountModel} from 'app/models';
import {TokenNFTmodel} from '../models/tokenNFT.model';
import {KycTypeDocuments} from '../models/kycTypeDocuments.model';
import {AuctionNFTModel} from '../models/auctionNFT.model';
import {ICheckName} from '../types/common.interface';
import {IPromotionApplyResponse} from "@app/interfaces/promotion.interface";
import {AppToastrService} from "@services/toastr.service";
import {VerifyUser} from "@app/interfaces/verify-email.interface";
import {ApiService} from "@services/api.service";

interface IReminderNFT {
  email: string;
  isCollectible: boolean;
}
interface IReminderPayload {
  type: string;
}
interface IReminderResponse {
  status: string;
}

@Injectable()
export class ProfileService {
  apiUrl: string;
  token: string;
  imgUrl: string;
  private _notificationsSubject = new BehaviorSubject<boolean>(false);
  private _updateButtonClick = new BehaviorSubject<boolean>(false);
  private user: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  user$: Observable<any> = this.user.asObservable().pipe(shareReplay(1));
  private bankAccounts: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  bankAccounts$: Observable<any> = this.bankAccounts.asObservable().pipe(shareReplay(1));
  private httpClient: HttpClient;

  constructor(private http: HttpClient,
              private handler: HttpBackend,
              private setting: SettingService,
              private apiService: ApiService,
              private toastr: AppToastrService,
              private storage: StorageService) {
    this.apiUrl = this.setting.apiUrl;
    this.imgUrl = this.setting.imgUrl;
    this.token = this.storage.accessToken;
    this.httpClient = new HttpClient(handler);
  }

  emitProfileUpdate(user: UserModel) {
    this.user.next(user);
  }

  syncGetUser(): UserModel {
    return this.user.getValue();
  }

  emitBankAccountsUpdate(updatedAccounts: any): void {
    this.bankAccounts.next(updatedAccounts);
  }

  isProfileCompleted(): boolean {
    return !!this.syncGetUser()?.contact.birthdate;
  }

  getUser(req?): Observable<UserModel> {
    const params = req ? req.getApiModel() : null;
    return this.http.get(`${this.apiUrl}/profile`, {params}).pipe(
      map((resp: any) => {
        if (resp) {
          const user: UserModel = new UserModel(this.imgUrl).setFromApiModel(resp);
          this.emitProfileUpdate(user);
          return user;
        }
      }),
      catchError(() => {
        this.emitProfileUpdate(null);
        return of(null);
      }),
    );
  }

  secureAllTokens(slug: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/token/secure`, { params: { user: slug } })
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      );
  }

  checkUBOstatus(): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/ubo/status`)
      .pipe(
        map((resp: any) => {
          if (resp) {
            return resp;
          }
        })
      );
  }

  keepUserPostedUS(payload: IReminderPayload){
    const endpoint = `${this.apiUrl}/user/reminder`;
    return this.apiService.post<IReminderResponse>(endpoint, payload);
  }

  statusOldTokens(slug: string): Observable<boolean> {
    return this.http.get(`${this.apiUrl}/token/status`, { params: { user: slug } }).pipe(
      map((resp: any) => {
        if (resp) {
          return resp.statusBlocked;
        }
      })
    );
  }

  getTypeDocument(): Observable<any> {
    return this.http.get(`${this.apiUrl}/user/type-documents`).pipe(
      map((resp: any) => {
        if (resp) {
          return new KycTypeDocuments().setFromApiModel(resp);
        }
      })
    );
  }

  getNftToken(
    user,
    req
  ): Observable<{ list: Array<AuctionNFTModel | TokenNFTmodel> }> {
    const params = req.getApiModel();
    return this.http.get(`${this.apiUrl}/user/tokens`, { params }).pipe(
      map((resp: any) => {
        const result: any = resp.list;

        if (result) {
          const list: Array<AuctionNFTModel | TokenNFTmodel> = result.map(
            (i, index) => {
              if (i.internalNumber) {
                return new AuctionNFTModel(this.imgUrl).setFromApiModel(i, index);
              } else {
                return new TokenNFTmodel(this.imgUrl).setFromApiModel(i, index);
              }
            }
          );
          return { list };
        }
      })
    );
  }

  calcualteRecoupable(balance): number {
    let totalRecoup = 0;
    if (balance.open && balance.recoupable) {
      balance.recoupable.forEach((r) => {
        totalRecoup += r.status !== 'deleted' ? r.grossRecoupable : 0;
      });
    }
    return Math.abs(balance.grossBalance - totalRecoup);
  }

  deleteUser() {
    return this.http.delete(`${this.apiUrl}/user`);
  }

  _saveWalletNFT(token: string): Observable<VerifyUser> {
    return this.http.post(`${this.apiUrl}/token/wallet`, {_t: token})
      .pipe(
        map((resp: VerifyUser) => {
          return resp;
        }),
        catchError((err: any) => {
          this.toastr.showToastFromError(err);
          throw err;
        }));
  }

  _submitReminderNFT(data: IReminderNFT): any {
    return this.http.post(`${this.apiUrl}/user/token/reminder`, data).pipe(
      map((resp: any) => {
        if (resp) {
          return true;
        }
      })
    );
  }

  updateUserSettingsNft(data): Observable<UserModel> {
    return this.http.put(`${this.apiUrl}/user/token/settings`, data).pipe(
      map((resp: any) => {
        return resp;
      })
    );
  }

  updateUser(data): Observable<UserModel> {
    return this.http.put(`${this.apiUrl}/user/update`, data).pipe(
      map((resp: any) => {
        const user: UserModel = new UserModel(this.imgUrl).setFromApiModel(resp);
        this.emitProfileUpdate(user);
        return user;
      })
    );
  }

  getSettings(): Observable<any> {
    return this.http.get(`${this.apiUrl}/profile-settings`).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  checkValidNickName(data): Observable<ICheckName> {
    return this.http.post(`${this.apiUrl}/user/nickname`, {nickname: data}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  setupAuth() {
    return this.http.post(`${this.apiUrl}/tfa/setup`, {}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _enableTFA(token: string) {
    return this.http.post(`${this.apiUrl}/tfa/enable`, {token}).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  _removeTFA(token: string) {
    return this.http.post(`${this.apiUrl}/tfa/remove`, {token}).pipe(map((resp: any) => {
      if (resp) {
        return true;
      }
    }));
  }

  prepareAudioFile(event): any {
    const files = event.files;
    if (files.item(0)) {
      const file = files.item(0);
      const fileUpload = new FormData();
      fileUpload.append('file', file);
      const sizeBytes = 10 * 1024 * 1024;
      const extension = ['mp3'];
      if (file.size > sizeBytes) {
        return {error: 'File size should be less than 50MB'};
      }
      if (extension.indexOf(file.name.split('.').slice(-1)[0]) < 0) {
        return {error: 'Allowed mp3 formats only'};
      }
      return {fileUpload, file};
    }
  }


  saveSong(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/song`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  saveArtistOpportunity(data): Observable<any> {
    return this.http.post(`${this.apiUrl}/opportunity`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  saveUser(data): Observable<UserModel> {
    return this.http.put(`${this.apiUrl}/profile`, data).pipe(map((resp: any) => {
      if (resp) {
        const user = new UserModel(this.imgUrl).setFromApiModel(resp);
        this.storage.user = new UserModel(this.imgUrl).setStorageUser(resp);
        return user;
      }
    }), tap((user: UserModel)=>this.emitProfileUpdate(user)));
  }

  setStatusProfile(data) {
    this._notificationsSubject.next(data);
  }


  getStatusProfile(): Observable<any> {
    return this._notificationsSubject.asObservable();
  }

  setUpdateProfileClick(data) {
    this._updateButtonClick.next(data);
  }

  saveSettings(data): Observable<UserModel> {
    return this.http.put(`${this.apiUrl}/profile/settings`, data).pipe(map((resp: any) => {
      return resp;
    }));
  }

  uploadFile(file, params): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/profile/upload-image?${this.serialize(params)}`,
      file
    );
  }

  uploadFileImage(file, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/upload-image-file?${this.serialize(params)}`, file);
  }

  uploadFileAudio(file, params): Observable<any> {
    return this.http.put(`${this.apiUrl}/upload-audio-file?${this.serialize(params)}`, file);
  }

  uploadFileKYC(file): Observable<any> {
    return this.http.put(`${this.apiUrl}/kyc-file`, file);
  }

  // updateBankSettings(
  //   data: { bankId: number; placeholder?: string },
  //   country: string
  // ): Observable<any []> {
  //   return this.http.put(`${this.apiUrl}/bank-account/settings`, data).pipe(
  //     map((resp: any[]) => {
  //       if (resp) {
  //         return resp;
  //       }
  //     }),
  //     tap((resp) => {
  //       const accounts: BankAccountModel[] = resp.map((i) =>
  //         new BankAccountModel().setFromApiModel(i, country)
  //       );
  //       this.emitBankAccountsUpdate(accounts);
  //     })
  //   );
  // }

  getBankAccount() {
    return this.http.get(`${this.apiUrl}/bank-accounts`).pipe(map((resp: any) => {
      if (resp) {
        const accounts: BankAccountModel [] = resp.accounts.map(i => new BankAccountModel().setFromApiModel(i, resp.holder.country));
        this.emitBankAccountsUpdate(accounts);
        return {
          accounts,
          holder: resp.holder,
          mangopay: resp.mangopay
        };
      }
    }))
  }

  addBankAccount(data, country) {
    return this.http.post(`${this.apiUrl}/bank-account`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp.map(i => new BankAccountModel().setFromApiModel(i, country));
      }
    }), tap(this.emitBankAccountsUpdate.bind(this)));
  }

  applyVoucherCode(data): Observable<IPromotionApplyResponse> {
    return this.http.post(`${this.apiUrl}/voucher/apply`, data)
      .pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  payoutMoney(data) {
    return this.http.post(`${this.apiUrl}/dashboard/withdraw`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  slackFetch(data?: any) {
    return this.http.post(`${this.apiUrl}/slack-notification`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp;
      }
    }));
  }

  updateBankAccount(data, country) {
    const id = data.id;
    delete data.id;
    return this.http.put(`${this.apiUrl}/bank-account/${id}`, data).pipe(map((resp: any) => {
      if (resp) {
        return resp.map(i => new BankAccountModel().setFromApiModel(i, country));
      }
    }));
  }

  deleteBankAccount(id) {
    return this.http.delete(`${this.apiUrl}/bank-account/${id}`).pipe(
      tap(() => {
        const filteredAccounts = this.bankAccounts
          .getValue()
          ?.filter(({bankId}) => bankId != +id);
        this.emitBankAccountsUpdate(filteredAccounts);
      })
    );
  }

  private serialize(obj) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
