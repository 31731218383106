<div class="item item-dropdown"
     (click)="toggleMenu()">
  <div class="empty" *ngIf="showValue">{{value}}</div>
  <i class="fas fa-chevron-down"></i>
</div>
<div class="menu"
     [style.height]="menuActive ? (list?.length * 38) + 'px' : 0"
     [style.width]="width + 'px'"
     [class.active]="menuActive">
  <div *ngFor="let item of list; let i =index"
       (click)="selectItem(item, i)"
       class="menu-item">
    {{item?.value ? item?.value : item}}
  </div>
</div>
