import {
  Component, OnInit,
} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {AppToastrService, PledgeService} from '../../../services/index';
declare global {
  interface Window {
    ApplePaySession: any;
  }
}

//let ApplePaySession = window.ApplePaySession;


@Component({
  selector: 'app-apple-pay',
  templateUrl: './apple-pay.html',
})

export class ApplePayButtonComponent implements OnInit {
  reinvestOnly: boolean;
  applePaySupported: boolean;
  canMakePayments: boolean;
  applePaySession: any;

  constructor(private fb: FormBuilder,
              private pledgeService: PledgeService,
              private toastr: AppToastrService) {
  }

  ngOnInit(): void {
    if ((window as any).ApplePaySession) {
      console.error('This device supports Apple Pay');
    }
    if (!(window as any).ApplePaySession) {
      console.error('This device dont supports Apple Pay');
    }

    this.checkApplePaySupport();
  }

  checkApplePaySupport() {
    if ((window as any).ApplePaySession) {
      this.applePaySupported = true;
      this.canMakePayments = (window as any).ApplePaySession.canMakePayments();
    } else {
      this.applePaySupported = false;
      this.canMakePayments = false;
    }
  }


  completePayment(session, payment) {
    // Send payment details to server for processing
    // If payment is successful, complete payment
    session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
  }

  validateMerchant(validationURL: string) {
    return new Promise((resolve, reject) => {
      this.pledgeService.setApplyPaySession(validationURL).subscribe(resp => {
        const merchantSession = resp && resp.responseText ? JSON.parse(resp.responseText) : '';
        resolve(merchantSession);
      });
    })
  }


  applePay(e) {

    const request = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex'],
      merchantCapabilities: ['supports3DS'],
      total: {label: 'Demo (Card is not charged)', amount: '2.00', type: 'final'},
    };
    const session = new (window as any).ApplePaySession(3, request);

    session.onvalidatemerchant = event => {
      console.log('VALID merchant')
      const validationData: any = {ValidationUrl: event.validationURL};
      this.pledgeService.setApplyPaySession(validationData).subscribe(resp => {

      });
      // this.validateMerchant().then(resp => {
      //   session.completeMerchantValidation(resp);
      // });
      // fetch(event.validationURL)
      //   .then(res => res.json()) // Parse response as JSON.
      //   .then(merchantSession => {
      //     session.completeMerchantValidation(merchantSession);
      //   })
      //   .catch(err => {
      //     console.error('Error fetching merchant session', err);
      //   });
      // api service call with event.validationUrl to get the session data
    };
    session.onpaymentmethodselected = function(event) {
      const update = {};
      session.completePaymentMethodSelection(update);
    };
    session.onshippingmethodselected = (event: any) => {
      // Define ApplePayShippingMethodUpdate based on the selected shipping method.
      // No updates or errors are needed, pass an empty object.
      const update = {};
      session.completeShippingMethodSelection(update);
    };
    session.onpaymentauthorized = (event: any) => {
      // Define ApplePayPaymentAuthorizationResult
      console.log('Authorization APPLE PAY');
      const result = {
        status: (window as any).ApplePaySession.STATUS_SUCCESS
      };
      session.completePayment(result);
    };
    session.onshippingcontactselected = (event: any) => {
      // Define ApplePayShippingContactUpdate based on the selected shipping contact.
      const update = {
        status: 'STATUS_SUCCESS',
        newShippingMethods: {
          label: 'Free Shipping',
          detail: 'Arrives in 5 to 7 days',
          amount: '0.00',
          identifier: 'FreeShip'
        },
        newTotal: {
          label: 'Free Shipping',
          amount: '50.00',
          type: 'final'
        },
        newLineItems: [{
          label: 'Free Shipping',
          amount: '50.00',
          type: 'final'
        }]
      };
      session.completeShippingContactSelection(update);
    };
    session.oncancel = function(event) {


    };
    session.begin();
  }
}
