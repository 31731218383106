import {ChangeDetectionStrategy, Component} from '@angular/core';


@Component({
  selector: 'app-footer-about-company',
  templateUrl: './footer-about-company.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterAboutCompanyComponent {
  constructor() {
  }
}
