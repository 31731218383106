import * as moment from 'moment';
import {IMG_TRANS} from '../common/constants';

export class PledgesModel {
  id: string;
  name: string;
  createdAt: string;
  date: any;
  type: string;
  amount: any;
  title: string;
  project: string;
  transactionID: string;
  description: string;
  classTitle: string;
  status: string;
  share: number | null;

  setFromApiModel(apiModel: any): this {
    this.name = apiModel.name;
    this.id = apiModel._id || apiModel.id;

    this.project = apiModel.project;
    this.createdAt = apiModel.createdAt;
    return this;
  }

  setBalanceApiModel(apiModel: any): this {
    this.id = apiModel.id;
    this.type = apiModel.type;
    this.amount = apiModel.amount;

    if (apiModel.type === 'pledge') {
      this.share = apiModel.share ? apiModel.share : null;
      this.date = apiModel.date ? moment(apiModel.date, 'YYYY/MM/DD') : null;
      if (apiModel.hasOwnProperty('refundObj')) {
        this.title = 'Refund';
        this.transactionID = apiModel.transactionID;
      } else if (apiModel.transactionID === 'reinvestment' && apiModel.refundStatus) {
        this.title = 'Refund Re-investment';
        this.transactionID = '-';
      } else if (apiModel.transactionID === 'reinvestment') {
        this.title = 'Re-investment';
        this.transactionID = '-';
      } else {
        this.transactionID = apiModel.transactionID;
        this.title = 'Investment';
      }
      this.description = apiModel.projectTitle;
      this.status = apiModel.status ? apiModel.status : 'succeeded';
    }
    if (apiModel.type === 'deposit') {
      this.date = apiModel.date ? moment(apiModel.date, 'YYYY/MM/DD') : null;
      this.transactionID = apiModel.transactionID;
      this.title = 'Deposit';
      this.status = apiModel.status ? apiModel.status : 'succeeded';
    }
    if (apiModel.type === 'revenue') {
      if (apiModel.isAdvance) {
        this.title = 'Proceeds distribution Advance';
      }
      if (apiModel.date && moment(apiModel.date, 'L HH:mm').isValid()) {
        this.date = moment(apiModel.date, 'L HH:mm');
      } else {
        this.date = apiModel.time ? moment(Number(apiModel.time)) : null;
      }
      this.title = apiModel.isAdvance ? 'Proceeds distribution Advance' : 'Proceeds distribution';
      this.description = apiModel.projectTitle;
      this.transactionID = apiModel.transactionId && apiModel.transactionId !== 'notAmount' ? apiModel.transactionId : '-';
      this.status = 'succeeded';
    }
    if (apiModel.type === 'payout') {
      this.date = apiModel.date ? moment(apiModel.date, 'YYYY/MM/DD') : null;
      this.title = apiModel.returned ? 'Payout Refunded' : 'Pay-Out';
      this.transactionID = apiModel.resourceId;
      this.status = apiModel.failed || apiModel.refunded ? 'failed' : 'succeeded';
    }
    this.classTitle = IMG_TRANS[this.title];
    return this;
  }
}
