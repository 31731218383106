import {Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from '@angular/forms';
import {
  FacebookLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {RegisterModel} from '@app/models/register.model';
import {CustomFieldErrorMatcher} from '@app/common/errorMatcher';
import {ISocialUser} from '@app/interfaces/social-user.interface';

// Constants
import {
  EMAIL_PATTERN,
  ONLY_LETTERS_FOR_NAME,
  PASSWORD_PATTERN_ANY,
} from 'app/common';

// Services
import {AppToastrService, DictionaryService} from 'app/services';

import {AuthenticationService} from '@services/authentication.service';
import {MagicAuthService} from "@services/magic-auth.service";

@UntilDestroy()
@Component({
  selector: 'register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.scss'],
})
export class RegisterFormComponent implements OnInit {
  @Output() onSuccess: EventEmitter<void> = new EventEmitter();
  @Output() userTypeChange: EventEmitter<boolean> = new EventEmitter();
  form: FormGroup;
  loginCtrl: FormControl;
  repeatPasswordCtrl: FormControl;
  passwordCtrl: FormControl;
  artistNameCtrl: FormControl;
  firstNameCtrl: FormControl;
  lastNameCtrl: FormControl;
  countryCtrl: FormControl;
  regionCtrl: FormControl;
  submitPressed: boolean;
  submitting: boolean;
  getNewsletter = false;
  defaultFanUser = true;
  fbData: any = {};
  notValidPassword = false;
  isRememberMe = false;

  constructor(
    private authenticationService: AuthenticationService,

    private magicWalletService: MagicAuthService,
    private dictionaryService: DictionaryService,
    private socialAuthService: SocialAuthService,
    private toastr: AppToastrService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit() {
    this.prepareForm();
  }

  errorMatcher(control: FormControl, additionalCheck?: boolean) {
    let error;
    if (additionalCheck) {
      error =
        this.repeatPasswordCtrl.value !== this.passwordCtrl.value &&
        this.repeatPasswordCtrl.touched;
    }
    return new CustomFieldErrorMatcher(control, this.submitPressed, error);
  }

  errorMatcherPassword(control: FormControl, additionalCheck?: boolean) {
    let error;
    return new CustomFieldErrorMatcher(
      control,
      this.submitPressed || this.notValidPassword,
      error
    );
  }

  errorMatcherName(control: FormControl, additionalCheck?: boolean) {
    let error;
    return new CustomFieldErrorMatcher(
      control,
      this.submitPressed || control.touched,
      error
    );
  }

  prepareForm() {
    this.loginCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(EMAIL_PATTERN),
    ]);
    this.passwordCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(PASSWORD_PATTERN_ANY),
    ]);
    this.regionCtrl = this.fb.control('');
    this.countryCtrl = this.fb.control('', [Validators.required]);
    this.firstNameCtrl = this.fb.control('', {
      validators: [
        Validators.required,
        Validators.pattern(ONLY_LETTERS_FOR_NAME),
      ],
      updateOn: 'change',
    });
    this.lastNameCtrl = this.fb.control('', {
      validators: [
        Validators.required,
        Validators.pattern(ONLY_LETTERS_FOR_NAME),
      ],
      updateOn: 'change',
    });
    this.artistNameCtrl = this.fb.control('', [
      !this.defaultFanUser ? Validators.required : Validators.nullValidator,
    ]);
    this.repeatPasswordCtrl = this.fb.control('', [
      Validators.required,
      Validators.pattern(PASSWORD_PATTERN_ANY),
    ]);
    this.form = this.fb.group({
      login: this.loginCtrl,
      password: this.passwordCtrl,
      country: this.countryCtrl,
      region: this.regionCtrl,
      repeat: this.repeatPasswordCtrl,
      firstName: this.firstNameCtrl,
      lastName: this.lastNameCtrl,
      artistName: this.artistNameCtrl,
    });
  }

  registerUser() {
    this.submitPressed = true;
    this.form.markAllAsTouched();
    if (this.form.valid && !this.submitting) {
      if (
        this.fbData.id ||
        this.passwordCtrl.value === this.repeatPasswordCtrl.value
      ) {
        this.submitting = true;
        const data: any = {
          email: this.loginCtrl.value,
          password: this.passwordCtrl.value,
          confirm: this.repeatPasswordCtrl.value,
          firstName: this.firstNameCtrl.value.replace(
            ` ${this.lastNameCtrl.value}`,
            ''
          ),
          lastName: this.lastNameCtrl.value,
          country: this.dictionaryService.getCountryCode(
            this.countryCtrl.value
          ),
          artistName: this.artistNameCtrl.value,
          isArtist: !this.defaultFanUser,
          facebookId: this.fbData.id,
          facebookToken: this.fbData.authToken,
          notifications: this.getNewsletter,
        };
        if (this.regionCtrl.value) {
          data.region = this.regionCtrl.value;
        }
        const requestData = new RegisterModel().getApiModel(data);
        this.authenticationService.signUp(requestData).subscribe(
          () => {
            /** Magic flow **/

            this.magicWalletService.generateWallet(data.email);
            this.onSuccess.emit();
          },
          (err) => {
            this.submitting = false;
            this.toastr.showToastFromError(err);
          }
        );
      }
    } else {
      this.submitting = false;
    }
  }

  checkPassword() {
    this.notValidPassword = !this.passwordCtrl.valid;
  }

  toggleUser(key: boolean) {
    this.defaultFanUser = key;
    this.userTypeChange.emit(key);
  }

  fbLogin() {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID)
    .then(() => {
      this.socialAuthService.authState.pipe(untilDestroyed(this)).subscribe(
        (user: ISocialUser) => {
          if (user) {
            this.fbData = user;
            this.loginCtrl.setValue(user.email);
            this.firstNameCtrl.setValue(user.firstName);
            this.lastNameCtrl.setValue(user.lastName);
            this.passwordCtrl.setValidators([]);
            this.passwordCtrl.updateValueAndValidity();
            this.repeatPasswordCtrl.setValidators([]);
            this.repeatPasswordCtrl.updateValueAndValidity();
            this.socialAuthService.signOut();
            if (this.defaultFanUser) {
              this.registerUser();
            }
          }
        },
        (err) => {
          this.toastr.showToastFromError({message: 'FB login failed'});
        }
      );
    })
    .catch(() => {
      this.socialAuthService.signOut();
      this.toastr.showToastFromError({ message: 'FB login failed' });
    });
    
  }
}
