import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-project-teaser',
  templateUrl: './project-teaser.html',
  styleUrls: ['./project-teaser.scss'],
})
export class ProjectTeaserComponent {
  @Input() project;

  projectCountry(): string{
    if (this.project?.country) {
      return this.project.country;
    } else if (this.project?.artist?.country) {
      return this.project.artist.country;
    } else {
      return '';
    }
  }
  projectGenre(): string{
    if (this.project?.song.genre) {
      return this.project?.song.genre
    } else {
      return '';
    }
  }
}
