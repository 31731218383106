import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, map,} from 'rxjs';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {SettingService} from "@app/services";

interface ISettings {
  step: number;
  min: number;
  max: number;
  maxView: string;
  minView: string;
}

interface IDisableButton {
  max: boolean;
  min: boolean;
}

@UntilDestroy()
@Injectable({providedIn: 'root'})

export class CalculationService {
  private buttonDisabledSubject = new BehaviorSubject<string>('PRICE');
  private displaySettingsSubject = new BehaviorSubject<ISettings>(null);
  private projectSettingsSubject = new BehaviorSubject<any>(null);
  private minMaxSubject = new BehaviorSubject<IDisableButton>(null);
  public buttonDisabled$ = this.buttonDisabledSubject.asObservable();
  public minMaxDisabled$ = this.minMaxSubject.asObservable();
  public displaySettings$ = this.displaySettingsSubject.asObservable();
  public projectSettings$ = this.projectSettingsSubject.asObservable();

  constructor(private settingsService: SettingService) {
  }

  toggleButtonState(value: string) {
    this.buttonDisabledSubject.next(value);
  }

  tabIsActive(baseAmount: number, totalShareLeft: number, decimal: number) {
    this.projectSettingsSubject.next({baseAmount, totalShareLeft, decimal})
  }
  public getCurrencySign() {
    return this.settingsService.currencySymol;
  }

  public limitShare(data: IDisableButton) {
    this.minMaxSubject.next(data)
  }

  public calculateSettings() {
    combineLatest([
      this.buttonDisabled$,
      this.projectSettings$,
    ]).pipe(
      untilDestroyed(this)
    ).subscribe(([tab, setting]) => {
      const {baseAmount, totalShareLeft, decimal} = setting;
      let totalShare = this.totalShare(baseAmount, totalShareLeft);
      let totalPrice = this.totalPrice(baseAmount, totalShareLeft);
      const DATA = {
        step: decimal,
        min: decimal,
        max: totalShare,
        style: this.getThumbImageStyle(tab),
        maxView: this.maxView(tab, totalShare, totalPrice),
        minView: this.minView(tab, decimal, baseAmount)
      };
      this.displaySettingsSubject.next(DATA);
    })
  }

  private maxView(tab: string, totalShare: number, totalPrice: number): string {
    return tab !== 'PRICE' ? totalShare + '%' : this.getCurrencySign() + totalPrice;
  }

  private minView(tab: string, decimal: number, baseAmount: number): string {
    const priceMin = (decimal * baseAmount).toFixed(2);
    return tab !== 'PRICE' ? decimal + '%' :  this.getCurrencySign() + priceMin
  }

  private getThumbImageStyle(tab: string): string {
    return tab !== 'PRICE' ? 'thumb-image-percent' : 'thumb-image-euro';
  }

  public getMaxAmount() {
    return this.displaySettingsSubject?.value?.max;
  }

  private totalPrice(totalInvestorsLeft, baseAmount): number {
    if ((totalInvestorsLeft * baseAmount) > 5000) {
      return 5000;
    } else {
      return Math.round(totalInvestorsLeft * baseAmount * 100) / 100;
    }
  }

  private totalShare(baseAmount, totalInvestorsLeft): number {
    if ((totalInvestorsLeft * baseAmount) > 5000) {
      return baseAmount ? Math.round((5000 / baseAmount) * 100) / 100 : 0;
    } else {
      return totalInvestorsLeft;
    }
  }
}


