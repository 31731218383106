import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {SettingService} from 'app/services';
import {Dictionary} from '../../models';
import {ProjectsService} from '../../services/projects.service';


@Component({
  selector: 'app-filter-mobile',
  templateUrl: './filter-mobile.html'
})
export class FilterMobileComponent implements OnInit, OnDestroy {
  @Input() filter: any;
  @Input() activeTab: any;
  @Input() genres: any;
  @Input() artistList: any;
  @Input() count: any;
  @Input() status: any;
  @Input() countries: Dictionary[];
  @Input() projectType: any;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private projectService: ProjectsService,
              private settingService: SettingService) {
  }

  ngOnInit() {
    this.settingService.setScroll(true);
  }
  ngOnDestroy() {
    this.settingService.setScroll(false);
  }

  getList() {
    this.notify.emit({type: 'makeFilter', data: this.filter});
  }

  goBack() {
    this.notify.emit({type: 'closeFilter', data: this.filter});
  }

  clearFilter() {
    this.notify.emit({type: 'clearFilter', data: this.filter});
  }
}
