<form class="form" [formGroup]="form" *ngIf="!photoRegime">
  <div class="form-wrapper">
    <mat-form-field class="document-type">
      <mat-label>Document Type</mat-label>
      <mat-select
        [formControl]="doctypeCtrl"
        [errorStateMatcher]="errorMatcher(doctypeCtrl)"
        (selectionChange)="selectType()"
      >
        <mat-option *ngFor="let item of documentType" [value]="item.id"
          >{{item.text}}</mat-option
        >
      </mat-select>
    </mat-form-field>

    <div class="kyc-button-wrapper" *ngIf="!photoRegime">
      <button
        class="button yellow button-upload text-uppercase spinner__button"
        id="button-upload"
        (click)="onSubmit()"
        *ngIf="isSelectedType && isIdcardValid"
        [disabled]="!isSelectedType || !isIdcardValid || submitPressed"
      >
        <mat-spinner [diameter]="30" *ngIf="submitPressed"></mat-spinner>
        <span>Continue</span>
      </button>
    </div>

    <div class="wrapper-upload" [class.disabled]="!isSelectedType">
      <div
        class="idCard"
        *ngIf="([1,3].indexOf(doctypeCtrl?.value) >= 0 && !isMobileScreen) ||
        isMobileScreen && [1,3].indexOf(doctypeCtrl?.value) >= 0"
      >
        <span class="idCard__text">Upload front side</span>
        <div class="loader-picture">
          <app-image-cropper
            *ngIf="!imgURL?.filefront"
            [maintainAspectRatio]="true"
            [stringToFile]="true"
            [config]="imageConfig"
            (notify)="savePhoto($event, 'filefront')"
          ></app-image-cropper>
          <div class="loader-picture__wrapper" *ngIf="imgURL?.filefront">
            <img
              class="loader-picture__image"
              [src]="imgURL?.filefront"
              alt="loader"
            />
          </div>
        </div>
        <img
          *ngIf="showIDExamples?.front && doctypeCtrl?.value === 1"
          src="../../../../../assets/images/id_card_example_front.jpg"
          alt="id card example"
          title="id card"
          class="id__passport"
        />
        <img
          *ngIf="showIDExamples?.front && doctypeCtrl?.value === 3"
          alt="driver card example"
          title="driver card"
          src="../../../../../assets/images/driver_card_example_front.jpg"
          class="id__passport"
        />
      </div>
      <div
        class="idCard backside"
        *ngIf="(([1,3].indexOf(doctypeCtrl?.value) >= 0 && !isMobileScreen) ||
        isMobileScreen && [1,3].indexOf(doctypeCtrl?.value) >= 0) && imgURL?.filefront"
      >
        <span class="idCard__text">Upload back side</span>
        <div class="loader-picture">
          <app-image-cropper
            *ngIf="!imgURL?.fileback"
            [maintainAspectRatio]="true"
            [stringToFile]="true"
            [config]="imageConfig"
            (notify)="savePhoto($event, 'fileback')"
          ></app-image-cropper>
          <div class="loader-picture__wrapper" *ngIf="imgURL?.fileback">
            <img
              class="loader-picture__image"
              [src]="imgURL?.fileback"
              alt="loader"
            />
          </div>
        </div>
        <img
          *ngIf="showIDExamples?.back && doctypeCtrl?.value === 1"
          alt="id passport"
          title="id passport"
          src="../../../../../assets/images/id_card_example_back.jpg"
          class="id__passport"
        />
        <img
          *ngIf="showIDExamples?.back && doctypeCtrl?.value === 3"
          alt="id passport"
          title="id passport example"
          src="../../../../../assets/images/driver_card_example_back.jpg"
          class="id__passport"
        />
      </div>
      <div class="passport file-passport" *ngIf="doctypeCtrl?.value === 2">
        <div class="loader-picture">
          <app-image-cropper
            *ngIf="!imgURL?.filefront"
            [maintainAspectRatio]="true"
            [stringToFile]="true"
            [config]="imageConfig"
            (notify)="savePhoto($event, 'filefront')"
          ></app-image-cropper>
          <div class="loader-picture__wrapper" *ngIf="imgURL?.filefront">
            <img
              class="loader-picture__image"
              alt="loader"
              [src]="imgURL?.filefront"
            />
          </div>
        </div>
        <img
          *ngIf="showExamples"
          alt="image passport"
          title="image passport example"
          src="../../../../../assets/images/pasport_example.jpg"
          class="image__passport"
        />
      </div>
    </div>
  </div>

  <ng-template
    ngIf
    [ngIf]="!user?.isPrivate && [1,2,3].indexOf(doctypeCtrl?.value) >= 0"
  >
    <div class="upload-wrapper">
      <div
        class="upload__file-doc file-doc"
        *ngIf="user?.legalType !== 'soletrader'"
      >
        <h2 class="file-doc__title file-doc__title_padding">
          {{ textCompanyDocuments?.article || 'Certified articles of association (Statute)'}}:
        </h2>
        <div class="file-doc__input" *ngIf="!pdfName?.statute">
          <label class="border-blue">
            <input
              (change)="uploadFile($event, 'statute')"
              type="file"
              [disabled]="!isSelectedType"
              class="desktop-upload"
              name="statute"
            />
            <i class="fas fa-camera"></i>
            <span>Upload statute</span>
          </label>
          <img
            [src]="imgURL?.statute"
            alt="image of statute"
            title="image of statute"
            *ngIf="imgURL?.statute"
          />
        </div>
        <p class="file-doc__pdf" *ngIf="pdfName?.statute">
          <i class="fas fa-times-circle" (click)="deletePDF('statute')"></i>
          <span>{{pdfName?.statute}}</span>
        </p>
      </div>
      <div class="upload__file-doc file-doc">
        <h2 class="file-doc__title file-doc__title_padding">
          {{textCompanyDocuments?.registration_proof || 'Company Register'}}:
        </h2>
        <div class="file-doc__input" *ngIf="!pdfName?.company_register">
          <label class="border-blue">
            <input
              (change)="uploadFile($event, 'company_register')"
              type="file"
              [disabled]="!isSelectedType"
              class="desktop-upload"
              name="statute"
            />
            <i class="fas fa-camera"></i>
            <span>Upload Company Register</span>
          </label>
          <img
            [src]="imgURL?.company_register"
            alt="image of company register"
            title="image of company register"
            *ngIf="imgURL?.company_register"
          />
        </div>
        <p class="file-doc__pdf" *ngIf="pdfName?.company_register">
          <i
            class="fas fa-times-circle"
            (click)="deletePDF('company_register')"
          ></i>
          <span>{{pdfName?.company_register}}</span>
        </p>
      </div>
    </div>
  </ng-template>

</form>
<div class="mobile-preview kyc-popup" *ngIf="photoRegime">
  <div class="wrapper-spinner">
    <mat-spinner [diameter]="30" *ngIf="loadSpinner"></mat-spinner>
  </div>
  <div class="img-block">
    <img
      [src]="imgURL?.filefront"
      alt="front side of document"
      title="front side of document"
      *ngIf="imgURL?.filefront && checkReadibility?.frontCheck"
    />
    <img
      [src]="imgURL?.fileback"
      alt="back side of document"
      title="back side of document"
      *ngIf="imgURL?.fileback && checkReadibility?.backCheck"
    />
    <img
      [src]="imgURL?.statute"
      alt="readibility of statute"
      title="readibility of statute"
      *ngIf="imgURL?.statute && checkReadibility?.statute && !user?.isPrivate"
    />
    <img
      [src]="imgURL?.company_register"
      alt="readibility of company_register"
      title="readibility of company_register"
      *ngIf="imgURL?.company_register && checkReadibility?.company_register && !user?.isPrivate"
    />
  </div>
  <div class="button-wrapper kyc-button-wrapper">
    <button
      class="button yellow text-uppercase button-readable"
      (click)="closePhotoRegime()"
    >
      My card is readable
    </button>

    <button
      class="button border-blue text-uppercase reload-photo"
      *ngIf="doctypeCtrl?.value === 2"
      (click)="closePhotoRegime(fileStep)"
    >
      Take other photo
    </button>
    <label
      class="button border-blue text-uppercase other-photo"
      *ngIf="[1,3].indexOf(doctypeCtrl?.value) >= 0"
    >
      <input
        class="mobile-upload"
        (change)="uploadFile($event, fileStep, true)"
        type="file"
        accept="image/*"
        #activeFrameinputFileBack
        capture="environment"
      />
      <span>Take other photo</span>
    </label>
  </div>
</div>
