<app-popup>
  <div class="inner">
    <div class="text"><span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    </div>
    <img src="./assets/images/bell.png" class="image" alt="bell icon">
    <!--    TODO insert correct text here-->
    <div class="text-block">
      <span class="text-center name">Hi {{user?.contact?.firstName}},</span> <br>
      <p class="text text-indent">we have added 2 factor authentication as an additional layer of security for your
        account. We recommend using Google Authenticator for 2FA when logging in to globalrockstar.com.</p>
    </div>
    <div class="button-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">
        Cancel
      </button>
      <button class="button yellow text-uppercase" (click)="onSubmit()">
        Activate
      </button>
    </div>
  </div>
</app-popup>
