import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Pages
import {HomePageComponent, LoginPageComponent, RegisterComponent} from './pages';
import { VerifyPageComponent } from './pages/verify/verify.component';
import { MainPageComponent } from './pages/main-page/main-page.component';

// Guards
import { LogoutGuard } from './guards/logout.guard';
import { ExitProfileGuard } from './guards/profile.guard';
import { WizardGuard } from './guards/wizard.guard';

const routes: Routes = [

  { path: 'api/login', component: LoginPageComponent },
  {
    path: 'api/register',
    component: RegisterComponent,
    data: {
      description: 'Global Rockstar - Your Share in Hit Songs',
      keywords: 'GlobalRockstar',
    },
  },
  {
    path: 'logout',
    component: LoginPageComponent,
    pathMatch: 'full',
    canActivate: [LogoutGuard],
  },
  { path: 'api/verify/:page', component: VerifyPageComponent },

  // {
  //   path: 'opportunity',
  //   loadChildren: () =>
  //     import('./pages/home/opportunity.module').then(
  //       (m) => m.OpportunityPageModule
  //     ),
  // },
  {
    path: 'projects/:slug',
    loadChildren: () =>
      import('./pages/project/project.module').then((m) => m.ProjectPageModule),
  },
  {
    path: 'collectible/:slug',
    loadChildren: () =>
      import('./pages/collectible/collectible.module').then(
        (m) => m.CollectiblePageModule
      ),
  },
  {
    path: 'api/revenues',
    loadChildren: () =>
      import('./pages/revenue/revenue.module').then((m) => m.RevenuePageModule),
  },
  {
    path: 'api/artist-details/:slug',
    loadChildren: () =>
      import('./pages/artist-details/artist-details.module').then(
        (m) => m.ArtistDetailsPageModule
      ),
  },
  {
    path: 'api/dashboard',
    loadChildren: () =>
      import('./pages/user-dashboard/user-dashboard.module').then(
        (m) => m.UserDashboardPageModule
      ),
  },
  {
    path: 'api/my-projects',
    loadChildren: () =>
      import('./pages/my-projects/my-projects.module').then(
        (m) => m.MyProjectsPageModule
      ),
  },
  {
    path: 'api/profile',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
  {
    path: 'about-grs',
    loadChildren: () =>
      import('./pages/about-grs/about-grs.module').then(
        (m) => m.AboutGrsModule
      ),
  },
  {
    path: 'how-it-works',
    loadChildren: () =>
      import('./pages/how-grs-works/how-grs-works.module').then(
        (m) => m.HowGrsWorksModule
      ),
  },
  {
    path: 'api/artist-song',
    loadChildren: () =>
      import('./pages/artist-song/artist-song.module').then(
        (m) => m.ArtistSongModule
      ),
  },
  {
    path: 'api/transactions',
    loadChildren: () =>
      import('./pages/balance/balance.module').then((m) => m.BalanceModule),
  },
  {
    path: 'api/change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordModule
      ),
  },
  {
    path: 'api/data-policy',
    loadChildren: () =>
      import('./pages/data-policy/data-policy.module').then(
        (m) => m.DataPolicyModule
      ),
  },
  {
    path: 'api/artist-submission/:id',
    loadChildren: () =>
      import('./pages/artist-submission/artist-submission.module').then(
        (m) => m.ArtistSubmissionModule
      ),
  },
  {
    path: 'api/terms-conditions',
    loadChildren: () =>
      import('./pages/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'api/forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'api/imprint',
    loadChildren: () =>
      import('./pages/imprint/imprint.module').then((m) => m.ImprintModule),
  },
  {
    path: 'api/landing/:slug',
    loadChildren: () =>
      import('./pages/landing-pages/landing-pages.module').then(
        (m) => m.LandingPagesModule
      ),
  },
  {
    path: 'api/create-project-component/:id',
    loadChildren: () =>
      import('./pages/project-create/project-create.module').then(
        (m) => m.ProjectCreateModule
      ),
  },
  {
    path: 'project-component-upcoming/:slug',
    loadChildren: () =>
      import('./pages/project-upcoming/project-upcoming.module').then(
        (m) => m.ProjectUpcomingModule
      ),
  },
  {
    path: 'api/voucher',
    loadChildren: () =>
      import('./pages/voucher/voucher.module').then((m) => m.VoucherModule),
  },
  {
    path: 'api/voucher/vouchersuccess/:id',
    loadChildren: () =>
      import('./pages/voucher-status/voucher-status.module').then(
        (m) => m.VoucherStatusModule
      ),
  },
  {
    path: 'api/withdraw',
    loadChildren: () =>
      import('./pages/withdraw/withdraw.module').then((m) => m.WithdrawModule),
  },
  {
    path: 'api/wizard/investmentsuccess/:id',
    loadChildren: () =>
      import('./pages/wizard-status/wizard-status.module').then(
        (m) => m.WizardStatusModule
      ),
  },
  {
    path: 'api/about-artist',
    loadChildren: () =>
      import('./pages/about-artist/about-artist.module').then(
        (m) => m.AboutArtistModule
      ),
  },
  {
    path: 'api/investments',
    loadChildren: () =>
      import('./pages/investment/investment.module').then(
        (m) => m.InvestmentModule
      ),
  },
  {
    path: 'api/my-nft',
    loadChildren: () =>
      import('./pages/my-nft/my-nft.module').then((m) => m.MyNftModule),
  },
  {
    path: 'label',
    loadChildren: () =>
      import('./pages/label-artist/label-artist.module').then(
        (m) => m.LabelArtistModule
      ),
  },
  {
    path: 'api/nft-redirect',
    loadChildren: () =>
      import('./pages/nft-redirect/nft-redirect.module').then(
        (m) => m.NftRedirectModule
      ),
  },
  {
    path: 'api/sell-bid',
    loadChildren: () =>
      import('./pages/sell-bid/sell-bid.module').then((m) => m.SellBidModule),
  },
  {
    path: 'api/my-auction',
    loadChildren: () =>
      import('./pages/my-auction/my-auction.module').then(
        (m) => m.MyAuctionModule
      ),
  },
  {
    path: 'api/nft-wizard',
    loadChildren: () =>
      import('./pages/nft-wizard/nft-wizard.module').then(
        (m) => m.NftWizardModule
      ),
  },
  {
    path: 'api/wizard',
    canActivate: [WizardGuard],
    data: {
      description: 'Global Rockstar - Your Share in Hit Songs',
      keywords: 'GlobalRockstar',
    },
    loadChildren: () =>
      import('./pages/wizard-page/wizard-page.module').then(
        (m) => m.WizardPageModule
      ),
  },
  {
    path: 'api/marketplace',
    loadChildren: () =>
      import('./pages/marketplace-lazy/marketplace-lazy.module').then(
        (m) => m.MarketplaceLazyModule
      ),
  },
  { path: 'opportunity', component: HomePageComponent },
  { path: '', component: MainPageComponent, pathMatch: 'full' },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [ExitProfileGuard],
})
export class AppRoutingModule {}
