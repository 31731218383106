<div class="detail-song-info__song-details song-details">
  <h1 class="flex-align-center item-content-title project__name">

      <span *ngIf="project?.isAlbum || project?.isBundle" class="album-logo">
        <i class="fas fa-list"></i>
        <span class="item-content-title-album">{{project?.projectTitle}}</span>
      </span>
    <span *ngIf="!project?.isAlbum && !project?.isCollectible && !project?.isBundle">
        {{project?.song?.name}}
      </span>
    <span *ngIf="!project?.isAlbum && project?.isCollectible && !project?.isBundle">
        {{project?.song}}
      </span>
  </h1>
  <div class="artist-name-wrapper">
    <div class="artist-name-item">
      <h2 class="artist-name-text">
        By {{project?.cardArtistTitle || project?.artist?.name}}
      </h2>
    </div>
    <div
      class="artist-name-item"
      *ngIf="project?.song?.genre || project?.genre"
    >
      <img src="./assets/images/project/musical-note.png" />
      <span class="count">{{projectGenre()}}</span>
    </div>
    <div class="artist-name-item">
      <span class="lnr lnr-map-marker"></span>
      <span class="count">{{projectCountry()}}</span>
    </div>
    <div class="artist-name-item" *ngIf="project?.isCollectible">
      <img src="./assets/images/project/editions.png" />
      <span class="count">{{project?.edition}} Editions</span>
    </div>
  </div>
  <p class="flex-align teaser">{{project?.details?.teaser}}</p>
  <div class="detail-song-info__static">
    <div
      class="detail-song-info__static-col"
      *ngFor="let itemTeaser of project?.offerTeaser"
    >
      <img
        class="detail-song-info__img"
        alt="{{itemTeaser?.headline}}"
        src="{{itemTeaser?.icon}}"
      />
      <div class="detail-song-info__static-text">
        <h3>{{itemTeaser?.headline}}</h3>
        <p>{{itemTeaser?.text}}</p>
      </div>
    </div>
  </div>
</div>
