import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';

import {EmitReport} from "@app/pages/revenue/types/revenue.interface";
import * as moment from "moment/moment";
import {UserModel} from "@app/models";
import * as FileSaver from 'file-saver';
import {ProjectsService} from "@services/projects.service";
import {AppToastrService} from "@app/services";
import { finalize } from 'rxjs/operators';
import {IRevenueHistory, IRevenueItem} from "@app/pages/revenue/types/revenue-response.interface";

@Component({
  selector: 'app-sub-table-revenue-history',
  templateUrl: './sub-table-revenue-history.html',
  styleUrls: ['./sub-table-revenue-history.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubTableRevenueHistoryComponent {
  ios: any;

  @Input() revenue: IRevenueItem;
  @Input() user: UserModel;
  @Output() showPdfPopUp: EventEmitter<any> = new EventEmitter<any>();
  @Output() reportEmit: EventEmitter<EmitReport> = new EventEmitter<EmitReport>();

  constructor(private projectService: ProjectsService,
              private toastr: AppToastrService,
              private cdr: ChangeDetectorRef) {
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.ios = /iphone|ipod|ipad/.test(userAgent);
  }

  loadReport(item: IRevenueHistory, preview: boolean) {
    if (!item.report) {
      return false;
    }
    this.reportEmit.emit({
      url: item.report, preview
    })
  }

  loadStatement(file: any, preview: boolean, event?: any) {

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    file.download = true;
    const MONTH = moment(file.date).format('MMM');
    const YEAR = moment(file.date).format('YYYY');
    const extension = this.typeFile(this.revenue);
    this.projectService.downloadStatement(file, this.revenue.slug, extension ? 'zip' : 'pdf').pipe(finalize(()=>{
      file.download = false;
      this.cdr.markForCheck();
    })).subscribe(resp => {
      if (preview && !extension) {
        this.showPdfPopUp.emit({data: resp});
      }

      if (!preview) {
        FileSaver.saveAs(resp, `proceeds-statement_${this.revenue.projectTitle}_${YEAR}/${MONTH}.${extension ? 'zip' : 'pdf'}`);
      }
    }, (err) => {
      this.toastr.showToastFromError(err);
    });
  }


  getRevenueIcon(revenueHistoryItem: IRevenueHistory): { icon: string, text: string } {
    const path: string = 'assets/images/revenues/'
    let icon, text;

    if (revenueHistoryItem.isRadio) {
      text = 'Publishing Royalties';
      icon = 'radio.svg';
    } else {
      text = 'Master Royalties';
      icon = 'streams.svg';
    }

    return {icon: `${path}${icon}`, text}
    // TODO: ADD 2 MORE SOURCES AFTER API UPDATE
  }

  private typeFile(revenue: IRevenueItem) {
    const ISARTIST = this.user.type === 'Artist';
    const ISAUSTRIA = this.user.country === 'AT';

  //  return !ISAUSTRIA && ISARTIST && revenue.isTax;
    return ISARTIST
  }
}
