import {
  Component, EventEmitter, HostListener,
  Input, OnInit,
  Output,
} from '@angular/core';

import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {Observable, Subscription} from "rxjs";
import {CalculationService} from "@app/components/calculation/service/calculation.service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {PipesModule} from "@app/pipes/pipes.module";
import { CommonModule } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-range-block',
  templateUrl: './range-block.html',
  styleUrls: ['./range-block.scss'],
  imports: [CommonModule, PipesModule, ReactiveFormsModule],
  standalone: true,
})
export class RangeBlockComponent implements OnInit {
  public formControl: FormControl;
  public isDisabled: boolean = true;
  subscription: Subscription;
  limitBlock$: Observable<any>;
  @Input() title: string;
  @Output() increaseItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() decreaseItem: EventEmitter<any> = new EventEmitter<any>();

  @Input() set formControlItem(value) {
    this.formControl = value;
  }

  @HostListener('click')
  onClick() {
    this.calculationService.toggleButtonState(this.title);
  }

  constructor(private calculationService: CalculationService) {
  }

  ngOnInit() {
    this.calculationService.buttonDisabled$
      .pipe(untilDestroyed(this))
      .subscribe((value: string) => {
      this.isDisabled = value !== this.title;
    });
   this.limitBlock$ = this.calculationService.minMaxDisabled$;
  }

  increase() {
    this.increaseItem.emit();
  }

  decrease() {
    this.decreaseItem.emit();
  }
}
