export const environment = {
  production: false,
  apiUrl: 'https://testing.globalrockstar.com/server',
  imgUrl: 'https://d1uo49nk1r0ezz.cloudfront.net',
  facebookId: '489079268542757',
  gaEnabled: true,
  fbEnabled: false,
  nftRedirectSlug: 'nft-redirect',
  appleClientId: 'com.globalrockstar.development.auth.client',
  appleRedirectURL: 'https://development.globalrockstar.com/server/apple-login',
  ourLabelSlug: 'our-label',
  allowUserSkipKycHours: 100,
  microsoftViewFileAPI: 'https://view.officeapps.live.com/op/embed.aspx?src=',
  merchantId: '12345678901234567890',
  merchantName: 'Demo Merchant',
  magicKey: 'pk_live_4C8D8C04F15FDD56',
  googlePay: 'TEST',
  lock: false,
  ipregistryKey: 'd01cnowhjsyit7hd',
  idealIxUrl: 'https://beta.idealidx.com',
  idealixParentWindowDomain: 'https://beta.idealidx.com',
};
