<form class="ubo__form form">
  <div class="form__col">
    <mat-form-field class="form__item">
      <mat-label>First name</mat-label>
      <input
        matInput
        [formControl]="firstNameCtrl"
        [errorStateMatcher]="errorMatcher(firstNameCtrl)"
      />
    </mat-form-field>
    <mat-form-field class="form__item">
      <mat-label>Last name</mat-label>
      <input
        matInput
        [formControl]="lastNameCtrl"
        [errorStateMatcher]="errorMatcher(lastNameCtrl)"
      />
    </mat-form-field>

    <mat-form-field class="form__item">
      <mat-label>Nationality</mat-label>
      <mat-select
        [formControl]="nationalityCtrl"
        [errorStateMatcher]="errorMatcher(nationalityCtrl)"
      >
        <mat-option *ngFor="let item of countries" [value]="item.id"
          >{{item.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="form__col">
    <div class="form__birthdate col__wrapper">
      <mat-form-field class="form__item">
        <mat-label>Date of birth*</mat-label>
        <mat-select
          [formControl]="birthDateCtrl"
          [errorStateMatcher]="errorMatcher(birthDateCtrl, true)"
        >
          <mat-option *ngFor="let item of days" [value]="item.value"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="form__item">
        <mat-label>Month*</mat-label>
        <mat-select
          [formControl]="birthMonthCtrl"
          [errorStateMatcher]="errorMatcher(birthMonthCtrl, true)"
        >
          <mat-option *ngFor="let item of month" [value]="item.value"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="form__item">
        <mat-label>Year*</mat-label>
        <mat-select
          [formControl]="birthYearCtrl"
          [errorStateMatcher]="errorMatcher(birthYearCtrl, true)"
        >
          <mat-option *ngFor="let item of years" [value]="item.value"
            >{{item.name}}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="form__item">
      <mat-label>Birth city</mat-label>
      <input
        matInput
        [formControl]="birthCityCtrl"
        [errorStateMatcher]="errorMatcher(birthCityCtrl)"
      />
    </mat-form-field>
    <mat-form-field class="form__item">
      <mat-label>Birth country</mat-label>
      <mat-select
        [formControl]="birthCountryCtrl"
        [errorStateMatcher]="errorMatcher(birthCountryCtrl)"
      >
        <mat-option *ngFor="let item of countries" [value]="item.id"
          >{{item.name}}</mat-option
        >
      </mat-select>
      <!--<input matInput [formControl]="birthCountryCtrl" [errorStateMatcher]="errorMatcher(birthCountryCtrl)">-->
    </mat-form-field>
  </div>
  <div class="form__col">
    <mat-form-field class="form__item">
      <mat-label>Address</mat-label>
      <input
        matInput
        [formControl]="addressCtrl"
        [errorStateMatcher]="errorMatcher(addressCtrl)"
      />
    </mat-form-field>

    <div class="col__wrapper form__address">
      <mat-form-field class="form__item">
        <mat-label>City</mat-label>
        <input
          matInput
          [formControl]="cityCtrl"
          [errorStateMatcher]="errorMatcher(cityCtrl)"
        />
      </mat-form-field>
      <mat-form-field class="form__item">
        <mat-label>Zip-code</mat-label>
        <input
          matInput
          [formControl]="zipCodeCtrl"
          [errorStateMatcher]="errorMatcher(zipCodeCtrl)"
        />
      </mat-form-field>
    </div>
    <mat-form-field class="form__item">
      <mat-label>Country</mat-label>
      <mat-select
        [formControl]="countryCtrl"
        [errorStateMatcher]="errorMatcher(countryCtrl)"
        (valueChange)="onCountryChange($event)"
      >
        <mat-option *ngFor="let item of countries" [value]="item.id"
          >{{item.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field class="form__item" *ngIf="showRegion && (regions$ | async)">
      <mat-label>Region</mat-label>
      <mat-select
        [formControl]="regionCtrl"
        [errorStateMatcher]="errorMatcher(regionCtrl)"
      >
        <mat-option *ngFor="let item of (regions$ | async)[showRegion]" [value]="item.id"
          >{{item.name}}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</form>
