<form class="login-form" [formGroup]="form">
  <div class="title">Log in</div>
  <div class="item" [class.error]="loginCtrl.errors && submitPressed">
    <i class="far fa-user"></i>
    <input
      type="text"
      placeholder="E-mail"
      class="input login"
      [formControl]="loginCtrl"
      (keyup.enter)="submitEnter($event)"
    />
  </div>
  <div class="item" [class.error]="passwordCtrl.errors && submitPressed">
    <i class="fas fa-key"></i>
    <input
      type="password"
      placeholder="Password"
      class="input password"
      [formControl]="passwordCtrl"
      (keyup.enter)="submitEnter($event)"
    />
    <a class="forgot" [routerLink]="['/api/forgot-password']"
      >Forgot password?</a
    >
  </div>
  <div
    class="item"
    [class.error]="codeTFACtrl?.errors && submitPressed"
    *ngIf="showCodeField"
  >
    <i class="fas fa-key"></i>
    <input
      type="text"
      placeholder="Two-Factor Auth code"
      class="input login"
      [formControl]="codeTFACtrl"
      (keyup.enter)="submitEnter($event)"
    />
  </div>
  <button type="button" class="button yellow" (click)="submit()">Log In</button>
  <!--    <button type="button" class="button button-sign-in button-google" (click)="getOAuthCode(AUTH_METHOD.GOOGLE)">-->
  <!--      <img src="../../../assets/images/social/g-logo.png"-->
  <!--           alt="google logo" title="google logo"-->
  <!--           class="logo__google">-->
  <!--      Sign in with Google-->
  <!--    </button>-->
  <!--    <button type="button" class="button button-sign-in button-apple" (click)="getOAuthCode(AUTH_METHOD.APPLE)">-->
  <!--      <img src="../../../assets/images/social/apple-logo-black.svg"-->
  <!--           alt="apple logo" title="apple logo"-->
  <!--           class="logo__apple">-->
  <!--      Sign in with Apple-->
  <!--    </button>-->
  <button
    type="button"
    class="button button-sign-in button-facebook"
    (click)="fbLogin()"
  >
    <img
      src="../../../assets/images/social/f_logo_RGB-White_144.png"
      alt="facebook logo"
      title="facebook logo"
      class="logo__facebook"
    />
    Log in with facebook
  </button>
</form>
<app-loader class="login-form__loader" *ngIf="isLoading"></app-loader>