import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { VerifyUser } from '@app/interfaces/verify-email.interface';

import { MagicAuthService } from '@services/magic-auth.service';
import { ModalService } from '@services/modal.service';
import { GoogleAnalyticsService } from '@services/google-analytics.service';
import { StorageService } from '../../services/storage.service';

import { MagicVerificationPopupComponent } from '@app/components/popups/magic-verification-popup/magic-verification-popup.component';
import { UserModel } from '@app/models';
import { ProfileService, SettingService } from '@app/services';
import { AuthenticationService } from '@app/services/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-verify-page',
  templateUrl: './verify.html',
  styleUrls: ['./verify.scss'],
})
export class VerifyPageComponent {
  route$: Observable<unknown>;

  constructor(
    private modalService: ModalService,
    private storage: StorageService,
    private setting: SettingService,
    private authService: AuthenticationService,
    private profileService: ProfileService,
    private magicWalletService: MagicAuthService,
    private route: ActivatedRoute,
    private googleAnalytics: GoogleAnalyticsService
  ) {
    const page = this.route.snapshot.paramMap.get('page');

    this.route$ = this.route.queryParams.pipe(
      map((params: Params) => {
        return params?.hasOwnProperty('magic_credential')
          ? params['magic_credential']
          : null;
      }),

      filter(Boolean),
      tap(() => {
        const grsToken: string = this.route.snapshot.queryParams.grstoken;
        if (this.storage.accessToken && this.storage.accessToken !== grsToken) {
          this.authService.logout();
          this.storage.accessToken = grsToken;
        } else if (!this.storage.accessToken && grsToken) {
          this.storage.accessToken = grsToken;
        }
      }),
      switchMap((magicToken: string) => {
        return this.sendMagicToken(magicToken);
      }),
      tap((resp) => {
        if (resp?.token) {
          if (this.storage.user) {
            this.storage.user = { ...this.storage.user, verified: true };
          } else if (resp.doc) {
            const user = new UserModel(this.setting.imgUrl).setFromApiModel(
              resp.doc
            );
            this.storage.user = user.setStorageUser(resp.doc);
            this.storage.user = { ...this.storage.user, verified: true };
            this.storage.accessToken = resp.token.access;
            this.storage.refreshToken = resp.token.refresh;
            this.profileService.emitProfileUpdate(user);
          } else return;

          this.googleAnalytics.logSignIn();
          this.modalService.open(MagicVerificationPopupComponent, page);
        }
      }),

      untilDestroyed(this)
    );

    this.route$.subscribe();
  }

  sendMagicToken(token: string): Observable<VerifyUser> {
    return this.magicWalletService.sendDIDToken(token);
  }
}
