  <mat-form-field class="refund__select-type">
    <mat-label>Refuntd Type*</mat-label>
    <mat-select [formControl]="refundTypeCtrl">
      <mat-option *ngFor="let item of refundT" [value]="item.id"
        >{{item.name}}</mat-option
      >
    </mat-select>
  </mat-form-field>
<p class="refund__text text">
  Refunds will instantly be issued to your Global Rockstar Account, available to <br>
  re-invest in further songs or be issued to your Bank Account within 3-4
  business days.
</p>
