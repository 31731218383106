export class FansModel {
  constructor(props) {
    this.imgUrl = props;
  }

  imgUrl: string;
  slug: string;
  name: string;
  route: string;
  image: string;

  setFromApiModel(apiModel: any): this {
    this.name = apiModel.name;
    this.route = apiModel.route;
    this.slug = apiModel.slug;
    this.image = apiModel.image ? `${this.imgUrl}/images/${apiModel.image}` : null;
    return this;
  }
}
