import {
  Component,
  Input,
  Output,
  EventEmitter, ChangeDetectionStrategy
} from '@angular/core';
import {IEventEmiter} from '../../../interfaces/social-user.interface';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
  menuActive: boolean;
  height: number;
  @Input() value = '';
  @Input() width: number;
  @Input() showValue: number;
  @Input() list: any[];
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {

  }

  selectItem(e, index): void {
    this.menuActive = false;
    const data: IEventEmiter = {type: 'drp', data: e, index};
    this.value = e.value ? e.value : e;
    this.valueChange.emit(data);
  }

  toggleMenu(): void {
    this.menuActive = true;
  }
}
