<!--<ng-template [ngIf]="['headliner', 'newcomer', 'talents'].indexOf(item?.text) >=0">-->
<div class="card-outer info-tab" (click)="openSupplierProfile(item, $event)">
  <div class="card__content">

    <div class="img-block">
      <img src="./assets/images/info-sign2.jpg">
    </div>
    <div class="description gray-text">
                  <span *ngIf="item?.headliner"><b>Headliner Artists</b> have already great successes with millions of
                  streams and international radio plays. <br><br>You invest in <b>keep-it-all</b> opportunities
                    which are hand-picked
                  by Global Rockstar. <br><br> Their songs will be produced in top quality
                    and marketed in any case! <br><br> Special offer:
                    With <b>EP and Album opportunities</b> you will participate in the
                    long-term career build up of Headliners for multiple songs - don’t miss out to be part of their successes.</span>
      <span *ngIf="item?.newcomer"><b>Newcomer Artists</b> are working on their first hit.
                    If you believe in their song, invest and be part of an amazing production. <br><br>Newcomer-songs will be released
                    and marketed only if all shares are sold (<b>all-or-nothing</b> opportunities) - otherwise you will get your
                    investment refunded 1:1.</span>
      <span *ngIf="item?.talents">Do you have a sixth sense for discovering new stars and hits?
                    <br><br> Cast your <b>vote</b> and help to decide which <b>Talents</b> should get
                    the chance to publish an investment opportunity as Newcomer on Global Rockstar.</span>
    </div>
  </div>
  <div class="item-highlighted"
       [class.newcomer]="item?.newcomer"
       [class.talents]="item?.talents"
       [class.headliner]="item?.headliner">
    <span class="text-uppercase">{{item?.text}}</span>
  </div>
</div>
<!--</ng-template>-->
