export class SongModel {
  genre: string;
  name: string;
  producer: string;
  label: string;

  setFromApiModel(apiModel: any): this {
    this.name = apiModel.name;
    this.producer = apiModel.producer;
    this.label = apiModel.label;
    if (Array.isArray(apiModel.genre)) {
      this.genre = '';
      apiModel.genre.forEach((g, i) => {
        this.genre += g.charAt(0).toUpperCase() + g.slice(1);
        if (i < (apiModel.genre.length - 1)) {
          this.genre += ', ';
        }
      });
    } else {
      this.genre = apiModel.genre;
    }
    return this;
  }

  getApiModel(data: any): any {
    const model: any = {
      description: data.description,
      songName: data.songName,
      audio: data.audio,
      songLink: {},
      social: {}
    };
    if (data.instagram) {
      model.social.instagram = data.instagram;
    }
    if (data.youtube) {
      model.social.youtube = data.youtube;
    }
    if (data.spotify) {
      model.social.spotify = data.spotify;
    }
    if (data.soundcloud) {
      model.social.soundcloud = data.soundcloud;
    }
    if (data.dropbox) {
      model.social.dropbox = data.dropbox;
    }
    if (data.tiktok) {
      model.social.tiktok = data.tiktok;
    }
    return model;
  }
}
