import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {StreamState} from '../models/stream-state.model';
import {SettingService} from "@services/setting.service";
import {isPlatformBrowser} from "@angular/common";


@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private stop$ = new Subject();
  private audioObj;
  audioEvents = [
    'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
  ];
  host: any;
  private state: StreamState = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    progress: 0,
    url: '',
    pause: false,
    canplay: false,
    error: false,
    volume: 50,
    muted: false,
    ended: false
  };
  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);
  public projectPlaying: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private settingService: SettingService, @Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.audioObj = new Audio();
    }

    this.host = settingService.imgUrl;
  }

  private streamObservable(url) {
    if (isPlatformBrowser(this.platformId)) {
      return new Observable(observer => {
        // Play audio
        this.audioObj.src = url;
        this.audioObj.load();
        this.audioObj.volume = 0.5;
        this.audioObj.play();
        this.audioObj.ended;

        /** It needs for handling events pause, stop, pause **/

        const handler = (event: Event) => {
          this.updateStateEvents(event);
          observer.next(event);
        };

        this.addEvents(this.audioObj, this.audioEvents, handler);
        return () => {
          // Stop Playing
          this.audioObj.pause();

          this.audioObj.currentTime = 0;
          // remove event listeners
          this.removeEvents(this.audioObj, this.audioEvents, handler);
          // reset state
          this.resetState();
        };
      });
    }
  }

  private addEvents(obj, events, handler) {
    events.forEach(event => {
      obj.addEventListener(event, handler);
    });
  }

  private removeEvents(obj, events, handler) {
    events.forEach(event => {
      obj.removeEventListener(event, handler);
    });
  }

  public playSongAudio(song) {
    /** The same audio track to change on status PAUSE **/
    if (song.soundtrack === this.state.url && this.state.playing) {
      return this.pause();
    }
    /** If play another song to stop previous one **/
    if (this.state) {
      this.state.playing = false;
      this.stateChange.next(this.state);
      this.stop();
    }

    this.state.url = song.soundtrack;

    this.playStream(`${this.host}/${song.soundtrack}`).subscribe();
  }

  public playProjectAudio(project, index) {
    this.stop();
    this.projectPlaying.next(this.prepareProject(project, index));
    this.playStream(`${this.host}/${project.soundtrack}`).subscribe();
  }

  playStream(url) {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  play() {
    this.audioObj.play();
  }

  pause() {
    if (isPlatformBrowser(this.platformId)) {
      this.audioObj.pause();
    }
  }

  stop() {
    this.projectPlaying.next(null);
    this.stop$.next(null);
  }

  mute() {
    if (this.state.muted) {
      this.state.muted = false;
      this.audioObj.muted = false;
    } else {
      this.state.muted = true;
      this.audioObj.muted = true;
    }
  }

  volumeChange(event) {
    const skipTo = Math.round((event.offsetX / event.target.clientWidth) * parseInt(event.target.getAttribute('max'), 10));
    const volume = (1 * skipTo) / 100;
    this.state.volume = volume * 100;
    this.audioObj.volume = volume;
  }

  seekTo(seconds) {
    const currentTime = (seconds * this.audioObj.duration) / 100;
    this.audioObj.currentTime = currentTime;
  }

  formatTime(time: number, format: string = 'mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }


  private updateStateEvents(event: Event): void {
    switch (event.type) {
      case 'canplay':
        this.state.duration = this.audioObj.duration;
        this.state.readableDuration = this.formatTime(this.state.duration);
        this.state.canplay = true;
        break;
      case 'playing':
        this.state.playing = true;
        break;
      case 'pause':
        this.state.playing = false;
        this.state.pause = true;
        break;
      case 'timeupdate':
        this.state.currentTime = this.audioObj.currentTime;
        this.state.readableCurrentTime = this.formatTime(this.state.currentTime);
        const progress = (this.audioObj.currentTime * 100) / this.state.duration;
        this.state.progress = Number(progress) ? (progress > 100 ? 100 : progress) : 0;
        break;
      case 'error':
        this.resetState();
        this.state.error = true;
        break;
      case 'ended':
        this.state.ended = true;
        this.resetState();
        break;

    }
    this.stateChange.next(this.state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      progress: 0,
      error: false,
      volume: 50,
      muted: false,
      ended: false
    };
  }

  getProjectPlaying(): Observable<any> {
    return this.projectPlaying.asObservable();
  }

  getState(): Observable<StreamState> {
    return this.stateChange.asObservable();
  }

  private prepareProject(project, index) {
    return {
      isSold: project.isSold,
      index: index,
      isCollectible: project.isCollectible,
      finished: project.finished,
      releaseCreated: project.releaseCreated,
      slug: project.slug,
      isAlbum: project.isAlbum,
      projectTitle: project.projectTitle,
      song: project.song.name,
      artist: project.artist,
      backgroundImage: project.backgroundImage
    }
  }
}
