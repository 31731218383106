import {
  Component,
  Output,
  EventEmitter,
  HostListener,
  OnInit,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { UserModel } from '@app/models';

// Services
import { ModalService } from '@app/services/modal.service';
import { ProfileService } from '@app/services';
import { MagicAuthService } from '@app/services/magic-auth.service';

@UntilDestroy()
@Component({
  selector: 'app-verify-email-popup',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event: KeyboardEvent) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }

  email: string = null;

  constructor(
    private modalService: ModalService,
    private profileService: ProfileService,
    private magicWalletService: MagicAuthService
  ) {}

  ngOnInit(): void {
    this.profileService
      .getUser()
      .pipe(untilDestroyed(this))
      .subscribe((profile: UserModel) => {
        this.email = profile.contact.email;
      });
  }

  resendEmail(): void {
    this.magicWalletService.generateWallet(this.email);
    this.onClose();
  }

  onClose() {
    this.notify.emit({ type: 'close' });
    this.modalService.close();
  }
}
