import {Component, HostListener, OnInit} from '@angular/core';
import {AppToastrService, ProfileService} from 'app/services';
import {UserModel} from '../../models';
import {STATE_NAMES} from '../../common';
import {RequestModel} from '../../models/request.model';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';


@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.html',
  // styleUrls: ['./_user-dashboard.scss']
})

export class UserDashboardComponent implements OnInit {
  projectList: any[] = [];
  user: UserModel;
  isLoaded = false;
  total: number;
  activeUser: any;
  sumProjects: any[] = [];
  loadedArray: boolean;
  isLoadedAdditional: boolean;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '5%',
    variableWidth: false,
    dots: false,
    nextArrow: '<div class=\'nav-btn next-slide\'><span class="lnr lnr-chevron-left"></span></div>',
    prevArrow: '<div class=\'nav-btn prev-slide\'><span class="lnr lnr-chevron-right"></span></div>',
  };
  paging = {
    offset: 0,
    limit: 8
  };
  artist: any[] = [];

  @HostListener('window:resize') windowResize() {
    if (window.innerWidth < 1024) {
      this.slideConfig.slidesToShow = 3;
    }

  }

  constructor(private projectService: ProjectsService,
              private router: Router,
              private toastr: AppToastrService,
              private profile: ProfileService,
  ) {
  }

  ngOnInit() {
    this.initData();

  }

  initData() {
    this.getUser();
    this.getInvestments();
    this.getFavorites();
  }

  checkFansCount() {
    if (this.artist.length === 1) {
      this.slideConfig.slidesToShow = 1;
    } else if (this.artist.length > 7) {
      this.slideConfig.slidesToShow = 7;
    } else {
      this.slideConfig.slidesToShow = 3;
    }
    if (window.innerWidth < 1024) {
      this.slideConfig.slidesToShow = 3;
      this.slideConfig.centerPadding = '2%';
    }
  }

  getUser() {
    const request = new RequestModel({kyc: true, investment: true});
    this.profile.getUser(request).subscribe((resp) => {
      this.user = resp;

    });
  }


  goTo(type: string) {
    this.router.navigate([`/${STATE_NAMES[type]}`]);
  }

  afterChange(e) {
    if (this.artist && this.artist.length) {
      this.artist.forEach((item, index) => {
        if (e.currentSlide === index) {
          this.activeUser = item;
        }
      });
    }
  }

  slickInit(e) {
    if (this.artist && this.artist.length) {
      setTimeout(() => {
        this.activeUser = this.artist[0];
      }, 4);

    }
  }

  loadMore() {
    this.isLoadedAdditional = false;
    this.paging.offset = this.paging.offset + 8;
    this.getInvestments();
  }

  onPageChange(pageNumber: number) {
    this.paging.offset = pageNumber * this.paging.limit;
    this.getInvestments();
  }

  getFavorites() {
    this.projectService.getFavorites().subscribe(resp => {
      if (resp) {
        this.artist = resp;
        this.checkFansCount();
      }
    }, err => {
      this.toastr.showToastFromError(err);
    });
  }

  getInvestments() {
    this.isLoaded = false;
    const data: any = {
      limit: this.paging.limit,
      offset: this.paging.offset,
    };

    const request = new RequestModel(data);
    this.projectService.getInvestments(request).subscribe(resp => {
      if (resp) {
        const emptyArray = [];
        this.loadedArray = !resp.projects.length && data.offset >= 8;
        this.isLoadedAdditional = true;
        this.sumProjects = [...this.sumProjects, ...resp.projects];
        this.total = resp.total;
        [1, 2, 3].forEach(item => {
          emptyArray.push({isEmptyItem: true});
        });
        this.projectList = [...this.sumProjects, ...emptyArray];
        this.isLoaded = true;
      }
    }, err => {
      this.toastr.showToastFromError(err);
      this.isLoaded = true;
      this.isLoadedAdditional = true;
    });
  }

  get kycFailedReason(): string {
    if(this.user?.kycFailedReason === 'Mangopay STATUS CREATED') {
      return 'KYC verification failed. Please contact support.'
    }
    return `IDENTITY_PROOF: ${this.user?.kycFailedReason}`
  }
}
