import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

// Consts
import { STATE_NAMES } from '.';

// Services
import { AuthenticationService } from '@services/authentication.service';
import { StorageService } from '@app/services/storage.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  private authService: AuthenticationService;

  constructor(
    private injector: Injector,
    private router: Router,
    private storageService: StorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            if (
              this.router.routerState.snapshot.url.includes(STATE_NAMES.wizard)
            ) {
              return;
            }
            const lastAttemptedUrl = this.checkRoute(
              this.router.routerState.snapshot.url
            );
            this.authService = this.injector.get(AuthenticationService);
            this.authService.logout(true);
            this.storageService.setStorageItem(
              'lastAttemptedUrl',
              lastAttemptedUrl
            );
          }
        }
      )
    );
  }

  private checkRoute(urlToCheck: string): string {
    const forbidden: string[] = [STATE_NAMES.login];
    const isForbidden: boolean = forbidden.some((forbiddenUrl) => {
      return urlToCheck.includes(forbiddenUrl);
    });
    return isForbidden ? '' : urlToCheck;
  }
}
