export const STATE_NAMES = {
  about: 'about-grs',
  app: 'app',
  artists: 'app.artists',
  artistSong: 'api/artist-song',
  artistSubmission: 'api/artist-submission',
  balance: 'api/transactions',
  bankAccount: 'api/bank-account',
  base: '/',
  calculate: 'api/calculate',
  changePassword: 'api/change-password',
  collectible: 'collectible',
  dashboard: 'api/dashboard',
  forgot: 'api/forgot-password',
  grsLabel: 'label',
  home: '',
  howItWorks: 'how-it-works',
  howItWorksArtist: 'app.howItWorksArtist',
  imprint: 'app.imprint',
  investments: 'api/investments',
  investors: 'app.investors',
  landing: 'api/landing',
  login: 'api/login',
  logout: 'logout',
  marketplace: 'api/marketplace',
  myAuction: 'api/my-auction',
  myProjects: 'api/my-projects',
  nft: 'api/my-nft',
  nftWizard: 'api/nft-wizard',
  opportunity: 'opportunity',
  profile: 'api/profile',
  projects: 'projects',
  projectsItem: 'projects',
  projectsItemUpcoming: 'project-component-upcoming',
  register: 'api/register',
  revenue: 'api/revenues',
  sellBid: 'api/sell-bid',
  team: 'app.team',
  terms: 'app.terms',
  verify: 'api/verify',
  voucher: 'api/voucher',
  voucherStatus: 'api/voucher/vouchersuccess',
  withdraw: 'api/withdraw',
  wizard: 'api/wizard',
  wizardNFT: 'api/nft-wizard',
  wizardStatus: 'api/wizard/investmentsuccess',
};

export const STATE_NAMES_ALIAS: any = {
  forArtists: `${STATE_NAMES.landing}/for-artists`,
}
