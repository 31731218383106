import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

// Services
import { GetLocaleService } from '@app/services/get-locale.service';
@UntilDestroy()
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  isUSA$: Observable<boolean> = this.localeService.isUsLocale$.pipe(
    untilDestroyed(this)
  );

  constructor(private localeService: GetLocaleService) {}
}
