import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// Types
import { Collection } from '@app/types';

// Services
import { ModalService } from '@app/services/modal.service';

@Component({
  selector: 'app-reminder-complete-profile',
  templateUrl: './reminder-complete-profile.component.html',
})
export class ReminderCompleteProfileComponent {
  @Output() notify: EventEmitter<Collection> = new EventEmitter<Collection>();

  constructor(private modalService: ModalService, private router: Router) {}

  onClose(): void {
    this.notify.emit({ type: 'closePopup' });
    this.modalService.close();
  }

  onSubmit(): void {
    this.router.navigate(['/api/profile']);
    this.modalService.close();
  }
}
