import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import {CARD_TOOLTIP_CONTENT} from "@app/common/content/card-page.content";
import {STATE_NAMES} from "@app/common";
import {PROJECT_TYPE} from "@app/common/content/artist-category";

@Component({
  selector: 'app-card-tooltip-rights',
  templateUrl: './card-tooltip-rights.html',
  styleUrls: ['./card-tooltip-rights.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardTooltipRightsComponent implements OnInit {
  CARD_TOOLTIP_CONTENT = CARD_TOOLTIP_CONTENT;
  STATE_NAMES = STATE_NAMES;
  @Input() project: any;


  constructor(
    private router: Router,
  ) {}

  ngOnInit(): void {

  }
  learnMore(e): void {
    e.preventDefault();
    e.stopPropagation();
    this.router.navigate([`/${STATE_NAMES.howItWorks}`]);
  }
  musType(){
    return (!this.project.royaltiesFromMaster && !this.project.royaltiesFromPublishingRights) || this.project.artistCategory === PROJECT_TYPE.exclusive;
  }
}
