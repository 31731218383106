<table class="bids__table">
  <thead>
  <tr class="table-header">
    <th class='max-screen column mid-screen'>Date</th>
    <th class='max-screen column'>Time</th>
    <th class='max-screen column mid-screen'>Song Name</th>
    <th class='max-screen column mid-screen'>Bid Amount</th>
    <th class='max-screen column mid-screen'>Status</th>
    <th class='max-screen column mid-screen'></th>
  </tr>
  </thead>
  <tbody>
  <ng-template ngFor let-bid [ngForOf]="tokenBids" let-i="index">
    <tr class="border_bottom bids__row ">
      <td class='max-screen mid-screen'>
        <span class="d-date">{{bid?.bidDate}}</span>
        <span class="m-date">{{bid?.bidDateMonth}}</span>
      </td>
      <td class='max-screen mid-screen'>
        {{bid?.bidTime}}
      </td>
      <td class='max-screen mid-screen bid-song-name'>
        {{project?.song}}
      </td>
      <td class='max-screen mid-screen bid-amount'>
        {{bid?.amount}}
      </td>
      <td class='max-screen mid-screen bid-status'
          [class.isLoosing]="!bid?.isWinning"
          [class.isWinning]="bid?.isWinning">
        <span class="d-date">{{bid?.isWinning ? 'Bid qualified ' : 'Bid too low '}}</span>
        <span class="m-date">{{bid?.isWinning ? 'Bid qualified ' : 'Bid too low '}}{{ bid?.isWinning ? (bid?.amount | localeCurrency) : ''}}</span>
      </td>
      <td class='max-screen mid-screen'>
        <button class="button yellow bids__button"
                [class.disable]="bid?.submitBtn"
                (click)="updateItemBid(bid)">Increase
          <mat-spinner id="button-spinner" [diameter]="20" *ngIf="bid?.submitBtn"></mat-spinner>
        </button>
      </td>
    </tr>

  </ng-template>
  </tbody>
</table>
<app-bid-popup *ngIf="showPopup" (notify)="onNotify($event)"></app-bid-popup>
