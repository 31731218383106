<ul class="music-cards"  *ngIf="projectList?.length">
  <li class="music-cards__item" *ngFor="let item of projectList; let i = index">
    <app-card-project
      *ngIf="!item.isNews && !item?.isEmptyItem && !item.infoCard"
      [exportItem]="true"
      [index]="i"
      [enableTimer]="enableTimer"
      (enablePlayer)="getVideoObject($event)"
      [item]="item">
    </app-card-project>
    <app-card-news
      *ngIf="item.isNews && item.isPublished && !item?.isEmptyItem && !item.infoCard"
      [item]="item">
    </app-card-news>
    <app-card-information
      *ngIf="item?.infoCard"
      [item]="item">
    </app-card-information>
    <ng-template [ngIf]="item.isEmptyItem">
      <div class="empty-item"></div>
    </ng-template>
  </li>
</ul>

<ng-template [ngIf]="!exportItem">
  <app-video-player *ngIf="enableVideoPlayer"
                    [video]="video"
                    [project]="cardProject"
                    [user]="user"
                    [stateVideo]="state"
                    (notify)="stopPlayer($event)"></app-video-player>
</ng-template>

<app-song-preview
  [user]="user"
></app-song-preview>
