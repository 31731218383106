<div class="disclaimer">
  <h4 class="disclaimer__title m-b-15">Disclaimer</h4>
  <p class="disclaimer__text">
    Before investing carefully consider the underlying funds 'objectives, risks,
    charges, and expenses.
  </p>
  <p class="disclaimer__text">
    All investments involve risk, including loss of principal. Past performance
    does not guarantee future results. There is no assurance that the investment
    process will consistently lead to successful investing.
  </p>
  <p class="disclaimer__text">
    Asset allocation and diversification do not eliminate the risk of
    experiencing investment losses.
  </p>
  <p class="disclaimer__text">
    The information provided on this website does not, and is not intended to,
    constitute legal advice; instead, all information, content, generated
    suggestions and materials available on this site are for general
    informational purposes only.
  </p>
  <p class="disclaimer__text m-b-15">
    Information on this website may not constitute the most up-to-date legal or
    other information.
  </p>
  <p class="disclaimer__text">
    Users of this website should contact their attorney to obtain advice with
    respect to any particular legal matter.
  </p>
  <p class="disclaimer__text">
    No reader, user, or browser of this site should act or refrain from acting
    on the basis of information on this site without first seking legal advice
    from counsel in the relevant jurisdiction. Only your individual attorney can
    provide assurances that the information contained herein - and your
    interpretation of it - is applicable or appropriate to your particular
    situation.
  </p>
</div>

<div class="top-wrapper">
  <div class="item web">
    <div class="title">About</div>
    <div class="links">
      <a
        (click)="navigateTo('about-grs', $event)"
        href="https://www.globalrockstar.com/about-grs"
        class="nowrap"
        >About us</a
      >
      <span class="spacer">|</span>
      <a href="mailto:office@globalrockstar.com">Contact</a>
      <span class="spacer">|</span>
      <a
        (click)="navigateTo('how-it-works', $event)"
        class="nowrap"
        href="https://www.globalrockstar.com/how-it-works"
        >How it works</a
      >
    </div>
  </div>
  <div class="item web legal">
    <div class="title">Legal</div>
    <div class="links">
      <a [grsOpenFile]="'terms'">Terms</a>
      <span class="spacer">|</span>
      <a
        (click)="navigateTo('api/imprint', $event)"
        href="https://www.globalrockstar.com/api/imprint"
        >Imprint</a
      >
      <span class="spacer">|</span>
      <a [grsOpenFile]="'mangopay'" target="_blank">Mangopay</a>
    </div>
  </div>
  <div class="mobile-rotation">
    <div class="item">
      <div class="title">About</div>
      <div class="links">
        <a
          (click)="navigateTo('how-it-works', $event)"
          class="nowrap"
          href="https://www.globalrockstar.com/how-it-works"
          >How it works</a
        >
        <a href="mailto:office@globalrockstar.com">Contact</a>
        <a
          (click)="navigateTo('about-grs', $event)"
          href="https://www.globalrockstar.com/about-grs"
          class="nowrap"
          >About us</a
        >
      </div>
    </div>
    <div class="item">
      <div class="title">Legal</div>
      <div class="links">
        <a [grsOpenFile]="'terms'">Terms</a>
        <a
          (click)="navigateTo('api/imprint', $event)"
          href="https://www.globalrockstar.com/api/imprint"
          >Imprint</a
        >
        <a [grsOpenFile]="'mangopay'" target="_blank">Mangopay</a>
      </div>
    </div>
  </div>
  <div class="item social">
    <a
      href="https://twitter.com/Global_Rockstar"
      target="_blank"
      class="footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar twitter"
        title="GlobalRockstar twitter"
        src="./assets/images/social/twitter-footer.png"
      />
    </a>
    <a
      href="https://www.facebook.com/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar facebook"
        title="GlobalRockstar facebook"
        src="./assets/images/social/facebook-footer.png"
      />
    </a>
    <a
      href="https://www.youtube.com/user/TheGlobalRockstar"
      target="_blank"
      class="footer-social footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar youtube"
        title="GlobalRockstar youtube"
        src="./assets/images/social/youtube-footer.png"
      />
    </a>
    <a
      href="https://www.instagram.com/globalrockstarmusic/?hl=de"
      target="_blank"
      class="footer-social footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar instagram"
        title="GlobalRockstar instagram"
        src="./assets/images/social/instagram-footer.png"
      />
    </a>
    <a
      href="https://at.linkedin.com/company/global-rockstar-gmbh"
      target="_blank"
      class="footer-social footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar linkedin"
        title="GlobalRockstar linkedin"
        src="./assets/images/social/linkedin-footer.png"
      />
    </a>
    <a
      href="https://www.tiktok.com/@globalrockstar"
      target="_blank"
      class="footer-social footer-social"
    >
      <img
        class="project-highlighted__logo"
        loading="lazy"
        alt="globalrockstar tiktok"
        title="GlobalRockstar tiktok"
        src="./assets/images/social/tiktok-footer.png"
      />
    </a>
  </div>
  <div class="item img-wrapper-payment">
    <div class="img-wrapper-image">
      <img
        src="assets/images/footer/visa-white.svg"
        alt="visa-icon"
        loading="lazy"
        title="VISA"
        class="footer-payment"
      />
    </div>
    <div class="img-wrapper-image">
      <img
        src="assets/images/footer/master-card.svg"
        alt="mastercard-icon"
        loading="lazy"
        title="mastercard"
        class="footer-payment"
      />
    </div>
    <div class="img-wrapper-image">
      <img
        src="assets/images/footer/usa-payment.png"
        alt="icon"
        loading="lazy"
        class="footer-payment"
      />
    </div>
  </div>
</div>
<div class="bottom-wrapper web-footer">
  <div class="flex-align bottom-wrapper__info">
    <div class="img-wrapper">
      <img
        src="assets/images/logo/grs-x-idx-logo.png"
        alt="globalrockstar logo"
        (click)="navigateTo('app.home')"
        class="logo"
      />
    </div>
    <div class="bottom-wrapper__copy">
      Copyright © {{ globalTime }} Global Rockstar<br />All rights reserved.
      Worldwide.
    </div>
  </div>
  <img
    src="assets/images/footer/payment-systems-usa.png"
    alt="icons"
    class="bottom-wrapper__payments"
  />
</div>
