import {Component, Output, EventEmitter, HostListener, OnInit, Input} from '@angular/core';
import {ProjectsService} from '../../../services/projects.service';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.html'
})


export class SharePopupComponent implements OnInit {
  projectImage: string;
  songname: string;
  artistname: string;
  biography: string;
  @Input() config: any;
  @Input() slug: string;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(private projectservice: ProjectsService) {
  }

  ngOnInit() {
    this.projectservice.getProject(this.slug).subscribe(data => {
      if (data) {
        this.projectImage = data.image;
        this.songname = data.song?.name;
        this.artistname = data.artist?.name;
        this.biography = data.biography.substr(0, data.biography.indexOf('.'));
        this.biography = this.biography.replace(new RegExp('<p>', 'g'), '');
        this.biography = this.biography.replace(new RegExp('</p>', 'g'), '');
      }
    });
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }
}
