import {Injectable} from '@angular/core';
import {SettingService} from "@services/setting.service";

@Injectable({providedIn: 'root'})
export class AppleSignUpService {
  constructor(private settingService: SettingService) {
  }
  signInWithApple() {
    this.initAppleSignUp();
    (window as any).AppleID.auth.signIn();
  }

  private initAppleSignUp() {
    if ((window as any).AppleID) {
      (window as any).AppleID.auth.init({
        clientId: this.settingService.appleClientId,
        scope: 'name email',
        redirectURI: this.settingService.appleRedirectUrl,
        state: 'grs sign in',
        usePopup: false
      });
    }
  }
}
