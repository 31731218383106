import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {StreamState} from '../models/stream-state.model';


@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  private stop$ = new Subject();
  private songPlaying: BehaviorSubject<any> = new BehaviorSubject(null);
  audioEvents = [
    'ended', 'error', 'play', 'playing', 'pause', 'timeupdate', 'canplay', 'loadedmetadata', 'loadstart'
  ];
  private state: any = {
    playing: false,
    readableCurrentTime: '',
    readableDuration: '',
    duration: undefined,
    currentTime: undefined,
    progress: 0,
    url: '',
    canplay: false,
    error: false,
  };
  private stateChange: BehaviorSubject<StreamState> = new BehaviorSubject(this.state);

  private streamObservable(url) {

    return new Observable(observer => {
      // Play audio
      const audioObj = new Audio();
      audioObj.src = url;
      audioObj.load();
      // audioObj.play();
      const state = {
        playing: false,
        readableCurrentTime: '',
        readableDuration: '',
        duration: undefined,
        currentTime: undefined,
        canplay: false,
        audPlayer: null,
        url: '',
        progress: 0,
        error: false
      };
      const handler = (event: Event, urlrem, audioObjO, stateParam) => {
        this.updateStateEvents(event, urlrem, audioObjO, stateParam);
        observer.next(event);
      };

      this.addEvents(audioObj, this.audioEvents, url, audioObj, state, handler);
      return () => {
        // Stop Playing
        audioObj.pause();
        audioObj.currentTime = 0;
        // remove event listeners
        this.removeEvents(audioObj, this.audioEvents, url, handler);
        // reset state
        this.resetState();
      };
    });
  }

  private addEvents(obj, events, url, audioObj, stateParam, handler) {
    events.forEach(event => {
      obj.addEventListener(event, (e) => {
          handler(e, url, audioObj, stateParam);
        }
      )
      ;
    });
  }

  private removeEvents(obj, events, url, handler) {
    events.forEach(event => {
      obj.removeEventListener(event, (e) => {
        handler(e, url);
      });
    });
  }

  playStream(url) {
    return this.streamObservable(url).pipe(takeUntil(this.stop$));
  }

  play(state) {

    const songIsPlaying = this.songPlaying.getValue();
    if(songIsPlaying){
      this.pause(songIsPlaying);
    }
    this.songPlaying.next(state);
    state.play();

  }

  pause(state) {
    state.pause();
  }

  stop(state?) {
    if (state) {
      state.load();
    }
    this.stop$.next(null);
  }

  reset() {
    this.resetState();
  }

  // seekTo(seconds) {
  //   this.audioObj.currentTime = seconds;
  // }

  formatTime(time: number, format: string = 'mm:ss') {
    const momentTime = time * 1000;
    return moment.utc(momentTime).format(format);
  }


  private updateStateEvents(event: Event, url, audioObj, state): void {
    switch (event.type) {
      case 'canplay':
        state.duration = audioObj.duration;
        state.readableDuration = this.formatTime(state.duration);
        state.canplay = true;
        break;
      case 'playing':
        state.playing = true;
        break;
      case 'pause':
        state.playing = false;
        break;
      case 'timeupdate':
        state.currentTime = audioObj.currentTime;
        state.readableCurrentTime = this.formatTime(state.currentTime);
        const progress = (audioObj.currentTime * 100) / state.duration;
        state.progress = Number(progress) ? (progress > 100 ? 100 : progress) : 0;
        break;
      case 'error':
        this.resetState();
        state.error = true;
        break;
    }
    state.url = url;
    state.audPlayer = audioObj;
    this.stateChange.next(state);
  }

  private resetState() {
    this.state = {
      playing: false,
      readableCurrentTime: '',
      readableDuration: '',
      duration: undefined,
      currentTime: undefined,
      canplay: false,
      url: '',
      progress: 0,
      error: false
    };
  }

  getState(): Observable<any> {
    return this.stateChange.asObservable();
  }
}
