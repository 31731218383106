import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {SliderModel} from '../../models';
import {ImageService} from '../../services';
import {NewsCardModel} from '../../models/newsCard.model';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-news-slider',
  templateUrl: './news-slider.component.html',
  styleUrls: ['./news-slider.component.scss']
})
export class NewsSliderComponent implements OnInit {
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: false,
    autoplaySpeed: 5000,
    nextArrow: '<div class=\'nav-btn prev-slide\' style=""><span class="lnr lnr-chevron-right"></span></div>',
    prevArrow: '<div class=\'nav-btn next-slide\' style=""><span class="lnr lnr-chevron-left"></span></div>',
  };
  slideWrapper: any;
  slide: any;
  user: any;
  sliders: {
    title: string,
    list: SliderModel[]
  };
  mobile: boolean;
  visibleNews: any[];
  @Input() list: NewsCardModel[];
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  @HostListener('window:resize') windowResize() {
    if (window.innerWidth > 550) {
      this.mobile = false;
    }
    if (window.innerWidth < 550) {
      this.mobile = true;
    }
  }

  constructor(
    private imageService: ImageService,
    private storageService: StorageService,
  ) {
  }

  ngOnInit() {
    this.user = this.storageService.user;
    this.windowResize();
    if (this.list.length === 1){
      this.slideConfig.dots = false;
    }
    this.visibleNews = this.list.slice(0, 3);
  }

  trackByFn(index, item) {
    return item.id;
  }
}
