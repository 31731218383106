import {ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {Router} from '@angular/router';
import {isPlatformBrowser} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {COOKIES, STATE_NAMES} from '../../common';
import {RoutingService} from '../../services/routing.service';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.html',
  styleUrls: ['./cookies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CookiesComponent implements OnInit {
  showCookies: boolean;
  allCookies: any;
  openSettings: boolean;
  showSubscribe: boolean;
  settingsToggle = {
    statistics: true,
    essential: true,
    marketing: true
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cookieService: CookieService,
    private routingService: RoutingService,
    private storage: StorageService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.cookies();
    }
  }

  cookies(): void {
    const notification = this.cookieService.get('notification');
    this.allCookies = this.cookieService.getAll();
    const cookieSettings = this.storage.cookiesSetting ? JSON.parse(this.storage.cookiesSetting) : null;
    if (!notification) {
      this.showCookies = true;
    }
    if (notification && cookieSettings) {
      this.checkCookies();
    }
  }

  getCookiesSettings(): void {
    this.openSettings = true;
  }

  updateNotification(type): void {
    this.showCookies = false;
    const parsed = JSON.parse(this.cookieService.get('notification') || '{}');
    parsed[type] = true;
    const date = new Date();
    date.setDate(date.getDate() + 365 * 3);
    document.cookie = 'notification' + '=' + JSON.stringify(parsed) + '; expires=' + date.toUTCString() + '; path=/';
  }

  applyCookiesSettings(): void {
    this.storage.cookiesSetting = JSON.stringify(this.settingsToggle);
    this.checkCookies(true);
    this.updateNotification('cookies');
  }

  checkCookies(ifReload?: boolean): void {
    let reload = false;
    if (!this.settingsToggle.marketing) {
      reload = true;
      const names = COOKIES.marketing;
      names.forEach(item => {
        for (const key in this.allCookies) {
          if (this.allCookies.hasOwnProperty(key) && key === item) {
            this.cookieService.delete(key);
          }
        }
      });
    }
    if (!this.settingsToggle.statistics) {
      reload = true;
      const names = COOKIES.statistics;
      names.forEach(item => {
        for (const key in this.allCookies) {
          if (this.allCookies.hasOwnProperty(key) && key.indexOf(item) >= 0) {
            this.cookieService.delete(key);
          }
        }
      });
    }
    if (reload && ifReload) {
      this.router.navigate([`/${STATE_NAMES.home}`]);
    }
  }
}
