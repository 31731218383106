import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Modules
import { DirectivesModule } from '@app/directives/directives.module';

// Components
import { GlobalFooterComponent } from './global-footer/global-footer.component';
import { UsaFooterComponent } from './usa-footer/usa-footer.component';
import { FooterComponent } from './footer.component';
@NgModule({
  imports: [CommonModule, DirectivesModule],
  exports: [FooterComponent, GlobalFooterComponent, UsaFooterComponent],
  declarations: [GlobalFooterComponent, UsaFooterComponent, FooterComponent],
})
export class FooterModule {}
