<div class="wrapper">
  <div class="inner" id="calculation">
    <div class="calculation__inner"
         [class.blur]="project?.finished || project?.projectNotStarted || project?.isFunded"
         *ngIf="project?.isFunded || (!project?.isFunded && project?.daysLeft >= 0 && !project?.isFailed) || project?.isCollectible">

      <div class="text-uppercase profile-title" *ngIf="project.isCollectible">PLACE YOUR BID</div>
      <div class="select-share" *ngIf="!project.isCollectible">

        <app-select-share
          (selectedAmount)="selectAmountToPay($event)"
          [project]="project">
        </app-select-share>
      </div>
      <ng-template [ngIf]="project?.isCollectible">

        <input type="text"
               placeholder="0" class="input-box" [(ngModel)]="bidAmount">
        <div class="amount-not-allowed">
          <img class="amount-bid" src="./assets/images/project/bid.png"  alt="bid">
          Minimum Bid: {{project?.startingPrice | localeCurrency}}
        </div>
        <a href="#" type="button"
           [class.disable]="submitBtn || (project?.offeredBy === user?.nickname)"
           class="button yellow align-btn default-link"
           (click)="_makeBid($event)">
          Bid now
          <mat-spinner [diameter]="30" *ngIf="submitBtn"></mat-spinner>
        </a>
        <div class="calculation__description">
          User can place bid during the allowed time period. Once the period of time has concluded, the # of editions
          highest bidders will all receive one of the editions, and will pay the price that they bid on the piece.
        </div>
        <div class="calculation__timer"
             *ngIf="!project?.finished && project?.isCollectible && !project?.projectNotStarted">
          <app-timer *ngIf="project"
                     [project]="project" [config]="config"></app-timer>
        </div>
      </ng-template>

      <a href="#" *ngIf="!project?.isCollectible" type="button"
         class="button yellow align-btn default-link"
         (click)="goToShare($event, payAmount > 5000)" [class.disabled]="payAmount > 5000 || project?.isFunded">
        Purchase now
      </a>

      <div class="calculation__description">
        <ng-template [ngIf]="!project?.isCollectible">
          Maximum amount per payment: 2,500€
        </ng-template>
      </div>
      <div class="calculation__timer" *ngIf="project && !project?.isCollectible && !project?.isFunded">
        <app-timer *ngIf="project" [project]="project" [config]="config"></app-timer>
      </div>
    </div>


    <div class="bid-closed"
         *ngIf="project?.finished || project?.projectNotStarted || project?.isFunded">
      <ng-template [ngIf]="project?.projectNotStarted && !project?.finished" id="project-not-started">
        <ng-template [ngIf]="!remindMeStatus && !project?.reminderExist" id="project-not-started-remind-user">
          <ng-container *ngTemplateOutlet="notStartedCollectible; context: {project: project}"></ng-container>
        </ng-template>
        <ng-template [ngIf]="remindMeStatus || project?.reminderExist" id="project-not-started-remind-user-submitted">
          <ng-container *ngTemplateOutlet="userSubscribedCollectible; context: {project: project}"></ng-container>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="project?.finished && !project?.projectNotStarted || project?.isFunded" id="project-sold-out">
        <ng-template [ngIf]="!user" id="user-not-from-grs">
          <div class="bid-closed__sold-out">
            sold out
          </div>
          <p class="bid-closed__text">
            Join Global Rockstar and
            Never Miss an Opportunity Again!
          </p>
          <a id="bid-closed__button-join" (click)="gotToRegister($event)" class="button yellow bid-closed__button"
             href="#">
            Join now
          </a>
        </ng-template>

        <ng-template [ngIf]="user && !newsLetterNotification && !sendNewsletter">
          <div class="bid-closed__sold-out">
            sold out
          </div>
          <p class="bid-closed__text bid-closed__sing-up-text">
            Sign Up for Our Newsletter and
            Never Miss an Opportunity Again!
          </p>
          <input type="email"
                 placeholder="Enter email address"
                 class="bid__reminder input-box"
                 [formControl]="emailCtrl">
          <a id="bid-closed__sing-up" (click)="subscribeNewsletter($event)" class="button yellow bid-closed__button"
             href="#">
            Sign Up now
          </a>
          <p class="bid__text">By submitting this form, I agree to the Global Rockstar <br><a
            class="link-blue" [grsOpenFile]="'terms'">Terms & Conditions</a> and
            <a class="link-blue" [grsOpenFile]="'dataPolicy'">Privacy Policy.</a></p>
        </ng-template>
        <ng-template [ngIf]="user && !newsLetterNotification && sendNewsletter">
          <ng-container *ngTemplateOutlet="userSubscribedCollectible; context: {project: project}"></ng-container>
        </ng-template>
        <ng-template [ngIf]="user && newsLetterNotification">
          <div class="bid-closed__sold-out">
            sold out
          </div>
          <p class="bid-closed__text">Check out our other opportunities!</p>
          <a (click)="goToHome($event)" href="https://www.globalrockstar.com"
             class="button yellow bid-closed__button button-explore">
            Explore now
          </a>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
<ng-template #notStartedCollectible let-project="project">
  <div class="collectible-scheduled">
    <div class="calculation__timer" *ngIf="project && !project?.finished">
      <app-timer [project]="innerProject"></app-timer>
    </div>
    <input type="email"
           placeholder="Enter email address"
           class="bid__reminder input-box"
           [formControl]="emailCtrl">
    <a id="bid-closed__button" [class.disable]="!form?.valid || remindMeStatus" (click)="remindMe($event)"
       class="button yellow bid-closed__button" href="#">
      Remind me
    </a>
    <p class="bid__text">By submitting this form, I agree to the Global Rockstar <br><a
      class="link-blue" [grsOpenFile]="'terms'">Terms & Conditions</a> and
      <a class="link-blue" [grsOpenFile]="'dataPolicy'">Privacy Policy.</a></p>
  </div>
</ng-template>

<ng-template #userSubscribedCollectible let-project="project">
  <div class="bid-closed__remind-submitted">
    <img class="image image-check" src="./assets/images/be-icons/check.png" alt="check">
    <p>You’re all set. In the meantime
      check out our other opportunities!</p>
    <a id="bid-closed__button-explore" (click)="goToHome($event)" href="https://www.globalrockstar.com"
       class="button yellow bid-closed__button button-explore">
      Explore now
    </a>
  </div>
</ng-template>
<app-reminder-kyc-popup *ngIf="showPopup"
                        [bidAmount]="bidAmount"
                        [user]="user" (notify)="addWallet($event)">
</app-reminder-kyc-popup>

<app-alert-popup
  *ngIf="getShare"
  [image]="'be-icons/reminder.jpg'"
  (closeEmit)="alertPopupHandle(true)"
>
  Due to local laws and regulations Securities in Music Shares are currently not available to residents of the
  <b>United States.</b> We are working tirelessy on enabling these investments to all users worldwide in the near future and
  ask for your understanding.<br><br>
  In the meantime keep your eye out for Music Collectibles. These are <b>available to all users!</b>
  <div class="button-wrapper">
    <button class="button yellow"
            type="button"
            (click)="alertPopupHandle()">
      EXPLORE NOW
    </button>
  </div>
</app-alert-popup>
