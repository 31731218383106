<app-popup class="app-popup-shared">
  <div class="inner">
    <app-popup-part-header
      [icon]="'./assets/images/be-icons/reminder.PNG'"
      (notify)="onClose()"
    >
      <p class="popup-sub-title">
        In order to be KYC verified and invest in an opportunity, it is
        necessary to complete your profile.
      </p>
    </app-popup-part-header>
    <div class="popup-content">
      <button
        class="button yellow text-uppercase popup-button"
        (click)="onSubmit()"
      >
        Go to Profile
      </button>
    </div>
  </div>
</app-popup>
