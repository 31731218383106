import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output, ViewChild,
} from '@angular/core';

import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WizardService} from "@services/wizard.service";
import {IInvestParam} from "@app/interfaces/payment.interface";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {combineLatest, map, Observable} from "rxjs";
import {CalculationService} from "@app/components/calculation/service/calculation.service";
import {SettingService} from "@app/services";

@UntilDestroy()
@Component({
  selector: 'app-select-share',
  templateUrl: './select-share.html',
  styleUrls: ['./select-share.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectShareComponent implements OnInit, AfterViewInit {
  form: FormGroup;
  sliderValue: number = 0;
  priceForShare: number;
  baseAmount: number;

  public settings$: Observable<any>;
  lastUpdated: { share: number, slider: number } = {share: 0, slider: 0};
  thumbImageClass = 'thumb-image-euro';
  @Input() project: any;
  @Output() selectedAmount: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('sliderInput', {static: false}) sliderInput: ElementRef;

  constructor(
    private wizardService: WizardService,
    private settingsService: SettingService,
    private calculationService: CalculationService,
    private fb: FormBuilder,
  ) {
    this.settings$ = this.calculationService.displaySettings$;
  }

  ngOnInit() {
    this.definePricePerShare();
    this.initForm();
    this.calculationService.calculateSettings();
  }

  ngAfterViewInit() {
    this.listenChange();
    this.setValue();
  }

  get formPrice() {
    return this.form.controls.price;
  }

  get controlShare() {
    return this.form.controls.share;
  }

  get controlSlider() {
    return this.form.controls.slider;
  }

  get sharesValue() {
    let amount = this.controlShare.value.toString();
    return amount.indexOf('%') >= 0 ? amount.split('%')[0] : amount;
  }

  get shareDecimal() {
    return this.project.decimalType && this.project.decimalType === 'hundred' ? 1 / 100 : 1 / 10;
  }

  get pricePerShareMin() {
    return this.baseAmount * this.shareDecimal;
  }
  private setValue() {
    const initShare = this.project.displayShare || this.shareDecimal;
    this.controlShare.setValue(initShare);
    this.controlSlider.setValue(initShare);
  }

  onInputChange() {
    if (this.sliderInput) {
      const progress = (this.sharesValue / this.calculationService.getMaxAmount()) * 100;
      this.sliderValue = progress;
      const sliderEl = this.sliderInput.nativeElement;
      if (sliderEl && sliderEl.style) {
        sliderEl.style.background = `linear-gradient(to right, #5AA2E5 ${progress}%, #fff ${progress}%)`;
      }
    }
  }

  definePricePerShare() {
    const sale = this.project.saleAmount;
    this.priceForShare = this.wizardService.priceForShare(this.project.priceForShare);
    this.baseAmount = sale ? this.priceForShare - (this.priceForShare * (sale / 100)) : this.priceForShare;
    this.calculationService.tabIsActive(this.baseAmount, this.project.totalInvestorsLeft, this.shareDecimal);
  }


  getCurrencySign() {
    return this.settingsService.currencySymol;
  }

  removeSignCurrency(value) {
    let amount = value.toString();
    const currency = this.getCurrencySign();
    const numericValue = amount.indexOf(currency) >= 0 ? amount.split(currency)[1] : amount;
    return parseFloat(numericValue);
  }

  removeSign(value) {
    let amount = value.toString();
    const numericValue = amount.indexOf('%') >= 0 ? amount.split('%')[0] : amount;
    return parseFloat(numericValue);
  }

  increasePrice() {
    const amount = this.removeSignCurrency(this.formPrice.value);
    if (amount >= (this.calculationService.getMaxAmount() * this.baseAmount)) {
      return false;
    }

    const chAmount = this.roundValue(amount + this.pricePerShareMin);
    const share = this.roundValue(chAmount * this.shareDecimal / this.pricePerShareMin);
    this.updateShare(share);
    this.updatePrice(chAmount);
  }

  decreasePrice() {
    const amount = this.removeSignCurrency(this.formPrice.value);
    if (amount <= this.pricePerShareMin) {
      return false;
    }
    const chAmount = this.roundValue(amount - this.pricePerShareMin);
    const share = this.roundValue(chAmount * this.shareDecimal / this.pricePerShareMin);
    this.updateShare(share);
    this.updatePrice(chAmount);
  }


  increaseShare() {
    const amount = this.sharesValue;
    if (amount >= this.calculationService.getMaxAmount()) {
      return false;
    }

    const chAmount = this.roundValue(+amount + this.shareDecimal);
    this.updateShare(chAmount);

  }

  decreaseShare() {
    const amount = this.sharesValue;
    const chAmount = amount > this.shareDecimal ? Math.round((+amount - this.shareDecimal) * 100) / 100 : this.shareDecimal;
    this.updateShare(chAmount);
  }

  private updateShare(share) {
    this.controlShare.setValue(share + '%');
  }

  private listenChange() {
    const combined$ = combineLatest([
      this.controlShare.valueChanges,
      this.controlSlider.valueChanges
    ]).pipe(
      map((state) => {
        return state.map(item => this.removeSign(item))
      }),
      untilDestroyed(this)
    );

    combined$.subscribe(([shareInput, shareSlide]) => {
      let shareUpdate;
      if (shareInput !== this.lastUpdated.share) {
        shareUpdate = shareInput;
        this.controlSlider.setValue(shareInput, {emitEvent: false});
      }

      if (shareSlide !== this.lastUpdated.slider) {
        shareUpdate = shareSlide;
        this.controlShare.setValue(shareSlide + '%', {emitEvent: false});
      }

      this.onInputChange();
      if (shareUpdate) {
        const price = this.calculatePrice(shareUpdate);
        this.updatePrice(price);
      }
      this.setLimitChangeShare();
      this.setServiceData();
      this.lastUpdated = {share: shareInput, slider: shareSlide};
    });
  }

  private setLimitChangeShare() {
    this.calculationService.limitShare({
      max: +this.sharesValue === this.calculationService.getMaxAmount(),
      min: +this.sharesValue === this.shareDecimal
    });
  }

  private updatePrice(value) {
    const amount = this.getCurrencySign() + this.roundValue(value);
    this.formPrice.setValue(amount, {emitEvent: false});

  }

  private roundValue(value): number {
    return Math.round(value * 100) / 100
  }

  private setServiceData() {
    const share = this.sharesValue;
    const payAmount = this.wizardService.getSaleAndOriginAmount(+share, this.baseAmount);
    const originAmount = this.wizardService.getSaleAndOriginAmount(+share, this.priceForShare);
    const DATA: IInvestParam = {
      payAmount: this.roundValue(payAmount),
      originAmount: this.roundValue(originAmount),
      share: +share,
      project_slug: this.project.slug,
      salePercent: this.project.saleAmount
    };

    this.wizardService.setInvestDetail(DATA);
    this.selectedAmount.emit(DATA);
  }

  private calculatePrice(share) {
    return share * this.baseAmount;
  }

  private initForm() {
    this.form = this.fb.group({
      share: new FormControl('', {
        validators: [Validators.required]
      }),
      slider: new FormControl(''),
      price: new FormControl('', {
        validators: [Validators.required]
      }),
    })
  }
}

