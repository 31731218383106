  <form [formGroup]="form">
    <div class="title">Join</div>
    <div class="flex-align select-user">
      <div class="button border-blue" [class.isActive]="defaultFanUser" (click)="toggleUser(true)">I'm a fan</div>
      <div class="button border-blue" [class.isActive]="!defaultFanUser" (click)="toggleUser(false)">I'm an artist</div>
    </div>
    <mat-form-field class="item" *ngIf="!defaultFanUser">
      <mat-label>Your artist name</mat-label>
      <input matInput [formControl]="artistNameCtrl" id="artist_name"
             [errorStateMatcher]="errorMatcher(artistNameCtrl)">
    </mat-form-field>
    <mat-form-field class="item">
      <mat-label>First name</mat-label>
      <input matInput id="first_name" [formControl]="firstNameCtrl"
             [errorStateMatcher]="errorMatcherName(firstNameCtrl)">
      <mat-error *ngIf="!firstNameCtrl?.valid">First name should contain only letters
      </mat-error>
    </mat-form-field>
    <mat-form-field class="item">
      <mat-label>Last name</mat-label>
      <input matInput id="last_name" [formControl]="lastNameCtrl" [errorStateMatcher]="errorMatcherName(lastNameCtrl)">
      <mat-error *ngIf="!lastNameCtrl?.valid">Last name should contain only letters
      </mat-error>
    </mat-form-field>
    <mat-form-field class="item">
      <mat-label>Email</mat-label>
      <input matInput id="email" [formControl]="loginCtrl" [errorStateMatcher]="errorMatcher(loginCtrl)">
    </mat-form-field>
    <country-select 
      [countryControl]="countryCtrl" 
      [regionControl]="regionCtrl"
    ></country-select>
    <mat-form-field class="item" *ngIf="!fbData.id">
      <mat-label>Password</mat-label>
      <input matInput id="password" [formControl]="passwordCtrl" (blur)="checkPassword()"
             [errorStateMatcher]="errorMatcherPassword(passwordCtrl)" type="password">
      <mat-error *ngIf="!passwordCtrl?.valid && (notValidPassword || submitPressed)">
        Password is invalid
      </mat-error>
    </mat-form-field>
    <mat-form-field class="item" *ngIf="!fbData.id">
      <mat-label>Repeat password</mat-label>
      <input matInput [formControl]="repeatPasswordCtrl" id="r_password"
             [errorStateMatcher]="errorMatcher(repeatPasswordCtrl, true)"
             type="password">
      <mat-error *ngIf="repeatPasswordCtrl.value !== passwordCtrl.value ">Passwords do not match</mat-error>
    </mat-form-field>
    <div class="agreement">
      <div class="agreement-item flex-align">
        <app-checkbox [(value)]="getNewsletter"
                      [defaultValue]="true">
        </app-checkbox>
        <div class="title14 color-light-grey agreement-text">
          Sign me in to get <span class="font-bold">exclusive information about upcoming songs to invest in</span> and
          backstage insights about releases.
        </div>
      </div>
      <div class="agreement-item flex-align">
        <app-checkbox [(value)]="isRememberMe"
                      [defaultValue]="true"></app-checkbox>
        <div class="title14 color-light-grey agreement-text">
          I agree to the
          <a [routerLink]="['/api/terms-conditions']" target="_blank">Terms and Conditions</a>
          and
          <a [routerLink]="['/api/data-policy']" target="_blank">Privacy Policy.</a>
        </div>
      </div>

    </div>
    <button type="button" class="button yellow" (click)="registerUser()"
            [disabled]="(!form.valid && submitPressed) || (repeatPasswordCtrl.value !== passwordCtrl.value && submitPressed) || submitting || !isRememberMe">
      Join
    </button>
    <button type="button" class="button blue" (click)="fbLogin()" [disabled]="submitting || !isRememberMe">
      <i class="fab fa-facebook-f"></i> Join with facebook
    </button>
  </form>
