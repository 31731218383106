import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import {CustomFieldErrorMatcher} from '../../common/errorMatcher';
import {Dictionary} from 'app/models';

// Consts
import {ALPHA_NUMERIC, DAYS, MONTH, YEARS, ONLY_LETTERS_WHITESPACE} from '../../common';

// Services
import { DictionaryService } from 'app/services';
@Component({
  selector: 'app-ubo',
  templateUrl: './ubo.html',
  styleUrls: ['./ubo.scss']
})
export class UboComponent implements OnInit, OnChanges {
  @Input() countries: Dictionary[];
  @Input() inForm: any;
  @Input() isEdit: boolean;
  @Input() user: any;
  @Input() saveElement: boolean;
  @Output() saveUbo: EventEmitter<any> = new EventEmitter<any>();
  days = DAYS;
  month = MONTH;
  years = YEARS;
  today = new Date();
  maxDate: {
    year: any;
    month: any;
    day: any;
  };
  isDateValid: boolean;
  submitPressed: boolean;
  showRegion: string;
  form: FormGroup;
  phoneCtrl: FormControl;
  addressCtrl: FormControl;
  firstNameCtrl: FormControl;
  lastNameCtrl: FormControl;
  regionCtrl: FormControl;
  nationalityCtrl: FormControl;
  cityCtrl: FormControl;
  artistNameCtrl: FormControl;
  zipCodeCtrl: FormControl;
  countryCtrl: FormControl;
  gendersCtrl: FormControl;
  birthDateCtrl: FormControl;
  birthMonthCtrl: FormControl;
  birthYearCtrl: FormControl;
  birthCityCtrl: FormControl;
  birthCountryCtrl: FormControl;
  regions$: Observable<any> = this.dictionaryService.getRegions();

  constructor (
    private fb: FormBuilder,
    private dictionaryService: DictionaryService,
  ) {}

  ngOnInit() {
    this.initData();

    this.maxDate = {
      year: parseInt(moment(this.today).format('YYYY')),
      month: parseInt(moment(this.today).format('MM')),
      day: parseInt(moment(this.today).format('DD')),
    };
  }


  ngOnChanges(data) {
    if (data.saveElement && data.saveElement.currentValue) {
      this.submitPressed = true;
      this.saveUbo.emit({data: this.form});
      if (this.form.valid) {
        this.disableForm();
      }
    }
    if (data.inForm && data.inForm.currentValue && !this.submitPressed) {
      this.initData(data.inForm.currentValue);
    }
    if (data.user && data.user.currentValue) {
      this.checkValidity();
    }
    if (data.isEdit && data.isEdit.currentValue) {
      this.enableForm();
    }
  }

  initData(elem?) {
    this.addressCtrl = this.fb.control('', [Validators.required]);
    this.firstNameCtrl = this.fb.control('', [Validators.required]);
    this.lastNameCtrl = this.fb.control('', [Validators.required]);
    this.birthCityCtrl = this.fb.control('', [Validators.required, Validators.pattern(ONLY_LETTERS_WHITESPACE)]);
    this.birthCountryCtrl = this.fb.control('', [Validators.required]);
    this.cityCtrl = this.fb.control('', [Validators.required, Validators.pattern(ONLY_LETTERS_WHITESPACE)]);
    this.countryCtrl = this.fb.control('', [Validators.required]);
    this.nationalityCtrl = this.fb.control('', [Validators.required]);
    this.birthDateCtrl = this.fb.control('', [Validators.required]);
    this.birthYearCtrl = this.fb.control('', [Validators.required]);
    this.regionCtrl = this.fb.control('');
    this.birthMonthCtrl = this.fb.control('', [Validators.required]);
    this.zipCodeCtrl = this.fb.control('', [Validators.required, Validators.pattern(ALPHA_NUMERIC)]);
    this.form = this.fb.group({
      address: this.addressCtrl,
      zipCode: this.zipCodeCtrl,
      birthMonth: this.birthMonthCtrl,
      birthCity: this.birthCityCtrl,
      birthYear: this.birthYearCtrl,
      region: this.regionCtrl,
      birthDay: this.birthDateCtrl,
      birthCountry: this.birthCountryCtrl,
      lastName: this.lastNameCtrl,
      firstName: this.firstNameCtrl,
      nationality: this.nationalityCtrl,
      country: this.countryCtrl,
      city: this.cityCtrl
    });
    if (elem) {
      setTimeout(() => {
        this.prepareForm(elem);
      }, 4);

    }
  }

  onCountryChange(e) {
    this.showRegion = ['CA', 'US'].indexOf(e) >= 0 ? e : null;
    if (this.showRegion) {
      this.regionCtrl.enable();
      this.regionCtrl.setValidators(this.showRegion ? [Validators.required] : null);
      this.regionCtrl.updateValueAndValidity();
    } else {
      this.regionCtrl.disable();
    }
  }

  disableForm() {
    this.addressCtrl.disable();
    this.firstNameCtrl.disable();
    this.lastNameCtrl.disable();
    this.cityCtrl.disable();
    this.countryCtrl.disable();
    this.nationalityCtrl.disable();
    this.birthCityCtrl.disable();
    this.birthCountryCtrl.disable();
    this.zipCodeCtrl.disable();
    this.regionCtrl.disable();
    this.birthDateCtrl.disable();
    this.birthMonthCtrl.disable();
    this.birthYearCtrl.disable();
    this.form.updateValueAndValidity();
  }

  enableForm() {
    this.addressCtrl.enable();
    this.firstNameCtrl.enable();
    this.lastNameCtrl.enable();
    this.cityCtrl.enable();
    this.countryCtrl.enable();
    this.nationalityCtrl.enable();
    this.birthCityCtrl.enable();
    this.birthCountryCtrl.enable();
    this.zipCodeCtrl.enable();
    this.regionCtrl.enable();
    this.birthDateCtrl.enable();
    this.birthMonthCtrl.enable();
    this.birthYearCtrl.enable();
    this.form.updateValueAndValidity();
  }

  prepareForm(elem) {
    this.addressCtrl.setValue(elem.billingAddress);
    this.firstNameCtrl.setValue(elem.firstName ? elem.firstName : null);
    this.lastNameCtrl.setValue(elem.lastName ? elem.lastName : null);
    this.cityCtrl.setValue(elem.city ? elem.city : null);
    this.countryCtrl.setValue(elem.country ? elem.country : null);
    this.nationalityCtrl.setValue(elem.nationality ? elem.nationality : null);
    this.birthCityCtrl.setValue(elem.birthCity ? elem.birthCity : null);
    this.birthCountryCtrl.setValue(elem.birthCountry ? elem.birthCountry : null);
    this.zipCodeCtrl.setValue(elem.postalCode ? elem.postalCode : null);
    this.regionCtrl.setValue(elem.region ? elem.region : null);
    let birthdate: any = elem.birthdate;
    if (birthdate) {
      birthdate = birthdate.split('/').map(i => Number(i));
    }

    this.birthDateCtrl.setValue(birthdate ? birthdate[2] : null);
    this.birthMonthCtrl.setValue(birthdate ? birthdate[1] : null);
    this.birthYearCtrl.setValue(birthdate ? birthdate[0] : null);
    this.checkValidity(elem);

    this.checkCountry(elem);
  }

  checkValidity(data?) {
    if (this.user && this.user.statusUBO && data && Object.keys(data).length) {
      this.disableForm();
    }
  }

  checkCountry(value) {
    this.showRegion = ['CA', 'US'].indexOf(value.country) >= 0 ? value.country : null;
    if (this.showRegion) {
      this.regionCtrl.setValidators([Validators.required]);
      this.regionCtrl.updateValueAndValidity();
    } else {
      this.regionCtrl.disable();
    }
  }

  errorMatcher(control: FormControl, dateCheck?: boolean) {
    let error;
    const day = this.birthDateCtrl.value;
    const month = this.birthMonthCtrl.value;
    const year = this.birthYearCtrl.value;
    let showError = this.submitPressed;
    if (day > this.maxDate.day && month === this.maxDate.month && year === this.maxDate.year) {
      this.birthDateCtrl.setErrors({incorrect: true});
      this.isDateValid = false;
    }
    else if (month > this.maxDate.month && year === this.maxDate.year) {
      this.birthMonthCtrl.setErrors({incorrect: true});
      this.isDateValid = false;
    }
    if (dateCheck && day && month && year) {
      error = !moment(`${month}/${day}/${year}`, 'L').isValid();
      showError = true;
      this.isDateValid = !error;
    }
    return new CustomFieldErrorMatcher(control, showError, error);
  }
}
