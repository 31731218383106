<div class="inner-card" #myDiv>
  <div
    class="card"
    [class.flipped]="toggleProperty"
    #myCard
    id="{{ this.index + 'card' }}"
  >
    <div class="front" #frontCard id="{{ index }}">
      <div class="card-image-wrapper">
        <img
          class="card__background"
          loading="lazy"
          alt="project image {{ item?.projectTitle }}"
          src="{{ item?.backgroundImage }}"
        />
      </div>
      <a class="default-link link-block card-outer collectible">
        <div class="card__header">
          <p class="card__header-col year">{{ currentDate | date : "yyyy" }}</p>
          <p class="card__header-col card__title">
            <img
              class="card__logo-title"
              loading="lazy"
              title="GlobalRockstar logo"
              alt="GlobalRockstar logo"
              src="./assets/images/logo/small-logo.png"
            />
          </p>
          <div class="card__header-col card__logo">
            <app-tooltip
              class="tooltip-component"
              [img]="'./assets/images/cards/diamond.svg'"
            >
              <div img-content>
                <img loading="lazy"
                     alt="logo tooltip" [src]="'./assets/images/cards/diamond.svg'">
              </div>
              <div text-content>
                Collectibles are unreleased and never to be released recordings by
                superstars or established artists. Owners have the exclusive right
                to play the recording in full-length from their Global Rockstar
                dashboard, whenever they want and will be able to trade the
                collectibles on the Global Rockstar marketplace or on other
                platforms in the near future.
              </div>

            </app-tooltip>
          </div>
        </div>

        <div class="card__content">
          <div class="play-button" (click)="playVideo(item)">
            <i class="fas fa-play"></i>
          </div>
          <ng-template [ngIf]="showArrows && item?.internalNumbers?.length > 1">
            <div
              *ngIf="item?.internalNumbers?.length"
              class="chvr-left"
              (click)="changeEditionNumber(editionIndex)"
            ></div>
            <div
              *ngIf="item?.internalNumbers?.length"
              class="chvr-right"
              (click)="changeEditionNumber(editionIndex)"
            ></div>
          </ng-template>
        </div>
        <div class="card__name">
          <div>
            <h2 class="card__title-h2 card__name--padding">
              {{ item?.artist?.name || item?.artist }}
            </h2>
            <h3 class="card__title-h3 card__name--padding">
              {{ item?.song?.name || item?.song }}
            </h3>
            <p class="card__description">{{ item?.cardDescription }}</p>
          </div>
        </div>
        <div class="card__footer">
          <span class="artist-collection-text"
            >The {{ item?.artist?.name || item?.artist }} Music Asset
            Collection</span
          >
          <div
            class="price__per-share button card__end"
            [style.backgroundColor]="item?.cardColor"
          >
            #{{ item?.editionNumber?.internalNumber }}/{{ item?.edition }}
          </div>
        </div>
      </a>
    </div>
    <div
      class="back"
      (mouseenter)="showPause = true"
      (mouseleave)="showPause = false"
    >
      <div class="card__content">
        <div
          class="play-button"
          *ngIf="showPause && currentPlayingVideo?.buffered"
          (click)="pauseVideo()"
        >
          <i class="fas fa-stop"></i>
        </div>
      </div>
      <video
        class="video"
        (ended)="pauseVideo($event)"
        (playing)="onPlayingVideo($event, item)"
        (play)="onPlayingVideo($event, item)"
        preload="auto"
        #myVideo
        id="{{ item?.id + 'video' }}"
        width="310px"
        height="473px"
      >
        <source [src]="item?.video" type="video/mp4" />
        <source [src]="item?.video" type="video/webm" />
      </video>
    </div>
  </div>
</div>
