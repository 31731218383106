<div class="popup-wrapper">
  <div class="inner">
    <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
    <div class="content-popup">
      <img
        class="logo"
        loading="lazy"
        title="GlobalRockstar logo"
        alt="GlobalRockstar logo"
        src="./assets/images/logo/grs-tm-white.png"
      />
      <p class="divider"></p>
      <p class="text"><span>SOMETHING</span><br> <span class="text-middle">BIG</span><br><span>IS COMING</span></p>
      <p class="divider bottom"></p>
      <button class="button yellow" (click)="redirectURL()">{{settings.title}}</button>
    </div>

  </div>

</div>




