import * as moment from 'moment';

export class BidModelList {
  bids: BidModel[];
}

export class BidModel {
  bidDate: string;
  bidTime: string;
  bidDateMonth: string;
  amount: number;
  id: number;
  projectNotStarted?: boolean;
  auctionId: number;
  daysLeft?: number;
  finished?: boolean;
  slug?: string;
  daysBefore?: number;
  collectionName?: string;
  isCollectible?: boolean;
  endDate: string;
  isWinning: boolean;
  song?: string;
  auctionMarket: string;
  isInitialOffering: boolean;
  winningEdition: number;

  constructor() {

  }

  setFromApiModel(apiModel: any, winning: boolean): this {

    const localTime = moment().utc(true);
    const utcTime = moment().utc(false);
    const diff = Math.ceil(localTime.diff(utcTime) / (1000 * 60 * 60));
    this.bidDate = moment(apiModel.BidDateTime).format('DD.MM.YYYY');
    this.bidDateMonth = moment(apiModel.BidDateTime).format('DD/MM');
    this.bidTime = moment(apiModel.BidDateTime).add(diff, 'hour').format('HH:mm');
    this.amount = apiModel.BidValue;
    this.daysLeft = apiModel.daysLeft;
    this.auctionMarket = apiModel.auctionMarket;
    this.finished = apiModel.finished;
    this.slug = apiModel.slug;
    this.isCollectible = apiModel.isCollectible;
    this.isInitialOffering = apiModel.IsInitialOffering;
    this.collectionName = apiModel.collectionName;
    this.winningEdition = apiModel.collectibleEdition ? apiModel.collectibleEdition.internalNumber : apiModel.WinningTokenPriority;
    this.isWinning = winning;
    this.endDate = apiModel.endDate;
    this.song = apiModel.song;
    this.auctionId = apiModel.AuctionId;
    this.id = apiModel.Id || apiModel.BidId;
    return this;
  }
}
