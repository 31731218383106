import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserModel} from '../../models';
import {AppToastrService, PledgeService} from '../../services';
import {ActivatedRoute, Router} from '@angular/router';


@Component({
  selector: 'app-transfer-money',
  templateUrl: './transfer-money.html'
})
export class TransferMoneyComponent {
  @Input() user: UserModel;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  showPopup = false;

  constructor(private pledgeService: PledgeService,
              private _activatedRoute: ActivatedRoute,
              private router: Router,
              private toastr: AppToastrService) {

    const snapshot = this._activatedRoute.snapshot;
    const params = {...snapshot.queryParams};
    if (params && params.deposit) {
      this.toastr.showToast({title: 'Payment success, transaction in process'});
    }
    delete params.deposit;
    this.router.navigate([], {queryParams: params});
  }

  depositMoney(): void {
    this.showPopup = true;
  }

  onNotify(e) {
    if (e.type === 'submit') {
      this.procceedPayment(e.data);
    }
    if (e.type === 'closePopup') {
      this.showPopup = false;
    }
  }

  successMessageDisplay(data): void {
    this.toastr.showToast({title: 'Payment success'});
   // this.notify.emit({type: 'deposit', data: data.amount});
  }

  procceedPayment(data): void {
    this.pledgeService.paymentToAccount(data).subscribe(resp => {
      if (resp && resp.url && !data.paymentData) {
        location.replace(resp.url);
      }
      if (data.paymentData) {
        this.successMessageDisplay(data);
      }
      this.showPopup = false;
    }, (err) => {
      this.toastr.showToastFromError(err);
      this.showPopup = false;
    });
  }
}
