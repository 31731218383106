import {STATE_NAMES} from "@app/common";


export const USA_REDIRECT_POPUP: any = {
  LOGGED_IN: {
    title: 'KEEP ME POSTED',
    link: STATE_NAMES.opportunity
  },
  NON_LOGGED_IN: {
    title: 'JOIN NOW',
    link: STATE_NAMES.register
  },
};

