<mat-form-field class="item">
  <mat-label>{{ label }}</mat-label>
  <input
    type="text"
    placeholder="Search country"
    aria-label="Country"
    matInput
    [formControl]="countryControl"
    [matAutocomplete]="countryAutoComplete"
  />
  <mat-autocomplete #countryAutoComplete="matAutocomplete">
    <mat-option
      *ngFor="let country of filteredCountries$ | async"
      [value]="country"
      >{{ country }}</mat-option
    >
  </mat-autocomplete>
  <mat-error *ngIf="countryControl?.errors?.required"
    >Country is required</mat-error
  >
  <mat-error *ngIf="countryControl?.errors?.match"
    >Please select a correct country</mat-error
  >
</mat-form-field>
<mat-form-field *ngIf="showRegion && regionCountryCode && regions" class="item">
  <mat-label>Region</mat-label>
  <mat-select [formControl]="regionControl">
    <mat-option
      *ngFor="let item of regions[regionCountryCode]"
      [value]="item.id"
      >{{ item.name }}</mat-option
    >
  </mat-select>
  <mat-error *ngIf="regionControl?.errors?.required"
    >Region is required</mat-error
  >
</mat-form-field>
