import {Component, OnInit} from '@angular/core';
import {AppToastrService} from 'app/services';
import {STATE_NAMES} from 'app/common';
import {Router} from '@angular/router';
import {ProjectsService} from '../../services/projects.service';
import {StorageService} from '../../services/storage.service';

@Component({
  selector: 'app-my-projects',
  templateUrl: './my-projects..html',
  styleUrls: ['./_my-projects.scss']
})

export class MyProjectsComponent implements OnInit {
  projectList: any[] = [];
  originLength: number;
  user: any;
  loaded: boolean;
  count: {
    active: number,
    funded: number
  };
  myProjects: any;
  activeTab: number;

  constructor(private projectService: ProjectsService,
              private toastr: AppToastrService,
              private router: Router,

              private storageService: StorageService) {
  }

  ngOnInit() {
    this.user = this.storageService.user;
    if (this.user && this.user.artist) {
      this.initData();
    } else {

      this.router.navigate( [`/${STATE_NAMES.home}`]);
    }
  }

  initData() {
    this.getProjects();
    this.activeTab = 3;
  }

  setState(state: number): void {
    this.activeTab = state;
    this.projectList = [];
    if (this.activeTab === 2) {
      this.getUpcomingProject();
    } else if (this.activeTab === 3) {
      this.projectList = this.myProjects.active;
      this.originLength =  this.projectList.length;
    } else if (this.activeTab === 4) {
      this.projectList = this.myProjects.funded;
      this.originLength = this.projectList.length;
    } else {
      this.getProjects();
    }
    this.addEmpty();
  }

  getUpcomingProject() {
    this.loaded = false;
    this.projectService.getUpcomingProject().subscribe(resp => {
      this.projectList = resp;
      this.originLength = resp.length;
      this.loaded = true;
      [1, 2, 3].forEach(item => {
        this.projectList.push({isEmptyItem: true});
      });
    }, err => {
      this.loaded = true;
      this.toastr.showToastFromError(err);
    });
  }

  addEmpty() {
    if (this.projectList && this.projectList.length) {
      [1, 2, 3].forEach(item => {
        this.projectList.push({isEmptyItem: true});
      });
    }
  }

  getProjects() {
    this.loaded = false;
    this.projectService.getArtistProjects().subscribe(resp => {
      if (resp) {
        this.projectList = resp.opportunity || [];
        this.loaded = true;
        this.count = resp.count;
        this.myProjects = JSON.parse(JSON.stringify(resp));
        if (this.activeTab === 3) {
          this.projectList = this.myProjects.active;
        }
        this.originLength = this.projectList.length;
        this.addEmpty();
      }
    }, err => {
      this.loaded = true;
      this.toastr.showToastFromError(err);
      console.error(err);
    });
  }
}
