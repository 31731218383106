import {ComponentPortal} from '@angular/cdk/portal';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

// Types
import {Collection} from '@app/types';
import {Overlay, OverlayRef} from "@angular/cdk/overlay";

@Injectable({providedIn: 'root'})
export class ModalService {
  private overlayRef: OverlayRef;
  private modal: Subject<any> = new Subject();
  private shownModals: BehaviorSubject<Collection<boolean>> = new BehaviorSubject({})

  constructor(private overlay: Overlay) {
    this.overlayRef = this.overlay.create();
  }

  modal$: Observable<any> = this.modal.asObservable();

  open(component: any, value?: any): void {
    let portal: ComponentPortal<any> = new ComponentPortal(component);

    const componentRef = this.overlayRef.attach(portal);
    componentRef.instance.inputValue = value;
    this.modal.next(portal);
    this.emitModalShowAction(component.name);
  }

  close(): void {
    this.overlayRef.detach();
    this.modal.next(null);
  }

  emitModalShowAction(componentName: string): void {
    const oldValue: Collection<boolean> = this.shownModals.getValue();

    this.shownModals.next({...oldValue, [componentName]: true});
  }

  isModalShown(componentName: string): boolean {
    return !!this.shownModals.getValue()[componentName];
  }

}
