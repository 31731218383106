import {UrlService, TransitionService} from '@uirouter/angular';
import {Injectable} from '@angular/core';
import {STATE_NAMES} from 'app/common/state-names';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Observable} from 'rxjs';

// import {GlobalObjectService} from "./global-object.service";

@Injectable()
export class RoutingService {
  private previousUrl: string;
  private currentUrl: string;
  private nextUrl: string;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
      if (event instanceof NavigationStart) {
          this.nextUrl = event.url;
      }
    });
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getNextUrl() {
    return this.nextUrl;
  }
  // get params(): any {
  //   return this.stateService.params;
  // }
  //
  // go(to: StateOrName, rawParams?: RawParams, reload = false) {
  //   this.stateService.go(to, rawParams, {reload});
  // }
  //
  // goToLoginPage() {
  //   if (this.stateService.current.name !== STATE_NAMES.login) {
  //     this.stateService.go(STATE_NAMES.login);
  //   }
  // }

  openNewTab(linkTo, projectSlug?: string): void {
    let w;
    if (linkTo === 'home') {
      w = location.href;
    } else if (linkTo === 'opportunity') {
      w = location.href;
    } else {
      const to = linkTo ? linkTo : `${projectSlug}`;
      w = location.href + to;
    }
    window.open(w);
  }

  // queryString(key: string): string {
  //   return this.urlService.parts().search[key];
  // }
}
