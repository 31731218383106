import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SettingService} from "@services/setting.service";
import {PriceForShare} from "@app/types";
import {StorageService} from "@services/storage.service";
import {IInvestParam} from "@app/interfaces/payment.interface";
import * as moment from "moment/moment";
import {Moment} from "moment/moment";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  selectedShare: BehaviorSubject<IInvestParam> = new BehaviorSubject(null);
  $selectedShareObservable = this.selectedShare.asObservable();
  localeCurrencyCode: string = this.settingsService.curency;

  constructor(private settingsService: SettingService, private storageService: StorageService) {
  }

  getSaleAndOriginAmount(percentAmount, share): number {
    return share * percentAmount;
  }

  priceForShare(priceForShare: PriceForShare): number {
    return priceForShare[this.localeCurrencyCode.toLowerCase()];
  }

  disallowKycUploadDocument(endDate: Moment){
    const now = moment();
    const hoursDifference = endDate.diff(now, 'hours');
    return hoursDifference >= environment.allowUserSkipKycHours;
  }

  removeInvestDetail(){
    this.storageService.setStorageItem('investment', null)
  }

  setInvestDetail(value: IInvestParam) {
    if (value) {
      this.storageService.investmentDetail = value;
    }

    this.selectedShare.next(value);
  }

  getInvestDetail(): IInvestParam {
    return this.selectedShare.getValue();
  }
}
