import {Component, Input, Output, EventEmitter, OnInit, HostListener} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AppToastrService} from 'app/services';
import {EMAIL_PATTERN} from 'app/common';
import {CustomFieldErrorMatcher} from '../../../common/errorMatcher';
import {AuthenticationService} from '../../../services/authentication.service';

@Component({
  selector: 'app-subscribe-popup',
  templateUrl: './subscribe.html'
})
export class SubscribeComponent implements OnInit {
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();
  nameCtrl: FormControl;
  emailCtrl: FormControl;
  form: FormGroup;
  submitPressed: boolean;
  submitting: boolean;

  @HostListener('window:keyup', ['$event']) onKey(event) {
    if (event.keyCode === 27) {
      this.onClose();
    }
  }


  constructor(private toast: AppToastrService,
              private authenticationService: AuthenticationService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.prepareForm();
  }

  private prepareForm() {
    this.nameCtrl = this.fb.control('', [Validators.required]);
    this.emailCtrl = this.fb.control('', [Validators.required, Validators.pattern(EMAIL_PATTERN)]);
    this.form = this.fb.group({
      firstName: this.nameCtrl,
      email: this.emailCtrl
    });
  }

  onClose() {
    this.notify.emit({type: 'close'});
  }

  onSubmit() {
    this.submitPressed = true;
    if (this.form.valid && !this.submitting) {
      this.submitting = true;
      const data = this.form.value;
      this.authenticationService.subscribeVisitor(data).subscribe((res) => {
        this.submitting = false;
      }, (err) => {
        this.submitting = false;
      });
      this.toast.showToast({title: 'You have been subscribed to our newsletter'});
      this.notify.emit({type: 'close'});
    }
  }

  errorMatcher(control: FormControl) {
    return new CustomFieldErrorMatcher(control, this.submitPressed);
  }
}
