import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainSliderComponent {
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: '',
    prevArrow: '',
  };
  slide: any;
  user: any;
  @Input() sliders: any;
  @Input() type: any;

  constructor() {
  }

  checkUrlExist($event: MouseEvent, url: string|null): void {
    if(!url) {
      $event.preventDefault();
    }
  }

  slickInit(e) {
  }

  breakpoint(e) {
    // console.log('breakpoint');
  }

  _afterChange(e) {
    // console.log('afterChange');
  }

  _beforeChange(e) {
    // console.log('beforeChange');
  }

  trackByFn(index, item) {
    return item.id;
  }
}
