<app-popup>
  <div class="inner">
    <div>
      <span class="lnr lnr-cross default-cross" (click)="onClose()"></span>
      <div class="title">Add bank account</div>
    </div>
    <bank-account-form
      class="popup__form"
      [country]="country"
      [account]="account"
      styleType="vertical"
      (onSuccess)="onSubmit($event)"
      (onCancel)="onClose()"
    ></bank-account-form>
    <!-- <div class="buttons-wrapper">
      <button class="button border-blue text-uppercase" (click)="onClose()">
        Cancel
      </button>
      <button
        class="button yellow text-uppercase"
        (click)="onSubmit()"
      >
        <ng-template ngIf [ngIf]="!account.bankId">Add</ng-template>
      </button>
    </div> -->
  </div>
</app-popup>
