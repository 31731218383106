<div class="m-sort-tab" [class.active]="sortOpen" *ngIf="sortOpen">
  <div class="sort-tab__header text-uppercase">
    Sort By <span class="picture-cross" (click)="closeMenu()">
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59
14L14 12.59L8.41 7L14 1.41Z" fill="#5AA2E5"/>
</svg>
</span>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('desc', SORT_TYPE_MOBILE.REMAINING_SHARES)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div class="sort-icon  sort-icon--share-enable "
             *ngIf="sortBy === SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow === 'desc'">
        </div>
        <div  class="sort-icon sort-icon--share-disable "
          *ngIf="(sortBy === SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow === 'asc')
           || !filter?.sorting?.sortHow || (sortBy !== SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.REMAINING_SHARES === sortBy">
          LEAST SHARES LEFT</span>
      </div>
      <div class="col-50 item-s">
          <span *ngIf="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.REMAINING_SHARES === sortBy">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.79496 10.875L1.62496 6.70504L0.204956 8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z"
                            fill="#5AA2E5"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('asc', SORT_TYPE_MOBILE.REMAINING_SHARES)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div  class="sort-icon sort-icon--share-enable"
              *ngIf="sortBy === SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow === 'asc'">
        </div>
        <div  class="sort-icon sort-icon--share-disable "
              *ngIf="(sortBy === SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow === 'desc') ||
           !filter?.sorting?.sortHow || (sortBy !== SORT_TYPE_MOBILE.REMAINING_SHARES && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter?.sorting?.sortHow === 'asc' && SORT_TYPE_MOBILE.REMAINING_SHARES === sortBy">
          MOST SHARES LEFT</span>
      </div>
      <div class="col-50 item-s">
          <span *ngIf="filter?.sorting?.sortHow === 'asc' && SORT_TYPE_MOBILE.REMAINING_SHARES === sortBy">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.79496 10.875L1.62496 6.70504L0.204956 8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z"
                            fill="#5AA2E5"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('desc', SORT_TYPE_MOBILE.ENDING_SOON)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div class="sort-icon  sort-icon--end-soonest-disable" *ngIf="sortBy === SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow === 'desc'">
        </div>
        <div class = "sort-icon sort-icon--end-soonest-active"
          *ngIf="(sortBy === SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow === 'asc') ||
          !filter?.sorting?.sortHow || (sortBy !== SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.ENDING_SOON === sortBy">
          ENDING SOONEST</span>
      </div>
      <div class="col-50 item-s">
          <span *ngIf="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.ENDING_SOON === sortBy">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.79496 10.875L1.62496 6.70504L0.204956 8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z"
                            fill="#5AA2E5"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('asc', SORT_TYPE_MOBILE.ENDING_SOON)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div class = "sort-icon sort-icon--end-soonest-disable"
             *ngIf="sortBy === SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow === 'asc'">
        </div>
        <div class = "sort-icon sort-icon--end-soonest-active"
          *ngIf="(sortBy === SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow === 'desc')
           || !filter?.sorting?.sortHow || (sortBy !== SORT_TYPE_MOBILE.ENDING_SOON && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter?.sorting?.sortHow === 'asc' && SORT_TYPE_MOBILE.ENDING_SOON === sortBy">
          ENDING LATEST</span>
      </div>
      <div class="col-50 item-s">
          <span *ngIf="filter?.sorting?.sortHow === 'asc' && SORT_TYPE_MOBILE.ENDING_SOON === sortBy">
            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M5.79496 10.875L1.62496 6.70504L0.204956 8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z"
                            fill="#5AA2E5"/>
            </svg>
          </span>
      </div>
    </div>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('desc', SORT_TYPE_MOBILE.DATE_PUBLISHED)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div class="sort-icon  sort-icon--newest-disable"
          *ngIf="sortBy === SORT_TYPE_MOBILE.DATE_PUBLISHED && filter?.sorting?.sortHow === 'desc'">
        </div>
        <div class = "sort-icon sort-icon--newest-enable"
          *ngIf="(sortBy === SORT_TYPE_MOBILE.DATE_PUBLISHED && filter?.sorting?.sortHow === 'asc')
           || !filter?.sorting?.sortHow || (sortBy !== 1 && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.DATE_PUBLISHED === sortBy">
          Newest first</span>
      </div>
      <div class="col-50 item-s">
<span *ngIf="filter?.sorting?.sortHow === 'desc' && SORT_TYPE_MOBILE.DATE_PUBLISHED === sortBy">
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.79496 10.875L1.62496 6.70504L0.204956 8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z"
        fill="#5AA2E5"/>
</svg>
</span>
      </div>
    </div>
  </div>
  <div class="sort-tab__item-s text-uppercase" (click)="sortList('asc', SORT_TYPE_MOBILE.DATE_PUBLISHED)">
    <div class="wrapper">
      <div class="col-50 item-s">
        <div class="sort-icon sort-icon--newest-disable"
          *ngIf="sortBy === SORT_TYPE_MOBILE.DATE_PUBLISHED && filter?.sorting?.sortHow === 'asc'">
        </div>
        <div class = "sort-icon sort-icon--newest-enable "
          *ngIf="(sortBy === SORT_TYPE_MOBILE.DATE_PUBLISHED && filter?.sorting?.sortHow === 'desc')
           || !filter?.sorting?.sortHow || (sortBy !== SORT_TYPE_MOBILE.DATE_PUBLISHED && filter?.sorting?.sortHow)">
        </div>
        <span class="item-s__title"
              [class.active]="filter.sorting.sortHow === 'asc' && SORT_TYPE_MOBILE.DATE_PUBLISHED === sortBy">Oldest first</span>
      </div>
      <div class="col-50 item-s">
<span *ngIf="filter?.sorting?.sortHow === 'asc' && SORT_TYPE_MOBILE.DATE_PUBLISHED === sortBy">
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.79496 10.875L1.62496 6.70504L0.204956
8.11504L5.79496 13.705L17.795 1.70504L16.385 0.295044L5.79496 10.875Z" fill="#5AA2E5"/>
</svg>
</span>
      </div>
    </div>
  </div>
</div>
