import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ONLY_NUM_LETTER_HYPEN} from '../../../common';
import {AppToastrService, ProfileService} from '../../../services';
import {TokenNFTmodel} from '../../../models/tokenNFT.model';
import {StorageService} from '../../../services/storage.service';

@Component({
  selector: 'app-nickname-popup',
  templateUrl: './nickname-popup.html'
})


export class NicknamePopupComponent implements OnInit {
  nicknameCtrl: FormControl;
  form: FormGroup;
  duplicate: boolean;
  submitBtn: boolean;
  @Input() item: TokenNFTmodel;
  @Output() notify: EventEmitter<any> = new EventEmitter<any>();

  constructor(private fb: FormBuilder,
              private profileService: ProfileService,
              private storageService: StorageService,
              private toast: AppToastrService) {
  }

  ngOnInit(): void {
    this.nicknameCtrl = this.fb.control('', [Validators.required, Validators.pattern(ONLY_NUM_LETTER_HYPEN)]);
    this.form = this.fb.group({
      nickname: this.nicknameCtrl
    });
  }

  onClose(): void {
    this.notify.emit({type: 'closePopup'});
  }

  changeValue() {
    this.duplicate = false;
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.submitBtn = true;
      this.profileService.checkValidNickName(this.nicknameCtrl.value).subscribe(resp => {
        this.submitBtn = false;
        if (resp && resp.duplicate) {
          this.duplicate = true;
        } else {
          this.storageService.nickName = true;
          this.toast.showToast({title: 'Nickname is saved'});
          this.notify.emit({type: 'closePopup'});
        }
      }, err => {
        this.submitBtn = false;
        this.toast.showErrorToast(err);
      });
    }

  }
}
