import {
  Component,
  Input, ChangeDetectionStrategy
} from '@angular/core';


@Component({
  selector: 'app-artist-social-links',
  templateUrl: './artist-social-links.html',
  styleUrls: ['./artist-social-links.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArtistSocialLinksComponent {
  @Input() project;

  constructor() {
  }
}
