import {
  Component,
  Input, ChangeDetectionStrategy, Inject, PLATFORM_ID
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {isPlatformBrowser} from "@angular/common";
import {IFRAME_LINK} from "@app/pages/project/constant/constants";
import {IframeUrlGeneratorService} from "@app/pages/project/service/iframe-generator-url.service";
import {iFrameLink} from "@app/pages/project/type/iframe-link";
import {ProjectsModel} from "@app/models";

@Component({
  selector: 'app-project-social-iframe',
  templateUrl: './project-social-iframe.html',
  styleUrls: ['./project-social-iframe.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSocialIframeComponent {
  iFrameLinkEnum = IFRAME_LINK;
  @Input() project: ProjectsModel;

  constructor(private iframeUrlGeneratorService: IframeUrlGeneratorService,
              private sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: any) {
  }
  description() {
    if (this.project.sLink && this.project.sLink.content) {
      return this.sanitizer.bypassSecurityTrustHtml(this.project.sLink.content);
    }
    return ''
  }

  getId(linkType): string{
    const instagramObject = this.project.sLink.link.find(obj => linkType in obj);
    return instagramObject ? instagramObject[linkType] : null;
  }

  generateIframeUrl(linkType: iFrameLink): SafeResourceUrl {
    if (this.project && this.project.sLink && this.project.sLink.link) {
      const id = this.getId(linkType);
      return this.iframeUrlGeneratorService.generateIframeUrl(linkType, id);
    }
    return '';
  }

  isSpotifyLink(): boolean {
    return this.project.sLink.link.some(obj => Object.keys(obj).includes('spotify'));
  }

  isYoutubeLink(): boolean {
    return this.project.sLink.link.some(obj => Object.keys(obj).includes('youtube'));
  }

  isSoundcloudLink(): boolean {
    return this.project.sLink.link.some(obj => Object.keys(obj).includes('soundcloud'));
  }

  isInstagramLink(): boolean {
    return this.project.sLink.link.some(obj => Object.keys(obj).includes('instagram'));
  }
  initLoad() {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }
    return true
  }
}
